// roles
const SCOUT = 'scout';
const CLIENT = 'client';
const CLIENT_EMPLOYEE = 'clientEmployee';
const OWNER = 'owner';
const SUPER_ADMIN = 'superAdmin';

export {
  SCOUT,
  CLIENT,
  CLIENT_EMPLOYEE,
  OWNER,
  SUPER_ADMIN,
};

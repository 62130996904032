/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  TextField, Grid, List, ListItem, ListItemText, ListItemIcon,
  Typography, IconButton, FormControlLabel, Checkbox, Switch, Box,
  Button,
} from '@material-ui/core';

// icons
import ClearIcon from '@material-ui/icons/Clear';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { VIEW_MODE, EDIT_MODE } from '../../../utils/const/surveyModes';

import useStyles from '../styles';
import Modal from '../../Modal';
import Snackbar from '../../Snackbar';

function ImageSettingsModal({
  requiresTeleaudit, setRequiresTeleaudit, kpiDetails,
  setKpiDetails, setOpenSettings, openSettings, questionImageType,
  shareOfShelfType, setShareOfShelfType, target, setTarget, handleKpiSave,
  mode,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [currentKpi, setCurrentKpi] = useState({
    title: '',
    description: '',
  });
  const [currentStep, setCurrentStep] = useState(1);

  const handleTextChange = (event) => {
    if (event.target.id === 'kpi-title') {
      setCurrentKpi({
        ...currentKpi,
        title: event.target.value,
      });
    } else {
      setCurrentKpi({
        ...currentKpi,
        description: event.target.value,
      });
    }
  };

  const handleSaveKpiDetail = () => {
    if (currentKpi.title === '') {
      setError(t('survey.surveyForm.kpisModal.titleErr'));
      return;
    }
    if (currentKpi.description === '') {
      setError(t('survey.surveyForm.kpisModal.titleErr'));
      return;
    }
    let generatedId;
    if (kpiDetails.length < 1) {
      generatedId = 1;
    } else {
      // eslint-disable-next-line prefer-spread
      generatedId = Math.max.apply(Math, kpiDetails.map((el) => el.id)) + 1;
    }
    const newKpi = {
      id: generatedId,
      title: currentKpi.title,
      description: currentKpi.description,
      teleauditType: 'binary',
    };
    setKpiDetails([...kpiDetails, newKpi]);
    setCurrentKpi({
      title: '',
      description: '',
    });
  };

  const deleteItem = (value) => {
    if (mode === EDIT_MODE) {
      const kpiDetailConst = kpiDetails.filter((item) => item.id !== value);
      setKpiDetails(kpiDetailConst);
    }
  };

  const handleNextStep = () => {
    if (target < 0 || target > 100) return;
    if (questionImageType === 'multiple' && currentStep === 1 && requiresTeleaudit) {
      if (kpiDetails.length === 0) {
        setError(t('survey.surveyForm.kpisModal.missingKpiErr'));
        return;
      }
      setCurrentStep(currentStep + 1);
    } else {
      if (mode === EDIT_MODE) {
        handleKpiSave();
      }
      setCurrentStep(1);
      setOpenSettings(false);
    }
  };

  const handleShareOfShelfSwitch = () => {
    if (shareOfShelfType === 'none') {
      setShareOfShelfType('linear-share');
    } else {
      setShareOfShelfType('none');
    }
  };

  const isValidNumber = (value) => /^[0-9]*$/.test(value) || /^[0-9]+\.[0-9]+$/.test(value);

  const handleTargetChange = (value) => {
    if (isValidNumber(value)) {
      setTarget(value);
    }
  };

  return (
    <>
      <Modal
        title={t('survey.surveyForm.kpisModal.modalTitle')}
        defaultOpen={openSettings}
        setCurrentOpen={setOpenSettings}
      >
      <div className={styles.modalForm}>
        { currentStep === 1
          && (
            <>
              <Grid container style={{ paddingTop: '3%' }}>
                <Grid item xs={3}></Grid>
                <Grid item xs={5}>
                  <div align='center'>
                    <Typography variant='h4' className={styles.firstStepText}>
                      {t('survey.surveyForm.kpisModal.teleauditQuestion')}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={requiresTeleaudit}
                      onChange={() => setRequiresTeleaudit(!requiresTeleaudit)}
                      name="teleauditNeeded"
                      color="primary"
                      disabled={mode === VIEW_MODE}
                    />
                  }
                />
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              <Grid container spacing={2} style={{ paddingTop: '3%' }}>
                <Grid item xs={1}></Grid>
                <Grid item xs={4} style={{ paddingTop: '30px' }}>
                  <TextField
                    required
                    id="kpi-title"
                    label={t('survey.surveyForm.kpisModal.textfieldTitle')}
                    className={`${styles.descriptionKpi}`}
                    value={currentKpi.title}
                    onChange={(e) => handleTextChange(e)}
                    style={{ paddingLeft: '2%' }}
                    disabled={!requiresTeleaudit || mode === VIEW_MODE}
                  />
                  <TextField
                    required
                    id="kpi-description"
                    label={t('survey.surveyForm.kpisModal.textfieldDescription')}
                    className={`${styles.descriptionKpi}`}
                    value={currentKpi.description}
                    onChange={(e) => handleTextChange(e)}
                    multiline
                    style={{ paddingLeft: '2%', paddingTop: '10%' }}
                    disabled={!requiresTeleaudit || mode === VIEW_MODE}
                  />
                </Grid>
                <Grid item xs={2} style={{ paddingTop: '50px', paddingLeft: '3%' }}>
                  <IconButton
                    onClick={() => handleSaveKpiDetail()}
                    disabled={!requiresTeleaudit || mode === VIEW_MODE} >
                    <ArrowForwardIcon/>
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <Box border={1}>
                    <List className={styles.kpiList} dense component="div" role="list">
                      {kpiDetails.map((value) => {
                        const labelId = `transfer-list-all-item-${value.id}-label`;
                        return (
                          <ListItem key={value.id} role="listitem" >
                            <ListItemIcon>
                              <ClearIcon
                                onClick={() => deleteItem(value.id)}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.title} />
                          </ListItem>
                        );
                      })}
                      <ListItem />
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        { currentStep === 2
          && (
            <>
              <Grid container spacing={2} style={{ paddingTop: '2%' }}>
                <Grid item xs={3}></Grid>
                <Grid item xs={5}>
                  <div align='center'>
                    <Typography variant='h4' className={styles.firstStepText}>
                      {t('survey.surveyForm.kpisModal.shareOfShelfRequire')}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shareOfShelfType !== 'none'}
                        onChange={(e) => handleShareOfShelfSwitch(e)}
                        name="checkedB"
                        color="primary"
                        disabled={mode === VIEW_MODE}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              <Grid container style={{ paddingTop: '5%' }}>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <Grid container direction='column'>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={styles.checkbox}
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<RadioButtonCheckedIcon style={{ color: 'green' }}/>}
                            name="linear-share"
                            onClick={() => setShareOfShelfType('linear-share')}
                            checked={shareOfShelfType === 'linear-share'}
                            disabled={shareOfShelfType === 'none' || mode === VIEW_MODE}
                            />
                        }
                        label={t('survey.surveyForm.kpisModal.linearShare')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={styles.checkbox}
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<RadioButtonCheckedIcon style={{ color: 'green' }}/>}
                            name="volumetric-share"
                            onClick={() => setShareOfShelfType('volumetric-share')}
                            checked={shareOfShelfType === 'volumetric-share'}
                            disabled={shareOfShelfType === 'none' || mode === VIEW_MODE}
                            />
                        }
                        label={t('survey.surveyForm.kpisModal.volumetricShare')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={styles.checkbox}
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<RadioButtonCheckedIcon style={{ color: 'green' }}/>}
                            name="facial-share"
                            onClick={() => setShareOfShelfType('facial-share')}
                            checked={shareOfShelfType === 'facial-share'}
                            disabled={shareOfShelfType === 'none' || mode === VIEW_MODE}
                          />
                        }
                        label={t('survey.surveyForm.kpisModal.facingShare')}
                      />
                    </Grid>
                    </Grid>
                  </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="standard-number"
                    label={t('survey.surveyForm.kpisModal.target')}
                    type="text"
                    value={target}
                    onChange={(e) => handleTargetChange(e.target.value)}
                    disabled={shareOfShelfType === 'none' || mode === VIEW_MODE}
                    error={target < 0 || target > 100}
                    helperText={target < 0 || target > 100 ? t('survey.surveyForm.kpisModal.targetError') : ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0,
                      inputMode: 'numeric',
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid container direction='row-reverse' style={{ paddingTop: '5%' }}>
            <Grid item xs={3}>
              <Button
                id='continue'
                onClick={() => handleNextStep()}
                className={styles.buttonNext}
                >
                { questionImageType === 'multiple' && currentStep === 1 && requiresTeleaudit
                  ? t('survey.surveyForm.kpisModal.buttons.continue')
                  : t('survey.surveyForm.kpisModal.buttons.save')}
              </Button>
            </Grid>
            <Grid item xs={3}>
              { questionImageType === 'multiple' && currentStep === 2
                && (
                  <Button
                  id='back'
                  className={styles.buttonBack}
                  onClick={() => setCurrentStep(1)}
                  >
                  {t('survey.surveyForm.kpisModal.buttons.back')}
                </Button>
                )}
            </Grid>
          </Grid>
      </div>
    </Modal>
    <div>
      <Snackbar
        open={error !== ''}
        message={error}
        severity="error"
        onClose={() => setError('')}
      />
    </div>
  </>
  );
}

export default ImageSettingsModal;

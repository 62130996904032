import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Paper, Typography,
} from '@material-ui/core';
// const
import { SUPER_ADMIN } from '../../utils/const/user';

import Changes from '../../components/UploadView/Changes';

import {
  updateProductChanges,
  uploadProductFile,
} from '../../requests/api/product';

import useStyles from './styles';

function ProductsImportView() {
  const classes = useStyles();
  const { t } = useTranslation();

  const account = useSelector((state) => state.account);
  const [uploadDifference, setUploadDifference] = useState([]);

  if (account.user.role !== SUPER_ADMIN) {
    window.location.href = '/home';
  }

  const tableHeaders = [
    {
      id: 'ean',
      label: 'EAN',
    },
    {
      id: 'name',
      label: t('products.productForm.name'),
    },
    {
      id: 'internalCode',
      label: t('products.productForm.internalCode'),
    },
    {
      id: 'company',
      label: t('products.productForm.company'),
    },
    {
      id: 'distributor',
      label: t('products.productForm.distributor'),
    },
    {
      id: 'description',
      label: t('products.productForm.description'),
    },
    {
      id: 'size',
      label: t('products.productForm.size'),
    },
    {
      id: 'category',
      label: t('products.productForm.category'),
    },
    // {
    //   id: 'subCategory',
    //   label: t('products.productForm.subCategory'),
    // },
  ];

  const properties = ['Ean', 'Name', 'InternalCode', 'CompanyName', 'Distributor', 'Description', 'Size', 'Category', 'SubCategory'];

  return (
    <div className={classes.content}>
      <Typography className={classes.title} variant="h3" color="textPrimary">
        {t('products.uploadFile')}
      </Typography>
      <Paper className={classes.changesTable}>
        <Changes
          properties={properties}
          tableHeaders={tableHeaders}
          uploadDifference={uploadDifference || []}
          setUploadDifference={setUploadDifference}
          updateFunction={updateProductChanges}
          uploadFunction={uploadProductFile}
        />
      </Paper>
    </div>
  );
}

export default ProductsImportView;

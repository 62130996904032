/* eslint-disable no-param-reassign */
import produce from 'immer';
import companyActions from '../actions/company';

const initialState = {
  companyId: 0,
  refresh: false,
};

require('dotenv');

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case companyActions.SET_COMPANY: {
      const { companyId } = action.payload;

      return produce(state, (draft) => {
        draft.companyId = companyId;
      });
    }

    case companyActions.REFRESH: {
      return produce(state, (draft) => {
        draft.refresh = !draft.refresh;
      });
    }

    default: {
      return state;
    }
  }
};

export default companyReducer;

/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../utils/const/http';

/**
 * Return list of surveys responses of a task
 *
 * @param int taskResponseId Task response identifier.
 * @param int page Pagination: number of page to show.
 * @param int limit Pagination: how many items to show.
 * @returns Object with surveys responses list.
 */
const getSurveyResponses = async ({ taskResponseId, page, limit }) => {
  try {
    const { data } = await axios.get(`/api/task-responses/${taskResponseId}/surveys-responses?page=${page}&limit=${limit}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

/**
 * Set a survey response as "in review" with a "pending" status.
 *
 * @param number surveyResponseId Survey Response identificator.
 * @returns
 */
const startSurveyResponseReview = async (surveyResponseId) => {
  try {
    const { data } = await axios.post(`/api/surveys-responses/${surveyResponseId}/start-review`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';
    const surveyResponseReview = err.response ? err.response.data.data.surveyResponseReview : {};

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
        surveyResponseReview,
      },
    };
  }
};

/**
 * Fetch a survey response with questions responses.
 *
 * @param {int} Survey response identifier.
 * @returns {object} Survey response data or error description object if any.
 */
const getSurveyResponseById = async (surveyResponseId) => {
  try {
    const { data } = await axios.get(`/api/surveys-responses/${surveyResponseId}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';
    const surveyResponseReview = err.response ? err.response.data.data.surveyResponseReview : {};

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
        surveyResponseReview,
      },
    };
  }
};

/**
 * Reject full survey responses.
 * @param {int} Survey response identifier.
 */
const postRejectSurvey = async (surveyResponseId, feedbackLabelId, feedbackComment) => {
  try {
    const { data } = await axios.put(`/api/surveys-responses/${surveyResponseId}/reject`, {
      feedbackLabelId,
      feedbackComment,
    });
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

/**
 * Reject selected question responses
 * @param {int} Survey response identifier.
 * @param {array} List of question responses identifiers to reject.
 */
const postRejectSelectedQuestionsResponses = async (
  surveyResponseId,
  questionResponseList,
  questionResponseImageList,
  feedbackLabelId,
  feedbackComments,
) => {
  try {
    const { data } = await axios.put(`/api/surveys-responses/${surveyResponseId}/rejectSelectedQuestionResponses`,
      {
        questionResponseList,
        questionResponseImageList,
        feedbackLabelId,
        feedbackComments,
      });
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

/**
 * Approve survey responses
 * @param {int} Survey response identifier.
 */
const postApproveSurvey = async (surveyResponseId) => {
  try {
    const { data } = await axios.put(`/api/surveys-responses/${surveyResponseId}/approve`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const getSurveyResponsesTeleaudit = async ({ id, page, limit }) => {
  try {
    const { data } = await axios.get(`/api/survey-responses/teleaudit/${id}/?page=${page}&limit=${limit}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const getSurveyResponsesReaudit = async ({ id, page, limit }) => {
  try {
    const { data } = await axios.get(`/api/survey-responses/reaudit/${id}/?page=${page}&limit=${limit}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

export {
  getSurveyResponses,
  startSurveyResponseReview,
  getSurveyResponseById,

  postRejectSurvey,
  postRejectSelectedQuestionsResponses,
  postApproveSurvey,

  getSurveyResponsesTeleaudit,
  getSurveyResponsesReaudit,
};

import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../utils/const/http';

const getListingById = async (id, listingsPage, productsPage, limit) => {
  try {
    const { data } = await axios.get(`/api/listing/${id}?listingsPage=${listingsPage}&productsPage=${productsPage}&limit=${limit}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const editListing = async (id, name, productIds) => {
  try {
    const { data } = await axios.put(`/api/listing/${id}`, {
      name,
      productIds,
    });

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postNewListing = async (values) => {
  try {
    const { data } = await axios.post('/api/listing', values);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const deleteListing = async (id) => {
  try {
    const { data } = await axios.delete(`/api/listing/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const getListingCategories = async (id) => {
  try {
    const { data } = await axios.get(`/api/listing-categories/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getFilteredProductsInGroup = async (
  id, productsInGroupPage, limit, searchValue, column, idsInTheGroup, idsNotInTheGroup,
) => {
  try {
    const { data } = await axios.get(`/api/listing/filtered-products-in/${id}?page=${productsInGroupPage}&limit=${limit}&searchValue=${searchValue}&column=${column}&idsInTheGroup=${idsInTheGroup}&idsNotInTheGroup=${idsNotInTheGroup}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getFilteredProductsNotInGroup = async (
  id, productsNotInGroupPage, limit, searchValue, column, idsInTheGroup, idsNotInTheGroup,
) => {
  try {
    const { data } = await axios.get(`/api/listing/filtered-products-out/${id}?page=${productsNotInGroupPage}&limit=${limit}&searchValue=${searchValue}&column=${column}&idsInTheGroup=${idsInTheGroup}&idsNotInTheGroup=${idsNotInTheGroup}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  getListingById,
  editListing,
  postNewListing,
  deleteListing,
  getListingCategories,
  getFilteredProductsInGroup,
  getFilteredProductsNotInGroup,
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  CardActionArea,
  Box,
  Grid,
} from '@material-ui/core';
import useStyles from './styles';

function Gantt() {
  const styles = useStyles();
  const { t } = useTranslation();
  const account = useSelector((state) => state.account);

  const renderNotionGantt = () => {
    if (account.user.email.match(/^.*@tcglatam.com.*$/)) {
      return <Card className={styles.infoWidget}>
      <CardActionArea
        href={
          'https://tcgrauland.notion.site/ed894d020a4e4c7db733189765f9c471?v=88b0dce80b7b48fbbc78c5ffab19738b'
        }
        target="_blank"
        className={styles.actionArea}
      >
        <div className={styles.knowledge}>
          <CardMedia
            className={styles.knowImg}
            component="img"
            image="/rounded_tcg.png"
          />
        </div>
        <CardContent>
          <Typography
            className={styles.centerTypo}
            variant="h2"
            color="textSecondary"
            component="h1"
          >
            {t('gantt.ticketsGanttChart')}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>;
    }
    return null;
  };

  return (

  <div className={styles.headerContent}>
    <div className={styles.header}>
      <Box sx={{ width: '100%' }}>
        <Typography variant='h1' className={styles.title} gutterBottom>
        {t('gantt.title')}
        </Typography>
        <Typography variant='h4' gutterBottom display="inline">
        {t('gantt.subtitle')}
        </Typography>
      </Box>
    </div>
    <div className={styles.toolbar} />
    <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    >
      {renderNotionGantt()}
    </Grid>
  </div>
  );
}

export default Gantt;

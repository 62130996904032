const SET_COMPANY = '@account/set-company';
const REFRESH = '@account/refresh';

const setCompany = (companyId) => (dispatch) => dispatch({
  type: SET_COMPANY,
  payload: {
    companyId,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setCompany,
  SET_COMPANY,
  refresh,
  REFRESH,
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChallengesList from './ChallengesList';
import useStyles from './styles';
import Snackbar from '../../components/Snackbar';
import NewChallengeModal from './NewChallengeModal';

function Challenges() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openNewChallengeModal, setOpenNewChallengeModal] = useState(false);
  const [message, setMessage] = useState({ type: null, text: '' });

  const sendResponseHandler = async (response) => {
    if (response.success) {
      setMessage({ type: 'success', text: t('support.ticketCreated') });
    } else {
      setMessage({ type: 'error', text: t('support.serverError') });
    }
  };

  return (
    <div className={classes.content}>
      <ChallengesList setOpenNewChallengeModal={setOpenNewChallengeModal} />
      <NewChallengeModal
        open={openNewChallengeModal}
        setOpenNewChallengeModal={setOpenNewChallengeModal}
        sendResponse={sendResponseHandler}
      />
      <div>
        <Snackbar
          open={message.text !== ''}
          message={message.text}
          severity={message.type}
          onClose={() => setMessage({ type: null, text: '' })}
        />
      </div>
    </div>
  );
}

export default Challenges;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Toolbar,
  CircularProgress,
  IconButton,
  Checkbox,
} from '@material-ui/core';

// icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from '@material-ui/icons/Edit';

// components
import Modal from '../../../components/Modal';
import useStyles from '../styles';
import Snackbar from '../../../components/Snackbar';
import Pagination from '../../../components/Pagination';

import { getNewsPerPage } from '../../../requests/api/news';
import Button from '../../../components/Button';
import NewsForm from './newsForm';

// utils

import { parseDate } from '../../../utils/date';
// const
import { UNAUTHORIZED } from '../../../utils/const/http';

// eslint-disable-next-line no-shadow
const NewsAdmin = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  // table states
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [totalRowsQuantity, setTotalRowsQuantity] = useState(0);
  const [currentNews, setCurrentNews] = useState({
    active: '',
    description: '',
    title: '',
    urlNews: '',
    urlImage: '',
    type: '',
  });
  // auxiliar states
  const [openCreateNews, setOpenCreateNews] = useState(false);
  const [openUpdateNews, setOpenUpdateNews] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');

  const newsTableHeaders = [
    {
      id: 'imageUrl',
      label: <b>Logo</b>,
    },
    {
      id: 'title',
      label: <b>{t('administration.news.title')}</b>,
    },
    {
      id: 'created_at',
      label: <b>{t('administration.news.created')}</b>,
    },
    {
      id: 'active',
      label: <b>{t('administration.news.active')}</b>,
    },
    {
      id: 'actions',
      label: <b>{t('administration.actions')}</b>,
    },
  ];

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const handleCreateNews = () => {
    setCurrentNews(
      {
        active: '',
        description: '',
        title: '',
        urlNews: '',
        type: 'img',
      },
    );
    setOpenCreateNews(true);
  };

  const handleUpdateNews = (row) => {
    setCurrentNews({
      active: row.active,
      description: row.description,
      title: row.title,
      urlNews: row.urlNews,
      urlImage: row.urlImage,
      type: row.type,
      id: row.id,
    });
    setOpenUpdateNews(true);
  };

  const newsRequestSearch = async (pageOfTable, limit) => {
    const getNewsResponse = await getNewsPerPage(pageOfTable, limit);
    if (getNewsResponse.success) {
      setRowsToShow(getNewsResponse.data.data.news);
      setTotalRowsQuantity((getNewsResponse.data.data.totalNews));
    }
    if (getNewsResponse.status === UNAUTHORIZED.status) {
      window.location.href = '/dashboard';
    }
  };

  const reload = async () => {
    load();
    return newsRequestSearch(page, rowsPerPage);
  };

  useEffect(() => {
    reload();
  }, [page, rowsPerPage, totalRowsQuantity]);

  const newsToolbar = () => (
    <Toolbar className={styles.toolbarTable}>
            <Typography
            className={styles.toolbarButton}
          > </Typography>
            <Button
            id="create-project-btn"
            text={t('administration.news.formCreate')}
            variant="contained"
            height="40px"
            width="100%"
            backgroundColor='#2C9DC9'
            color='white'
            borderRadius='10px'
            onClick = {handleCreateNews}
          />
          {openCreateNews ? (
          <Modal title={t('administration.news.formCreate')} defaultOpen={openCreateNews} setCurrentOpen={setOpenCreateNews}>
            <NewsForm
            setCurrentOpen={setOpenCreateNews}
            currentSelection={currentNews}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={false}
            />
          </Modal>
          ) : null}
          </Toolbar>
  );

  const newsTableHeader = () => (
    <TableHead>
      <TableRow>
        {newsTableHeaders.map((tableHeader) => (
          <TableCell
            key={tableHeader.id}
            className={styles.tableHeader}
            align={tableHeader.id === 'actions' ? 'center' : 'left'}
            padding="normal"
          >
            {tableHeader.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const contentNews = () => {
    if (!totalRowsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={3} className={styles.users}>
            <AssignmentIcon />
            <h4>{t('administration.news.noNews')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row) => (
            <TableRow
              hover
              key = {row.id}
            ><TableCell
            component="th"
            scope="row"
            padding="none"
            className={styles.projectName}
          >{
            row.urlImage
            && <img
            className={styles.logoUrl}
            src={row.urlImage}
            alt="N/A"
            />
          }
          </TableCell>
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.title}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{parseDate(row.createdAt)}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >
                  <Checkbox
                  checked={row.active}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled
                />
                </TableCell>
              <TableCell align="center">
                <IconButton
                onClick = {() => handleUpdateNews(row)}
                >
                  <EditIcon/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {openUpdateNews ? (
          <Modal title={t('administration.news.formUpdate')} defaultOpen={openUpdateNews} setCurrentOpen={setOpenUpdateNews}>
            <NewsForm
            setCurrentOpen={setOpenUpdateNews}
            currentSelection={currentNews}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={true}
            />
          </Modal>
          ) : null}
      </>
    );
  };

  const tableContent = () => {
    if (loading) {
      return (
      <TableRow>
        <TableCell colSpan={9} className={styles.progress}>
          <CircularProgress />
          <h4>{t('users.userInfo.loading')}</h4>
        </TableCell>
      </TableRow>
      );
    }
    return contentNews();
  };

  return (
    <>
          <Paper className={styles.documentsTable}>
          <>
          {newsToolbar()}
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
               {newsTableHeader()}
                <TableBody>
                  {tableContent()}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          <Pagination
              setPage={setPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={totalRowsQuantity}
            />
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </>
  );
};

export default NewsAdmin;

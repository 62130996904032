import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  InputLabel,
  Checkbox,
} from '@material-ui/core';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import useStyles from '../styles';
import { createHomeMessage, updateHomeMessage } from '../../../requests/api/home-message';

const HomeMessageForm = ({
  reload,
  currentSelection,
  setCurrentOpen,
  setError,
  setSucc,
  update,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [inputValues, setInputValues] = useState(currentSelection);
  const handleClose = () => {
    setCurrentOpen(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputValues({
      ...inputValues,
      [id]: value,
    });
  };
  const handleActive = (e) => {
    setInputValues({
      ...inputValues,
      active: e.target.checked,
    });
  };

  const handleSend = async () => {
    let response;
    if (update) {
      response = await updateHomeMessage(inputValues);
    } else {
      if ((Object.values(inputValues).some((value) => value === ''))) {
        setError(t('administration.validation'));
        return;
      }
      response = await createHomeMessage({
        ...inputValues,
      });
    }
    if (response.success) {
      setSucc(t('administration.success'));
      handleClose();
      reload();
    } else {
      setError(t('administration.error'));
      handleClose();
      reload();
    }
  };

  return (
        <div>
            <div className={styles.formWrapper}>
              <Grid container spacing={2} alignItems='flex-end'>
                <Grid item xs={12}>
                  <InputLabel variant='h4'>{t('administration.homeMessage.name')}</InputLabel>
                  <TextField
                    variant="outlined"
                    id="name"
                    value={inputValues.name}
                    onChange={handleChange}
                    fullWidth
                  />
                  </Grid>
                  <Grid item xs={12}>
                  <InputLabel variant='h4'>{t('administration.homeMessage.spanishMessage')}</InputLabel>
                  <TextField
                    variant="outlined"
                    id="spanishMessage"
                    value={inputValues.spanishMessage}
                    onChange={handleChange}
                    fullWidth
                  />
                  </Grid>
                  <Grid item xs={12}>
                  <InputLabel variant='h4'>{t('administration.homeMessage.englishMessage')}</InputLabel>
                  <TextField
                    variant="outlined"
                    id="englishMessage"
                    value={inputValues.englishMessage}
                    onChange={handleChange}
                    fullWidth
                  />
                  </Grid>
                <Grid item xs={6}>
                  <InputLabel>{t('administration.homeMessage.active')}</InputLabel>
                  <Checkbox
                    id="active"
                    checked={inputValues.active}
                    color="primary"
                    onChange={handleActive}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Grid>
                <Grid item xs={4}>
                <Button
                  id="submit-ticket-btn"
                  onClick={handleSend}
                  variant="contained"
                  text={update ? t('administration.buttons.update') : t('administration.buttons.create')}
                  height="35px"
                  width="100%"
                  backgroundColor="#2C9DC9"
                  color="#FFFFFF"
                  borderRadius="10px"
                />
                </Grid>
                </Grid>
              </div>
        </div>
  );
};

export default HomeMessageForm;

import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from '@material-ui/core';

// Deprecated: use MUI Select component instead
import Select from '../../components/Select';

import useStyles from './styles';
import { PRODUCT } from '../../utils/const/formTypes';

const SettingsFormDialog = ({
  openSettingsDialog, setOpenSettingsDialog,
  georeference, setGeoreference,
  revisionPolicy, setRevisionPolicy,
  verticalForm, setVerticalForm, formType,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const revisionOptions = [
    {
      name: t('survey.surveyEdition.revisionPolicy.placeholder'),
      value: '',
    },
    {
      name: t('survey.surveyEdition.revisionPolicy.yes'),
      value: 'yes',
    },
    {
      name: t('survey.surveyEdition.revisionPolicy.no'),
      value: 'no',
    },
    {
      name: t('survey.surveyEdition.revisionPolicy.random'),
      value: 'random',
    },
  ];

  const handleOnChangeRevision = (event) => {
    setRevisionPolicy(event.target);
  };

  const handleGeoreference = () => {
    setGeoreference(!georeference);
  };

  const handleOpenSettingsDialog = () => {
    setOpenSettingsDialog(!openSettingsDialog);
  };

  return (
    <Dialog open={openSettingsDialog} onClose={handleOpenSettingsDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('survey.surveyEdition.settings')}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              className={styles.switch}
              control={<Switch checked={georeference} onChange={handleGeoreference} color="primary"/>}
              label={t('survey.surveyEdition.georeference')}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={styles.select}>
              <Grid item xs={12}>
                <p className={styles.revisionPolicy}>
                  {t('survey.surveyEdition.revisionPolicyQuestion')}
                </p>
              </Grid>
              <Grid item xs={12}>
                <Select
                  name='requires-revision'
                  items={revisionOptions}
                  value={revisionPolicy ? revisionPolicy.value : ''}
                  onChange={handleOnChangeRevision}
                  />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              className={`${styles.switch} ${styles.switchVerticalOption}`}
              control={<Switch checked={verticalForm} onChange={() => setVerticalForm(!verticalForm)} color="primary"/>}
              label={t('survey.surveyEdition.verticalForm')}
              disabled={formType.name === PRODUCT}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOpenSettingsDialog} color="primary">Cancelar</Button>
        <Button onClick={handleOpenSettingsDialog} color="primary">OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsFormDialog;

import { getProjects } from '../requests/api/project';

const PROJECTS_REQUEST = '@projects/fetch-projects';
const PROJECTS_SUCCESS = '@projects/fetch-success';
const PROJECTS_ERROR = '@projects/fetch-error';

const actionFetchProjects = () => async (dispatch) => {
  try {
    dispatch({ type: PROJECTS_REQUEST });

    const response = await getProjects();
    if (response.success) {
      const { projects } = response.data.data;
      dispatch({
        type: PROJECTS_SUCCESS,
        payload: {
          projects,
        },
      });
    }
  } catch (error) {
    dispatch({ type: PROJECTS_ERROR });
    throw error;
  }
};

export default {
  actionFetchProjects,
  PROJECTS_SUCCESS,
  PROJECTS_ERROR,
  PROJECTS_REQUEST,
};

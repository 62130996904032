import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  Grid,
  InputLabel,
} from '@material-ui/core';

import Select from 'react-select';
import TextField from '../../components/TextField';
import Button from '../../components/Button';
import { createUserProject, updateUserProject } from '../../requests/api/project';
import useStyles from './styles';

const AssociateProjectForm = ({
  reload,
  currentSelection,
  setCurrentOpen,
  options,
  setError,
  setSucc,
  update,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  // const projects = useSelector((state) => state.projects.projects);
  const [inputValues, setInputValues] = useState({
    userId: id,
    projectId: currentSelection.projectId,
    role: currentSelection.role,
    projectName: currentSelection.name,
  });

  const selectOptions = [];

  function logSelectOptions(element) {
    const aux = {};
    aux.label = element.name;
    aux.value = element.id;
    selectOptions.push(aux);
  }

  if (options) options.forEach(logSelectOptions);

  const rolesOptions = [
    { id: 'qa', name: 'QA' },
    { id: 'admin', name: 'Admin' },
    { id: 'owner', name: 'Owner' },
    { id: 'client', name: 'Client' },
    { id: 'clientEmployee', name: 'Client employee' },
    { id: 'scout', name: 'Scout' },
  ];

  const handleClose = () => {
    setCurrentOpen(false);
  };

  const selectProject = (event) => {
    setInputValues({
      ...inputValues,
      projectId: event.value,
    });
  };

  const selectRole = (e) => {
    const { value } = e.target;
    setInputValues({
      ...inputValues,
      role: value,
    });
  };

  const handleSend = async () => {
    let response;
    if ((Object.values(inputValues).some((value) => value === ''))) {
      setError(t('support.ticketForm.required'));
      return;
    }
    if (update) {
      response = await updateUserProject(inputValues);
    } else {
      response = await createUserProject(inputValues);
    }
    if (response.success) {
      setSucc(t('users.userInfo.assocSuccess'));
      handleClose();
      reload();
    } else {
      setError(t('users.userInfo.error'));
      handleClose();
      reload();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };
  return (
        <div>
            <div className={styles.formWrapper}>
              <Grid container spacing={2} alignItems='flex-end'>
                <Grid item xs={6}>
                  <InputLabel>{t('users.userInfo.userReportForm.report')}</InputLabel>
                  {!update ? <Select options={selectOptions} name="project" onChange={(event) => (
                    selectProject(event)
                  )}/> : <TextField
                  id="proyect"
                  type="text"
                  variant="outlined"
                  value={inputValues.projectName}
                  fullWidth
                  disabled
                />
              }
              </Grid>
                <Grid item xs={6}>
                  <InputLabel>{t('users.userInfo.userProjectForm.role')}</InputLabel>
                  <TextField
                    id="role"
                    type="text"
                    variant="outlined"
                    value={inputValues.role}
                    options= {rolesOptions}
                    onChange={selectRole}
                    select={true}
                    fullWidth
                    InputProps={{
                      onKeyDown: handleKeyDown,
                    }}
                  />
                </Grid>
                <Grid item xs= {8}/>
                <Grid item xs={4}>
                <Button
                  id="submit-ticket-btn"
                  onClick={handleSend}
                  text={t('users.userInfo.buttons.save')}
                  height="35px"
                  width="100%"
                  backgroundColor="#2C9DC9"
                  color="#FFFFFF"
                  borderRadius="10px"
                />
                </Grid>
                </Grid>
              </div>
        </div>
  );
};

export default AssociateProjectForm;

import React from 'react';
import useStyles from './styles';

import TeleauditAll from './TeleauditCarousel';

function Teleaudit() {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <TeleauditAll />
    </div>
  );
}

export default Teleaudit;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosOriginal from 'axios';
import {
  Grid,
  Typography,
  CircularProgress,
  IconButton,
} from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '../../components/Button';
import Snackbar from '../../components/Snackbar';
import { editUserAvatar } from '../../requests/api/user';
import useStyles from './styles';
import bytesToSize from '../../utils/bytesToSize';

const AvatarForm = ({
  setCurrentOpen, update, setSucc, reload, userId,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [defaultAvatar, setDefaultAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const defaultAvatars = {
    male: 'https://tcg-public.s3.sa-east-1.amazonaws.com/dev/reports-images/default_avatar_1646160661690',
    female: 'https://tcg-public.s3.sa-east-1.amazonaws.com/dev/reports-images/female-avatar_1646159587675',
    shadow: 'https://tcg-public.s3.sa-east-1.amazonaws.com/dev/reports-images/avatar_1646160911127',
  };

  const handleRemoveAll = () => {
    setFile(null);
    setDefaultAvatar(null);
  };

  const handleClose = () => {
    setCurrentOpen(false);
  };

  const handleUpload = async () => {
    setLoading(true);
    const fileParts = file.name.split('.');
    const timestamp = Date.now();
    const fileName = `${fileParts[0]}_${timestamp}`;
    const uploadData = {
      fileName,
      fileType: file.type,
    };
    const response = await axiosOriginal.post('/api/admin/signS3', uploadData);
    const options = {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': 'attachment',
      },
    };
    const { returnData } = response.data.data;
    const { signedRequest } = returnData;
    try {
      const uploadResponse = await axiosOriginal.put(
        signedRequest,
        file,
        options,
      );
      if (uploadResponse.status === 200) {
        const fileUrl = uploadResponse.config.url.split('?')[0];
        setError('');
        setLoading(false);
        return (fileUrl);
      }
    } catch (err) {
      setError(t('home.error'));
      return (err);
    }
    return (false);
  };

  const handleSelect = ({ target }) => {
    setFile(target.files[0]);
    setError('');
  };

  const uploaderRender = () => {
    if (file || defaultAvatar) {
      return (
        <Grid
        container
        item
        justifyContent='flex-end'
        direction='row'
        xs={12}
        >
          <Grid
          container
          item
          justifyContent='flex-end'
          direction='row'
          alignItems='center'
          xs={8}
          >
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('home.avatarSelected')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {file ? file.name : null}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {file ? bytesToSize(file.size) : null}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs>
          <div className={styles.actions}>
            {loading ? (<CircularProgress />)
              : <ClearIcon onClick={handleRemoveAll} className={styles.uploadIcon} fontSize='large'/>
            }
          </div>
          </Grid>
        </Grid>
      );
    } return (
            <Grid
            container
            item
            justifyContent='flex-start'
            accept='.jpg, .png, .jpeg'
            direction='row-reverse'
            xs={12}
            >
              <input
                className={styles.input}
                id="contained-button-file"
                multiple
                accept='.jpg, .png, .jpeg'
                type="file"
                onChange={handleSelect}
              />
              <label className={styles.uploadIcon} htmlFor="contained-button-file">
                      <CloudUploadIcon fontSize='large'/>
              </label>
         </Grid>
    );
  };

  const handleSend = async () => {
    let response;
    let url;
    if (defaultAvatar) {
      url = defaultAvatar;
    }
    if (file) {
      url = await (file && handleUpload(file));
    }

    if (update) {
      if (url) {
        response = await editUserAvatar(Number.parseInt(userId, 10), url);
      }
    } else {
      setError(t('home.required'));
      return;
    }
    if (response.success) {
      setSucc(t('home.success'));
      handleClose();
      reload();
    } else {
      setError(t('home.error'));
      handleClose();
      reload();
    }
  };

  const handleEditAvatar = (event, avatarId) => {
    setDefaultAvatar(defaultAvatars[avatarId]);
  };

  return (
        <div>
            <div className={styles.formWrapper}>
              <Grid container spacing={2} alignItems='flex-end'>
              <Grid item xs= {8}>
              <IconButton onClick={(event) => handleEditAvatar(event, 'male')}>

              <Avatar
                    className={styles.avatar}
                    src={'/default_avatar.png'}
                  />
              </IconButton>
              <IconButton onClick={(event) => handleEditAvatar(event, 'female')}>
              <Avatar
                    className={styles.avatar}
                    src={'/avatar-2.png'}
                  />
              </IconButton>
              <IconButton onClick={(event) => handleEditAvatar(event, 'shadow')}>
              <Avatar
                    className={styles.avatar}
                    src={'/avatar.png'}
                  />
              </IconButton>
              </Grid>
                <Grid item xs= {8}>
                  {uploaderRender()}
                </Grid>
                <Grid item xs={4}>
                <Button
                  id="submit-ticket-btn"
                  onClick={handleSend}
                  variant="contained"
                  text={t('home.update')}
                  height="35px"
                  width="100%"
                  backgroundColor="#2C9DC9"
                  color="#FFFFFF"
                  borderRadius="10px"
                />
                </Grid>
                </Grid>
              </div>
          <Snackbar
            open={error !== ''}
            message={error}
            severity="error"
            onClose={() => setError('')}
          />
        </div>
  );
};

export default AvatarForm;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Button, Divider, IconButton, Paper, Typography,
} from '@material-ui/core';
// icons
import BackIcon from '@material-ui/icons/ReplyOutlined';

import TaskAssociationDetail from './TaskAssociation/taskAssociationDetail';

import useStyles from './CreateTask/styles';

function TaskDetail({
  name, project, cluster, forms, setTaskId, taskInstances,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [associations, setAssociations] = useState(false);

  const handleBack = () => {
    setTaskId(null);
  };

  const handleViewAssociations = async () => {
    setAssociations((prevState) => !prevState);
  };

  useEffect(() => {}, [associations]);

  return (
    <>
      <div className={styles.headerContainer}>
        <IconButton
          id="backButton"
          aria-label="back"
          className={styles.backButton}
          onClick={handleBack}
        >
          <BackIcon fontSize="small" />
          <span>{t('groups.actionButtons.back')}</span>
        </IconButton>
        <div className={styles.header}>
          <Typography variant="h4" className={styles.title}>
            {name}
          </Typography>
          <p>{project.name}
            {cluster.name ? (
              <>
                {', '}
                <span
                  className={styles.coloredSpan}
                >
                  {cluster.name}
                </span>
              </>
            ) : null}
          </p>
        </div>
      </div>
      <Divider />
      <div className={styles.taskContent}>
        <div className={styles.editionArea}>
          {associations ? (
            <TaskAssociationDetail
              taskInstances={taskInstances}
            />
          ) : (
            <div>
              {Object.entries(forms).map(([category, formsInCategory]) => (
                <div key={category}>
                  <p
                    className={styles.categoryTitle}
                  >
                    {category === 'null' ? t('task.noCategory') : category}
                  </p>
                  {formsInCategory.map((formInCategory) => (
                    <div className={styles.formDetail} key={formInCategory.id}>
                      <p>{formInCategory.title}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.toolsContainer}>
          <p className={styles.sectionTitle}>
            {t('task.taskEdition.tools')}
          </p>
          <Paper>
            <div className={styles.toolsContent}>
              <Button className={styles.button} onClick={handleViewAssociations}>
                {!associations
                  ? t('task.association.viewAssociations')
                  : t('task.association.viewTaskDetail')
                }
              </Button>
            </div>
          </Paper>
        </div>
      </div>
    </>
  );
}

export default TaskDetail;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext } from 'react-beautiful-dnd';
// utils
import {
  attributes,
  props,
  USERS_IN,
  USERS_NOT_IN,
  USER_GROUPS_IN,
  USER_GROUPS_NOT_IN,
  USER_GROUPS,
  USER_GROUP,
  USER_ROWS,
  USERS,
  LOCATIONS_IN,
  LOCATIONS_NOT_IN,
  CLUSTERS_IN,
  CLUSTERS,
} from '../../../utils/const/association';
import {
  elementsNotInFunction, elementsInFunction, groupsInFunction, groupsNotInFunction,
} from '../../../utils/associationFunctions';
// requests
import { getUserGroupById } from '../../../requests/api/userGroup';

import TableComponent from './tableComponent';

function TableDragAndDrop({
  info,
  setInfo,
  loading,
  pageIn,
  setPageIn,
  pageOut,
  setPageOut,
  rowsPerPage,
  searchText,
  setSearchText,
  selected,
  setSelected,
  setFilter,
  setColumn,
  setOut,
  tab,
  quantityIn,
  quantityOut,
  setDrag,
  drag,
}) {
  const { t } = useTranslation();

  const tableHeadersUsersGroups = [
    {
      id: 'name',
      label: t('task.columnNames.name'),
    },
    {
      id: 'createdAt',
      label: t('task.columnNames.createdAt'),
    },
  ];

  const tableHeadersUsers = [
    {
      id: 'fullName',
      label: t('users.columnNames.user'),
    },
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'email',
      label: t('users.columnNames.email'),
    },
    {
      id: 'role',
      label: t('users.columnNames.role'),
    },
    {
      id: 'active',
      label: t('users.columnNames.active'),
    },
  ];

  const tableHeadersLocations = [
    {
      id: 'name',
      label: t('locations.columnNames.name'),
    },
    {
      id: 'internal_code',
      label: t('locations.columnNames.internal_code'),
    },
    {
      id: 'address',
      label: t('locations.columnNames.address'),
    },
    {
      id: 'city',
      label: t('locations.columnNames.city'),
    },
    {
      id: 'chain',
      label: t('locations.columnNames.chain'),
    },
  ];

  const elementsLabel = {
    0: t('task.association.tabs.userGroups'),
    1: t('task.association.tabs.users'),
    2: t('task.association.tabs.locations'),
  };

  const tableHeaders = {
    0: tableHeadersUsersGroups,
    1: tableHeadersUsers,
    2: tableHeadersLocations,
  };

  const onDragEnd = async ({ destination, source, draggableId }) => {
    if (!destination) return;

    const itemSelected = selected.find((object) => object.index === source.index);

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = info[source.droppableId];
      const destColumn = info[destination.droppableId];

      let sourceItems = [...sourceColumn];
      const destItems = [...destColumn];

      const removedItems = [];

      let newSourceItemsArray = sourceItems;

      if (itemSelected) {
        selected.forEach((item) => {
          sourceItems = [...sourceColumn];
          // eslint-disable-next-line no-param-reassign
          item.column = destination.droppableId;
          removedItems.push(...sourceItems.splice(item.index, 1));
        });
        // I have saved the index when the array is complete, so I always remove
        // the elements of the initial array and at the end I filter the moved elements.
        sourceItems = [...sourceColumn];
        const itemsToRemove = removedItems.map((rmi) => rmi.id);
        newSourceItemsArray = sourceItems.filter((item) => !itemsToRemove.includes(item.id));
        setSelected([]);
      } else {
        removedItems.push(...sourceItems.splice(source.index, 1));
      }

      destItems.splice(destination.index, 0, ...removedItems);

      const changes = {};
      // When a user is removed, the userGroups containing that user are removed.
      if (destination.droppableId === USERS_NOT_IN) {
        elementsNotInFunction(info, USERS_IN, draggableId, USER_GROUPS, changes, USER_GROUPS_IN);
      }

      // When a user is added, it checks if all users belonging to the same userGroups
      // are included in the usersIn, in which case the userGroup is added to userGroupsIn.
      if (destination.droppableId === USERS_IN) {
        await elementsInFunction(info, USERS_IN, USERS_NOT_IN, USER_GROUPS_IN,
          draggableId, USER_GROUPS, USER_GROUP, changes, getUserGroupById, USER_ROWS);
      }

      // Adds users from the userGroup to users association table.
      if (destination.droppableId === USER_GROUPS_IN) {
        groupsInFunction(info, USER_GROUPS_NOT_IN,
          draggableId, changes, USERS, USERS_IN, USERS_NOT_IN);
      }

      // Removes users from the userGroup in users association table.
      if (destination.droppableId === USER_GROUPS_NOT_IN) {
        groupsNotInFunction(info, USER_GROUPS_IN, draggableId, USERS, USERS_IN, changes);
      }

      // When a location is removed, the clusters containing that location are removed.
      if (destination.droppableId === LOCATIONS_NOT_IN) {
        elementsNotInFunction(info, LOCATIONS_IN, draggableId, CLUSTERS, changes, CLUSTERS_IN);
      }

      setInfo({
        ...info,
        [source.droppableId]: newSourceItemsArray,
        [destination.droppableId]: destItems,
        ...changes,
      });
      setOut(true);
      setDrag(!drag);
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
        <TableComponent
            tab={tab}
            element={elementsLabel[tab]}
            attributes={attributes[tab]}
            tableHeaders={tableHeaders[tab]}
            prop={props[tab][0]}
            propShow={props[tab][2]}
            selected={selected}
            setSelected={setSelected}
            info={info}
            page={pageIn}
            setPage={setPageIn}
            rowsPerPage={rowsPerPage}
            quantity={quantityIn}
            setColumn={setColumn}
            searchText={searchText}
            setSearchText={setSearchText}
            setFilter={setFilter}
            setOut={setOut}
            out={false}
            loading={loading}
        />
        <TableComponent
            tab={tab}
            element={elementsLabel[tab]}
            attributes={attributes[tab]}
            tableHeaders={tableHeaders[tab]}
            prop={props[tab][1]}
            propShow={props[tab][1]}
            selected={selected}
            setSelected={setSelected}
            info={info}
            setPage={setPageOut}
            page={pageOut}
            rowsPerPage={rowsPerPage}
            quantity={quantityOut}
            setColumn={setColumn}
            searchText={searchText}
            setSearchText={setSearchText}
            setFilter={setFilter}
            setOut={setOut}
            out
            loading={loading}
        />
    </DragDropContext>
  );
}

export default TableDragAndDrop;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import queryString from 'query-string';
// Components
import { Typography } from '@material-ui/core';
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import Snackbar from '../../components/Snackbar';

import useStyles from './styles';
// services
import authService from '../../services/authService';

function ResetPassword() {
  const styles = useStyles();
  const { t } = useTranslation();

  // Get query info
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { token } = query;

  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState(false);
  const [inputValues, setInputValues] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;

    setInputValues({
      ...inputValues,
      [id]: value,
    });
  };

  const handleSend = async () => {
    if (!token) {
      setSeverity('error');
      setMessage(t('resetPassword.messages.error'));
      return;
    }

    if (!inputValues.password) {
      setSeverity('error');
      setMessage(t('resetPassword.messages.requiredPassword'));
      return;
    }

    if (inputValues.password.length < 8) {
      setSeverity('error');
      setMessage(t('resetPassword.messages.requiredMinSizePassword'));
      return;
    }

    if (!inputValues.confirmPassword) {
      setSeverity('error');
      setMessage(t('resetPassword.messages.confirmPassword'));
      return;
    }

    if (inputValues.password !== inputValues.confirmPassword) {
      setSeverity('error');
      setMessage(t('resetPassword.messages.matchPasswords'));
      return;
    }

    const resetPasswordResponse = await authService.resetPassword(
      token, inputValues.password, inputValues.confirmPassword,
    );

    if (!resetPasswordResponse.success) {
      setSeverity('error');
      setMessage(resetPasswordResponse.errorMessage);
      return;
    }

    setSeverity('success');
    setMessage(t('resetPassword.messages.updateSuccess'));

    setTimeout(() => {
      window.location.href = '/login';
    }, 500);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <div className={styles.resetPasswordWrapper}>
      <div className={styles.resetPassword}>
        <img className={styles.image} src="/TCG_logo.png" alt="TCG logo" />
        <Typography variant="h5" className={styles.title} align="center">{t('resetPassword.title')}</Typography>
        <div>
          <TextField
            id="password"
            type="password"
            variant="outlined"
            label={t('resetPassword.labels.password')}
            value={inputValues.password}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <div>
          <TextField
            id="confirmPassword"
            type="password"
            variant="outlined"
            label={t('resetPassword.labels.confirmPassword')}
            value={inputValues.confirmPassword}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <Button
          id="submit-btn"
          backgroundColor="#1b5d77"
          color="#FFFFFF"
          onClick={handleSend}
          text={t('resetPassword.button')}
          height="40px"
          width="100%"
        />
      </div>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div>
  );
}

export default ResetPassword;

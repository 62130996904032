// Select all rows
const handleSelectAllClick = (event, rows, column, setSelected) => {
  if (event.target.checked) {
    const newSelectedIds = rows.map((n) => n.id);
    const newSelected = [];
    newSelectedIds.forEach((element) => {
      newSelected.push({
        column,
        id: element,
        index: rows.findIndex((row) => row.id === element),
      });
      // newSelected.push(element);
    });
    setSelected(newSelected);
    return;
  }
  setSelected([]);
};

// Select all rows
const handleSelectAllClickGroups = (event, rows, column, setSelected) => {
  if (event.target.checked) {
    const newSelectedIds = rows.map((n) => n.id);
    const newSelected = [];
    newSelectedIds.forEach((element) => {
      newSelected.push({
        column,
        id: element,
        index: rows.findIndex((row) => row.id === element),
      });
    });
    setSelected(newSelected);
    return;
  }
  setSelected([]);
};

// Select this row
const handleSelect = (event, row, selected, setSelected, form) => {
  let newSelected = [];
  let selectedIndex;

  let newRow = row;

  if (!form) {
    if (typeof row === 'object') {
      selectedIndex = selected.findIndex((object) => object.id === row.id);
    } else {
      selectedIndex = selected.indexOf(row);
    }
  } else {
    const [title, id, type] = form.split('~');
    selectedIndex = selected.findIndex((s) => s.surveyId === id);

    newRow = {
      surveyId: id,
      title,
      type,
    };
  }
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, newRow);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }
  setSelected(newSelected);
};

const isSelected = (name, selected, form) => {
  if (form) {
    return selected.findIndex((object) => object.surveyId === `${name}`) !== -1;
  }
  if (selected.length && typeof selected[0] === 'object') {
    if (selected[0].surveyId && typeof name !== typeof selected[0].surveyId) {
      return selected.findIndex((object) => `${object.surveyId}` === `${name}`) !== -1;
    }
    return selected.findIndex((object) => object.id === name) !== -1;
  }
  return selected.indexOf(name) !== -1;
};

export {
  handleSelectAllClick,
  handleSelectAllClickGroups,
  handleSelect,
  isSelected,
};

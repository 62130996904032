import React, { useState, useEffect } from 'react';
// core
import {
  Checkbox, FormControlLabel,
} from '@material-ui/core';
// icons
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';

import useStyles from './styles';

function TaskAssociationConfirm({
  associations, cluster,
}) {
  const styles = useStyles();

  const [updateCheckboxes, setUpdateCheckboxes] = useState(false);

  const handleCheck = (event) => {
    if (event.target.name.includes('-')) {
      const [userId, locationId] = event.target.name.split('-');
      const userFound = associations.find((association) => `${association.id}` === userId);
      if (!userFound) return;

      const locationFound = userFound.locations.find((l) => `${l.id}` === locationId);
      if (!locationFound) return;

      locationFound.checked = !locationFound.checked;
    } else {
      const userFound = associations.find((association) => `${association.id}` === event.target.name);
      userFound.checked = !userFound.checked;
    }
    setUpdateCheckboxes((prevState) => !prevState);
  };

  useEffect(() => {}, [updateCheckboxes]);

  return (
    <>
        {associations.length ? associations.map((user, index) => (
        <div
            key={user.id}
            className={styles.associationIndividual}
        >
            <div className={styles.locationsContainer}>
            {!cluster ? (
              <FormControlLabel
                  className={styles.checkbox}
                  control={
                    <Checkbox
                        icon={<CheckBoxOutlineBlank />}
                        checkedIcon={<CheckBox />}
                        name={`${user.id}`}
                        checked={associations[index].checked}
                        onChange={handleCheck}
                    />
                  }
                  label={(
                  <p>{`${user.fullName} - DNI ${user.dni}`}</p>
                  )}
                />
            ) : (
              <p>{`${user.fullName} - DNI ${user.dni}`}</p>
            )}
            {user.locations.map((location, lindex) => (
                <FormControlLabel
                key={location.id}
                className={styles.checkbox}
                control={
                    <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    name={`${user.id}-${location.id}`}
                    checked={associations[index].locations[lindex].checked}
                    onChange={handleCheck}
                    />
                }
                label={(
                    <p>{`${location.name}, ${location.chain}`}</p>
                )}
                />
            ))}
            </div>
        </div>
        )) : <p className={styles.emptyAssociations}>Aún no hay asociaciones</p>}
    </>
  );
}

export default TaskAssociationConfirm;

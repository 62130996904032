/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../utils/const/http';

const getQuestionResponses = async (surveyResponseId, page, limit) => {
  try {
    const { data } = await axios.get(`/api/question-responses/${surveyResponseId}/?page=${page}&limit=${limit}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const getQuestionResponseImages = async ({ surveyResponseId }) => {
  try {
    const { data } = await axios.get(`/api/question-responses/${surveyResponseId}/image`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const getQuestionResponseById = async ({ questionResponseId }) => {
  try {
    const { data } = await axios.get(`/api/question-responses/${questionResponseId}/question`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const putQuestionReponseTeleauditStatus = async ({
  questionResponseId, teleauditStatus, taskResponseId, last,
}) => {
  try {
    const { data } = await axios.put(`/api/question-responses/${questionResponseId}`, {
      teleauditStatus,
      taskResponseId,
      last,
    });
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

export {
  getQuestionResponses,
  getQuestionResponseImages,
  getQuestionResponseById,
  putQuestionReponseTeleauditStatus,
};

function parseDate(date) {
  try {
    return date.split('T')[0].split('-').reverse().join('-');
  } catch {
    return '';
  }
}

function formatDateToLocalString(dateString, nexus) {
  const userDate = new Date(dateString);
  const hours = String(userDate.getHours()).padStart(2, '0');
  const minutes = String(userDate.getMinutes()).padStart(2, '0');
  const date = String(userDate.getDate()).padStart(2, '0');
  const month = String(userDate.getMonth() + 1).padStart(2, '0');
  const year = String(userDate.getFullYear());

  return `${hours}:${minutes} ${nexus} ${date}-${month}-${year}`;
}

export {
  parseDate,
  formatDateToLocalString,
};

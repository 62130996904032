import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  TableSortLabel,
} from '@material-ui/core';

// components
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import Snackbar from '../../components/Snackbar';
import TableToolbarActions from '../../components/TableToolbarActions';
// import CreateGroup from './createGroupModal/createGroup';

import useStyles from './styles';

// utils
import { UNAUTHORIZED } from '../../utils/const/http';
import { SUPER_ADMIN } from '../../utils/const/user';

// groups tables
import UserGroupTable from './userGroupTable';
import ListingTable from './listingTable';
import ClusterTable from './clusterTable';
import MainBody from './mainBody';
import MainToolbar from './mainToolbar';

// requests
import { getGroupsPerPage } from '../../requests/api/group';

// functions
import { handleRequestSort } from '../../utils/orderFunctions';
import load from '../../utils/load';

function Groups() {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const rowsPerPage = 10;
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [groupsQuantity, setGroupsQuantity] = useState(0);
  const [groupToView, setGroupToView] = useState(null);
  const [onlyView, setOnlyView] = useState(false);

  // auxiliar states
  const [loading, setLoading] = useState(false);
  const [create, setCreate] = useState(null);
  const [tab, setTab] = useState(0);
  const [activeGroups, setActiveGroups] = useState(0);
  const [update, setUpdate] = useState(false);
  // snackbar states
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  // Filter states
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  // order
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  // Group data states
  const [inputValuesGroup, setInputValuesGroup] = useState({
    name: '',
  });
  const [listing, setListing] = useState('');
  const [elementType, setElementType] = useState(t('groups.userGroup'));
  const [groupData, setGroupData] = useState(null);
  const [groupInfo, setGroupInfo] = useState({
    inTheGroup: [],
    notInTheGroup: [],
  });

  //   const [createModalOpen, setCreateModalOpen] = useState(false);

  const account = useSelector((state) => state.account);
  const company = useSelector((state) => state.company);

  const tableHeaders = [
    {
      id: 'name',
      label: t('groups.columnNames.name'),
    },
    {
      id: 'deleted',
      label: t('groups.columnNames.deleted'),
    },
    {
      id: 'createdAt',
      label: t('groups.columnNames.createdAt'),
    },
    {
      id: 'actions',
      label: '',
    },
  ];

  const handleEdit = async (event) => {
    const { id } = event.currentTarget;
    setSelected([]);
    setOnlyView(false);
    setGroupToView(id);
  };

  const handleView = async (event) => {
    const { id } = event.currentTarget;
    setSelected([]);
    setOnlyView(true);
    setGroupToView(id);
  };

  // Sets create state TRUE
  const handleCreate = async () => {
    setCreate(true);
    setOnlyView(false);
  };

  // Change the tool tab
  const handleChangeTab = async (event, newTab) => {
    setGroupToView(null);
    setCreate(false);
    setTab(newTab);
    setSelected([]);
    setSearchText('');
    setFilter('');
    setColumn(false);
    setPage(0);

    let response;

    switch (newTab) {
      case 1:
        response = await getGroupsPerPage('listing', page, rowsPerPage, '', false, true);
        setElementType(t('groups.listing'));
        break;
      case 2:
        response = await getGroupsPerPage('cluster', page, rowsPerPage, '', false, true);
        setElementType(t('groups.cluster'));
        break;
      default:
        response = await getGroupsPerPage('user-group', page, rowsPerPage, '', false, true);
        setElementType(t('groups.userGroup'));
        break;
    }

    if (response.success) {
      setRowsToShow(response.data.data.groups);
      setGroupsQuantity(response.data.data.totalGroups);

      const active = response.data.data.groups.filter((group) => !group.deleted);
      setActiveGroups(active.length);
    }
  };

  useEffect(() => {
    load(200, setLoading);
  }, [t, order, orderBy, rowsToShow]);

  useEffect(() => {
    if (account.user.role !== SUPER_ADMIN) {
      window.location.href = '/home';
    }

    const requestSearch = async (pageOfTable, limit, searchValue, columnName) => {
      let response;
      switch (tab) {
        case 1:
          response = await getGroupsPerPage('listing', pageOfTable, limit, searchValue, columnName, true);
          setElementType(t('groups.listing'));
          break;
        case 2:
          response = await getGroupsPerPage('cluster', pageOfTable, limit, searchValue, columnName, true);
          setElementType(t('groups.cluster'));
          break;
        default:
          response = await getGroupsPerPage('user-group', pageOfTable, limit, searchValue, columnName, true);
          setElementType(t('groups.userGroup'));
          break;
      }
      if (response.success) {
        setRowsToShow(response.data.data.groups);
        setGroupsQuantity(response.data.data.totalGroups);

        const active = response.data.data.groups.filter((group) => !group.deleted);
        setActiveGroups(active.length);
      }

      if (response.status === UNAUTHORIZED.status) {
        window.location.href = '/home';
      }
    };
    requestSearch(page, rowsPerPage, filter, column);
    // eslint-disable-next-line
  }, [page, rowsPerPage, account.user.role, column, filter, update, company, tab]);

  // TABLE to render
  const tableDetail = {
    0: (
      <UserGroupTable
        userGroupId={groupToView}
        setGroupToView={setGroupToView}
        create={create}
        groupData={groupData}
        setGroupData={setGroupData}
        groupInfo={groupInfo}
        setGroupInfo={setGroupInfo}
        elementType={elementType}
        inputValuesGroup={inputValuesGroup}
        setInputValuesGroup={setInputValuesGroup}
        handleCreate={handleCreate}
        handleChangeTab={handleChangeTab}
        setCreate={setCreate}
        selected={selected}
        setSelected={setSelected}
        onlyView={onlyView}
      />
    ),
    1: (
      <ListingTable
        listingId={groupToView}
        setGroupToView={setGroupToView}
        create={create}
        groupData={groupData}
        setGroupData={setGroupData}
        groupInfo={groupInfo}
        setGroupInfo={setGroupInfo}
        elementType={elementType}
        inputValuesGroup={inputValuesGroup}
        setInputValuesGroup={setInputValuesGroup}
        handleCreate={handleCreate}
        handleChangeTab={handleChangeTab}
        setCreate={setCreate}
        selected={selected}
        setSelected={setSelected}
        onlyView={onlyView}
      />
    ),
    2: (
      <ClusterTable
        clusterId={groupToView}
        setGroupToView={setGroupToView}
        create={create}
        groupData={groupData}
        setGroupData={setGroupData}
        groupInfo={groupInfo}
        setGroupInfo={setGroupInfo}
        elementType={elementType}
        inputValuesGroup={inputValuesGroup}
        setInputValuesGroup={setInputValuesGroup}
        handleCreate={handleCreate}
        handleChangeTab={handleChangeTab}
        setCreate={setCreate}
        selected={selected}
        setSelected={setSelected}
        listing={listing}
        setListing={setListing}
        onlyView={onlyView}
      />
    ),
  };

  const content = () => {
    if (!groupsQuantity) {
      return (
        <TableRow key="no-content-row" className={styles.noContent}>
          <TableCell colSpan={7} className={styles.groups}>
            <h4>{t('groups.noContent')}</h4>
            <Button
              id="create-btn"
              variant="outlined"
              text={t('groups.createButton', { elementType })}
              height="40px"
              width="250px"
              backgroundColor='theme-secondary-blue'
              color='theme-primary-white'
              borderRadius='10px'
              onClick={() => setCreate(true)}
            />
          </TableCell>
        </TableRow>
      );
    }
    return (
      <MainBody
        rowsToShow={rowsToShow}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        selected={selected}
        setSelected={setSelected}
        handleEdit={handleEdit}
        handleView={handleView}
      />
    );
  };
  return (
    <>
      {groupToView || create ? (
        tableDetail[tab]
      ) : (
        <div className={styles.content}>
          <div className={styles.header}>
            <Typography variant='h4' className={styles.title}>{t('groups.title')}</Typography>
            <p>{t('groups.subTitle')} <span className={styles.span}>{t('groups.active', { activeGroups })}</span></p>
          </div>
          <MainToolbar
            tab={tab}
            create={create}
            setCreate={setCreate}
            groupToView={groupToView}
            setGroupToView={setGroupToView}
            elementType={elementType}
            groupInfo={groupInfo}
            inputValuesGroup={inputValuesGroup}
            listing={listing}
            setMessage={setMessage}
            setSeverity={setSeverity}
            handleCreate={handleCreate}
            handleChangeTab={handleChangeTab}
            />
          <Paper className={styles.groupTable}>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableToolbarActions
                      tab={tab}
                      selected={selected}
                      setSelected={setSelected}
                      rowsToShow={rowsToShow}
                      setMessage={setMessage}
                      setSeverity={setSeverity}
                      setUpdate={setUpdate}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      setColumn={setColumn}
                      setFilter={setFilter}
                      placeholder={t('groups.tableToolbar.filterPlaceholder')}
                    />
                    <TableRow key="headers-row">
                      <TableCell></TableCell>
                      {tableHeaders.map((tableHeader) => (
                        <TableCell
                          key={tableHeader.id}
                          className={styles.tableHeader}
                          align={tableHeader.id === 'active' || tableHeader.id === 'createdAt' ? 'center' : 'left'}
                          padding="normal"
                          sortDirection={orderBy === tableHeader.id ? order : false}
                        >
                          {tableHeader.id !== 'deleted' ? (
                            <TableSortLabel
                              active={tableHeader.id !== 'actions'}
                              direction={orderBy === tableHeader.id ? order : 'asc'}
                              onClick={() => (
                                handleRequestSort(tableHeader.id, order, setOrder, setOrderBy)
                              )}
                            >
                              {tableHeader.label}
                            </TableSortLabel>
                          ) : tableHeader.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow key="loading-row">
                        <TableCell colSpan={7} className={styles.progress}>
                          <CircularProgress />
                          <h4>{t('groups.loading')}</h4>
                        </TableCell>
                      </TableRow>
                    ) : content()}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                setPage={setPage}
                page={page}
                rowsPerPage={rowsPerPage}
                quantity={groupsQuantity}
              />
          </Paper>
          {/* {createModalOpen
            ? <>
                <CreateGroup
                  defaultOpen={createModalOpen}
                  inputValuesGroup={inputValuesGroup}
                  setInputValuesGroup={setInputValuesGroup}
                  setCurrentOpen={setCreateModalOpen}
                  tab={tab}
                  setSelected={setSelected}
                  setGroupToView={setGroupToView}
                />
              </>
            : null} */}
          <Snackbar
            open={message !== ''}
            message={message}
            severity={severity}
            onClose={() => setMessage('')}
          />
        </div>
      )}
    </>
  );
}

export default Groups;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dragAndDropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(2),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  dropText: {
    fontSize: '13px',
    color: theme.palette.primary.middleGray,
  },
  dragAndDropContainer: {
    minHeight: '30px',
  },
  paperContainer: {
    borderRadius: '12px',
    minWidth: '400px',
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6),
    '& .MuiPaper-root': {
      elevation: 3,
    },
    '& button': {
      float: 'right',
    },
    '& .MuiCircularProgress-svg': {
      color: theme.palette.primary.white,
      padding: '10px',
    },
  },
  deleteButton: {
    marginLeft: '95%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  addButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
    minWidth: '140px',
    '& button': {
      minWidth: '140px',
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.secondary.blue,
      borderRadius: '10px',
    },
  },
  list: {
    maxHeight: 320,
  },
  flexListContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '700px',
    overflow: 'auto',
    width: '100%',
    height: '150px',
  },
  emailElement: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    height: '25px',
    '& .MuiIconButton-root': {
      right: '0',
      top: '0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  errorMessage: {
    padding: 10,
    color: 'red',
  },
}));

export default useStyles;

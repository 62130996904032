import React from 'react';

import {
  Grid,
  IconButton,
} from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';
import useStyles from '../styles';

const EmojiForm = ({
  setCurrentOpen, setEmojiSet, bucket, handleEmojiSet,
}) => {
  const styles = useStyles();

  const handleClose = () => {
    setCurrentOpen(false);
  };

  const handleEditEmoji = (event, id) => {
    handleEmojiSet(id);
    setEmojiSet(id);
    handleClose();
  };

  const concatString = (first, second) => first + second;

  return (
        <div>
            <div className={styles.formWrapper}>
              <Grid container spacing={2} alignItems='flex-start' direction='row'>
              <Grid item xs= {12}>
              <IconButton onClick={(event) => handleEditEmoji(event, 'emojiSet1')}>

              <Avatar
                    className={styles.avatar}
                    src={concatString(bucket, '/emojiSet1/emoji5.png')}
                  />
              </IconButton>
              <IconButton onClick={(event) => handleEditEmoji(event, 'emojiSet2')}>
              <Avatar
                    className={styles.avatar}
                    src={concatString(bucket, '/emojiSet2/emoji5.png')}
                  />
              </IconButton>
              <IconButton onClick={(event) => handleEditEmoji(event, 'emojiSet3')}>
              <Avatar
                    className={styles.avatar}
                    src={concatString(bucket, '/emojiSet3/emoji5.png')}
                  />
              </IconButton>
              </Grid>
                </Grid>
              </div>
        </div>
  );
};

export default EmojiForm;

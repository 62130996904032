import React from 'react';
import PropTypes from 'prop-types';

import CoreSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Snackbar({
  open,
  severity,
  message,
  onClose,
}) {
  if (!severity) return null;

  return (
    <CoreSnackbar open={open} autoHideDuration={4000} onClose={onClose}>
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </CoreSnackbar>
  );
}

Snackbar.propTypes = {
  open: PropTypes.bool,
  severity: PropTypes.string,
  onClose: PropTypes.func,
};

Snackbar.defaultProps = {
  open: true,
  severity: 'success',
  message: '',
  onClose: () => { },
};

export default Snackbar;

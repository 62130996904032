import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR, OK } from '../../utils/const/http';

const postNewTicket = async ({
  subject,
  content,
  category,
  subcategory,
  projectId,
  locationId,
  fileUrl,
}) => {
  try {
    const response = await axios.post('/api/ticket', {
      subject,
      content,
      category,
      subcategory,
      projectId,
      locationId,
      fileUrl,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      totalTickets: response.data,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getTickets = async (
  page,
  limit,
  searchValue,
  column,
  challenge,
  sortBy,
  orderBy,
  onlyFiles,
  filterChallengeStatus,
  filterUser,
  filterLocation,
  startDate,
  endDate,
) => {
  try {
    const response = await axios.get(`/api/ticket/?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}&challenge=${challenge}&sortBy=${sortBy}&orderBy=${orderBy}&onlyFiles=${onlyFiles}&filterChallengeStatus=${filterChallengeStatus}&filterUser=${filterUser}&startDate=${startDate}&endDate=${endDate}&filterLocation=${filterLocation}`);
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const updateTicketState = async (
  id,
  state,
  tokens,
) => {
  const bodyRequest = { state };
  if (tokens) { bodyRequest.tokens = tokens; }

  try {
    const response = await axios.put(`/api/ticket/${id}`, bodyRequest);
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      totalTickets: response.data,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.data.data.errorMessage : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const updateTicketInfo = async ({
  id,
  state,
  subject,
  content,
  category,
  subcategory,
  fileUrl,
  challengesResponseFile,
}) => {
  try {
    const response = await axios.put(`/api/ticket/${id}`, {
      state,
      subject,
      content,
      category,
      subcategory,
      fileUrl,
      challengesResponseFile,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      totalTickets: response.data,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  postNewTicket, getTickets, updateTicketState, updateTicketInfo,
};

/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import projectsAction from '../actions/projects';

const initialState = {
  projects: null,
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectsAction.PROJECTS_SUCCESS: {
      const { projects } = action.payload;
      return produce(state, (draft) => {
        draft.projects = projects;
      });
    } default: {
      return state;
    }
  }
};

export default projectsReducer;

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  title: {
    fontSize: '19px',
    margin: '10px',
    marginTop: '30px',
    fontWeight: 600,
    color: theme.palette.primary.mainBlue,
  },
  uploadFile: {
    display: 'flex',
    height: '40px',
    width: '160px',
    backgroundColor: theme.palette.secondary.blue,
    color: theme.palette.primary.white,
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& svg': {
      color: 'white',
    },
  },
  headerContainer: {
    margin: '10px 15px 20px',
    '& button': {
      color: theme.palette.secondary.blue,
      border: `1px solid ${theme.palette.secondary.blue}`,
    },
  },
  disabled: {
    backgroundColor: theme.palette.secondary.gray,
    cursor: 'default',
  },
  messageError: {
    color: theme.palette.secondary.red,
  },
}));

export default useStyles;

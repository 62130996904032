/* eslint-disable no-nested-ternary */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

import {
  TableContainer,
} from '@material-ui/core';

import useStyles, {
  TableHead,
} from './styles';
import ExpandableSurveyRow from './ExpandableSurveyRow';

export default function ExpandableRowTable({
  data,
  categoriesQuantity,
  questionResponsesDone,
  reaudit,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  if (categoriesQuantity === 0) {
    return (
      <Paper>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} className={styles.users}>
                  <h4>{t('teleaudit.surveyExpandableTable.noCategories')}</h4>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  return (
    <Paper className={styles.categoryListTable}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell colSpan={2} align='left'>
                <b>{t('teleaudit.surveyExpandableTable.headers.categoryName')}</b>
              </TableCell>
              <TableCell colSpan={2} align='left'>
                <b>{t('teleaudit.surveyExpandableTable.headers.surveysQuantity')}</b>
              </TableCell>
              <TableCell colSpan={2} align='center'>
                <b></b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map(([categoryName, value]) => {
              const fixedCategory = categoryName === null || categoryName === 'No Category'
                ? t('teleaudit.surveyExpandableTable.body.noCategory') : categoryName;
              return (
              <ExpandableSurveyRow
                key={fixedCategory}
                expandComponents={value.surveyResponses}
                t={t}
                questionResponsesDone={questionResponsesDone}
                reaudit={reaudit}
              >
                <TableCell component="th" scope="row" colSpan={2} align="left">
                  <b>{fixedCategory}</b>
                </TableCell>
                <TableCell component="th" scope="row" colSpan={2} align="left">
                  <b>{value.surveyResponses.length} {value.surveyResponses.length <= 1
                    ? t('teleaudit.surveyExpandableTable.body.survey') : t('teleaudit.surveyExpandableTable.body.surveys')}</b>
                </TableCell>
              </ExpandableSurveyRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GroupsTable from './groupsTable';

// requests
import {
  editUserGroup, getFilteredUsersInGroup, getFilteredUsersNotInGroup, getUserGroupById,
} from '../../requests/api/userGroup';
import { getUsersPerPageByCompanyId } from '../../requests/api/user';

import { IN_THE_GROUP, NOT_IN_THE_GROUP } from '../../utils/const/groups';

function UserGroupTable({
  userGroupId,
  setGroupToView,
  create,
  groupData,
  setGroupData,
  groupInfo,
  setGroupInfo,
  elementType,
  inputValuesGroup,
  setInputValuesGroup,
  handleCreate,
  handleChangeTab,
  setCreate,
  selected,
  setSelected,
  onlyView,
}) {
  const { t } = useTranslation();

  // table states
  const rowsPerPage = 10;
  const [userGroupsPage, setUserGroupsPage] = useState(0);
  const [usersPage, setUsersPage] = useState(0);

  // Filter states
  const [searchText, setSearchText] = useState({
    inTheGroup: '',
    notInTheGroup: '',
  });
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState('name');
  const [inOrOut, setInOrOut] = useState(null);

  // quantities
  const [usersInTheGroupQ, setUsersInTheGroupQ] = useState(0);
  const [usersNotInTheGroupQ, setUsersNotInTheGroupQ] = useState(0);
  const [dragChange, setDragChange] = useState(false);

  // backup all elements in the group without filters, only changes in drag and drop
  const [allElementsInTheGroup, setAllElementsInTheGroup] = useState([]);
  const [allElementsNotInTheGroup, setAllElementsNotInTheGroup] = useState([]);

  const tableHeaders = [
    {
      id: 'fullName',
      label: t('users.columnNames.user'),
    },
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'email',
      label: t('users.columnNames.email'),
    },
    {
      id: 'role',
      label: t('users.columnNames.role'),
    },
    {
      id: 'active',
      label: t('users.columnNames.active'),
    },
    {
      id: 'deleted',
      label: t('groups.columnNames.deleted'),
    },
  ];

  const attributes = ['fullName', 'id', 'email', 'role', 'active'];

  const findUserGroup = async () => {
    const response = await getUserGroupById(userGroupId, userGroupsPage, usersPage, rowsPerPage);

    if (response.success) {
      setGroupData(response.data.data.userGroup.data);

      setGroupInfo({
        inTheGroup: response.data.data.userGroup.usersRows.data,
        notInTheGroup: response.data.data.users.rows,
      });

      setUsersInTheGroupQ(response.data.data.userGroup.usersRows.data.length);
      setUsersNotInTheGroupQ(response.data.data.users.count);
    }
  };

  const findUsers = async () => {
    // let allUsers = [];
    const responseUserGroup = await getUserGroupById(
      userGroupId, userGroupsPage, usersPage, rowsPerPage,
    );
    const responseAllUserGroup = await getUserGroupById(
      userGroupId, userGroupsPage, usersPage, 10000000,
    );
    if (responseUserGroup.success && responseAllUserGroup.success) {
      const usersInGroup = responseUserGroup.data.data.userGroup.usersRows.data;
      const allUsersInGroup = responseAllUserGroup.data.data.userGroup.usersRows.data;
      const totalUsersInGroup = responseUserGroup.data.data.userGroup.usersRows.count;
      const usersNotInGroup = responseUserGroup.data.data.users.rows;
      const allUsersNotInGroup = responseAllUserGroup.data.data.users.rows;
      const totalUsersNotInGroup = responseUserGroup.data.data.users.count;
      setGroupInfo({
        inTheGroup: usersInGroup,
        notInTheGroup: usersNotInGroup,
      });
      setUsersInTheGroupQ(totalUsersInGroup);
      setUsersNotInTheGroupQ(totalUsersNotInGroup);
      setAllElementsInTheGroup(allUsersInGroup);
      setAllElementsNotInTheGroup(allUsersNotInGroup);
    } else {
      const response = await getUsersPerPageByCompanyId(usersPage, rowsPerPage, '', false, []);
      const responseAllUsers = await getUsersPerPageByCompanyId(usersPage, 100000000, '', false, []);
      if (response.success) {
        const { users } = response.data.data;
        setGroupInfo({
          ...groupInfo,
          notInTheGroup: users,
        });
        setUsersNotInTheGroupQ(response.data.data.totalUsers);
        setAllElementsNotInTheGroup(responseAllUsers.data.data.users);
      }
    }
  };

  const requestFilter = async (id, pageOfTable, limit, searchValue, columnName) => {
    let response;
    const idsNotInTheGroup = allElementsNotInTheGroup.map((user) => user.id);
    const idsInTheGroup = allElementsInTheGroup.map((user) => user.id);
    if (inOrOut === IN_THE_GROUP) {
      response = await getFilteredUsersInGroup(
        id, pageOfTable, limit, searchValue.inTheGroup, columnName, idsNotInTheGroup, idsInTheGroup,
      );
      if (response.success) {
        const { users } = response.data.data;
        const filteredUsers = users.filter(
          (user) => allElementsNotInTheGroup.find(
            (userNotInGroup) => userNotInGroup.id === user.id,
          ) === undefined && allElementsInTheGroup.find(
            (userInGroup) => userInGroup.id === user.id,
          ) !== undefined,
        );
        setGroupInfo({
          ...groupInfo,
          inTheGroup: filteredUsers,
        });
        if (searchValue.InTheGroup === '') {
          setUsersInTheGroupQ(allElementsInTheGroup.length);
        } else {
          setUsersInTheGroupQ(filteredUsers.length);
        }
        setUsersNotInTheGroupQ(allElementsNotInTheGroup.length);
      }
    } else {
      response = await getFilteredUsersNotInGroup(
        id, pageOfTable, limit, searchValue.notInTheGroup,
        columnName, idsInTheGroup, idsNotInTheGroup,
      );
      if (response.success) {
        const { users } = response.data.data;
        const filteredUsers = users.filter(
          (user) => allElementsInTheGroup.find(
            (userInGroup) => userInGroup.id === user.id,
          ) === undefined && allElementsNotInTheGroup.find(
            (userNotInGroup) => userNotInGroup.id === user.id,
          ) !== undefined,
        );
        setGroupInfo({
          ...groupInfo,
          notInTheGroup: filteredUsers,
        });
        if (searchValue.notInTheGroup === '') {
          setUsersNotInTheGroupQ(allElementsNotInTheGroup.length);
        } else {
          setUsersNotInTheGroupQ(filteredUsers.length);
        }
        setUsersInTheGroupQ(allElementsInTheGroup.length);
      }
    }
  };

  useEffect(() => {
    if (inOrOut) {
      const pageToLoad = inOrOut === IN_THE_GROUP ? userGroupsPage : usersPage;
      requestFilter(userGroupId, pageToLoad, rowsPerPage, searchText, column);
    } else {
      findUsers();
      setInOrOut(NOT_IN_THE_GROUP);
    }
  }, [
    filter,
    column,
    inOrOut,
    setGroupInfo,
    userGroupId,
    userGroupsPage,
    usersPage,
    dragChange,
  ]);

  useEffect(() => {
    setUsersPage(0);
    if (inOrOut) {
      const pageToLoad = 0;
      requestFilter(userGroupId, pageToLoad, rowsPerPage, searchText, column);
    }
  }, [
    searchText,
  ]);

  return (
    <>
      <GroupsTable
        tab={0}
        create={create}
        groupId={userGroupId}
        attributes={attributes}
        tableHeaders={tableHeaders}
        findGroup={findUserGroup}
        findElements={findUsers}
        editFunction={editUserGroup}
        quantityElementsIn={usersInTheGroupQ}
        quantityElementsOut={usersNotInTheGroupQ}
        groupData={groupData}
        groupInfo={groupInfo}
        groupsPage={userGroupsPage}
        setGroupsPage={setUserGroupsPage}
        elementsPage={usersPage}
        setElementsPage={setUsersPage}
        elementType={elementType}
        inputValuesGroup={inputValuesGroup}
        setInputValuesGroup={setInputValuesGroup}
        setGroupToView={setGroupToView}
        handleCreate={handleCreate}
        handleChangeTab={handleChangeTab}
        setCreate={setCreate}
        selected={selected}
        setSelected={setSelected}
        setColumn={setColumn}
        searchText={searchText}
        setSearchText={setSearchText}
        setInOrOut={setInOrOut}
        setFilter={setFilter}
        setGroupInfo={setGroupInfo}
        onlyView={onlyView}
        setDragChange={setDragChange}
        dragChange={dragChange}
        allElementsIn={allElementsInTheGroup}
        setAllElementsIn={setAllElementsInTheGroup}
        allElementsOut={allElementsNotInTheGroup}
        setAllElementsOut={setAllElementsNotInTheGroup}
      />
    </>
  );
}

export default UserGroupTable;

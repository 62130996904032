import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
    '& button': {
      float: 'right',
    },
  },
  categoryTable: {
    '& .MuiTableCell-root': {
      border: 'none',
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-paddingNone': {
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& .MuiCheckbox-root': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableRow-root': {
      borderBottom: `1px solid ${theme.palette.primary.snow}`,
      '&:hover': {
        backgroundColor: 'rgb(44, 157, 201, 0.05)',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(44, 157, 201, 0.05)',
    },
  },
  iconsCell: {
    '& svg': {
      color: theme.palette.primary.gray,
      cursor: 'pointer',
    },
  },
  title: {
    flexGrow: 1,
    marginBottom: '20px',
    color: theme.palette.primary.main,
    fontSize: '24px',
  },
  noContent: {
    height: '710px',
  },
  progress: {
    height: '610px',
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  categoryInfo: {
    display: 'grid',
    gridTemplateColumns: '45% 45%',
    columnGap: '5%',
    padding: '30px',
    paddingTop: '8px',
    width: '70vw',
    borderRadius: '10px',
    '& .MuiInputLabel-formControl': {
      transform: 'none',
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.secondary.darkGray,
      fontSize: '14px',
    },
    '& .MuiFormControl-root': {
      marginTop: '25px',
    },
    '& input': {
      padding: '10px',
    },
    '& .MuiSelect-root': {
      padding: '10px',
      fontSize: '13.5px',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'end',
    gridColumn: 2,
    marginTop: '25px',
    '& button': {
      width: '50%',
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.secondary.blue,
      borderRadius: '10px',
    },
  },
  buttonColumn: {
    gridRow: 3,
  },
  categories: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& svg': {
      width: '40px',
      height: '40px',
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  header: {
    display: 'flex',
  },
  tableHeader: {
    color: theme.palette.secondary.gray,
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
    '& .MuiCheckbox-indeterminate ': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
  },
}));

export default useStyles;

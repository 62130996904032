import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { useStyles } from '@material-ui/core/styles';

import {
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  TextField,
} from '@material-ui/core';
import BackspaceIcon from '@material-ui/icons/Backspace';
import DeleteIcon from '@material-ui/icons/Delete';
import { red, blue, green } from '@material-ui/core/colors';
import GestureIcon from '@material-ui/icons/Gesture';

import useStyles from './styles';

export default function ImageButtonsAlternative({
  onColorChange, onErase, erase, setLines,
  shareType, facialScores, setFacialScores,
  currentImage, questionType,
}) {
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const styles = useStyles();

  const handleChange = (event, newValue) => {
    if (newValue === 'clean-all' || newValue === value) {
      setValue('');
    } else {
      setValue(newValue);
    }
  };

  const handlePresenceChange = (e) => {
    const faces = parseInt(e.target.value, 10);
    const row = facialScores[currentImage.id];
    row.presence = faces;
    setFacialScores({ ...facialScores, [currentImage.id]: row });
  };

  const handleTotalChange = (e) => {
    const faces = parseInt(e.target.value, 10);
    const row = facialScores[currentImage.id];
    row.total = faces;
    setFacialScores({ ...facialScores, [currentImage.id]: row });
  };

  return (
    <>
      <Grid container item xs={12} direction='column'>
        { questionType === 'multiple image question'
          ? (<>
            { shareType === 'facial-share'
              ? (
                  <>
                    <Grid item xs={12} style={{ paddingTop: '10px' }}>
                      <TextField
                        id='presence'
                        label={t('teleaudit.facialShare.presence')}
                        type='number'
                        value={facialScores[currentImage.id].presence}
                        onChange={(e) => {
                          handlePresenceChange(e);
                        }}/>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '10px' }}>
                      <TextField
                        id='total'
                        label='Total'
                        type='number'
                        value={facialScores[currentImage.id].total}
                        onChange={(e) => {
                          handleTotalChange(e);
                        }}/>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '160%' }}></Grid>
                  </>
              )
              : (
                <>
                  <Grid item xs={2} style={{ paddingTop: '10px', paddingLeft: '90%' }} >
                    <BottomNavigation
                      value={value}
                      onChange={handleChange}
                      className={styles.buttonsRoot}
                      showLabels
                      style={{ align: 'right' }}
                      >
                      <BottomNavigationAction
                        label={t('teleaudit.buttons.tray')}
                        value="blue"
                        onClick={() => onColorChange('blue')}
                        icon={<GestureIcon
                          style={{ color: blue[500] }}/>} />
                      </BottomNavigation>
                  </Grid>
                  <Grid item xs={2} style={{ paddingTop: '10px', paddingLeft: '90%' }}>
                    <BottomNavigation
                      value={value}
                      onChange={handleChange}
                      className={styles.buttonsRoot}
                      showLabels
                      >
                    <BottomNavigationAction
                      label={t('teleaudit.buttons.presence')}
                      value="red"
                      onClick={() => onColorChange('red')}
                      icon={<GestureIcon
                        style={{ color: red[500] }}/>} />
                    </BottomNavigation>
                  </Grid>
                  <Grid item xs={2} style={{ paddingTop: '10px', paddingLeft: '90%' }}>
                    <BottomNavigation
                      value={value}
                      onChange={handleChange}
                      className={styles.buttonsRoot}
                      showLabels
                      >
                      <BottomNavigationAction
                        label={t('teleaudit.buttons.rivalry')}
                        value="green"
                        onClick={() => onColorChange('green')}
                        icon={<GestureIcon
                          style={{ color: green[500] }}/>} />
                    </BottomNavigation>
                  </Grid>
                  <Grid item xs={2} style={{ paddingTop: '10px', paddingLeft: '90%' }}>
                    <BottomNavigation
                      value={value}
                      onChange={handleChange}
                      className={styles.buttonsRoot}
                      showLabels
                      >
                      <BottomNavigationAction
                        label={t('teleaudit.buttons.erase')}
                        value="clean-unique"
                        onClick={() => onErase(!erase)}
                        icon={<BackspaceIcon />} />
                    </BottomNavigation>
                  </Grid>
                  <Grid item xs={2} style={{ paddingTop: '10px', paddingLeft: '90%' }}>
                    <BottomNavigation
                      value={value}
                      onChange={handleChange}
                      className={styles.buttonsRoot}
                      showLabels
                      >
                      <BottomNavigationAction
                        label={t('teleaudit.buttons.cleanAll')}
                        value="clean-all"
                        onClick={() => setLines([])}
                        icon={<DeleteIcon />} />
                    </BottomNavigation>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: '116%' }}></Grid>
                </>
              )
            }
            </>
          )
          : null
        }
      </Grid>
    </>
  );
}

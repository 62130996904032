import React from 'react';
import { Line, Layer } from 'react-konva';

const ImageLines = ({
  lines, cleanLine, handleMouseUp, handleMouseMove, handleMouseDown,
}) => {
  // eslint-disable-next-line arrow-body-style
  // Se puede cambiar a algo mejor este renderedLines
  const renderedLines = lines.map((line) => (
      <Line
        key={line.id}
        x = {0}
        y = {0}
        points={ [line.x1, line.y1, line.x2, line.y2] }
        stroke={line.color}
        onClick={ () => {
          cleanLine(line.id);
        }}
        onMouseMove={(e) => {
          handleMouseMove(e);
        }}
        onMouseUp={() => {
          handleMouseUp();
        }}
        onMouseDown={(e) => {
          handleMouseDown(e);
        }}
        strokeWidth={5}
      />
  ));
  return (
    <Layer>
      {renderedLines}
    </Layer>
  );
};

export default ImageLines;

import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  noChallenges: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& svg': {
      width: '40px',
      height: '40px',
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  projectName: {
    paddingLeft: '16px',
  },
  icon: {
    '& svg': {
      color: '#2C9DC9',
    },
    '& svg:hover': {
      color: '#2C9DC9',
    },
  },
  header: {
    display: 'flex',
    paddingBottom: '20px',
  },
  title: {
    flexGrow: 1,
    letterSpacing: '-0.5px',
  },
  underlined: {
    textDecoration: 'underline',
  },
  searchToolbar: {
    '& .MuiOutlinedInput-root': {
      height: '35px',
      fontSize: '13px',
      width: '100vh',
      flexGrow: 1,
    },
    '& .Mui-focused': {
      border: `1px solid ${theme.palette.secondary.gray}`,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.gray,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '2px',
    },
    '& button': {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.secondary.gray,
      fontSize: '13.5px',
      '& svg': {
        color: theme.palette.secondary.gray,
      },
    },
    '& .MuiMenuItem-root': {
      color: 'black',
    },
  },
  boxTitle: {
    flexGrow: 1,
  },
  optionsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  formWrapper: {
    width: '70vw',
    paddingBottom: '10px',
    '& .MuiFormLabel-root': {
      color: '#808080',
      margin: '15px auto 8px',
      fontSize: '12px',
    },
    '& input': {
      backgroundColor: 'white',
      padding: '10px',
    },
    '& .MuiSelect-root': {
      padding: '10px',
      fontSize: '13.5px',
    },
  },
  challengeTable: {
    '& .MuiTableCell-root': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-paddingNone': {
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: 'rgb(44, 157, 201, 0.05)',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(44, 157, 201, 0.05)',
    },
  },
  tableHeader: {
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
    '& .MuiCheckbox-indeterminate ': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
  },
  progress: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  tag: {
    borderRadius: '5px',
    padding: '5px 8px',
    maxWidth: '160px',
  },
  tagOpen: {
    color: theme.palette.secondary.green,
    backgroundColor: theme.palette.secondary.lightGreen,
  },
  tagWaitinfForApproval: {
    color: theme.palette.secondary.orange,
    backgroundColor: theme.palette.secondary.lightOrange,
  },
  tagApproved: {
    color: theme.palette.secondary.purple,
    backgroundColor: theme.palette.secondary.lightPurple,
  },
  tagProcessing: {
    color: theme.palette.secondary.gray,
    backgroundColor: theme.palette.secondary.lightGray,
  },
  tagClose: {
    color: theme.palette.secondary.blue,
    backgroundColor: theme.palette.secondary.lightBlue,
  },
  tagRejected: {
    color: theme.palette.secondary.red,
    backgroundColor: theme.palette.secondary.lightRed,
  },
  tagPendingDate: {
    color: theme.palette.secondary.bluegray,
    backgroundColor: theme.palette.secondary.lightBluegray,
  },
  tagNA: {
    color: theme.palette.secondary.bluegray,
    backgroundColor: theme.palette.secondary.lightBluegray,
  },
  root: {
    width: '60vh',
    maxWidth: 500,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.blue,
  },
  success: {
    color: theme.palette.primary.success,
    borderColor: theme.palette.primary.success,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  error: {
    color: theme.palette.primary.error,
    borderColor: theme.palette.primary.error,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  warning: {
    color: theme.palette.primary.warning,
    borderColor: theme.palette.primary.warning,
    borderWidth: '2px',
    borderRadius: '6px',
    backgroundColor: theme.palette.transparent.warning,
  },
  processing: {
    color: theme.palette.primary.blue,
    borderColor: theme.palette.primary.blue,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  align: {
    textAlign: 'center',
    paddingTop: '15px',
  },
  input: {
    display: 'none',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    '& svg': {
      color: '#E74C3C',
      margin: '0px',
    },
    '& svg:hover': {
      opacity: '0.8',
    },
  },
  uploadIcon: {
    '& svg': {
      height: '50px',
      width: '100%',
      color: '#2C9DC9',
      margin: '0px',
    },
    '& svg:hover': {
      opacity: '0.8',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  labelDates: {
    margin: '0 0 0 8px !important',
  },
  formControlDates: {
    minWidth: 200,
    margin: '8px',
  },
}));

export const RedBorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#E74C3C',
  },
}))(LinearProgress);

export const YellowBorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#F1C40F',
  },
}))(LinearProgress);

export const GreenBorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#2ECC71',
  },
}))(LinearProgress);

export default useStyles;

import { combineReducers } from 'redux';
import accountReducer from './account';
import projectReducer from './project';
import ticketsReducer from './tickets';
import companyReducer from './company';
import locationReducer from './location';

const rootReducer = combineReducers({
  account: accountReducer,
  projects: projectReducer,
  locations: locationReducer,
  tickets: ticketsReducer,
  company: companyReducer,
});

export default rootReducer;

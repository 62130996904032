import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
  },
  register: {
    display: 'grid',
    gridTemplateColumns: '45% 45%',
    columnGap: '5%',
    padding: '30px',
    '& .MuiFormLabel-root': {
      color: theme.palette.secondary.darkGray,
      margin: '15px auto 8px',
      fontSize: '12px',
    },
    '& input': {
      backgroundColor: 'white',
      padding: '10px',
    },
    '& .MuiSelect-root': {
      padding: '10px',
      fontSize: '13.5px',
    },
  },
  selectRole: {
    marginBottom: '30px',
  },
}));

export default useStyles;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TextField as MuiTextField,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import {
  Droppable, Draggable,
} from 'react-beautiful-dnd';

// icons
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
// components
import Pagination from '../../../components/Pagination';

// functions
import { handleSelectAllClick, handleSelect, isSelected } from '../../../utils/selectFunctions';
import setFilterOptions from '../../../utils/filterFunctions';
import { parseDate } from '../../../utils/date';

import useStyles from './styles';

function TableComponent({
  element,
  tab,
  out,
  setOut,
  attributes,
  tableHeaders,
  selected,
  setSelected,
  info,
  page,
  setPage,
  rowsPerPage,
  prop,
  propShow,
  quantity,
  setColumn,
  searchText,
  setSearchText,
  setFilter,
  loading,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [infoShow, setInfoShow] = useState(info);

  const handleSearch = () => {
    if (searchText[propShow] === '' || out) {
      setInfoShow(info);
      return;
    }
    if (tab === 1 && searchText[propShow] && searchText[propShow].length > 0 && !out) {
      const infoCopy = { ...info };
      infoCopy[propShow] = info[propShow].filter(
        (row) => row.fullName.toLowerCase().includes(searchText[propShow].toLowerCase()),
      );
      setInfoShow(infoCopy);
    } else if (tab !== 1 && searchText[propShow] && searchText[propShow].length > 0) {
      const infoCopy = { ...info };
      infoCopy[propShow] = info[propShow].filter(
        (row) => row.name.toLowerCase().includes(searchText[propShow].toLowerCase()),
      );
      setInfoShow(infoCopy);
    } else {
      setInfoShow(info);
    }
  };

  const handleFilter = async (event, column) => {
    setOut(out);
    const { value } = event.target;
    setFilterOptions(value, column, setColumn, setSearchText, setFilter, searchText, prop);
    handleSearch();
  };

  useEffect(() => {
    handleSearch();
  }, [info]);

  useEffect(() => {
    if (quantity <= rowsPerPage) setPage(0);
  }, [page, rowsPerPage, quantity]);

  return (
    <Paper className={styles.groupTable}>
      <>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow className={styles.divider}>
                <TableCell colSpan={12}>
                  <MuiTextField
                    id={prop}
                    variant="outlined"
                    value={searchText[prop]}
                    fullWidth
                    className={styles.searchBar}
                    onChange={(e) => handleFilter(e, tab === 1 ? 'id,fullName' : 'name')}
                    placeholder={t('task.association.placeholder', { element })}
                    InputProps={{
                      startAdornment: <SearchIcon fontSize="small" />,
                      endAdornment: (
                        <IconButton
                          id="clear"
                          title="Clear"
                          aria-label="Clear"
                          size="small"
                          onClick={(e) => handleFilter(e, false)}
                          style={{ visibility: searchText ? 'visible' : 'hidden' }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.tableHeader} padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0
                      && selected.length < info[prop].length
                    }
                    checked={
                      info[prop] && info[prop].length > 0
                      && selected.length === info[prop].length
                    }
                    onChange={(event) => (
                      handleSelectAllClick(event, info[prop], true, setSelected)
                    )}
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
                {tableHeaders.map((tableHeader) => (
                  <TableCell
                    key={tableHeader.id}
                    className={styles.tableHeader}
                    align={
                      tableHeader.id === 'createdAt'
                        ? 'center'
                        : 'left'
                    }
                    padding="normal"
                  >
                    {tableHeader.id === 'name' && selected.length
                      ? `Selected ${selected.length} groups`
                      : tableHeader.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} className={styles.progress}>
                    <CircularProgress />
                    <h4>{t('task.loading')}</h4>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : null}
            {!loading ? (
              <Droppable droppableId={prop}>
                {(provided) => (
                  <TableBody
                    ref={provided.innerRef} {...provided.droppableProps}
                  >
                    {infoShow[propShow] && !infoShow[propShow].length ? (
                      <Draggable
                        draggableId="noContent"
                        index={0}
                        key="noContent"
                      >
                        {(prov) => (
                          <TableRow
                            id="noContent"
                            ref={prov.innerRef}
                            {...prov.draggableProps}
                            {...prov.dragHandleProps}
                            tabIndex={-1}
                            key="noContent"
                          >
                            <TableCell colSpan={12} align="center">
                              <p className={styles.emptySpace}>{t('task.association.empty', { element })}</p>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ) : (
                      infoShow[propShow] && infoShow[propShow].map((row, index) => {
                        const isItemSelected = isSelected(row.id, selected);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <Draggable
                            draggableId={`${row.id}`}
                            index={index}
                            key={row.id}
                          >
                            {(prov) => (
                              <TableRow
                                id={row.id}
                                ref={prov.innerRef}
                                {...prov.draggableProps}
                                {...prov.dragHandleProps}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={(event) => {
                                      handleSelect(event, {
                                        column: prop, id: row.id, index,
                                      }, selected, setSelected);
                                    }}
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </TableCell>
                                {attributes.map((attribute) => {
                                  if (tab === 1 && attribute.includes('full')) {
                                    return (
                                      <TableCell key={attribute} align="left">
                                        {row.fullName || row.full_name}
                                      </TableCell>
                                    );
                                  }
                                  if (Array.isArray(attribute)) {
                                    return (
                                      <TableCell key={attribute} align="left">
                                        {row[attribute[0]] ? row[attribute[0]][attribute[1]] : 'N/A'}
                                      </TableCell>
                                    );
                                  }
                                  if (typeof row[attribute] === 'boolean') {
                                    return (
                                      <TableCell key={attribute} align="center">
                                        {row[attribute] ? (
                                          <DoneIcon fontSize="small" />
                                        ) : (
                                          <ClearIcon fontSize="small" />
                                        )}
                                      </TableCell>
                                    );
                                  }
                                  if (tab !== 1 && row[attribute] && row[attribute].includes(':')) {
                                    return (
                                      <TableCell key={attribute} align="center">
                                        {parseDate(row[attribute])}
                                      </TableCell>
                                    );
                                  }
                                  return (
                                    <TableCell key={attribute} align="left">
                                      {row[attribute]}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            )}
                          </Draggable>
                        );
                      })
                    )
                    }
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            ) : null}
          </Table>
        </TableContainer>
        <Pagination
          setPage={setPage}
          page={page}
          rowsPerPage={rowsPerPage}
          quantity={quantity}
        />
      </>
    </Paper>
  );
}

export default TableComponent;

/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// MUI components
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

// MUI Icons
import ForwardIcon from '@material-ui/icons/Forward';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import useStyles, {
  GreenBorderLinearProgress,
  RedBorderLinearProgress,
  TableHead,
  YellowBorderLinearProgress,
} from './styles';
import { parseDate } from '../../../utils/date';
import TableToolbar from './TableToolbar';

const ExpandableRow = ({
  children, customKey, expandComponents, t,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const styles = useStyles();
  const currentPath = window.location.pathname;
  const account = useSelector((state) => state.account);

  const handleType = (state) => {
    switch (state) {
      case 'general':
        return (
          <b>{t('qa.taskResponseView.surveyExpandableRow.type.general')}</b>
        );
      case 'producto':
        return (
                <b>{t('qa.taskResponseView.surveyExpandableRow.type.product')}</b>
        );
      case 'product':
        return (
              <b>{t('qa.taskResponseView.surveyExpandableRow.type.product')}</b>
        );
      case 'checkin':
        return (
            <b>{t('qa.taskResponseView.surveyExpandableRow.type.checkin')}</b>
        );
      case 'entrenamiento':
        return (
            <b>{t('qa.taskResponseView.surveyExpandableRow.type.training')}</b>
        );
      default:
        return (
          <b>N/A</b>
        );
    }
  };

  const handleStatus = (surveyResponseReview) => {
    // Return same pending status
    if (surveyResponseReview === undefined || surveyResponseReview === null) {
      return (
        <p align="left" className={`${styles.tag} ${styles.tagPending}`}>O <b>{t('qa.taskResponseView.surveyExpandableRow.status.pending')}</b></p>
      );
    }
    switch (surveyResponseReview.status) {
      case 'rejected':
        return (
          <p align="left" className={`${styles.tag} ${styles.tagRejected}`}><b>{t('qa.taskResponseView.surveyExpandableRow.status.rejected')}</b></p>
        );
      case 'objected':
        return (
          <p align="left" className={`${styles.tag} ${styles.tagObjected}`}><b>X {t('qa.taskResponseView.surveyExpandableRow.status.objected')}</b></p>
        );
      case 'completed':
        return (
          <p align="left" className={`${styles.tag} ${styles.tagCompleted}`}><b>✔ {t('qa.taskResponseView.surveyExpandableRow.status.completed')}</b></p>
        );
      case 'pending':
      default:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagPending}`}>O <b>{t('qa.taskResponseView.surveyExpandableRow.status.pending')}</b></p>
        );
    }
  };

  return (
    <React.Fragment key={customKey}>
      <TableRow key={`header-${customKey}`} onClick={() => setIsExpanded(!isExpanded)}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
          {children}
      </TableRow>
      <TableRow key={`rest-${customKey}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="left"><b>{t('qa.taskResponseView.surveyExpandableRow.tableHeaders.image')}</b></TableCell>
                    <TableCell align="left"><b>{t('qa.taskResponseView.surveyExpandableRow.tableHeaders.title')}</b></TableCell>
                    <TableCell align="left"><b>{t('qa.taskResponseView.surveyExpandableRow.tableHeaders.type')}</b></TableCell>
                    <TableCell align="center"><b>{t('qa.taskResponseView.surveyExpandableRow.tableHeaders.status')}</b></TableCell>
                    <TableCell align="center"><b>{t('qa.taskResponseView.surveyExpandableRow.tableHeaders.startedAt')}</b></TableCell>
                    <TableCell align="center"><b>{t('qa.taskResponseView.surveyExpandableRow.tableHeaders.finishedAt')}</b></TableCell>
                    <TableCell align="center"><b>{t('qa.taskResponseView.surveyExpandableRow.tableHeaders.check')}</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expandComponents
                    .map((row) => (
                      <>
                        <TableRow
                          hover
                          key={row.id}
                        >
                        <TableCell padding="checkbox" />
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          className={styles.projectName}
                        >{row.Survey.imageUrl
                          && <img
                            className={styles.logoUrl}
                            src={row.Survey.imageUrl}
                            alt="N/A" />}
                        </TableCell>
                        <TableCell align="left" width="30%">{row.Survey.title ? row.Survey.title : 'nt no survey title'}</TableCell>
                        <TableCell align="left">{handleType(row.Survey.type)}</TableCell>
                        <TableCell align="center">{handleStatus(row.SurveyResponseReview)}</TableCell>
                        <TableCell align="center">{parseDate(row.startedAt)}</TableCell>
                        <TableCell align="center">{parseDate(row.finishedAt)}</TableCell>
                        <TableCell align="center">
                          {(row.SurveyResponseReview
                            && row.SurveyResponseReview.status === 'pending')
                            ? (parseInt(account.user.id, 10) === row.SurveyResponseReview.reviewedBy
                              ? <IconButton component={Link} to={`${currentPath}/${row.id}`}>
                                <ForwardIcon title={t('qa.continue')} />
                              </IconButton>
                              : <VisibilityOffIcon title={t('qa.underReview')} />
                            )
                            : <IconButton component={Link} to={`${currentPath}/${row.id}`}>
                              <RemoveRedEyeIcon title={t('qa.review')} />
                            </IconButton>
                          }
                        </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default function ExpandableRowTable({
  data,
  categoriesQuantity,
  taskResponseId,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  if (categoriesQuantity === 0) {
    return (
      <Paper>
        <TableToolbar/>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} className={styles.users}>
                  <h4>{t('qa.taskResponseView.noCategories')}</h4>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  return (
    <Paper className={styles.categoryListTable}>
      <TableToolbar taskResponse={taskResponseId} />
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell colSpan={2} align='left'>
                <b>{t('qa.taskResponseView.tableHeaders.categoryName')}</b>
              </TableCell>
              <TableCell colSpan={2} align='left'>
                <b>{t('qa.taskResponseView.tableHeaders.numberOfSurveyResponses')}</b>
              </TableCell>
              <TableCell colSpan={2} align='center'>
                <b>{t('qa.taskResponseView.tableHeaders.completedForms')}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map(([categoryName, value]) => {
              const fixedCategory = categoryName === null || categoryName === 'No Category'
                ? t('qa.taskResponseView.surveyExpandableRow.noCategory') : categoryName;
              return (
              <ExpandableRow
                key={fixedCategory}
                customKey={fixedCategory}
                expandComponents={value.surveyResponses}
                t={t}
              >
                <TableCell component="th" scope="row" colSpan={2} align="left">
                  <b>{fixedCategory}</b>
                </TableCell>
                <TableCell component="th" scope="row" colSpan={2} align="left">
                  <b>{value.surveyResponses.length} {value.surveyResponses.length <= 1
                    ? t('qa.taskResponseView.surveyExpandableRow.survey') : t('qa.taskResponseView.surveyExpandableRow.surveys')}</b>
                </TableCell>
                <TableCell component="th" scope="row" colSpan={2} align="center">
                  {value.percentage < 30
                    ? <>
                    <RedBorderLinearProgress variant="determinate" value={value.percentage}/> {value.percentage}%
                  </>
                    : (value.percentage < 100 && value.percentage >= 30 ? <>
                    <YellowBorderLinearProgress variant="determinate" value={value.percentage}/> {value.percentage}%
                    </>
                      : <>
                    <GreenBorderLinearProgress variant="determinate" value={value.percentage}/> {value.percentage}%
                    </>
                    )
                  }
                </TableCell>
              </ExpandableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

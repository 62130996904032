import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR, OK } from '../../utils/const/http';

const getDocumentsPerPage = async (page, limit, searchValue) => {
  try {
    const response = await axios.get(`/api/documents?page=${page}&limit=${limit}&searchValue=${searchValue}`);
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postNewDocument = async ({
  name,
  fileKey,
}) => {
  try {
    const response = await axios.post('/api/documents', {
      name,
      fileKey,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getTermsAndConditons = async () => {
  try {
    const response = await axios.get('/api/documents/terms-and-conditions');
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  getDocumentsPerPage,
  postNewDocument,
  getTermsAndConditons,
};

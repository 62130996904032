/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// core
import {
  Typography,
  TextField as MuiTextField,
  Paper,
  CircularProgress,
  Box,
  IconButton,
  Toolbar,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

// icons
import WarningIcon from '@material-ui/icons/Warning';
import ReportIcon from '@material-ui/icons/Report';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import sleep from '../../../utils/sleep';
// components
import useStyles from './styles';
import { getReports } from '../../../requests/api/report';
import Snackbar from '../../../components/Snackbar';
import Pagination from '../../../components/Pagination';
import { parseDate } from '../../../utils/date';
// import UserInformation from './UserInformation';
// const
import { UNAUTHORIZED } from '../../../utils/const/http';

// eslint-disable-next-line no-shadow
const ReportsList = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [reportsQuantity, setReportsQuantity] = useState(0);

  // auxiliar states

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');

  const account = useSelector((state) => state.account);

  // Filter states
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  const requestSearch = async (pageOfTable, limit, searchValue, columnName) => {
    const response = await getReports(pageOfTable, limit, searchValue, columnName);

    if (response.success) {
      setRowsToShow(response.data.data.powerbiReports);
      setReportsQuantity(response.data.data.totalPowerbiReports);
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const setFilterOptions = async (newValue, tableColumn) => {
    setSearchText(newValue);
    await sleep(600);
    setColumn(tableColumn);
    setFilter(newValue);
    setPage(0);
    // Clear filters
    if (!tableColumn) {
      setSearchText('');
      setFilter('');
    }
  };

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    load();
  }, [rowsToShow]);

  useEffect(() => {
    requestSearch(page, rowsPerPage, filter, column);
  }, [page, rowsPerPage, filter, column, reportsQuantity, account]);

  const gridContent = () => {
    if (!reportsQuantity) {
      return (
        <>
          <div className={styles.noReports}>
            <div className={styles.container}>
            <ConfirmationNumberIcon /><h4>{t('reports.noReports')}</h4>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
      <Grid container direction='row' spacing={3}>
        {rowsToShow
          .map((row) => (
            <Grid key={row.reportName} className={styles.reportGrid} item xs={6} md={4} xl={3} >
                <Card className={styles.reportCard}>
                  <CardActionArea component={Link} to={`reports/${row.id}`} disabled={row.warningStatus === 'high'}>
                    <div>
                      <Box sx={{ position: 'relative' }}>
                        {!row.fileUrl ? <div
                          style={{
                            display: 'flex',
                            alignItem: 'center',
                            justifyContent: 'center',
                            filter: row.warningStatus === 'high' ? 'grayscale(100%)' : 'grayscale(0%)',
                          }}>
                            <CardMedia
                              style={{
                                width: 'auto',
                                maxHeight: '150px',
                              }}
                              component="img"
                              image="/rounded_tcg.png"
                            />
                          </div>
                          : <CardMedia
                              height="150"
                              component="img"
                              image={row.fileUrl}
                              alt="img"
                              style={{
                                width: 'available',
                                maxHeight: '150px',
                                filter: row.warningStatus === 'high' ? 'grayscale(100%)' : 'grayscale(0%)',
                              }}
                            />
                        }

                        {row.warningStatus === 'high'
                          ? <Box className={styles.highWarning}>
                            <Grid container>
                              <Grid item xs={2} justifyContent="center"alignItems="center" >
                                <ReportIcon fontSize="large"/>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant="body2">{row.warningMessage}</Typography>
                              </Grid>
                            </Grid>
                      </Box> : null}
                      {row.warningStatus === 'low'
                        ? <Box className={styles.lowWarning}>
                            <Grid container>
                              <Grid item xs={2} justifyContent="center"alignItems="center" >
                                <WarningIcon fontSize="large"/>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant="body2">{row.warningMessage}</Typography>
                              </Grid>
                            </Grid>
                      </Box> : null}
                      </Box>
                    </div>
                    <CardContent>
                      <Typography variant="h5" component="div" color="textPrimary" >
                        {row.reportName}
                      </Typography>
                        {!row.reportDescription ? (
                      <Typography variant="body2" color="text.secondary">
                        {t('reports.noDescription')}
                      </Typography >
                        )
                          : <Typography variant="body2" color="text.secondary">
                        {row.reportDescription}
                        </Typography>}
                      <Divider variant="middle" className={styles.divider}/>
                      <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Typography variant="body2" color="text.secondary">
                          {t('reports.createdAt')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="text.secondary">
                            {parseDate(row.createdAt)}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/*
                      <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Typography variant="body2" color="text.secondary">
                          {t('reports.updatedAt')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="text.secondary">
                            {parseDate(row.updatedAt)}
                          </Typography>
                        </Grid>
                      </Grid>
                      */}
                    </CardContent>
                  </CardActionArea>
                </Card>
            </Grid>
          ))}
      </Grid>
      </>
    );
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h1' className={styles.title} gutterBottom>
            {t('reports.title')}
          </Typography>
          <Typography variant='h4' gutterBottom display="inline">
          {t('support.subtitle')}
          </Typography>
          <Typography variant='subtitle1' className={styles.underlined} gutterBottom display="inline">
          {reportsQuantity} reportes
          </Typography>
        </Box>
      </div>
      <Toolbar className={styles.toolbarTable} position='fixed' disableGutters>
        <Box className={styles.boxTitle}>
          <MuiTextField
                className={styles.searchField}
                id="search"
                variant="outlined"
                value={searchText}
                onChange={(event) => setFilterOptions(event.target.value, 'reportName')}
                placeholder={t('reports.searchPlaceHolder')}
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" />,
                  endAdornment: (
                    <IconButton
                      id="clear"
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      onClick={() => setFilterOptions('', false)}
                      style={{ visibility: searchText ? 'visible' : 'hidden' }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
          </Box>
      </Toolbar>
          <>
          {loading ? (
            <CardContent className={styles.cardProgress}>
              <div className={styles.container}>
                <CircularProgress/>
              </div>
            </CardContent>
          ) : gridContent()}
          </>
      <Paper className={styles.reportTable}>
      <Pagination
              setPage={setPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={reportsQuantity}
            />
        </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </div>
  );
};

export default ReportsList;

import React from 'react';
import { Rect, Layer } from 'react-konva';

const ImageRects = ({
  lines, cleanLine, handleMouseDown, handleMouseMove, handleMouseUp,
}) => {
  const renderedRects = lines.map((line) => (
      <Rect
        key={line.id}
        x = {line.x1}
        y = {line.y1}
        width={line.x2 - line.x1}
        height={line.y2 - line.y1}
        stroke={line.color}
        onClick={ () => {
          cleanLine(line.id);
        }}
        onMouseDown={(e) => {
          handleMouseDown(e);
        }}
        onMouseMove={(e) => {
          handleMouseMove(e);
        }}
        onMouseUp={() => {
          handleMouseUp();
        }}
        fill={line.color}
        opacity={line.color === 'blue' ? 0.5 : 0.3 }
        />
  ));
  return (
    <Layer>
      {renderedRects}
    </Layer>
  );
};

export default ImageRects;

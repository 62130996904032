import React, { useEffect, useState } from 'react';
import BackIcon from '@material-ui/icons/ReplyOutlined';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import clsx from 'clsx';

// UI Components
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';

import Button from '../../../components/Button';
import Snackbar from '../../../components/Snackbar';
import ApproveSurveyDialog from './approveSurveyDialog';
import RejectSurveyDialog from './rejectSurveyDialog';
import RejectSelectedQuestionsResponsesDialog from './rejectSelectedQuestionsResponsesDialog';

import Pagination from '../../../components/Pagination';
import SurveyImageCarousel from './imagesCarousel';
import useStyles, { TableHead } from './styles';

// API calls
import { OK, CONFLICT } from '../../../utils/const/http';
import { startSurveyResponseReview, getSurveyResponseById } from '../../../requests/api/survey-response';
import SignatureCanvas from './SignatureCanvas';

const QuestionResponsesView = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { surveyResponseId } = useParams();

  // auxiliar states
  const [loading, setLoading] = useState(false);
  const [surveyResponse, setSurveyResponse] = useState({});
  const [selectedQuestionResponseList, setSelectedQuestionResponseList] = useState([]);
  const [selectedQuestionResponseImageList, setSelectedQuestionResponseImageList] = useState([]);

  // Return to survey list logic.
  const historyReactRouter = useHistory();
  const currentPath = window.location.pathname;
  const surveyResponsePath = `/${currentPath.split('/')[1]}/${currentPath.split('/')[2]}`;
  const [finishReview, setFinishReview] = useState(false);

  // Show/hide dialogs
  const [openApproveSurveyDialog, setOpenApproveSurveyDialog] = useState(false);
  const [openRejectSomeQuestionsSurveyDialog,
    setOpenRejectSomeQuestionsSurveyDialog] = useState(false);
  const [openRejectSurveyDialog, setOpenRejectSurveyDialog] = useState(false);
  const [showEmptySelectedQuestionResponseListSnack,
    setShowEmptySelectedQuestionResponseListSnack] = useState(false);

  const rowsPerPage = 5;
  const [questionResponsesQuantity, setQuestionResponsesQuantity] = useState(0);
  const [questionResponses, setQuestionResponses] = useState([]);
  const [imageQuestionResponses, setImageQuestionResponses] = useState([]);
  const account = useSelector((state) => state.account);
  const [page, setPage] = useState(0);

  /**
   * Set survey response review as Pending state.
   *
   * @param {int} surveyResponseId Survey response identifier
   */
  /* eslint-disable no-shadow */
  const markStartSurveyResponseReview = async (surveyResponseId) => {
    const a = await startSurveyResponseReview(surveyResponseId);

    if (a.status === OK.status) {
      // no op
      // console.log('Starting Task response review');
    } else if (
      a.status === CONFLICT.status
      && parseInt(account.user.id, 10) !== a.data.surveyResponseReview.reviewedBy) {
      // Already started
      //      alert(a.data.errorMessage);
    }
  };

  useEffect(() => {
    markStartSurveyResponseReview(surveyResponseId);
  }, []);

  /**
   * Load survey response from backend.
   */
  useEffect(() => {
    setLoading(true);
    async function getSurveyResponseData() {
      const surveyResponseData = await getSurveyResponseById(surveyResponseId);
      if (surveyResponseData.success) {
        const nonImageQuestions = surveyResponseData.data.data.QuestionResponses.filter(
          (qr) => {
            const lowerCaseType = qr.Question.type.toLowerCase();
            return (!lowerCaseType.includes('image')
              && !lowerCaseType.includes('page')
              && !lowerCaseType.includes('content'));
          },
        );
        const imageQuestions = surveyResponseData.data.data.QuestionResponses.filter(
          (qr) => qr.Question.type.toLowerCase().includes('image'),
        );
        setSurveyResponse(surveyResponseData.data.data);
        setQuestionResponses(nonImageQuestions.slice(
          page * rowsPerPage, rowsPerPage * (page + 1),
        ));
        setImageQuestionResponses(imageQuestions);
        setQuestionResponsesQuantity(nonImageQuestions.length);
      }
    }
    getSurveyResponseData();
  }, [page]);

  /**
   * Disable loading state when survey response data is set.
   */
  useEffect(() => {
    setLoading(false);
  }, [surveyResponse]);

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  /**
   * Function to handle show/hide approve dialog. Triggered from Approve button.
   */
  const handleOpenApproveSurveyDialog = () => {
    setOpenApproveSurveyDialog(true);
  };

  /**
   * Function to handle show/hide reject dialog. Triggered from Reject button.
   */
  const handleOpenRejectSurveyDialog = () => {
    setOpenRejectSurveyDialog(true);
  };

  /**
   * Function to handle show/hide reject some questions dialog. Triggered from
   * Reject selected button.
   */
  const handleOpenRejectSomeQuestionsSurveyDialog = () => {
    if (selectedQuestionResponseList.length === 0
      && selectedQuestionResponseImageList.length === 0) {
      setShowEmptySelectedQuestionResponseListSnack(true);
    } else {
      setOpenRejectSomeQuestionsSurveyDialog(true);
    }
  };

  /**
   * Receive notification from Dialogs components to return to list of surveys
   * responses of a task after a survey response review is saved.
   */
  useEffect(() => {
    if (finishReview) {
      historyReactRouter.push(surveyResponsePath);
    }
  }, [finishReview]);

  /**
   * Keep a list of question responses that need to be rejected.
   *
   * @param {int} questionResponseId Question Response identifier
   * @returns {undefined}
   */
  const handleSelectedQuestionResponse = (questionResponseId) => {
    const indexOf = selectedQuestionResponseList.indexOf(questionResponseId);
    const tempSelectedQuestionResponseList = selectedQuestionResponseList;

    if (indexOf !== -1) {
      tempSelectedQuestionResponseList.splice(indexOf, 1);
    } else {
      tempSelectedQuestionResponseList.push(questionResponseId);
    }
    setSelectedQuestionResponseList(tempSelectedQuestionResponseList);
  };

  const tableHeaders = [
    {
      id: 'title',
      label: <b>{t('qa.questionResponseView.tableHeaders.title')}</b>,
    },
    {
      id: 'description',
      label: <b>{t('qa.questionResponseView.tableHeaders.description')}</b>,
    },
    {
      id: 'type',
      label: <b>{t('qa.questionResponseView.tableHeaders.type')}</b>,
    },
    {
      id: 'answer',
      label: <b>{t('qa.questionResponseView.tableHeaders.answer')}</b>,
    },
    {
      id: 'check',
      label: <b>{t('qa.questionResponseView.tableHeaders.check')}</b>,
    },
  ];

  const handleType = (type) => {
    switch (type) {
      case 'content':
        return (
          <b>{t('qa.questionResponseView.questionTypes.content')}</b>
        );
      case 'image':
        return (
          <b>{t('qa.questionResponseView.questionTypes.image')}</b>
        );
      case 'end page':
        return (
          <b>{t('qa.questionResponseView.questionTypes.endPage')}</b>
        );
      case 'long answer':
        return (
          <b>{t('qa.questionResponseView.questionTypes.longAnswer')}</b>
        );
      case 'numeric answer':
        return (
          <b>{t('qa.questionResponseView.questionTypes.numericAnswer')}</b>
        );
      case 'nps question':
        return (
          <b>{t('qa.questionResponseView.questionTypes.npsQuestion')}</b>
        );
      case 'drop down menu':
        return (
          <b>{t('qa.questionResponseView.questionTypes.dropDownMenu')}</b>
        );
      case 'simple selection':
        return (
          <b>{t('qa.questionResponseView.questionTypes.simpleSelection')}</b>
        );
      case 'satisfaction':
        return (
          <b>{t('qa.questionResponseView.questionTypes.satisfaction')}</b>
        );
      case 'short answer':
        return (
          <b>{t('qa.questionResponseView.questionTypes.shortAnswer')}</b>
        );
      case 'short-text':
        return (
          <b>{t('qa.questionResponseView.questionTypes.shortText')}</b>
        );
      case 'signature question':
        return (
          <b>{t('qa.questionResponseView.questionTypes.signature')}</b>
        );
      case 'text':
        return (
          <b>{t('qa.questionResponseView.questionTypes.text')}</b>
        );
      case 'multiple selection':
        return (
          <b>{t('qa.questionResponseView.questionTypes.multipleSelection')}</b>
        );
      case 'yes/no question':
        return (
          <b>{t('qa.questionResponseView.questionTypes.yesNoQuestion')}</b>
        );
      case 'welcome page':
        return (
          <b>{t('qa.questionResponseView.questionTypes.welcomePage')}</b>
        );
      case 'multiple image question':
        return (
          <b>{t('qa.questionResponseView.questionTypes.multipleImageQuestion')}</b>
        );
      case 'date':
        return (
          <b>{t('qa.questionResponseView.questionTypes.date')}</b>
        );
      case 'barcode question':
        return (
          <b>{t('qa.questionResponseView.questionTypes.barcode')}</b>
        );
      case 'decimal answer':
        return (
          <b>{t('qa.questionResponseView.questionTypes.decimal')}</b>
        );
      case 'price':
        return (
          <b>{t('qa.questionResponseView.questionTypes.price')}</b>
        );
      default:
        return (
          <b>{t('qa.questionResponseView.questionTypes.default')}</b>
        );
    }
  };

  const handleQuestionResponseValue = (questionResponse) => {
    let htmlResponse = '';

    const dataString = questionResponse.value;
    let data;
    if (dataString) {
      data = dataString.split('_').map((point) => {
        const coordinates = point.split(',');
        return { x: parseFloat(coordinates[0]), y: parseFloat(coordinates[1]) };
      });
    }
    switch (questionResponse.Question.type) {
      case 'long answer':
      case 'numeric answer':
      case 'short answer':
      case 'short-text':
      case 'date':
      case 'price':
      case 'decimal answer':
      case 'barcode question':
      case 'nps question':
        htmlResponse = questionResponse.value ? questionResponse.value : 'SIN RESPUESTA';
        break;
      case 'text':
        htmlResponse = questionResponse.value ? questionResponse.value : 'SIN RESPUESTA';
        break;
      case 'drop down menu':
        try {
          const selectedQuestionChoiceId = questionResponse
            .QuestionChoiceResponses[0].questionChoiceId;
          const selectedQuestionChoice = questionResponse
            .Question.QuestionChoices.filter(
              (questionChoice) => questionChoice.id === selectedQuestionChoiceId,
            )[0];
          htmlResponse = selectedQuestionChoice.title;
        } catch (e) {
          htmlResponse = 'SIN RESPUESTA';
        }
        break;
      case 'simple selection':
        try {
          const selectedQuestionChoiceId = questionResponse
            .QuestionChoiceResponses[0].questionChoiceId;
          const selectedQuestionChoice = questionResponse
            .Question.QuestionChoices.filter(
              (questionChoice) => questionChoice.id === selectedQuestionChoiceId,
            )[0];
          htmlResponse = selectedQuestionChoice.title;
        } catch (e) {
          htmlResponse = 'SIN RESPUESTA';
        }
        break;
      case 'satisfaction':
        htmlResponse = questionResponse.value ? questionResponse.value : 'SIN RESPUESTA';
        // htmlResponse = t('qa.questionResponseView.questionTypes.satisfaction');
        break;
      case 'signature question':
        htmlResponse = data ? <SignatureCanvas data={data} /> : 'Firma';
        break;
      case 'multiple selection':
        try {
          const arrayText = [];
          questionResponse.QuestionChoiceResponses.forEach((questionChoiceResponse) => {
            const selectedQuestionChoiceId = questionChoiceResponse.questionChoiceId;
            const selectedQuestionChoice = questionResponse
              .Question.QuestionChoices.filter(
                (questionChoice) => questionChoice.id === selectedQuestionChoiceId,
              )[0];
            arrayText.push(selectedQuestionChoice.title);
          });
          htmlResponse = arrayText.join(', ');
        } catch (e) {
          htmlResponse = 'SIN RESPUESTA';
        }
        break;
      case 'yes/no question':
        try {
          const selectedQuestionChoiceId = questionResponse
            .QuestionChoiceResponses[0].questionChoiceId;
          const selectedQuestionChoice = questionResponse
            .Question.QuestionChoices.filter(
              (questionChoice) => questionChoice.id === selectedQuestionChoiceId,
            )[0];
          htmlResponse = selectedQuestionChoice.title;
        } catch (e) {
          htmlResponse = 'SIN RESPUESTA';
        }
        break;
      case 'content':
      case 'image':
      case 'end page':
      case 'welcome page':
      default: htmlResponse = t('qa.questionResponseView.questionTypes.noAnswer');
    }
    return htmlResponse;
  };

  /* Build questions responses list */
  const questionsTableRows = () => {
    if (questionResponses.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={9} className={styles.users}>
            <h4>{t('qa.noTaskResponses')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {questionResponses
          .map((row) => (
            <TableRow
              hover
              key={row.id}
            >{<>
              <TableCell
                component="th"
                scope="row"
                padding="none"
                className={styles.questionsTitle}
                width="25%"
              >{row.Question.title ? row.Question.title : t('qa.questionResponseView.noTitle')}</TableCell>
              <TableCell align="left" width="30%">{row.Question.description
                ? row.Question.description : t('qa.questionResponseView.noDescription')}</TableCell>
              <TableCell align="left" width="25%">{handleType(row.Question.type)}</TableCell>
              <TableCell align="left" >{handleQuestionResponseValue(row)}</TableCell>
              <TableCell align="center">
                <Checkbox
                  onChange={() => handleSelectedQuestionResponse(row.id)}
                />
              </TableCell>
            </>
              }
            </TableRow>
          ))}
      </>
    );
  };
  return (
    <div className={styles.content}>
      <IconButton
        id="backButton"
        aria-label="back"
        className={styles.backButtonQuestionResponse}
        onClick={() => {
          historyReactRouter.push(surveyResponsePath);
        }}
      >
        <BackIcon fontSize="small" />
        <span>{t('groups.actionButtons.back')}</span>
      </IconButton>
      {/* Header with title */}
      <div className={styles.header}>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h1' className={styles.title} gutterBottom>
            {t('qa.questionResponseView.title')}
          </Typography>
          <Typography variant='h4' gutterBottom display="inline">
            { /* t('qa.questionResponseView.subtitle') */}
            {surveyResponse.Survey ? surveyResponse.Survey.title : ''}
          </Typography>
        </Box>
      </div>

      {/* Content */}
      <Paper className={styles.documentsTable}>
        <>

          {/* Superior toolbar */}
          <Toolbar className={styles.toolbarTable}>
            <Typography className={styles.toolbarButton}> </Typography>

            {/* Button Finish review */}
            <Button
              id={1}
              text={t('qa.rejectSelected')}
              variant="outlined"
              width="100%"
              onClick={handleOpenRejectSomeQuestionsSurveyDialog}
            />

            {/* Button Finish review */}
            <Button
              id={2}
              text={t('qa.reject')}
              variant="outlined"
              width="100%"
              onClick={handleOpenRejectSurveyDialog}
            />

            {/* Button Finish review */}
            <Button
              id={3}
              text={t('qa.approve')}
              variant="outlined"
              width="100%"
              onClick={handleOpenApproveSurveyDialog}
            />
          </Toolbar>
        </>

        {/* Carrousel for image responses */}
        <Grid container direction='row' alignItems='center' spacing={3}>
          <Grid item xs={12}>
            <Card className={styles.carrousel}>
              <CardContent>
                <>
                  <Grid alignItems='center' container>
                    <Grid item xs md={9} xl={10}>
                      <Typography variant='h2'>
                        {t('qa.questionResponseView.imageTitle')}
                      </Typography>
                    </Grid>
                  </Grid>
                  {
                    imageQuestionResponses.length > 0
                      ? <SurveyImageCarousel
                        surveyResponseId={surveyResponseId}
                        questionResponseWithImageList={imageQuestionResponses}
                        selectedQuestionResponseImageList={selectedQuestionResponseImageList}
                        setSelectedQuestionResponseImageList={
                          setSelectedQuestionResponseImageList
                        }
                      />
                      : <>
                      </>
                  }
                </>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>

      {/* Questions responses */}
      <Paper className={styles.questionListTable}>
        <Table
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {tableHeaders.map((tableHeader) => (
                <TableCell
                  key={tableHeader.id}
                  className={clsx({
                    [styles.leftHeader]: true,
                    [styles.centerHeader]: ['check'].includes(tableHeader.id),
                  })}
                  padding="normal"
                  align="left"
                >
                  {tableHeader.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={9} className={styles.progress}>
                  <CircularProgress />
                  <h4>{t('qa.loading')}</h4>
                </TableCell>
              </TableRow>
            ) : questionsTableRows()}
          </TableBody>
        </Table>

        { /* Pagination stripe */}
        <Pagination
          setPage={setPage}
          handleChangePage={handleChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
          quantity={questionResponsesQuantity}
        />
      </Paper>

      { /* Dialog to finish revisions */}
      <ApproveSurveyDialog
        surveyResponseId={surveyResponseId}
        openDialog={openApproveSurveyDialog}
        setOpenDialog={setOpenApproveSurveyDialog}
        setFinishReview={setFinishReview}
      />
      <RejectSurveyDialog
        surveyResponseId={surveyResponseId}
        openDialog={openRejectSurveyDialog}
        setOpenDialog={setOpenRejectSurveyDialog}
        setFinishReview={setFinishReview}
      />
      <RejectSelectedQuestionsResponsesDialog
        surveyResponseId={surveyResponseId}
        openDialog={openRejectSomeQuestionsSurveyDialog}
        setOpenDialog={setOpenRejectSomeQuestionsSurveyDialog}
        setFinishReview={setFinishReview}
        selectedQuestionResponseList={selectedQuestionResponseList}
        selectedQuestionResponseImageList={selectedQuestionResponseImageList}
      />

      { /* Snack message for empty selected question list when rejecting
        selected questions */ }
      <Snackbar
        open={showEmptySelectedQuestionResponseListSnack}
        message={t('qa.selectAtLeastOneAnswer')}
        severity="error"
        onClose={() => setShowEmptySelectedQuestionResponseListSnack(false)}
      />
    </div>
  );
};

export default QuestionResponsesView;

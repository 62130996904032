/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Button, Checkbox, FormControlLabel, TextField, IconButton,
  Tooltip, Grid, List, ListItem, ListItemText,
} from '@material-ui/core';
// icons

import DeleteIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import useStyles from '../styles';
import ExampleImageQuestion from '../../ExampleImageQuestion';
import EmailsBackup from '../../EmailsBackup';

import {
  EDIT_MODE, VIEW_MODE,
} from '../../../utils/const/surveyModes';

function MultipleSelection({
  identifier, pages, info, setUpdate, handleDeleteQuestion,
  mode, // formType
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const initialId = 1;
  const [correct, setCorrect] = useState([]);
  let correctIds = [];
  // eslint-disable-next-line no-unused-vars
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [answerChecked, setAnswerChecked] = useState([]);
  const [options, setOptions] = useState(info ? info.QuestionChoices
    : pages[identifier - 1].questionChoices || []);

  const [open, setOpen] = useState(false);
  const [openEmails, setOpenEmails] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);
  const [file, setFile] = useState({});
  const [image, setImage] = useState({});
  useEffect(() => {
    if (info !== undefined) {
      setImage({
        ...image,
        url: info.imageUrl,
        name: info.imageKey,
      });
      if (
        info.QuestionEmails !== undefined
        && info.QuestionEmails.length !== 0) {
        setEmailsArray(info.QuestionEmails[0].emails.split(','));
      }
      setSendEmail(info.sendEmail);
      const corrects = info.CorrectQuestionChoices;
      correctIds = corrects.map((x) => x.questionChoiceId);
      const checkedCorrect = {};
      // eslint-disable-next-line array-callback-return
      info.QuestionChoices.map((x) => {
        if (correctIds.includes(x.id)) {
          checkedCorrect[x.id - 1] = true;
        } else {
          checkedCorrect[x.id - 1] = false;
        }
      });
      setAnswerChecked(checkedCorrect);
      setCorrectAnswers(correctIds);
      setOptions(info.QuestionChoices);
    } else if (pages && pages[identifier - 1].questionChoices) {
      const { questionChoices } = pages[identifier - 1];
      const optionsArray = [];
      let identifierChoice = 0;
      questionChoices.forEach((questionChoice) => {
        identifierChoice += 1;
        optionsArray.push({
          title: questionChoice.title,
          type: questionChoice.type,
          identifierOrder: `${identifierChoice}`,
          identifier: questionChoice.id,
          id: questionChoice.id,
        });
      });
      if (pages[identifier - 1].correctQuestionChoices) {
        const arrayAnswerChecked = [];
        const correctArray = [...correct];
        const correctsId = pages[identifier - 1].correctQuestionChoices.map(
          (x) => x.questionChoiceId,
        );
        optionsArray.forEach((option) => {
          if (correctsId.includes(option.id)) {
            arrayAnswerChecked[option.identifier - 1] = true;
            correctArray.push(option.identifierOrder);
          } else {
            arrayAnswerChecked[option.identifier - 1] = false;
          }
        });
        setAnswerChecked(arrayAnswerChecked);
        setCorrect(correctArray);
        pages[identifier - 1] = {
          ...pages[identifier - 1],
          questionAnswer: correctArray,
        };
      }
      setOptions(optionsArray);
      setSendEmail(pages[identifier - 1].sendEmail);
      if (
        pages[identifier - 1].questionEmails !== undefined
        && pages[identifier - 1].questionEmails.length !== 0) {
        setEmailsArray(pages[identifier - 1].questionEmails[0].emails.split(','));
      }
    } else {
      if (
        pages[identifier - 1].questionEmails !== undefined
        && pages[identifier - 1].questionEmails.length !== 0) {
        setEmailsArray(pages[identifier - 1].questionEmails[0].emails.split(','));
      }
      setOptions([]);
    }
  }, []);

  const handleAddOption = () => {
    if (options.length && !options[options.length - 1].title) return;
    setAnswerChecked([...answerChecked, false]);
    const generatedId = initialId + options.length;
    setOptions([
      ...options, {
        identifier: `${generatedId}`,
        title: '',
        type: 'text',
      }]);
  };

  const handleOnChange = (event) => {
    const { id, value, imageUrl } = event.target;

    setUpdate((prevState) => !prevState);

    if (id === 'title' || id === 'description' || id === 'imageKey'
    || id === 'sendEmail' || id === 'questionEmails') {
      pages[identifier - 1] = {
        ...pages[identifier - 1],
        [id]: value,
        questionLogic: [{
          questionIdentifier: identifier,
          questionChoiceIdentifier: null,
          nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
        }],
        imageUrl,
      };
    } else {
      const indexOption = options.findIndex((option) => option.identifier === id);

      if (indexOption !== -1) options[indexOption].title = value;

      pages[identifier - 1] = {
        ...pages[identifier - 1],
        questionChoices: options,
      };
    }
  };

  const handleCorrectAnswer = (event, identifierid) => {
    const correctArray = [...correct];
    if (event.target.checked) {
      if (correctArray.includes(identifierid)) {
        const index = correct.indexOf(identifierid);
        if (index > -1) {
          correctArray.splice(index, 1); // 2nd parameter means remove one item only
          setAnswerChecked((prevState) => {
            const newState = [...prevState];
            newState[identifierid - 1] = false;
            return newState;
          });
        }
      } else {
        correctArray.push(identifierid);
        setAnswerChecked((prevState) => {
          const newState = [...prevState];
          newState[identifierid - 1] = true;
          return newState;
        });
      }
    } else {
      const index = correctArray.indexOf(identifierid);
      correctArray.splice(index, 1); // 2nd parameter means remove one item only
      setAnswerChecked((prevState) => {
        const newState = [...prevState];
        newState[identifierid - 1] = false;
        return newState;
      });
    }
    setCorrect(correctArray);
    pages[identifier - 1] = {
      ...pages[identifier - 1],
      questionAnswer: correctArray,
    };
  };

  const isCorrect = (identifierid) => {
    if (!correct) return false;
    if (correct.includes(Number.parseInt(identifierid, 10))) {
      return true;
    }
    if (correctIds.includes(Number.parseInt(identifierid, 10))) {
      return true;
    }
    if (correctAnswers.includes(Number.parseInt(identifierid, 10))) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <p className={styles.identifier}>{identifier}.</p>
        <div className={styles.questionContainer}>
          <TextField
            id="title"
            className={styles.question}
            placeholder={t(
              'survey.surveyEdition.multipleSelectionText.question',
            )}
            value={info ? info.title : pages[identifier - 1].title}
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 70 }}
            multiline
            disabled={mode === VIEW_MODE}
          />
          <TextField
            id="description"
            className={styles.description}
            placeholder={t(
              'survey.surveyEdition.multipleSelectionText.description',
            )}
            value={info ? info.description : pages[identifier - 1].description}
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 140 }}
            multiline
            disabled={mode === VIEW_MODE}
          />
          {options.map((option) => (
            <div key={option.identifier || `${option.id}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={styles.checkbox}
                    checkedIcon={<CheckBoxIcon style={{ color: 'green' }} />}
                    name={option.identifier}
                    onClick={(event) => handleCorrectAnswer(event, option.identifier)}
                    checked={mode === VIEW_MODE ? isCorrect(option.identifier || `${option.id}`) : answerChecked[option.identifier - 1]}
                    disabled={mode === VIEW_MODE}
                  />
                }
                label={(
                  <TextField
                    id={option.identifier || `${option.id}`}
                    className={styles.option}
                    value={option.title}
                    placeholder={`${t('survey.surveyEdition.multipleSelectionText.write')} ${t('survey.surveyEdition.multipleSelectionText.option')}`}
                    InputProps={{ disableUnderline: true }}
                    onChange={mode === EDIT_MODE ? handleOnChange : null}
                    disabled={mode === VIEW_MODE}
                    multiline
                    maxRows={5}
                    fullWidth
                  />
                )}
              />
            </div>
          ))}
          <Button
            onClick={handleAddOption}
            className={clsx({
              [styles.hide]: info,
            })}
          >
            {t('survey.surveyEdition.simpleSelectionText.addOption')}
          </Button>
          {mode !== VIEW_MODE ? (
            <>
              <Button
                onClick={() => setOpen(true)}
              >
                {t('survey.surveyForm.addMediaTitle')}
              </Button>
              <Button
                onClick={() => setOpenEmails(true)}
              >
                {t('survey.surveyForm.addEmails')}
              </Button>
            </>
          ) : null
          }
          {image.url
            ? <>
              <Grid container>
                <Grid item xs={1}>
                  <Tooltip
                    title={
                      <img
                        alt='reference-image'
                        src={image.url}
                        width='100%'
                        height='100%'
                      />
                    }
                    arrow
                    placement='top-end'
                  >
                    <img
                      alt='reference-image'
                      src={image.url}
                      width='50px'
                      height='50px'
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={5}>
                  <List className={styles.list}>
                    <ListItem>
                      <ListItemText
                        primary={image.name}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondary={image.size ? `  (${image.size})` : null}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </>
            : null
          }
        </div>
      </div>
      {mode !== VIEW_MODE ? (
        <IconButton
          id={identifier}
          onClick={handleDeleteQuestion}
          className={styles.deleteButton}
        >
          <DeleteIcon />
        </IconButton>)
        : null}
      <ExampleImageQuestion
        open={open}
        setOpen={setOpen}
        file={file}
        setFile={setFile}
        handleOnChange={handleOnChange}
        setImage={setImage}
      />
      <EmailsBackup
        sendEmail={sendEmail}
        setSendEmails={setSendEmail}
        open={openEmails}
        setOpen={setOpenEmails}
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        handleOnChange={handleOnChange}
        disabled={!!info}
      />
    </div>
  );
}

export default MultipleSelection;

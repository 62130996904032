import React from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Toolbar,
} from '@material-ui/core';

import useStyles, { StyledTab, StyledTabs } from './styles';

// Filters area, table header
export default function TableToolbarTabs({ toolbarTabs, tab, setTab }) {
  const styles = useStyles();
  const { t } = useTranslation();

  // Filters the data in the Role and Active columns
  const handleSelectTab = (event) => {
    const { id } = event.currentTarget;
    setTab(toolbarTabs.indexOf(id));
  };

  return (
    <>
      <Toolbar className={styles.toolbarTable}>
        <StyledTabs value={tab} variant="scrollable" onChange={handleSelectTab}>
            <StyledTab id='companies' label={t('administration.tabs.companies')} />
            <StyledTab id='projects' label={t('administration.tabs.projects')} />
            <StyledTab id='reports' label={t('administration.tabs.reports')} />
            <StyledTab id='news' label={t('administration.tabs.news')} />
            <StyledTab id='tyc' label={'T&C'} />
            <StyledTab id='home message' label={t('administration.tabs.homeMessages')} />
          </StyledTabs>
      </Toolbar>
      </>
  );
}

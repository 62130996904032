import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Typography,
  Paper,
  Toolbar,
  CircularProgress,
  IconButton,
  TextField as MuiTextField,
  Box,
} from '@material-ui/core';

// icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import ClearIcon from '@material-ui/icons/Clear';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';

// components
import Modal from '../../../components/Modal';
import useStyles from '../styles';
import Snackbar from '../../../components/Snackbar';
import Pagination from '../../../components/Pagination';
import { getProjectsPerPage } from '../../../requests/api/project';
import { getCompanies } from '../../../requests/api/company';
import Button from '../../../components/Button';
import ProjectForm from './projectForm';
import UpdateTokensForm from './updateTokensForm';
import sleep from '../../../utils/sleep';

// const
import { UNAUTHORIZED } from '../../../utils/const/http';

// eslint-disable-next-line no-shadow
const ProjectsAdmin = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  // table states
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [totalRowsQuantity, setTotalRowsQuantity] = useState(0);
  const [currentProject, setCurrentProject] = useState({
    name: '',
    id: '',
    companyId: '',
    tokens: 10,
  });
  const [companiesOptions, setCompaniesOptions] = useState();
  // auxiliar states
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [openUpdateProject, setOpenUpdateProject] = useState(false);
  const [openUpdateTokens, setOpenUpdateTokens] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  /* eslint-disable no-use-before-define */
  const reload = async () => {
    load();
    return projectsRequestSearch(page, rowsPerPage, filter, column);
  };

  //
  const handleTokenSuccessfullModal = async (message) => {
    reload();
    setSucc(message);
  };

  //
  const handleTokenFailModal = async (message) => {
    reload();
    setErr(message);
  };
  const projectsTableHeaders = [
    {
      id: 'projectName',
      label: <b>{t('administration.project.name')}</b>,
    },
    {
      id: 'company',
      label: <b>{t('administration.project.company')}</b>,
    },
    {
      id: 'tokens',
      label: <b>{'Tokens'}</b>,
    },
    {
      id: 'actions',
      label: <b>{t('administration.actions')}</b>,
    },
  ];

  const setFilterOptions = async (newValue, tableColumn) => {
    setSearchText(newValue);
    await sleep(100);
    setColumn(tableColumn);
    setFilter(newValue);
    setPage(0);
    // Clear filters
    if (!tableColumn) {
      setSearchText('');
      setFilter('');
    }
  };

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const projectsTableHeader = () => (
    <TableHead>
      <TableRow>
        {projectsTableHeaders.map((tableHeader) => (
          <TableCell
            key={tableHeader.id}
            className={styles.tableHeader}
            align={ tableHeader.id === 'actions' ? 'center' : 'left'}
            padding="normal"
          >
            {tableHeader.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
  const handleUpdateProject = (row) => {
    setCurrentProject({
      name: row.name,
      companyId: row.companyId,
      id: row.id,
      tokens: row.tokens,
    });
    setOpenUpdateProject(true);
  };

  const projectsRequestSearch = async (pageOfTable, limit, searchValue, columnName) => {
    const getProjectsResponse = await getProjectsPerPage(pageOfTable, limit,
      searchValue, columnName);
    const getCompaniesResponse = await getCompanies();
    if (getProjectsResponse.success && getCompaniesResponse.success) {
      setRowsToShow(getProjectsResponse.data.data.projects.Projects);
      setTotalRowsQuantity(getProjectsResponse.data.data.projects.totalProjects);
      setCompaniesOptions(getCompaniesResponse.data.data.companies);
    }
    if (getProjectsResponse.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const handleCreateProject = () => {
    setCurrentProject({
      name: '',
      companyId: '',
      tokens: 10,
    });
    setOpenCreateProject(true);
  };

  const handleUpdateTokens = (row) => {
    setCurrentProject({
      id: row.id,
      name: row.name,
      companyId: row.companyId,
      tokens: 0,
    });
    setOpenUpdateTokens(true);
  };

  useEffect(() => {
    reload();
  }, [page, rowsPerPage, totalRowsQuantity, filter, column]);

  const projectsToolbar = () => (
    <Toolbar className={styles.toolbarTable}>
        <Box className={styles.boxTitle}>
          <MuiTextField
                className={styles.searchField}
                id="search"
                variant="outlined"
                value={searchText}
                onChange={(event) => setFilterOptions(event.target.value, 'name')}
                placeholder={t('administration.buttons.searchProjectPlaceHolder')}
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" />,
                  endAdornment: (
                    <IconButton
                      id="clear"
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      onClick={() => setFilterOptions('', false)}
                      style={{ visibility: searchText ? 'visible' : 'hidden' }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
          </Box>
            <Typography
            className={styles.toolbarButton}
          > </Typography>
            <Button
            id="create-project-btn"
            text={t('administration.buttons.createProject')}
            variant="contained"
            height="40px"
            width="100%"
            backgroundColor='#2C9DC9'
            color='white'
            borderRadius='10px'
            onClick = {handleCreateProject}
          />
          {openCreateProject ? (
          <Modal title={t('administration.project.formCreate')} defaultOpen={openCreateProject} setCurrentOpen={setOpenCreateProject}>
            <ProjectForm
            setCurrentOpen={setOpenCreateProject}
            currentSelection={currentProject}
            options={companiesOptions}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={false}
            />
          </Modal>
          ) : null}
          </Toolbar>
  );

  const contentProjects = () => {
    if (!totalRowsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={3} className={styles.users}>
            <AssignmentIcon />
            <h4>{t('administration.project.noProjects')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    const filterCompanyById = (id) => {
      const filterCompany = companiesOptions.filter(
        (company) => company.id === id,
      );
      if (filterCompany.length > 0) {
        return filterCompany[0].name;
      }
      return null;
    };
    return (
      <>
        {rowsToShow
          .map((row) => (
            <TableRow
              hover
              key = {row.id}
            >
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.name}</TableCell>
              <TableCell align="left">
                {filterCompanyById(row.companyId)}
              </TableCell>
              <TableCell align='left'>
                <div className={styles.tokensColumn}>
                  <b>{row.tokens}</b>
                </div>
              </TableCell>
              <TableCell align="center">
                <IconButton onClick = {() => handleUpdateProject(row)} >
                  <EditIcon/>
                </IconButton>
                <IconButton onClick = {() => handleUpdateTokens(row)} >
                  <CreditCardIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {openUpdateProject ? (
          <Modal title={t('administration.project.formUpdate')} defaultOpen={openUpdateProject} setCurrentOpen={setOpenUpdateProject}>
            <ProjectForm
            setCurrentOpen={setOpenUpdateProject}
            currentSelection={currentProject}
            options={companiesOptions}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={true}
            />
          </Modal>
          ) : null}
          {openUpdateTokens ? (
          <UpdateTokensForm
            open={openUpdateTokens}
            setOpen={setOpenUpdateTokens}
            tokenSuccessfullModal={handleTokenSuccessfullModal}
            tokenFailModal={handleTokenFailModal}
            projectId={currentProject.id}
            />
          ) : null}
      </>
    );
  };

  const tableContent = () => {
    if (loading) {
      return (
      <TableRow>
        <TableCell colSpan={9} className={styles.progress}>
          <CircularProgress />
          <h4>{t('users.userInfo.loading')}</h4>
        </TableCell>
      </TableRow>
      );
    }
    return contentProjects();
  };

  return (
    <>
      <Paper className={styles.documentsTable}>
        <>
        {projectsToolbar()}
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
            {projectsTableHeader()}
              <TableBody>
                {tableContent()}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        <Pagination
            setPage={setPage}
            handleChangePage={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            quantity={totalRowsQuantity}
          />
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </>
  );
};

export default ProjectsAdmin;

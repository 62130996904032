import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

// import Checkbox from '@material-ui/core/Checkbox';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import Avatar from '@material-ui/core/Avatar';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BuildIcon from '@material-ui/icons/Build';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ClearIcon from '@material-ui/icons/Clear';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import Divider from '@material-ui/core/Divider';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
// import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
// import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import TextField from '@material-ui/core/TextField';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';

import { updateTicketState } from '../../../requests/api/ticket';
import { parseDate } from '../../../utils/date';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '60vh',
    maxWidth: 500,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.blue,
  },
  success: {
    color: theme.palette.primary.success,
    borderColor: theme.palette.primary.success,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  error: {
    color: theme.palette.primary.error,
    borderColor: theme.palette.primary.error,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  warning: {
    color: theme.palette.primary.warning,
    borderColor: theme.palette.primary.warning,
    borderWidth: '2px',
    borderRadius: '6px',
    backgroundColor: theme.palette.transparent.warning,
  },
  processing: {
    color: theme.palette.primary.blue,
    borderColor: theme.palette.primary.blue,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  align: {
    textAlign: 'center',
    paddingTop: '15px',
  },
}));

function InformationCard({
  data, stateBadge, ticketSuccessfullModal, ticketFailModal, projects,
}) {
//    console.log(data);
  const classes = useStyles();
  const { t } = useTranslation();
  const [category, setCategory] = useState('');
  const [canEvaluateTicket, setCanEvaluateTicket] = useState(false);
  const [canQuoteTicket, setCanQuoteTicket] = useState(false);
  const [canRejectTicket, setCanRejectTicket] = useState(false);
  const [canApproveQuoteTicket, setCanApproveQuoteTicket] = useState(false);
  const [canProcessTicket, setCanProcessTicket] = useState(false);
  const [canFinishTicket, setCanFinishTicket] = useState(false);
  const [canObjectTicket, setCanObjectTicket] = useState(false);
  const [canAdjustTicket, setCanAdjustTicket] = useState(false);
  const [tokens, setTokens] = useState(null);
  const account = useSelector((state) => state.account);

  const handleDownload = () => {
    if (data.fileUrl) {
      window.open(data.fileUrl);
    }
  };

  const handleEvaluate = async () => {
    const response = await updateTicketState(data.id, 'evaluation');
    if (response.success) {
      ticketSuccessfullModal(t('support.ticketEvaluation'));
    } else {
      ticketFailModal(t('support.serverError'));
    }
  };

  const handleChangeTokens = (event) => {
    setTokens(event.target.value);
  };

  const handleQuoteTicket = async () => {
    const response = await updateTicketState(data.id, 'quoted', tokens);
    if (response.success) {
      ticketSuccessfullModal(t('support.ticketQuoted'));
    } else {
      ticketFailModal(t('support.serverError'));
    }
  };

  const handleRejectTicket = async () => {
    const response = await updateTicketState(data.id, 'rejected');
    if (response.success) {
      ticketSuccessfullModal(t('support.ticketRejected'));
    } else {
      ticketFailModal(t('support.serverError'));
    }
  };

  const handleApproveQuoteTicket = async () => {
    const response = await updateTicketState(data.id, 'approved');
    if (response.success) {
      ticketSuccessfullModal(t('support.quoteApproved'));
    } else {
      ticketFailModal(response.message);
    }
  };

  const handleProcessTicket = async () => {
    const response = await updateTicketState(data.id, 'processing');
    if (response.success) {
      ticketSuccessfullModal(t('support.ticketProcessing'));
    } else {
      ticketFailModal(t('support.serverError'));
    }
  };

  const handleFinishTicket = async () => {
    const response = await updateTicketState(data.id, 'finished');
    if (response.success) {
      ticketSuccessfullModal(t('support.ticketFinished'));
    } else {
      ticketFailModal(t('support.serverError'));
    }
  };

  const handleCloseTicket = async () => {
    const response = await updateTicketState(data.id, 'close');
    if (response.success) {
      ticketSuccessfullModal(t('support.ticketClosed'));
    } else {
      ticketFailModal(t('support.serverError'));
    }
  };

  const handleObjectTicket = async () => {
    const response = await updateTicketState(data.id, 'objected');
    if (response.success) {
      ticketSuccessfullModal(t('support.ticketObjected'));
    } else {
      ticketFailModal(t('support.serverError'));
    }
  };

  const handleAdjustTicket = async () => {
    const response = await updateTicketState(data.id, 'adjust');
    if (response.success) {
      ticketSuccessfullModal(t('support.ticketAdjust'));
    } else {
      ticketFailModal(t('support.serverError'));
    }
  };

  useEffect(() => {
    switch (data.category) {
      case 'display':
        setCategory('Display');
        break;
      case 'qa':
        setCategory('QA');
        break;
      case 'seek':
        setCategory('Seek');
        break;
      default:
        break;
    }
    //    console.log(data);
    //    console.log('account');
    //    console.log(account);
    //    console.log(projects);
    for (let i = 0; i < projects.length; i += 1) {
      if (projects[i].projectId === data.projectId && account.user.role === 'superAdmin') {
        setCanEvaluateTicket(true);
        setCanRejectTicket(true);
        setCanQuoteTicket(true);
        setCanProcessTicket(true);
        setCanFinishTicket(true);
        setCanAdjustTicket(true);
      }
      if (projects[i].projectId === data.projectId && (account.user.role === 'owner')) {
        setCanApproveQuoteTicket(true);
      }
      if (projects[i].projectId === data.projectId
            && (account.user.role === 'client' || account.user.role === 'owner')) {
        setCanObjectTicket(true);
      }
    }
  }, [data, projects]);

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="ticket" className={classes.avatar}>
              {data.User.full_name ? data.User.full_name[0] : '?'}
          </Avatar>
        }
        action={
            stateBadge
        }
        title={data.subject}
        subheader= {[t('support.ticketDetail.createdAt'), parseDate(data.createdAt), t('support.ticketDetail.by'), data.User.full_name].join('')}
      />
      <CardContent style={{ maxHeight: 500, overflow: 'auto' }}>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.ticketId')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.crmTicketId}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.creator')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.User.fullName}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.description')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.content}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.category')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {category}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.subcategory')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.subcategory}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.comments')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.comments ? data.comments : t('support.ticketDetail.noComments')}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
        {t('support.columnNames.estimatedClosing')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.estimatedClosingDate ? parseDate(data.estimatedClosingDate) : '-'}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
        {t('support.columnNames.updatedAt')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.lastModifiedDate ? parseDate(data.lastModifiedDate) : '-'}
        </Typography>
        <br/>

        {/* Tokens */}
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
        {t('support.columnNames.tokens')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.tokensConsumed ? parseInt(data.tokensConsumed, 10) : '-'}
        </Typography>
        <br/>

        <Divider variant="middle"/>
        <br/>
        {data.Approver
          ? <>
            <Typography variant="h6" color="textSecondary" component="p">
                {t('support.ticketDetail.approver')}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                  {data.Approver.fullName}
              </Typography>
              <br/>
        <Divider variant="middle"/>
          </>
          : ''
        }
        {data.fileUrl
          ? <>
          <br/>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="h6" align="left" color="textSecondary" component="p">
              {t('support.ticketDetail.file')}
            </Typography>
              <IconButton align='right' onClick={handleDownload} disabled={data.fileUrl === null}>
                  <CloudDownloadRoundedIcon fontSize="large" className={classes.icon}/>
                </IconButton>
          </Grid>
          <br/>
          <Divider variant="middle"/>
          <br/>
          </>
          : ''
        }
        {
        // Botón "Evaluación de ticket".
        // Sólo SuperAdmins. Ticket "abierto" o "nuevo"
        data.state === 'open' && canEvaluateTicket
          ? <>
          <Grid container alignItems="center" justifyContent="space-around">
            <Grid item xs={6} className={classes.align}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleEvaluate}
                startIcon={<AssignmentTurnedInIcon />}
              >
                {t('support.ticketDetail.evaluation')}
              </Button>
            </Grid>
          </Grid>
          </>
          : ''
        }

        {
        // Botón "Cotizar ticket".
        // Sólo SuperAdmins. Ticket "en evaluación"
        (data.state === 'evaluation') && (canQuoteTicket || canRejectTicket)
          ? <>
          <Grid container alignItems="center" justifyContent="space-around">
            <Grid item xs={6} className={classes.align}>
              <>

              {/* <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    icon={<RadioButtonUncheckedIcon/>}
                    checkedIcon={<RadioButtonCheckedIcon style={{ color: 'green' }}/>}
                    name="tokens"
                    />
                }
                label="Asignar tokens 2"
              /> */}
                <TextField
                  id="tokens-quote"
                  label="Cantidad de tokens"
                  onChange={(event) => { handleChangeTokens(event); }}
                />
              </>
              <>
                <Button
                  variant="contained"
                  onClick={handleQuoteTicket}
                  startIcon={<MonetizationOnIcon/>}
                  >
                {t('support.ticketDetail.quote')}
                </Button>
              </>
            </Grid>
            <Grid item xs={6} className={classes.align}>
              {/* <RadioGroup aria-label="accion" name="accion1">
                <FormControlLabel value="rechazar" control={<Radio />} label="Rechazar" />
              </RadioGroup> */}
              {/* <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    icon={<RadioButtonUncheckedIcon/>}
                    checkedIcon={<RadioButtonCheckedIcon style={{ color: 'green' }}/>}
                    name="tokens"
                  />
                }
                label="Rechazar"
              /> */}
              {/* <TextField
                id="motivo-rechazo"
                label="Multiline"
                multiline
                minRows={4}
                variant="filled"
              /> */}
              <Button
                variant="contained"
                onClick={handleRejectTicket}
                startIcon={<ClearIcon/>}
              >
                {t('support.ticketDetail.reject')}
              </Button>
            </Grid>
          </Grid>
          </>
          : ''
        }

        {
        // Botón "Aprobar ticket".
        // Sólo Cliente y Owner. Ticket "Cotizado"
        (data.state === 'quoted') && canApproveQuoteTicket
          ? <>
          <Grid container alignItems="center" justifyContent="space-around">

            {/* Botón rechazar cotización */}
            <Grid item xs={6} className={classes.align}>
              <Button
                variant="contained"
                onClick={handleRejectTicket}
                startIcon={<ClearIcon/>}
              >
                {t('support.ticketDetail.reject')}
              </Button>
            </Grid>

            {/* Botón Aprobar cotización */}
            <Grid item xs={6} className={classes.align}>
              <Button
                variant="contained"
                onClick={handleApproveQuoteTicket}
                startIcon={<ThumbUpIcon/>}
              >
                {t('support.ticketDetail.approve')}
              </Button>
            </Grid>
          </Grid>
          </>
          : ''
        }

        {
        // Botón "Procesar ticket".
        // Sólo SuperAdmin. Ticket "Aprobado"
        (data.state === 'approved') && canProcessTicket
          ? <>
          <Grid container alignItems="center" justifyContent="space-around">
            <Grid item xs={6} className={classes.align}>
              <Button
                variant="contained"
                onClick={handleProcessTicket}
                startIcon={<BuildIcon/>}
              >
                {t('support.ticketDetail.process')}
              </Button>
            </Grid>
          </Grid>
          </>
          : ''
        }

        {
        // Botón "Terminar ticket".
        // Sólo SuperAdmin. Ticket "Procesando"
        (data.state === 'processing') && canFinishTicket
          ? <>
          <Grid container alignItems="center" justifyContent="space-around">
            <Grid item xs={6} className={classes.align}>
              <Button
                variant="contained"
                onClick={handleFinishTicket}
                startIcon={<DoneIcon/>}
              >
                {t('support.ticketDetail.finish')}
              </Button>
            </Grid>
          </Grid>
          </>
          : ''
        }

        {
        // Botón "Objetar ticket".
        // Sólo SuperAdmin. Ticket "Terminado"
        (data.state === 'finished') && canObjectTicket
          ? <>
          <Grid container alignItems="center" justifyContent="space-around">

            <Grid item xs={6} className={classes.align}>
              <Button
                variant="contained"
                onClick={handleObjectTicket}
                startIcon={<ReportProblemIcon/>}
              >
                {t('support.ticketDetail.object')}
              </Button>
            </Grid>

            {/* Botón cerrar ticket */}
            <Grid item xs={6} className={classes.align}>
              <Button
                variant="contained"
                onClick={handleCloseTicket}
                startIcon={<DoneAllIcon/>}
              >
                {t('support.ticketDetail.close1')}
              </Button>
            </Grid>
          </Grid>
          </>
          : ''
        }

        {
        // Botón "Ajustes ticket".
        // Sólo SuperAdmin. Ticket "Objetado"
        (data.state === 'objected') && canAdjustTicket
          ? <>
          <Grid container alignItems="center" justifyContent="space-around">
            {/* Botón Terminado */}
            <Grid item xs={6} className={classes.align}>
              <Button
                variant="contained"
                onClick={handleCloseTicket}
                startIcon={<DoneAllIcon/>}
              >
                {t('support.ticketDetail.close1')}
              </Button>
            </Grid>

            {/* Botón Ajustes */}
            <Grid item xs={6} className={classes.align}>
              <Button
                variant="contained"
                onClick={handleAdjustTicket}
                startIcon={<BuildIcon/>}
              >
                {t('support.ticketDetail.adjust')}
              </Button>
            </Grid>
          </Grid>
          </>
          : ''
        }

        {
        // Botón "Terminar ticket".
        // Sólo SuperAdmin. Ticket "Ajustes"
        (data.state === 'adjust') && canFinishTicket
          ? <>
          <Grid container alignItems="center" justifyContent="space-around">
            {/* Botón Ajustes */}
            <Grid item xs={6} className={classes.align}>
              <Button
                variant="contained"
                onClick={handleFinishTicket}
                startIcon={<DoneIcon/>}
              >
                {t('support.ticketDetail.finish')}
              </Button>
            </Grid>
          </Grid>
          </>
          : ''
        }

      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ projects: state.projects.projects });

export default connect(mapStateToProps)(InformationCard);

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  title: {
    fontSize: '19px',
    margin: '10px',
    marginTop: '30px',
    fontWeight: 600,
    color: theme.palette.primary.mainBlue,
  },
}));

export default useStyles;

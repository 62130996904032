const USERS_IN = 'usersIn';
const USERS_NOT_IN = 'usersNotIn';
const USER_GROUPS_IN = 'userGroupsIn';
const USER_GROUPS_NOT_IN = 'userGroupsNotIn';
const USER_GROUPS = 'UserGroups';
const USER_GROUP = 'userGroup';
const USER_ROWS = 'usersRows';
const USERS = 'Users';

const LOCATIONS_IN = 'locationsIn';
const LOCATIONS_NOT_IN = 'locationsNotIn';
const CLUSTERS_IN = 'clustersIn';
const CLUSTERS_NOT_IN = 'clustersNotIn';
const CLUSTERS = 'Clusters';
const CLUSTER = 'cluster';
const LOCATION_ROWS = 'locationsRows';
const LOCATIONS = 'Locations';

const elements = {
  0: ['groups', 'totalGroups'],
  1: ['users', 'totalUsers'],
  2: ['cluster'],
};

const attributes = {
  0: ['name', 'createdAt'],
  1: ['fullName', 'id', 'email', 'role', 'active'],
  2: ['name', 'internalCode', ['Address', 'address'], ['Address', 'city'], 'chain'],
};

const props = {
  0: ['userGroupsIn', 'userGroupsNotIn', 'userGroupsSearch'],
  1: ['usersIn', 'usersNotIn', 'usersSearch'],
  2: ['locationsIn', 'locationsNotIn', 'locationsSearch'],
};

export {
  elements,
  attributes,
  props,
  USERS_IN,
  USERS_NOT_IN,
  USER_GROUPS_IN,
  USER_GROUPS_NOT_IN,
  USER_GROUPS,
  USER_GROUP,
  USER_ROWS,
  USERS,
  LOCATIONS_IN,
  LOCATIONS_NOT_IN,
  CLUSTERS_IN,
  CLUSTERS_NOT_IN,
  CLUSTERS,
  CLUSTER,
  LOCATION_ROWS,
  LOCATIONS,
};

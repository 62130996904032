import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { parseDate } from '../../../utils/date';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '60vh',
    maxWidth: 500,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.blue,
  },
  success: {
    color: theme.palette.primary.success,
    borderColor: theme.palette.primary.success,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  error: {
    color: theme.palette.primary.error,
    borderColor: theme.palette.primary.error,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  warning: {
    color: theme.palette.primary.warning,
    borderColor: theme.palette.primary.warning,
    borderWidth: '2px',
    borderRadius: '6px',
    backgroundColor: theme.palette.transparent.warning,
  },
  processing: {
    color: theme.palette.primary.blue,
    borderColor: theme.palette.primary.blue,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  align: {
    textAlign: 'center',
    paddingTop: '15px',
  },
}));

function WalletMovementsInformation({
  data,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="ticket" className={classes.avatar}>
              {data.User ? data.User.full_name[0] : '?'}
          </Avatar>
        }
        title={data.comments}
        subheader= {[t('support.ticketDetail.createdAt'), parseDate(data.createdAt)].join('')}
      />
      <CardContent>
        <Typography variant="h6" color="textSecondary" component="p">
        {t('wallet.walletDetail.date')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {parseDate(data.createdAt)}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
        {t('wallet.walletDetail.tokensCharged')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {data.amount}
        </Typography>
        <br/>
      </CardContent>
    </Card>
  );
}

export default (WalletMovementsInformation);

const categories = [
  { id: 'display', name: 'Display' },
  { id: 'seek', name: 'Seek' },
  { id: 'qa', name: 'QA' },
];

const displayES = [
  { id: 'newDashboard', name: 'Nuevo dashboard' },
  { id: 'dashboardChanges', name: 'Cambio en el dashboard' },
  { id: 'dashboardProblem', name: 'Problemas Dashboard' },
];
const displayEN = [
  { id: 'newDashboard', name: 'New dashboard' },
  { id: 'dashboardChanges', name: 'Changes in dashboard' },
  { id: 'dashboardProblems', name: 'Dahsboard problem' },
];

const seekES = [
  { id: 'development', name: 'Desarrollo' },
  { id: 'support', name: 'Soporte' },
  { id: 'portal', name: 'Portal' },
];
const seekEN = [
  { id: 'development', name: 'Development' },
  { id: 'support', name: 'Support' },
  { id: 'portal', name: 'Portal' },
];

const qaES = [
  { id: 'retroaudit', name: 'Retroaudit' },
  { id: 'challenge', name: 'Challenges' },
  { id: 'other', name: 'Otro' },
];
const qaEN = [
  { id: 'retroaudit', name: 'Retroaudit' },
  { id: 'challenge', name: 'Challenges' },
  { id: 'other', name: 'Other' },
];

const noCatES = { id: 'noCat', name: 'Debes Seleccionar Categoría' };
const noCatEN = { id: 'noCat', name: 'Category Must be selected' };

export {
  categories,
  displayES,
  displayEN,
  qaES,
  qaEN,
  seekES,
  seekEN,
  noCatES,
  noCatEN,
};

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField as MuiTextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import getColor from '../../utils/color';

const useStyles = makeStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: (props) => getColor(theme, props.focusLabelColor),
    },
    '& label': {
      color: (props) => getColor(theme, props.labelColor),
    },
    '& .MuiInput-root': {
      backgroundColor: theme.palette.secondary.silver,
      borderRadius: '3px',
      paddingLeft: '7px',
    },
    '& .MuiInput-formControl': {
      marginTop: '20px',
    },
  },
}));

function TextField({
  id,
  label,
  value,
  type,
  onChange,
  disabled,
  fullWidth,
  variant,
  InputProps,
  select,
  options,
  required,
  multiline,
  rows,
  placeholder,
  keyId,
  ...props
}) {
  const styles = useStyles(props);
  return (
    <div>
      <FormControl className={styles.root} fullWidth={fullWidth}>
        <MuiTextField
          required = {required}
          multiline = {multiline}
          rows = {rows}
          id={id}
          type={type}
          label={label}
          value={value}
          variant={variant}
          onChange={onChange}
          disabled={disabled}
          select={select}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...InputProps,
          }}
        >
        {options ? options.map((option) => (
          <MenuItem key={option[keyId]} value={option[keyId].toString()}>{option.name}</MenuItem>
        )) : '' }
        </MuiTextField>
      </FormControl>
    </div>
  );
}

TextField.defaultProps = {
  focusLabelColor: 'theme-secondary-silver',
  labelColor: 'theme-secondary-silver',
  label: '',
  value: '',
  disabled: false,
  fullWidth: false,
  type: 'text',
  InputProps: {},
  variant: 'standard',
  select: false,
  placeholder: '',
  keyId: 'id',
};

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.string,
  InputProps: PropTypes.object,
  focusLabelColor: PropTypes.string,
  select: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
};

export default TextField;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// core
import {
  Typography,
  InputLabel,
  TextField,
} from '@material-ui/core';
// Components
import Button from '../../components/Button';
import Snackbar from '../../components/Snackbar';
import OutlinedSelect from '../../components/OutlinedSelect';
import countries from '../Register/countries';

import { postNewLocation, getLocation, putEditLocation } from '../../requests/api/location';
import { getCompanies } from '../../requests/api/company';
import useStyles from './styles';

const LocationForm = ({ locationId }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const currentCompany = useSelector((state) => state.company.companyId);
  const [inputValues, setInputValues] = useState({
    companyId: currentCompany,
    name: '',
    internalCode: '',
    chain: '',
    chainSubCategory: '',
    deleted: 'false',
    countryCode: '',
    city: '',
    region: '',
    sector: '',
    zone: '',
    address: '',
    latitude: '',
    longitude: '',
  });

  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [companies, setCompanies] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const deletedOptions = [
    {
      name: t('locations.locationForm.deletedOption'),
      value: 'true',
    },
    {
      name: t('locations.locationForm.activeOption'),
      value: 'false',
    },
  ];

  useEffect(() => {
    const getCompaniesRequest = async () => {
      const response = await getCompanies();
      if (response.success) {
        const allCompanies = response.data.data.companies;
        const companiesOptions = allCompanies.map(
          (c) => ({ name: c.name, value: c.id }),
        ).filter((c) => c.value === currentCompany);
        setCompanies(companiesOptions);
      }
    };

    getCompaniesRequest();
    // eslint-disable-next-line
  }, []);

  const selectCountry = (e) => {
    const { value } = e.target;
    setInputValues({
      ...inputValues,
      countryCode: value,
    });
  };

  useEffect(() => {
    const getLocationRequest = async () => {
      const response = await getLocation(locationId);
      if (response.success) {
        const { location } = response.data.data;
        setInputValues({
          companyId: location.companyId,
          name: location.name,
          internalCode: location.internalCode,
          chain: location.chain,
          chainSubCategory: location.chainSubCategory,
          deleted: location.deleted != null ? `${location.deleted}` : 'false',
          countryCode: location.Address.countryCode,
          city: location.Address.city,
          region: location.Address.region,
          sector: location.Address.sector,
          zone: location.Address.zone,
          address: location.Address.address,
          latitude: location.Address.latitude,
          longitude: location.Address.longitude,
        });
      }
    };
    if (locationId) {
      getLocationRequest();
    }
  }, [locationId]);

  const handleUpload = async () => {
    try {
      let response = null;
      if (locationId) {
        response = await putEditLocation(locationId, inputValues);
      } else {
        if (inputValues.name === '') {
          setSeverity('error');
          setMessage(t('locations.locationForm.nameMissingError'));
          return null;
        }
        if (inputValues.countryCode === '') {
          setSeverity('error');
          setMessage(t('locations.locationForm.countryCodeMissingError'));
          return null;
        }
        if (inputValues.city === '') {
          setSeverity('error');
          setMessage(t('locations.locationForm.cityMissingError'));
          return null;
        }
        if (inputValues.region === '') {
          setSeverity('error');
          setMessage(t('locations.locationForm.regionMissingError'));
          return null;
        }
        if (inputValues.address === '') {
          setSeverity('error');
          setMessage(t('locations.locationForm.addressMissingError'));
          return null;
        }
        response = await postNewLocation(inputValues);
      }
      if (response.status === 200) {
        setSeverity('success');
        setMessage(response.message);
        window.location.reload();
      } else {
        setSeverity('error');
        setMessage(response.data.errorMessage);
      }
    } catch (err) {
      setSeverity('error');
      setMessage('error');
      return (err);
    }
    return (false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputValues({
      ...inputValues,
      [id]: value,
    });
  };

  const selectCompany = (e) => {
    const { value } = e.target;
    setInputValues({
      ...inputValues,
      companyId: value,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const selectDeletedOptions = (e) => {
    const { value } = e.target;
    setInputValues({
      ...inputValues,
      deleted: value,
    });
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.locationInfo}>
        <div>
          <Typography display="inline">{t('locations.locationForm.name')}</Typography>
          <Typography className={styles.required} display="inline">{'   *'}</Typography>
          <TextField
            id="name"
            type="text"
            variant="outlined"
            value={inputValues.name}
            onChange={handleChange}
            fullWidth
          />
        </div>
        <div>
          <Typography display="inline">{t('locations.locationForm.internalCode')}</Typography>
          <TextField
            id="internalCode"
            type="text"
            value={inputValues.internalCode}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <Typography display="inline">{t('locations.locationForm.company')}</Typography>
          <Typography className={styles.required} display="inline">{'   *'}</Typography>
          <OutlinedSelect
            id="company"
            name="company"
            items={companies}
            value={inputValues.companyId}
            onChange={selectCompany}
            fullWidth
            disabled
          />
        </div>
        <div>
          <Typography>{t('locations.locationForm.chain')}</Typography>
          <TextField
            id='chain'
            type="text"
            variant='outlined'
            fullWidth
            value={inputValues.chain}
            onChange={handleChange}
          />
        </div>
        <div>
          <Typography>{t('locations.locationForm.chainSubCategory')}</Typography>
          <TextField
            id="chainSubCategory"
            type="text"
            value={inputValues.chainSubCategory}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
        </div>
        <div>
          <Typography variant='body1' className={styles.addressTitle}>{t('locations.locationForm.addressTitle')}</Typography>
        </div>
        <div />
        <div>
          <Typography display="inline">{t('locations.locationForm.countryCode')}</Typography>
          <Typography className={styles.required} display="inline">{'   *'}</Typography>
          <OutlinedSelect
            id="countryCode"
            type="text"
            variant="outlined"
            value={inputValues.countryCode}
            onChange={selectCountry}
            select={true}
            items = {countries}
            fullWidth
          />
        </div>
        <div>
          <Typography display="inline">{t('locations.locationForm.city')}</Typography>
          <Typography className={styles.required} display="inline">{'   *'}</Typography>
          <TextField
            id="city"
            type="text"
            value={inputValues.city}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <Typography display="inline">{t('locations.locationForm.region')}</Typography>
          <Typography className={styles.required} display="inline">{'   *'}</Typography>
          <TextField
            id="region"
            type="text"
            value={inputValues.region}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.sector')}</InputLabel>
          <TextField
            id="sector"
            type="text"
            value={inputValues.sector}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <Typography>{t('locations.locationForm.zone')}</Typography>
          <TextField
            id="zone"
            type="text"
            value={inputValues.zone}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <Typography display="inline">{t('locations.locationForm.address')}</Typography>
          <Typography className={styles.required} display="inline">{'   *'}</Typography>
          <TextField
            id="address"
            type="text"
            value={inputValues.address}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <Typography>{t('locations.locationForm.latitude')}</Typography>
          <TextField
            id="latitude"
            type="number"
            value={inputValues.latitude}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <Typography>{t('locations.locationForm.longitude')}</Typography>
          <TextField
            id="longitude"
            type="number"
            value={inputValues.longitude}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div />
        <div className={styles.buttonsContainer}>
          <Button
            id="submit-btn"
            onClick={handleUpload}
            text={locationId ? t('locations.locationForm.editButton') : t('locations.locationForm.button')}
            height="35px"
            width="100%"
            backgroundColor="#2C9DC9"
            color="#FFFFFF"
            borderRadius="10px"
          />
        </div>
      </div>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div >
  );
};

export default LocationForm;

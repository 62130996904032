import React from 'react';

// core
import {
  TableCell,
  TableRow,
  Checkbox,
  IconButton,
  Tooltip,
} from '@material-ui/core';

// icons
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import useStyles from './styles';

// functions
import { handleSelect, isSelected } from '../../utils/selectFunctions';
import { getComparator } from '../../utils/orderFunctions';

function MainBody({
  rowsToShow,
  order,
  orderBy,
  selected,
  setSelected,
  handleEdit,
  handleView,
}) {
  const styles = useStyles();
  return (
    <>
      {rowsToShow.sort(getComparator(order, orderBy))
        .map((row, index) => {
          const isItemSelected = isSelected(row.id, selected);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
              <TableRow
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleSelect(event, row.id, selected, setSelected)}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  {row.name || '-' }
                </TableCell>
                <TableCell align="left">
                  {row.deleted ? (
                    <DoneIcon fontSize="small" />
                  ) : (
                    <ClearIcon fontSize="small" />
                  )}
                </TableCell>
                <TableCell align="center">{new Date(row.createdAt).toLocaleDateString('es', {
                  weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric',
                })}</TableCell>
                <TableCell align="right" className={styles.iconsCell}>
                  <Tooltip title="Edit">
                    <IconButton
                      id={row.id}
                      aria-label="edit"
                      onClick={handleEdit}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View">
                    <IconButton
                      id={row.id}
                      aria-label="view"
                      onClick={handleView}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
          );
        })}
      </>
  );
}

export default MainBody;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  CardActionArea,
  Tooltip,
  Grow,
  Box,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { Link } from 'react-router-dom';
import GaugeChart from 'react-gauge-chart';
import mixpanel from 'mixpanel-browser';
import useStyles from './styles';
import Carrousel from './carrousel';
import Button from '../../components/Button';
import Snackbar from '../../components/Snackbar';
import WeatherWidget from './weatherWidget';
import { getUser } from '../../requests/api/user';
import { getProjects } from '../../requests/api/project';
import { getLastReport } from '../../requests/api/report';
import { getHomeMessages } from '../../requests/api/home-message';
import { parseDate } from '../../utils/date';
import AvatarForm from './avatarForm';
import Modal from '../../components/Modal';

const DrawerTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.blue,
    color: theme.palette.primary.white,
    textAlign: 'start',
    width: '100%',
    maxWidth: '100%',
    fontSize: '1em',
    borderRadius: '20px',
  },
  arrow: {
    color: theme.palette.primary.blue,
    border: '0px',
  },
}))(Tooltip);

function Dashboard() {
  const { t } = useTranslation();
  const styles = useStyles();
  const [succ, setSucc] = useState('');
  const [userData, setUserData] = useState('');
  const [projectsData, setProjectsData] = useState([]);
  const [lastReport, setLastReport] = useState('');
  const [homeMessageData, setHomeMessageData] = useState('');
  const [ticketTotals, setTicketTotals] = useState({
    total: 0,
    open: 0,
    waitingForApproval: 0,
    approved: 0,
    processing: 0,
    close: 0,
    rejected: 0,
    solved: 0,
    explained: 0,
    pending: 0,
    challenges: 0,
  });
  const [challengeKpi, setChallengeKpi] = useState(0);
  const [err, setErr] = useState('');

  const company = useSelector((state) => state.company);
  const [openEditAvatar, setOpenEditAvatar] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseUser = await getUser();
        await setUserData(responseUser.data.data.user);
        mixpanel.people.set({
          $name: responseUser.data.data.user.fullName,
        });
        const reportResponse = await getLastReport();
        await setLastReport(reportResponse.data.data.reportStat);
        const responseProjects = await getProjects();
        await setProjectsData(responseProjects.data.data.projects);
        await setTicketTotals(responseProjects.totalTickets);
        const responseHomeMessage = await getHomeMessages();
        if (responseHomeMessage.data.data[0]) {
          if (t('language') === 'EN') {
            await setHomeMessageData(responseHomeMessage.data.data[0].englishMessage);
          } else {
            await setHomeMessageData(responseHomeMessage.data.data[0].spanishMessage);
          }
        } else {
          await setHomeMessageData('');
        }
      } catch {
        setErr(t('layout.userError'));
      }
    };
    fetchData();
  }, [t, company]);

  const reload = async () => {
    const fetchData = async () => {
      try {
        const responseUser = await getUser();
        await setUserData(responseUser.data.data.user);
        const reportResponse = await getLastReport();
        await setLastReport(reportResponse.data.data.reportStat);
        const responseProjects = await getProjects();
        await setProjectsData(responseProjects.data.data.projects);
        await setTicketTotals(responseProjects.totalTickets);
        const responseHomeMessage = await getHomeMessages();
        if (responseHomeMessage.data.data[0]) {
          if (t('language') === 'EN') {
            await setHomeMessageData(responseHomeMessage.data.data[0].englishMessage);
          } else {
            await setHomeMessageData(responseHomeMessage.data.data[0].spanishMessage);
          }
        } else {
          await setHomeMessageData('');
        }
      } catch {
        setErr(t('layout.userError'));
      }
    };
    fetchData();
  };

  useEffect(() => {
    if (ticketTotals.challenges !== 0 && (ticketTotals.explained + ticketTotals.solved) > 0) {
      // The total does not include pending challenge
      setChallengeKpi((ticketTotals.explained
        / (ticketTotals.explained + ticketTotals.solved)).toFixed(2));
    } else {
      setChallengeKpi(1);
    }
  }, [ticketTotals.challenges, ticketTotals.explained]);

  const handleEditAvatar = () => {
    setOpenEditAvatar(true);
  };

  return (
    <div className={styles.content}>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item xs={5}>
          <Card className={styles.greetingCard}>
            <CardContent>
              <Grid alignItems="center" spacing={1} container>
                <Grid item xs={4}>
                  <Grid
                    className={styles.ticketContainer}
                    direction="column"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <DrawerTooltip
                      TransitionComponent={Grow}
                      placement="right"
                      interactive
                      leaveDelay={200}
                      title={
                        <Grid
                          direction="column"
                          container
                          alignItems="flex-start"
                          spacing={1}
                          className={styles.tooltipWidget}
                        >
                          <Typography
                            display="inline"
                            align="center"
                            className={styles.tooltipText}
                            variant="h3"
                          >
                            &nbsp;&nbsp;{t('home.editAvatar')}
                          </Typography>
                        </Grid>
                      } arrow
                    >
                      <IconButton onClick={handleEditAvatar} className={styles.iconButton}>
                        <Avatar
                          className={styles.avatar}
                          src={
                            userData.fileUrl
                              ? userData.fileUrl
                              : '/avatar.png'
                          }
                        >
                          {userData.fullName ? userData.fullName.charAt() : ''}
                        </Avatar>
                      </IconButton>
                    </DrawerTooltip>
                  </Grid>
                  {openEditAvatar ? (
                    <Modal title={t('home.editAvatar')} defaultOpen={openEditAvatar} setCurrentOpen={setOpenEditAvatar}>
                      <AvatarForm
                        setCurrentOpen={setOpenEditAvatar}
                        setSucc={setSucc}
                        setError={setErr}
                        reload={reload}
                        update={true}
                        userId={userData.id}
                      />
                    </Modal>
                  ) : null}
                </Grid>
                <Grid item xs={8} >
                  <Typography variant="h2">
                    {t('home.welcome')} {userData.fullName}
                  </Typography>
                  <Box textOverflow='ellipsis' overflow='hidden' className={styles.greetingText}>
                    <Typography>{homeMessageData}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={styles.infoWidget}>
            <CardContent>
              <Typography
                variant="h2"
                component={Link}
                to={{
                  pathname: '/challenges',
                }}
                className={styles.challengesTitle}
                gutterBottom
              >
                Challenges
              </Typography>
              <Grid
                className={styles.ticketContainer}
                direction="column"
                container
                alignItems="center"
                spacing={1}
              >
                <DrawerTooltip
                  TransitionComponent={Grow}
                  placement="right-start"
                  interactive
                  leaveDelay={200}
                  title={
                    <>
                      <Grid
                        direction="column"
                        container
                        alignItems="flex-start"
                        spacing={1}
                        className={styles.tooltipWidget}
                      >
                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipNumber}
                              variant="h3"
                            >
                              {ticketTotals.solved + ticketTotals.explained}
                            </Typography>
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipBar}
                              variant="h3"
                            >
                              &nbsp;|&nbsp;
                            </Typography>
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipText}
                              variant="h3"
                            >
                              &nbsp;&nbsp;{t('home.tickets.total')}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipNumber}
                              variant="h3"
                            >
                              {ticketTotals.explained}
                            </Typography>
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipBar}
                              variant="h3"
                            >
                              &nbsp;|&nbsp;
                            </Typography>
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipText}
                              variant="h3"
                            >
                              &nbsp;&nbsp;{t('home.tickets.explained')}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipNumber}
                              variant="h3"
                            >
                              {ticketTotals.solved}
                            </Typography>
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipBar}
                              variant="h3"
                            >
                              &nbsp;|&nbsp;
                            </Typography>
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipText}
                              variant="h3"
                            >
                              &nbsp;&nbsp;{t('home.tickets.solved')}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipNumberPending}
                              variant="h3"
                            >
                              {ticketTotals.challenges
                                - ticketTotals.explained
                                - ticketTotals.solved}
                            </Typography>
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipBarPending}
                              variant="h3"
                            >
                              &nbsp;|&nbsp;
                            </Typography>
                            <Typography
                              display="inline"
                              align="center"
                              className={styles.tooltipTextPending}
                              variant="h3"
                            >
                              &nbsp;&nbsp;{t('home.tickets.pending')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  }
                  arrow
                >
                  <Grid item xs={12} className={styles.gaugeChart}>
                    <GaugeChart
                      id="gauge-chart3"
                      nrOfLevels={3}
                      colors={['#FF5F6D', '#4AEB15 ']}
                      needleColor={'#1b5d77'}
                      needleBaseColor={'#1b5d77'}
                      arcWidth={0.1}
                      percent={challengeKpi || 0}
                      hideText={true}
                      animate={false}
                    />
                  </Grid>
                </DrawerTooltip>
                <Typography
                  variant="h1"
                  className={styles.challengePercentage}
                  gutterBottom
                >
                  {challengeKpi * 100}%
                </Typography>
              </Grid>
            </CardContent>
            <div className={styles.challengeButton}>
                <Button
                  component={Link}
                  id="challenges-link"
                  text={t('home.challenges.button')}
                  variant="contained"
                  height="42px"
                  width="100%"
                  backgroundColor="#2C9DC920"
                  color="#22617B"
                  borderRadius="10px"
                  to={{ pathname: '/challenges' }}
                />
              </div>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={styles.tokens}>
            <CardContent>
              <Typography variant="h2">
                {t('home.tokens.title')}
              </Typography>
              <Grid className={styles.scrollable} container>
                {projectsData.map((project) => (
                  <React.Fragment key={project.id}>
                    <Grid item xs={9}>
                      <Typography
                        className={styles.ticketListTitle}
                        variant="h1"
                        gutterBottom
                        component={Link}
                        to={`wallet/${project.projectId}`}
                      >
                        {project.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <div className={styles.alignBalance}>
                        <Typography
                          display="inline"
                          align="center"
                          className={clsx({
                            [styles.balanceNegative]: project.tokensLeft < 0,
                            [styles.balancePositive]: project.tokensLeft >= 0,
                          })}
                          variant="h3"
                        >
                          {project.tokensLeft
                            ? project.tokensLeft.toString()
                            : 'N/A'}
                        </Typography>
                        {project.tokensLeft < 0 ? (
                          <TrendingDownIcon
                            className={styles.balanceNegative}
                          />
                        ) : (
                          <TrendingUpIcon className={styles.balancePositive} />
                        )}
                      </div>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </CardContent>
            <div className={styles.widgetButton}>
              <Button
                component={Link}
                id="support-link"
                text={t('home.tokens.button')}
                variant="contained"
                height="42px"
                width="150px"
                backgroundColor="#2C9DC920"
                color="#22617B"
                borderRadius="10px"
                to={{ pathname: '/wallet' }}
              />
            </div>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={styles.infoWidget}>
            <CardActionArea
              href={
                'https://tcgrauland.notion.site/Help-Center-1a810a3e45414d37bf6c016420f0fb1a'
              }
              target="_blank"
            >
              <div className={styles.knowledge}>
                <CardMedia
                  className={styles.knowImg}
                  component="img"
                  image="/rounded_tcg.png"
                />
              </div>
              <CardContent>
                <Typography
                  className={styles.centerTypo}
                  variant="h2"
                  color="textSecondary"
                  component="h1"
                >
                  {t('home.knowledge')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={styles.tokensDown}>
            <CardActionArea component={Link} to={`reports/${lastReport.id}`}>
              <div className={styles.report}>
                <CardMedia
                  component="img"
                  className={styles.reportImg}
                  image={
                    lastReport.fileUrl ? lastReport.fileUrl : '/no_preview.png'
                  }
                />
              </div>
              <CardContent>
                <Typography variant="h3" component="div">
                  {lastReport.name}
                </Typography>
                <Typography variant="h5" component="div">
                  {t('home.lastAccess')}{' '}
                  {lastReport.visitDate && parseDate(lastReport.visitDate)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={styles.ticketsWidget}>
            <CardContent>
              <Typography
                variant="h2"
                component={Link}
                to={{
                  pathname: '/support',
                }}
                className={styles.ticketListTitle}
                gutterBottom
              >
                {t('home.tickets.title')}
              </Typography>
              <Grid
                className={styles.ticketContainer}
                direction="column"
                container
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Typography
                      display="inline"
                      align="center"
                      className={styles.ticketBarSucc}
                      variant="h3"
                    >
                      |&nbsp;
                    </Typography>
                    <Typography
                      display="inline"
                      align="center"
                      className={styles.ticketListNumber}
                      variant="h3"
                    >
                      {ticketTotals.open}
                    </Typography>
                    <Typography
                      display="inline"
                      align="center"
                      className={styles.ticketListText}
                      variant="h3"
                    >
                      &nbsp;&nbsp;{t('home.tickets.open')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Typography
                      display="inline"
                      align="center"
                      className={styles.ticketBarPending}
                      variant="h3"
                    >
                      |&nbsp;
                    </Typography>
                    <Typography
                      display="inline"
                      align="center"
                      className={styles.ticketListNumber}
                      variant="h3"
                    >
                      {ticketTotals.waitingForApproval}
                    </Typography>
                    <Typography
                      display="inline"
                      align="center"
                      className={styles.ticketListText}
                      variant="h3"
                    >
                      &nbsp;&nbsp;{t('home.tickets.waitingForApproval')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Typography
                      display="inline"
                      align="center"
                      className={styles.ticketBarClosed}
                      variant="h3"
                    >
                      |&nbsp;
                    </Typography>
                    <Typography
                      display="inline"
                      align="center"
                      className={styles.ticketListNumber}
                      variant="h3"
                    >
                      {ticketTotals.close}
                    </Typography>
                    <Typography
                      display="inline"
                      align="center"
                      className={styles.ticketListText}
                      variant="h3"
                    >
                      &nbsp;&nbsp;{t('home.tickets.closed')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <div className={styles.widgetButton}>
              <Button
                component={Link}
                id="support-link"
                text={t('home.tickets.button')}
                variant="contained"
                height="42px"
                width="150px"
                backgroundColor="#2C9DC920"
                color="#22617B"
                borderRadius="10px"
                to={{ pathname: '/support' }}
              />
            </div>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <WeatherWidget className={styles.container} setErr={setErr} />
        </Grid>
        <Grid item xs={12}>
          <Card className={styles.carrousel}>
            <CardContent>
              <Grid alignItems="center" spacing={1} container>
                <Grid item xs={12}>
                  <Typography variant="h2">
                    {t('home.news.title')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Carrousel />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </div>
  );
}

export default Dashboard;

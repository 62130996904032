/* eslint-disable no-lonely-if */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import {
  Stage,
  Image,
  Layer,
  Line,
  Rect,
} from 'react-konva';
import { Html } from 'react-konva-utils';

import useImage from 'use-image';

import ImageLines from './ImageTypes/imageLines';
import ImageRects from './ImageTypes/imageRects';

const SurveyImage = ({
  buttonColor, lines, setLines, erase, questionResponseImage,
  lineList, setLineList, shareType, setUpdate, update,
}) => {
  // Rect se debe sacar con una request a la api.
  const [initialPoint, setInitialPoint] = useState({ x1: null, y1: null });
  const [lineId, setLineId] = useState(0);
  const [currentPoint, setCurrentPoint] = useState({ x: null, y: null });
  const [drawing, setDrawing] = useState(false);
  const [factor, setFactor] = useState(null);
  const [currentWidth, setCurrentWidth] = useState(0);

  // const styles = useStyles();
  const [imageTest] = useImage(questionResponseImage.imageUrl);

  const handleMouseDown = (event) => {
    if (buttonColor) {
      setInitialPoint({ ...initialPoint, x1: event.evt.layerX, y1: event.evt.layerY });
      setDrawing(true);
    }
  };

  const handleMouseMove = (event) => {
    if (drawing) {
      setCurrentPoint({ ...currentPoint, x: event.evt.layerX, y: event.evt.layerY });
    }
  };

  const handleMouseUp = () => {
    setDrawing(false);
    setCurrentPoint({ ...currentPoint, x: null, y: null });
    if (buttonColor && initialPoint.x1 && initialPoint.y1
      && currentPoint.x && currentPoint.y) {
      const line = {
        x1: initialPoint.x1,
        y1: initialPoint.y1,
        x2: currentPoint.x,
        y2: currentPoint.y,
        color: buttonColor,
        id: lineId,
      };
      setLines([...lines, line]);
      setLineId(lineId + 1);
    }
  };

  const handleEventListener = () => {
    setDrawing(false);
    setInitialPoint({
      ...initialPoint,
      x1: null,
      y1: null,
    });
    setCurrentPoint({
      ...currentPoint,
      x: null,
      y: null,
    });
  };

  useEffect(() => {
    if (drawing) {
      // eslint-disable-next-line no-undef
      document.body.style.cursor = 'pointer';
    } else {
      // eslint-disable-next-line no-undef
      document.body.style.cursor = 'context-menu';
    }
  }, [drawing]);

  const handleResize = () => {
    // eslint-disable-next-line no-console
    console.log('hice zoom');
  };

  useEffect(() => {
    if (lineList[questionResponseImage.id]) {
      setLines(lineList[questionResponseImage.id]);
      const arrayLength = lineList[questionResponseImage.id].length;
      if (arrayLength !== 0) {
        const lastId = lineList[questionResponseImage.id][arrayLength - 1].id;
        setLineId(lastId + 1);
      }
    }
    window.addEventListener('mouseup', handleEventListener);
    window.addEventListener('resize', handleResize);
    setCurrentWidth(window.innerWidth);
    return () => {
      window.removeEventListener('mouseup', handleEventListener);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const newLine = async () => {
      setInitialPoint({ ...initialPoint, x1: null, y1: null });
      const allImagesLines = lineList;
      allImagesLines[questionResponseImage.id] = lines;
      await setLineList(allImagesLines);
      setUpdate(!update);
    };

    if (lineList) {
      newLine();
    }
  }, [lines]);

  const cleanLine = (id) => {
    if (erase) {
      const linesArray = lines.filter((item) => (item.id !== id));
      const arrayLineList = lineList;
      arrayLineList[questionResponseImage.id] = linesArray;
      setLines(linesArray);
      setLineList(arrayLineList);
    }
  };

  // Se usa la razon: 432 x 540 (ancho por alto)
  // Espacio maximo:
  // inner.width / 3.16
  // inner.height / 1.2

  const getUseDimensions = (image) => {
    const actualWindowWidth = window.innerWidth / (1.1 * 3.16);
    const actualWindowHeight = window.innerHeight / (1.1 * 1.2);
    const heightFactor = actualWindowHeight / image.height;
    const widthFactor = actualWindowWidth / image.width;
    if (image.width > actualWindowWidth) {
      if (image.height > actualWindowHeight) {
        if (heightFactor > widthFactor) {
          setFactor(widthFactor);
        } else {
          setFactor(heightFactor);
        }
      } else {
        setFactor(widthFactor);
      }
    } else {
      if (image.height > actualWindowHeight) {
        setFactor(heightFactor);
      } else {
        if (heightFactor > widthFactor) {
          setFactor(widthFactor);
        } else {
          setFactor(heightFactor);
        }
      }
    }
  };

  // Esto se esta calculando en cada vez
  // No encuentro otra forma
  useEffect(() => {
    // quizas se puede almacenar el ancho primero y el de despues, para ver si tirar o no la wea
    if (imageTest) {
      getUseDimensions(imageTest);
    }
  }, [imageTest]);

  useEffect(() => {
    if (imageTest && currentWidth !== window.innerWidth) {
      getUseDimensions(imageTest);
      setCurrentWidth(window.innerWidth);
    }
  });

  return (
    <>
      <Stage
        width={(window.innerWidth / (1.1 * 3.16)) + 4 }
        height={(window.innerHeight / (1.1 * 1.2)) + 4 }
      >
        <Layer >
          {/* <Rect
            x={0}
            y={0}
            width={window.innerWidth / (3.16 * 1.1) + 2}
            height={window.innerHeight / (1.1 * 1.2) }
            stroke='black'
          /> */}
          <Image
            image={imageTest}
            scale={{ x: factor, y: factor }}
            x={2}
            y={2}
            onMouseDown={(e) => {
              handleMouseDown(e);
            }}
            onMouseUp={(e) => {
              handleMouseUp(e);
            }}
            onMouseMove={(e) => {
              handleMouseMove(e);
            }}
          />
        { drawing && currentPoint.x && initialPoint.x1
          ? <>
            { shareType === 'linear-share'
              ? (<>
                  <Html>
                    <p align="right" style={{ color: 'black', paddingLeft: '10px', backgroundColor: 'white' }}>
                      <b>
                        {Math.hypot(currentPoint.x - initialPoint.x1,
                          currentPoint.y - initialPoint.y1).toFixed(0)}
                      </b>
                    </p>
                  </Html>
                  <Line
                    onMouseUp={(e) => {
                      handleMouseUp(e);
                    }}
                    onMouseMove={(e) => {
                      handleMouseMove(e);
                    }}
                    key={lineId}
                    x={0}
                    y={0}
                    points={ [initialPoint.x1, initialPoint.y1, currentPoint.x, currentPoint.y] }
                    stroke={buttonColor}
                    strokeWidth={5}
                    />
                 </>
              )
              : (<Rect
                  onMouseUp={(e) => {
                    handleMouseUp(e);
                  }}
                  onMouseMove={(e) => {
                    handleMouseMove(e);
                  }}
                  x={initialPoint.x1}
                  y={initialPoint.y1}
                  width={currentPoint.x - initialPoint.x1}
                  height={currentPoint.y - initialPoint.y1}
                  stroke={buttonColor}
                  fill={buttonColor}
                  opacity={buttonColor === 'blue' ? 0.5 : 0.3}
                />
              )
              }
            </>
          : null
        }
        </Layer>
        {shareType === 'linear-share'
          ? <ImageLines
              lines={lines}
              cleanLine={cleanLine}
              handleMouseUp={handleMouseUp}
              handleMouseDown={handleMouseDown}
              handleMouseMove= {handleMouseMove}
              />
          : <ImageRects
              lines={lines}
              cleanLine={cleanLine}
              handleMouseUp={handleMouseUp}
              handleMouseDown={handleMouseDown}
              handleMouseMove={handleMouseMove}
            />
        }
      </Stage>

    </>
  );
};

export default SurveyImage;

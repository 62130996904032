import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// core
import {
  Typography,
  TextField as MuiTextField,
  Paper,
  CircularProgress,
  Box,
  IconButton,
  Toolbar,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Avatar,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

// icons
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { getUser } from '../../../requests/api/user';
import sleep from '../../../utils/sleep';

// components
import useStyles from './styles';
import { getProjects } from '../../../requests/api/project';

import Snackbar from '../../../components/Snackbar';
import Pagination from '../../../components/Pagination';

// import UserInformation from './UserInformation';
// const
import { UNAUTHORIZED } from '../../../utils/const/http';
import { CLIENT_EMPLOYEE } from '../../../utils/const/user';

// eslint-disable-next-line no-shadow
const WalletList = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [walletsQuantity, setWalletsQuantity] = useState(0);
  const [userData, setUserData] = useState('');

  // auxiliar states

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');

  const account = useSelector((state) => state.account);

  // Filter states
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  const company = useSelector((state) => state.company);

  const requestSearch = async (pageOfTable, limit, searchValue, columnName) => {
    const response = await getProjects(pageOfTable, limit, searchValue, columnName);

    if (response.success) {
      setRowsToShow(response.data.data.projects);
      setWalletsQuantity(response.data.data.totalProjects);
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const setFilterOptions = async (newValue, tableColumn) => {
    setSearchText(newValue);
    await sleep(600);
    setColumn(tableColumn);
    setFilter(newValue);
    setPage(0);
    // Clear filters
    if (!tableColumn) {
      setSearchText('');
      setFilter('');
    }
  };

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (account.user.role === CLIENT_EMPLOYEE) window.location.href = '/home';
  }, []);

  useEffect(() => {
    load();
  }, [rowsToShow]);

  useEffect(() => {
    requestSearch(page, rowsPerPage, filter, column);
  }, [page, rowsPerPage, filter, column, walletsQuantity, account, company]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseUser = await getUser();
        await setUserData(responseUser.data.data.user);
      } catch {
        setErr(t('layout.userError'));
      }
    };
    fetchData();
  }, [t]);

  const gridContent = () => {
    if (!walletsQuantity) {
      return (
        <>
          <div className={styles.noWallets}>
            <div className={styles.container}>
            <CreditCardIcon /><h4>{t('wallet.noProjectWallets')}</h4>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
      <Grid container spacing={4} alignItems="center">
        {rowsToShow
          .map((row) => (
            <Grid item container key={row.name} xs >
                  <Card className={styles.tokens}>
                    <CardActionArea key={row.name} component={Link} to={`wallet/${row.projectId}`}>
                    <CardContent>
                      <Grid className={styles.scrollable} container alignItems="center">
                        <Grid item xs={12}>
                          <Box>
                            {row.name.length > 19 ? (<Typography variant='h2' gutterBottom className={styles.cardFontName}>
                              {row.name.slice(0, 19)}...
                              </Typography>) : <Typography variant='h2' gutterBottom className={styles.cardFontName}>
                                {row.name}
                              </Typography>
                            }
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid container xs={4} direction="row">
                            <Grid item xs={6}><Avatar variant="square" className={styles.chip} style={{ height: '30px', width: '45px' }} alt="chip" src="/chip-mini.png" /></Grid>
                            <Grid item xs={6}><Avatar variant="square" className={styles.chip} style={{ height: '30px', width: '40px' }} alt="chip" src="/nfc.png" /></Grid>
                            </Grid>
                            <Grid item xs={4}
                              container
                              direction="row"
                              justifyContent="flex-end"
                              >
                              <Typography variant='h4' gutterBottom className={styles.cardFont}>
                                  Corporate Plan
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="center"
                        >
                        <Grid item xs={3}>
                          <Typography variant='h2' gutterBottom className={styles.cardNumber}>
                            ....
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant='h2' gutterBottom className={styles.cardNumber}>
                            ....
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant='h2' gutterBottom className={styles.cardNumber}>
                            ....
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant='h2' gutterBottom className={styles.cardNumber}>
                          {row.projectId < 10
                            ? '000'
                            : null}
                          {row.projectId > 10 && row.projectId < 100
                            ? '00'
                            : null}
                          {row.projectId > 100 && row.projectId < 1000
                            ? '0'
                            : null}
                          {row.projectId}
                          </Typography>
                        </Grid>
                        </Grid>
                        <Grid item xs={8}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                          >
                            <Grid item xs>
                              <Typography variant='h4' gutterBottom className={styles.cardFont}>
                              Balance: {row.tokensLeft} tokens
                              </Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography variant='h2' className={styles.cardFont} >
                              {userData.fullName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}
                          container
                          direction="row"
                          justifyContent="flex-end"
                          >
                          <CardMedia
                            style={{ height: 35, width: 90 }}
                            image="/TCGHRES2021BACK.png"
                            title="tokens"
                            component="img"
                          />
                        </Grid>
                        </Grid>
                </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
      </>
    );
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h1' className={styles.title} gutterBottom>
            {t('wallet.walletList.title')}
          </Typography>
          <Typography variant='h4' gutterBottom display="inline">
          {t('wallet.walletList.subtitle')}
          </Typography>
          <Typography variant='subtitle1' className={styles.underlined} gutterBottom display="inline">
          {walletsQuantity} {t('wallet.walletList.wallets')}
          </Typography>
        </Box>
      </div>
      <Toolbar className={styles.toolbarTable} position='fixed' disableGutters>
        <Box className={styles.boxTitle}>
          <MuiTextField
                className={styles.searchField}
                id="search"
                variant="outlined"
                value={searchText}
                onChange={(event) => setFilterOptions(event.target.value, 'reportName')}
                placeholder={t('wallet.walletList.searchPlaceHolder')}
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" />,
                  endAdornment: (
                    <IconButton
                      id="clear"
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      onClick={() => setFilterOptions('', false)}
                      style={{ visibility: searchText ? 'visible' : 'hidden' }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
          </Box>
      </Toolbar>

          <>
            {loading ? (
            <CardContent className={styles.cardProgress}>
              <div className={styles.container}>
                <CircularProgress/>
              </div>
            </CardContent>
            ) : gridContent()}
            <Paper className={styles.walletTable}>
            <Pagination
              setPage={setPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={walletsQuantity}
            />
            </Paper>
          </>

      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </div>
  );
};

export default WalletList;

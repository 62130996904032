import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Box,
  IconButton,
  InputLabel,
  Grid,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
// icons
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';

// components
import useStyles from './styles';
import { getTickets } from '../../../requests/api/ticket';
import Modal from '../../../components/Modal';
import InformationCard from './informationCard';
import Snackbar from '../../../components/Snackbar';
import Pagination from '../../../components/Pagination';
import projectActions from '../../../actions/projects';
// import UserInformation from './UserInformation';
// const
import { UNAUTHORIZED } from '../../../utils/const/http';
import { parseDate } from '../../../utils/date';

// eslint-disable-next-line no-shadow
const SupportTickets = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // table states
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [ticketsQuantity, setTicketsQuantity] = useState(0);

  // auxiliar states

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const account = useSelector((state) => state.account);

  const tableHeaders = [
    {
      id: 'project',
      label: <b>{t('support.columnNames.project')}</b>,
    },
    {
      id: 'title',
      label: <b>{t('support.columnNames.title')}</b>,
    },
    {
      id: 'creator',
      label: <b>{t('support.columnNames.creator')}</b>,
    },
    {
      id: 'created_at',
      label: <b>{t('support.columnNames.createdAt')}</b>,
    },
    {
      id: 'details',
      label: <b>{t('support.columnNames.details')}</b>,
    },
    {
      id: 'file',
      label: <b>{t('documents.file')}</b>,
    },
  ];

  const company = useSelector((state) => state.company);

  const requestSearch = async (pageOfTable, limit, searchValue, columnName) => {
    const response = await getTickets(pageOfTable, limit, searchValue,
      columnName, false, sortBy, orderBy, true);
    if (response.success) {
      setRowsToShow(response.data.data.tickets);
      setTicketsQuantity(response.data.data.totalTickets);
    }
    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const handleFilter = (event) => {
    const {
      target: { value },
    } = event;
    if (value === 'createdAt' || value === 'closed_date') {
      setSortBy(value);
    } else if (value === 'ASC' || value === 'DESC') {
      setOrderBy(value);
    }
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const handleState = (state) => {
    switch (state) {
      case 'open':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagOpen}`}><b>- {t('support.open')}</b></p>
        );
      case 'processing':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagProcessing}`}><b>O {t('support.processing')}</b></p>
        );
      case 'waitingForApproval':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagWaitinfForApproval}`}><b>! {t('support.waitingForApproval')}</b></p>
        );
      case 'approved':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagApproved}`}><b>O {t('support.approved')}</b></p>
        );
      case 'rejected':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagRejected}`}><b>X {t('support.rejected')}</b></p>
        );
      case 'close':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagClose}`}><b>✔ {t('support.close')}</b></p>
        );
      default:
        return (
          <p align="center" className={`${styles.tag} ${styles.tagNA}`}>O <b>N/A</b></p>
        );
    }
  };

  useEffect(() => {
    load();
  }, [rowsToShow]);

  useEffect(() => {
    const getData = async () => {
      await dispatch(projectActions.actionFetchProjects());
      requestSearch(page, rowsPerPage, '', false);
    };
    getData();
  }, [page, rowsPerPage, ticketsQuantity,
    account, dispatch, company, sortBy, orderBy]);

  const handleDownload = (file) => {
    if (file) {
      window.open(file);
    }
  };

  const content = () => {
    if (!ticketsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={9} className={styles.users}>
            <ConfirmationNumberIcon />
            <h4>{t('support.noTickets')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row) => (
            <TableRow
              hover
              key = {row.id}
            >
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.Project.name}</TableCell>
              <TableCell align="left" width="20%">{row.subject}</TableCell>
              <TableCell align="left">{row.User.fullName}</TableCell>
              <TableCell align="center">
                {parseDate(row.createdAt)}
              </TableCell>
              <TableCell align="center" className={styles.iconsCell}>
                <Modal view={true}>
                  <InformationCard
                    stateBadge={handleState(row.state)}
                    data={row}
                    />
                </Modal>
              </TableCell>
              <TableCell align="center">
                <IconButton align='right' onClick={() => handleDownload(row.fileUrl)} disabled={row.fileUrl === null}>
                    <CloudDownloadRoundedIcon fontSize="large"/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </>
    );
  };

  return (
    <>
      <div className={styles.header}>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h1' className={styles.title} gutterBottom>
          {t('documents.tableTitle')}
          </Typography>
          <Typography variant='h4' gutterBottom display="inline">
          {t('support.subtitle')}
          </Typography>
          <Typography variant='subtitle1' className={styles.underlined} gutterBottom display="inline">
          {ticketsQuantity} {t('documents.documents')}
          </Typography>
        </Box>
      </div>
      <div className={styles.formWrapper}>
        <Grid container xs={12} spacing={2}>
            <Grid item>
              <FormControl className={styles.formControl}>
              <InputLabel id="demo-simple-select-label">{t('support.filterBy')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortBy}
                  onChange={handleFilter}
                >
                      <MenuItem key={'createdAt'} value={'createdAt'}>
                      {t('support.columnNames.createdAt')}
                      </MenuItem>
                      <MenuItem key={'closed_date'} value={'closed_date'}>
                      {t('support.columnNames.closedAt')}
                      </MenuItem>
                </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={styles.formControl}>
              <InputLabel id="demo-simple-select-label">{t('support.sortBy')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={orderBy}
                  onChange={handleFilter}
                >
                      <MenuItem key={'ASC'} value={'ASC'}>
                      {t('support.oldest')}
                      </MenuItem>
                      <MenuItem key={'DESC'} value={'DESC'}>
                      {t('support.mostRecent')}
                      </MenuItem>
                </Select>
            </FormControl>
          </Grid>
        </Grid>

        </div>
      <Paper className={styles.supportTable}>
          <>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((tableHeader) => (
                      <TableCell
                        key={tableHeader.id}
                        className={styles.tableHeader}
                        align={tableHeader.id === 'tokens'
                              || tableHeader.id === 'state'
                              || tableHeader.id === 'actions'
                              || tableHeader.id === 'estimated_closing' ? 'center' : 'left'}
                        padding="normal"
                      >
                        {tableHeader.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={9} className={styles.progress}>
                        <CircularProgress />
                        <h4>{t('support.loading')}</h4>
                      </TableCell>
                    </TableRow>
                  ) : content()}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              setPage={setPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={ticketsQuantity}
            />
          </>
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </>
  );
};

export default SupportTickets;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    color: theme.palette.primary.middleGray,
  },
  paperContainer: {
    borderRadius: '12px',
    minWidth: '200px',
    '& .MuiPaper-root': {
      elevation: 3,
    },
    '& hr': {
      border: '0',
      height: '0.5px',
      borderTop: `0.5px solid ${theme.palette.primary.lightGray}`,
      margin: '0px',
    },
  },
  modalToolbar: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  modalTitle: {
    flexGrow: 1,
    paddingLeft: '30px',
    color: theme.palette.primary.darkGray,
    fontSize: '17.7px',
    fontWeight: '600',
  },
  icon: {
    '& svg': {
      color: theme.palette.primary.main,
    },
    '& svg:hover': {
      color: theme.palette.primary.blue,
    },
  },
}));

export default useStyles;

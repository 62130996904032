import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  InputLabel,
  Typography,
  CircularProgress,
  IconButton,
} from '@material-ui/core';

import axiosOriginal from 'axios';
import { useLocation } from 'react-router';
import ClearIcon from '@material-ui/icons/Clear';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import WarningIcon from '@material-ui/icons/Warning';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import bytesToSize from '../../../utils/bytesToSize';
import { createReport, updateReport } from '../../../requests/api/report';

import useStyles from '../styles';

const ReportForm = ({
  reload,
  currentReport,
  setCurrentOpen,
  setError,
  setSucc,
  update,
}) => {
  const styles = useStyles();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const [inputValues, setInputValues] = useState(currentReport);
  const [advanced, setAdvanced] = useState(false);

  const handleClose = () => {
    setCurrentOpen(false);
  };

  const handleUpload = async () => {
    setLoading(true);
    const fileParts = file.name.split('.');
    const timestamp = Date.now();
    const fileName = location.pathname === '/reports' ? `${fileParts[0]}-${timestamp}`
      : `${fileParts[0]} ${timestamp}`;
    const uploadData = {
      fileName,
      fileType: file.type,
    };
    const response = await axiosOriginal.post('/api/reports/signS3', uploadData);
    const options = {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': 'attachment',
      },
    };
    const { returnData } = response.data.data;
    const { signedRequest } = returnData;
    try {
      const uploadResponse = await axiosOriginal.put(
        signedRequest,
        file,
        options,
      );
      if (uploadResponse.status === 200) {
        const fileUrl = uploadResponse.config.url.split('?')[0];
        setError('');
        setLoading(false);
        return fileUrl;
      }
    } catch (err) {
      setError(t('administration.company.imageError'));
      return (err);
    }
    return (false);
  };

  const handleRestore = async () => {
    const response = await updateReport({
      id: inputValues.id,
      deleted: false,
    });
    if (response.success) {
      setSucc(t('administration.success'));
      handleClose();
      reload();
    } else {
      setError(t('administration.error'));
      handleClose();
      reload();
    }
  };

  const handleSelect = ({ target }) => {
    setFile(target.files[0]);
    setError('');
  };

  const handleMaxChar = (value) => {
    if (value.length >= 50) {
      return inputValues.warningMessage;
    }
    return value;
  };

  const handleChange = (e) => {
    const { id, name, value } = e.target;
    if (!id) {
      if (name === 'warningStatus' && value === 'normal') {
        setInputValues({
          ...inputValues,
          [name]: value,
          warningMessage: '',
        });
      } else {
        setInputValues({
          ...inputValues,
          [name]: value,
        });
      }
    } else if (id === 'warningMessage') {
      setInputValues({
        ...inputValues,
        [id]: handleMaxChar(value),
      });
    } else {
      setInputValues({
        ...inputValues,
        [id]: value,
      });
    }
  };

  const uploaderRender = () => {
    if (file) {
      return (
        <Grid
        container
        item
        justifyContent='flex-end'
        direction='row'
        xs={12}
        >
          <Grid
          container
          item
          justifyContent='flex-end'
          direction='row'
          alignItems='center'
          xs={8}
          >
            <Grid item xs={12}>
              <Typography variant="body1">
                {file.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {bytesToSize(file.size)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs>
          <div className={styles.actions}>
            {loading ? (<CircularProgress />)
              : <ClearIcon onClick={() => setFile(null)} className={styles.uploadIcon} fontSize='large'/>
            }
          </div>
          </Grid>
        </Grid>
      );
    } return (
            <Grid
            container
            item
            justifyContent='flex-start'
            direction='row-reverse'
            xs={12}
            >
              <Grid item xs>
              <input
                className={styles.input}
                id="contained-button-file"
                accept='.jpg, .png, .jpeg'
                multiple
                type="file"
                onChange={handleSelect}
              />
              <label className={styles.uploadIcon} htmlFor="contained-button-file">
                      <CloudUploadIcon fontSize='large'/>
              </label>
              </Grid>
         </Grid>
    );
  };

  const handleSend = async () => {
    let response;
    const url = await (file && handleUpload(file));
    inputValues.deleted = inputValues.deleted !== 0;
    if (update) {
      if (url) {
        response = await updateReport({
          ...inputValues,
          fileUrl: url,
        });
      } else {
        response = await updateReport(inputValues);
      }
    } else {
      if ((Object.values(inputValues).some((value) => value === ''))) {
        setError(t('administration.reportForm.required'));
        return;
      }
      response = await createReport({
        ...inputValues,
        fileUrl: url,
      });
    }
    if (response.success) {
      setSucc(t('administration.success'));
      handleClose();
      reload();
    } else {
      setError(t('administration.error'));
      handleClose();
      reload();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const showAdvanced = () => {
    setAdvanced(!advanced);
  };

  return (
    <div>
        <div className={styles.formWrapper}>
          <Grid container spacing={2} alignItems='flex-end'>
              <Grid item xs={12}>
              <InputLabel>{t('administration.report.formName')}</InputLabel>
              <TextField
                id="reportName"
                type="text"
                variant="outlined"
                value={inputValues.reportName}
                onChange={handleChange}
                fullWidth
                InputProps={{
                  onKeyDown: handleKeyDown,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('administration.report.description')}</InputLabel>
              <TextField
                id="reportDescription"
                type="text"
                variant="outlined"
                value={inputValues.reportDescription}
                onChange={handleChange}
                fullWidth
                InputProps={{
                  onKeyDown: handleKeyDown,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Workspace Id</InputLabel>
              <TextField
                id="workspaceId"
                type="text"
                variant="outlined"
                value={inputValues.workspaceId}
                onChange={handleChange}
                fullWidth
                InputProps={{
                  onKeyDown: handleKeyDown,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Report Id</InputLabel>
              <TextField
                id="reportId"
                type="text"
                variant="outlined"
                value={inputValues.reportId}
                onChange={handleChange}
                fullWidth
                InputProps={{
                  onKeyDown: handleKeyDown,
                }}
              />
            </Grid>
            <Grid item xs= {12}/>
            <IconButton onClick={showAdvanced}>
              <WarningIcon />
              <Typography>
                {t('administration.report.raiseWarning')}
              </Typography>
            </IconButton>
            <Grid item xs={12}>
            {advanced ? <div className={styles.formWrapper}>
            <Grid item xs={8}>
                  <InputLabel>{t('administration.report.warningStatus')}</InputLabel>
                  <Select
                    name="warningStatus"
                    items={[
                      { name: t('administration.report.normalWarning'), value: 'normal' },
                      { name: t('administration.report.lowWarning'), value: 'low' },
                      { name: t('administration.report.highWarning'), value: 'high' },
                    ]}
                    value={inputValues.warningStatus}
                    onChange={handleChange}
                    fullWidth
                  />
            </Grid>
            <Grid item xs={8}>
                  <InputLabel>{t('administration.report.warningMessage')}</InputLabel>
                  <TextField
                    variant="outlined"
                    id="warningMessage"
                    value={inputValues.warningMessage}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={2}
                    InputProps={{
                      onKeyDown: handleKeyDown,
                    }}
                    disabled={inputValues.warningStatus === 'normal'}
                  />
            </Grid>
            </div> : null}
              </Grid>
            <Grid item xs= {4}>
                  {uploaderRender()}
            </Grid>
            <Grid item xs={4}>
            <Button
              id="restore-report-btn"
              onClick={() => handleRestore()}
              text={t('administration.buttons.restore')}
              height="35px"
              width="100%"
              backgroundColor="#2C9DC9"
              color="#FFFFFF"
              borderRadius="10px"
              disabled={!currentReport.deleted}
            />
            </Grid>
            <Grid item xs={4}>
            <Button
              id="submit-ticket-btn"
              onClick={handleSend}
              text={update ? t('administration.buttons.update') : t('administration.buttons.create')}
              height="35px"
              width="100%"
              backgroundColor="#2C9DC9"
              color="#FFFFFF"
              borderRadius="10px"
            />
            </Grid>
            </Grid>
          </div>
    </div>
  );
};

export default ReportForm;

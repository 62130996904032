import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR, OK } from '../../utils/const/http';

const postTeleauditBinaryResponse = async (
  teleauditKpiId,
  questionResponseId,
  score,
) => {
  try {
    const response = await axios.post('/api/teleaudit-binary-response', {
      teleauditKpiId,
      questionResponseId,
      score,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deactivateTeleauditBinaryResponses = async ({
  questionResponseId,
}) => {
  try {
    const response = await axios.put(`/api/teleaudit-binary-response/question-response/${questionResponseId}`, {
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  postTeleauditBinaryResponse,
  deactivateTeleauditBinaryResponses,
};

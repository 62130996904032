import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

function Home() {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.homeWrapper}>
      <div className={styles.home}>
        <img className={styles.image} src="/TCG_logo.png" alt="TCG logo" />
        <div className={styles.homeLink}>
          <a href="/login">{t('login.links.home')}</a>
        </div>
      </div>
    </div>
  );
}

export default Home;

/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR, CONFLICT } from '../../utils/const/http';

const getTaskResponses = async (page, limit, companyId) => {
  try {
    const { data } = await axios.get(`/api/task-responses/?companyId=${companyId}&page=${page}&limit=${limit}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const startTaskResponseReview = async (taskResponseId) => {
  try {
    const { data } = await axios.post(`/api/task-responses/${taskResponseId}/start-review`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    // TODO: need better handle of response in CONFLICT state
    let taskResponseReview = '';
    if (err.response.status === CONFLICT) {
      taskResponseReview = err.response ? err.response.data.data.taskResponseReview : {};
    }

    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
        taskResponseReview,
      },
    };
  }
};

export {
  getTaskResponses,
  startTaskResponseReview,
};

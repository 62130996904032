import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// core
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableCell,
  TableRow,
  Checkbox,
  IconButton,
  TextField as MuiTextField,
} from '@material-ui/core';

// icons
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

// components
import Button from '../Button';

// requests
import { deleteUserGroup } from '../../requests/api/userGroup';
import { deleteCluster } from '../../requests/api/cluster';
import { deleteListing } from '../../requests/api/listing';

import setFilterOptions from '../../utils/filterFunctions';

// functions
import { handleSelectAllClick } from '../../utils/selectFunctions';

const useStyles = makeStyles((theme) => ({
  toolbarButtons: {
    display: 'table-cell',
    '& button': {
      marginRight: '10px',
      '& svg': {
        color: theme.palette.secondary.gray,
      },
    },
    '& .MuiOutlinedInput-root': {
      height: '35px',
      fontSize: '13px',
    },
  },
  toolbarContainer: {
    display: 'flex',
    marginTop: '8px',
  },
}));

function TableToolbarActions({
  tab,
  selected,
  setSelected,
  rowsToShow,
  setUpdate,
  setMessage,
  setSeverity,
  onlyDelete,
  searchText,
  setSearchText,
  setColumn,
  setFilter,
  placeholder,
  deleteFunction,
  table,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleDelete = async () => {
    let delFunction;

    if (!onlyDelete) {
      delFunction = {
        0: deleteUserGroup,
        1: deleteListing,
        2: deleteCluster,
      };
    }

    const responses = await Promise.all(selected.map(async (s) => {
      const response = await tab !== undefined ? delFunction[tab](s) : deleteFunction(s);
      return response.success;
    }));

    if (responses.includes(false)) {
      setMessage(t('snackBar.errors.removing'));
      setSeverity('error');
    } else {
      setMessage(t('snackBar.success.removing'));
      setSeverity('success');
    }

    setSelected([]);
    setUpdate((prevState) => !prevState);
  };

  const handleOpenDelete = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleApprove = async () => {
    setOpenDialog(false);
    await handleDelete();
  };
  return (
    <TableRow key="checkbow-row">
        {table ? null : (
          <TableCell padding="checkbox">
              <Checkbox
                indeterminate={
                selected.length > 0 && selected.length < rowsToShow.length
                }
                checked={rowsToShow.length > 0 && selected.length === rowsToShow.length}
                onChange={(event) => (
                  handleSelectAllClick(event, rowsToShow, 'name', setSelected)
                )}
                inputProps={{ 'aria-label': 'select all' }}
              />
          </TableCell>
        )}
        <TableCell colSpan={7} className={styles.toolbarButtons}>
            <div className={styles.toolbarContainer}>
                {table ? null : (
                  <Container>
                    <Button
                      id="delete-btn"
                      variant="outlined"
                      text={t('groups.actionButtons.delete')}
                      width="100px"
                      backgroundColor='theme-primary-white'
                      color='theme-primary-red'
                      borderColor="theme-primary-red"
                      borderRadius='10px'
                      onClick={handleOpenDelete}
                      disabled={selected.length === 0}
                    />
                      <Dialog open={openDialog} onClose={handleClose}>
                        <DialogTitle>{t('warnings.deleteTitle')}</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            {t('warnings.deleteMessage')}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button variant="outlined" onClick={handleClose}>{t('warnings.cancel')}</Button>
                          <Button variant="contained" onClick={handleApprove}>{t('warnings.delete')}</Button>
                        </DialogActions>
                      </Dialog>
                  </Container>

                )}
                <MuiTextField
                    id="search"
                    variant="outlined"
                    fullWidth
                    value={searchText}
                    onChange={(event) => setFilterOptions(event.target.value, 'name', setColumn, setSearchText, setFilter)}
                    placeholder={placeholder}
                    InputProps={{
                      startAdornment: <SearchIcon fontSize="small" />,
                      endAdornment: (
                            <IconButton
                            id="clear"
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            onClick={() => setFilterOptions('', false, setColumn, setSearchText, setFilter)}
                            style={{ visibility: searchText ? 'visible' : 'hidden' }}
                        >
                        <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                />
            </div>
        </TableCell>
    </TableRow>
  );
}

export default TableToolbarActions;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// core
import { IconButton, Toolbar, TextField } from '@material-ui/core';
// icons
import BackIcon from '@material-ui/icons/ReplyOutlined';
// components
import Snackbar from '../../../components/Snackbar';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
// utils
import { SUPER_ADMIN } from '../../../utils/const/user';
import load from '../../../utils/load';
import { elements, props } from '../../../utils/const/association';
// requests
import { getGroupsPerPage } from '../../../requests/api/group';
import { getUsersPerPageByCompanyId } from '../../../requests/api/user';
import { getClusterById } from '../../../requests/api/cluster';
import { postNewTaskInstance, putEditTaskInstance } from '../../../requests/api/taskInstance';

import TableDragAndDrop from './tableDragAndDrop';
import TaskAssociationConfirm from './taskAssociationConfirm';

import useStyles, { StyledTab, StyledTabs } from './styles';

function TaskAssociation({
  edit, cluster, name, project, taskId, setTaskId, setAssociate, taskInstances, setEdit,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  // table states
  const rowsPerPage = 10;
  const [selected, setSelected] = useState([]);
  const [pageIn, setPageIn] = useState(0);
  const [pageOut, setPageOut] = useState(0);
  const [quantityIn, setQuantityIn] = useState(0);
  const [quantityOut, setQuantityOut] = useState(0);
  // auxiliar states
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [out, setOut] = useState(true);
  const [ready, setReady] = useState(false);
  const [drag, setDrag] = useState(false);
  const [associationsDetail, setAssociationsDetail] = useState(false);
  const [update, setUpdate] = useState(false);
  const [pageChanged, setPageChanged] = useState(false);
  // Filter states
  const [searchText, setSearchText] = useState({
    usersIn: '',
    usersNotIn: '',
    usersSearch: '',
    userGroupsIn: '',
    userGroupsNotIn: '',
    userGroupsSearch: '',
    locationsIn: '',
    locationsNotIn: '',
    locationsSearch: '',
  });
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);
  // snackbar states
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  // Data states
  const [info, setInfo] = useState({
    usersIn: [],
    usersNotIn: [],
    usersSearch: [],
    userGroupsIn: [],
    userGroupsNotIn: [],
    userGroupsSearch: [],
    locationsIn: [],
    locationsNotIn: [],
    locationsSearch: [],
  });

  const [associations, setAssociations] = useState([]);

  const [resetScale, setResetScale] = useState({
    name: t('task.association.resetTimes.placeholder'),
    value: '',
  });
  const [responsesExpected, setResponsesExpected] = useState(0);

  const account = useSelector((state) => state.account);

  const resetTimes = [
    {
      name: t('task.association.resetTimes.placeholder'),
      value: '',
    },
    {
      name: t('task.association.resetTimes.hourly'),
      value: 'hour',
    },
    {
      name: t('task.association.resetTimes.daily'),
      value: 'daily',
    },
    {
      name: t('task.association.resetTimes.weekly'),
      value: 'weekly',
    },
    {
      name: t('task.association.resetTimes.biweekly'),
      value: 'biWeekly',
    },
    {
      name: t('task.association.resetTimes.monthly'),
      value: 'monthly',
    },
    {
      name: t('task.association.resetTimes.quarterly'),
      value: 'quartely',
    },
    {
      name: t('task.association.resetTimes.annual'),
      value: 'annual',
    },
    {
      name: t('task.association.resetTimes.notClose'),
      value: 'notClose',
    },
  ];

  const handleBack = () => {
    setEdit(false);
    setTaskId(null);
    setAssociate(false);
  };

  const handleSaveChanges = async () => {
    const taskInstanceNewInfo = associations.map((taskInstance) => {
      const locations = [];
      taskInstance.locations.forEach((location) => {
        if (!location.taskInstanceId && !location.checked) return;
        locations.push({
          taskInstanceId: location.taskInstanceId,
          id: location.id,
          checked: location.checked,
          active: true,
          resetScale: null,
          expectedResponses: null,
        });
      });
      return {
        taskInstanceId: taskInstance.taskInstanceId,
        taskId,
        id: taskInstance.id,
        checked: taskInstance.checked,
        active: true,
        resetScale: null,
        expectedResponses: null,
        locations,
      };
    });

    const response = await putEditTaskInstance({ taskInstanceNewInfo });

    if (response.success) {
      setSeverity('success');
      setMessage(t('task.association.success.creating'));

      setTimeout(() => {
        setTaskId(null);
        setEdit(false);
        setAssociate(false);
      }, 500);
    } else {
      setSeverity('error');
      setMessage(t('task.association.errors.creating'));
    }
  };

  // Saves the association / create the task_instance
  const handleSaveNew = async () => {
    // If not assigned to any user does not create the taskInstance.
    if (!info.usersIn.length) {
      setSeverity('error');
      setMessage(t('task.association.errors.usersEmpty'));
      return;
    }
    // If task is associated to a Cluster must select at least one location.
    if (cluster.name && !info.locationsIn.length) {
      setSeverity('error');
      setMessage(t('task.association.errors.locationsEmpty'));
      return;
    }

    if (!associationsDetail) {
      // First the associations are saved and the confirmation view is displayed.
      const associationsArray = info.usersIn.map((user) => {
        const locationsWithCheck = info.locationsIn.map((location) => {
          const locationWithCheck = {
            ...location,
            checked: true,
          };
          return locationWithCheck;
        });
        return ({
          checked: true,
          ...user,
          locations: locationsWithCheck,
        });
      });

      setAssociations(associationsArray);
      setAssociationsDetail(true);
      return;
    }

    const userLocationPairs = associations.map((user) => {
      const locations = [];
      user.locations.forEach((location) => {
        if (location.checked) {
          locations.push({
            id: location.id,
            active: true,
            resetScale: resetScale.value,
            expectedResponses: responsesExpected,
          });
        }
      });
      return [{
        id: user.id,
        active: true,
        resetScale: resetScale.value,
        expectedResponses: responsesExpected,
        checked: user.checked,
      }, locations];
    });

    const taskInstanceData = {
      taskId,
      clusterAssociation: !!cluster,
      userLocationPairs,
      taskName: name,
    };

    const response = await postNewTaskInstance(taskInstanceData);

    if (response.success) {
      setSeverity('success');
      setMessage(t('task.association.success.creating'));

      setTimeout(() => {
        setTaskId(null);
        setAssociate(false);
      }, 500);
    } else {
      setSeverity('error');
      setMessage(t('task.association.errors.creating'));
    }
  };

  // Reset Scale on change
  const handleOnChange = (event) => {
    setResetScale(event.target);
  };

  // Search entities
  const requestSearch = async (pageOfTable,
    limit, searchValue, columnName, IsOut, currentTab) => {
    let response;
    if (currentTab === 0) {
      const groupsIdIn = info.userGroupsIn.map((group) => group.id);
      const groupsIdOut = info.userGroupsNotIn.map((group) => group.id);
      response = await getGroupsPerPage('user-group', pageOfTable,
        limit, searchValue, columnName, 'noDeleted', groupsIdIn, groupsIdOut, IsOut);
    } else if (currentTab === 2) {
      const clusterId = cluster ? cluster.value.split('-')[0] : '';
      const locationsIdIn = info.locationsIn.map((location) => location.id);
      const locationsIdOut = info.locationsNotIn.map((location) => location.id);
      response = await getClusterById(
        clusterId, pageOfTable, pageOfTable, limit,
        searchValue, locationsIdIn, locationsIdOut, IsOut,
      );
    } else {
      const usersIdIn = info.usersIn.map((user) => user.id);
      const usersIdOut = info.usersNotIn.map((user) => user.id);
      response = await getUsersPerPageByCompanyId(pageOfTable, limit, searchValue, columnName,
        usersIdIn, usersIdOut, 'noDeleted', project.value);
    }
    if (response.success) {
      // Searches for the property to which the data belongs (in or out)
      const key = IsOut ? props[currentTab][1] : props[currentTab][0];
      const keySearch = props[currentTab][2];
      let newRecords = currentTab === 2
        ? response.data.data[elements[currentTab][0]].locationsRows.data
        : response.data.data[elements[currentTab][0]];
      if (IsOut) {
        const newQuantity = currentTab === 2
          ? response.data.data[elements[currentTab][0]].locationsRows.count
          : response.data.data[elements[currentTab][1]];

        if (info[props[currentTab][0]].length > 0) {
          const ids = info[props[currentTab][0]].map((entity) => entity.id);
          newRecords = newRecords.filter((record) => !ids.includes(record.id));
        }
        const paginationInElements = info[props[currentTab][0]].slice(
          pageIn * limit, (pageIn * limit) + limit,
        );
        setInfo({
          ...info,
          [key]: newRecords,
          [keySearch]: paginationInElements,
        });
        setQuantityOut(newQuantity);
        setQuantityIn(info[props[currentTab][0]].length);
      } else if (!IsOut && info[props[currentTab][0]].length > 0) {
        let insideElements;
        let attributeToFilter;
        if (tab === 1) {
          attributeToFilter = 'fullName';
        } else {
          attributeToFilter = 'name';
        }
        if (searchValue !== '') {
          insideElements = info[props[currentTab][0]].filter(
            (record) => record[attributeToFilter].toLowerCase()
              .includes(searchValue.toLowerCase()),
          );
        } else {
          insideElements = info[props[currentTab][0]];
        }
        const paginationInElements = insideElements.slice(
          pageIn * limit, (pageIn * limit) + limit,
        );
        setInfo({
          ...info,
          [props[currentTab][2]]: paginationInElements,
        });
        setQuantityIn(insideElements.length);
      }

      if (tab === 0) setReady(true);
      setUpdate((prevState) => !prevState);
    }
  };

  // Change the tool tab
  const handleChangeTab = async (event, newTab) => {
    setLoading(true);
    setTab(newTab);
    setSelected([]);
    setOut(true);
    setFilter('');
    setPageIn(0);
    setPageOut(0);
    requestSearch(pageOut, rowsPerPage, '', false, true, newTab);
    setLoading(false);
  };

  useEffect(() => {
    if (taskInstances) {
      // Before displaying the assignments create an object with the correct format to display.
      const userLocationPairs = [];
      taskInstances.forEach(({ id, User, Location }) => {
        if (!userLocationPairs.length) {
          userLocationPairs.push({
            taskInstanceId: id,
            ...User,
            checked: !Location,
            locations: Location ? [{
              taskInstanceId: id,
              ...Location,
              checked: true,
            }] : [],
          });
        } else {
          const index = userLocationPairs.findIndex(
            (userLocationPair) => userLocationPair.id === User.id,
          );

          if (index === -1) {
            userLocationPairs.push({
              taskInstanceId: id,
              ...User,
              checked: !Location,
              locations: Location ? [{
                taskInstanceId: id,
                ...Location,
                checked: true,
              }] : [],
            });
            return;
          }

          if (Location) {
            userLocationPairs[index].locations = [...userLocationPairs[index].locations, {
              taskInstanceId: id,
              ...Location,
              checked: true,
            }];
          }
        }
      });
      setAssociations(userLocationPairs);
    }
  }, [edit, update, taskInstances]);

  useEffect(() => {
    load(200, setLoading);

    if (ready && !info.usersNotIn.length && !edit) {
      requestSearch(pageOut, rowsPerPage, filter, column, out, tab);
    }
    // eslint-disable-next-line
  }, [ready]);

  useEffect(() => {
    setOut(false);
    setPageChanged((prevState) => !prevState);
  }, [pageIn]);

  useEffect(() => {
    setOut(true);
    setPageChanged((prevState) => !prevState);
  }, [pageOut]);

  useEffect(() => {
    if (account.user.role !== SUPER_ADMIN) {
      window.location.href = '/home';
    }
    let searchValue = '';
    if (out) {
      searchValue = searchText[props[tab][1]];
    } else {
      searchValue = searchText[props[tab][0]];
    }
    if (!edit) {
      requestSearch(pageOut, rowsPerPage, searchValue, column, out, tab);
    }
    // eslint-disable-next-line
  }, [pageChanged, rowsPerPage, account.user.role, column, filter, out, tab, drag]);

  return (
    <div className={styles.content}>
      <IconButton
        id="backButton"
        aria-label="back"
        className={styles.backButton}
        onClick={handleBack}
      >
        <BackIcon fontSize="small" />
        <span>{t('groups.actionButtons.back')}</span>
      </IconButton>
      <div className={styles.upperContainer}>
        <div>
          <h3>{name}</h3>
          <p>{project.name}</p>
        </div>
        <Button
          id="save-btn"
          variant="outlined"
          text={associationsDetail ? t('task.association.confirm') : t('task.association.createButton')}
          height="40px"
          width="220px"
          backgroundColor='theme-secondary-blue'
          color='white'
          borderRadius='10px'
          onClick={edit ? handleSaveChanges : handleSaveNew}
          disabled={edit ? !associations.length : false}
        />
      </div>
      {edit ? (
        <TaskAssociationConfirm
          associations={associations}
          cluster={cluster}
        />
      ) : (
        <>
          <h4 className={styles.associationsTitle}>
            {t('task.association.confirmAssociations')}
          </h4>
          {resetTimes && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                name="reset-scale"
                items={resetTimes}
                value={resetScale ? resetScale.value : ''}
                onChange={handleOnChange}
              />
              <p className={styles.numberInput}>
                {t('task.association.repetitionMessage')}
              </p>
              <TextField
                style={{ width: '50px', paddingTop: '2.5%', height: '20px' }}
                id="exepected-location-responses"
                type="number"
                value={responsesExpected}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const sanitizedValue = inputValue.replace(/[^0-9]/g, '');
                  if (sanitizedValue !== '' && Number(sanitizedValue) >= 0) {
                    setResponsesExpected(Number(sanitizedValue));
                  } else {
                    setResponsesExpected('');
                  }
                }}
                onKeyDown={(event) => {
                  // Evita que se ingresen los signos "+" y "-"
                  if (event.key === '+' || event.key === '-' || event.key === 'e') {
                    event.preventDefault();
                  }
                }}
              />

            </div>
          )}
          {associationsDetail ? (
            <div className={styles.associationsView}>
              <TaskAssociationConfirm
                associations={associations}
                cluster={cluster}
              />
            </div>
          ) : (
            <>
              <Toolbar className={styles.toolbarTable}>
                <StyledTabs
                  value={tab}
                  variant="scrollable"
                  onChange={handleChangeTab}
                >
                  <StyledTab label={t('task.association.tabs.userGroups')} />
                  <StyledTab label={t('task.association.tabs.users')} />
                  {cluster.name ? <StyledTab label={t('task.association.tabs.locations')} /> : null}
                </StyledTabs>
              </Toolbar>
              <TableDragAndDrop
                info={info}
                setInfo={setInfo}
                loading={loading}
                pageIn={pageIn}
                setPageIn={setPageIn}
                pageOut={pageOut}
                setPageOut={setPageOut}
                rowsPerPage={rowsPerPage}
                searchText={searchText}
                setSearchText={setSearchText}
                selected={selected}
                setSelected={setSelected}
                setFilter={setFilter}
                setColumn={setColumn}
                setOut={setOut}
                tab={tab}
                quantityIn={quantityIn}
                quantityOut={quantityOut}
                setDrag={setDrag}
                drag={drag}
              />
            </>
          )}
        </>
      )}
      <Snackbar
        open={message !== ''}
        message={message}
        severity={severity}
        onClose={() => setMessage('')}
      />
    </div>
  );
}

export default TaskAssociation;

import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../utils/const/http';

const getClusterById = async (id, clustersPage, locationsPage,
  limit, searchValue, locationsIdIn, locationsIdOut, isOut) => {
  try {
    const { data } = await axios.get(`/api/cluster/${id}?clustersPage=${clustersPage}&locationsPage=${locationsPage}&limit=${limit}&searchValue=${searchValue}&locationsIdIn=${locationsIdIn}&locationsIdOut=${locationsIdOut}&isOut=${isOut}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const editCluster = async (id, name, locationIds, listing) => {
  try {
    const { data } = await axios.put(`/api/cluster/${id}`, {
      name,
      locationIds,
      listing,
    });

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postNewCluster = async (values) => {
  try {
    const { data } = await axios.post('/api/cluster', values);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const deleteCluster = async (id) => {
  try {
    const { data } = await axios.delete(`/api/cluster/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const getCompanyClusters = async () => {
  try {
    const { data } = await axios.get('/api/company-cluster');

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getFilteredLocationsInGroup = async (
  id, locationsInGroupPage, limit, searchValue, column, idsNotInTheGroup, idsInTheGroup, noDeleted,
) => {
  try {
    const { data } = await axios.get(`/api/cluster/filtered-locations-in/${id}?page=${locationsInGroupPage}&limit=${limit}&searchValue=${searchValue}&column=${column}&noDeleted=${noDeleted}&idsNotInTheGroup=${idsNotInTheGroup}&idsInTheGroup=${idsInTheGroup}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getFilteredLocationsNotInGroup = async (
  id, locationsNotInGroupPage, limit, searchValue,
  column, idsInTheGroup, idsNotInTheGroup,
) => {
  try {
    const { data } = await axios.get(`/api/cluster/filtered-locations-out/${id}?page=${locationsNotInGroupPage}&limit=${limit}&searchValue=${searchValue}&column=${column}&idsInTheGroup=${idsInTheGroup}&idsNotInTheGroup=${idsNotInTheGroup}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  getClusterById,
  editCluster,
  postNewCluster,
  deleteCluster,
  getCompanyClusters,
  getFilteredLocationsInGroup,
  getFilteredLocationsNotInGroup,
};

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Typography,
  InputLabel,
  TextField,
} from '@material-ui/core';

import { getLocation } from '../../requests/api/location';
import useStyles from './styles';

const LocationInfo = ({ locationId }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const [inputValues, setInputValues] = useState({
    company: '',
    name: '',
    internalCode: '',
    chain: '',
    chainSubCategory: '',
    deleted: '',
    countryCode: '',
    city: '',
    region: '',
    sector: '',
    zone: '',
    address: '',
    latitude: '',
    longitude: '',
  });

  useEffect(() => {
    const getLocationRequest = async () => {
      const response = await getLocation(locationId);
      if (response.success) {
        const { location } = response.data.data;
        setInputValues({
          company: location.Company.name,
          name: location.name,
          internalCode: location.internalCode,
          chain: location.chain,
          chainSubCategory: location.chainSubCategory,
          deleted: location.deleted ? t('locations.locationForm.deletedOption') : t('locations.locationForm.activeOption'),
          countryCode: location.Address.countryCode,
          city: location.Address.city,
          region: location.Address.region,
          sector: location.Address.sector,
          zone: location.Address.zone,
          address: location.Address.address,
          latitude: location.Address.latitude,
          longitude: location.Address.longitude,
        });
      }
    };
    if (locationId) {
      getLocationRequest();
    }
  }, [locationId, t]);

  return (
    <div className={styles.formWrapper}>
      <div className={styles.locationInfo}>
        <div>
          <InputLabel>{t('locations.locationForm.name')}</InputLabel>
          <TextField
            disabled
            variant="outlined"
            value={inputValues.name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.internalCode')}</InputLabel>
          <TextField
            disabled
            value={inputValues.internalCode}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.company')}</InputLabel>
          <TextField
            disabled
            value={inputValues.company}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.chain')}</InputLabel>
          <TextField
            disabled
            variant='outlined'
            fullWidth
            value={inputValues.chain}
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.chainSubCategory')}</InputLabel>
          <TextField
            disabled
            value={inputValues.chainSubCategory}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.deleted')}</InputLabel>
          <TextField
            disabled
            name="deleted"
            value={inputValues.deleted}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <Typography variant='body1' className={styles.addressTitle}>{t('locations.locationForm.addressTitle')}</Typography>
        </div>
        <div />
        <div>
          <InputLabel>{t('locations.locationForm.countryCode')}</InputLabel>
          <TextField
            disabled
            value={inputValues.countryCode}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.city')}</InputLabel>
          <TextField
            disabled
            value={inputValues.city}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.region')}</InputLabel>
          <TextField
            disabled
            value={inputValues.region}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.sector')}</InputLabel>
          <TextField
            disabled
            value={inputValues.sector}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.zone')}</InputLabel>
          <TextField
            disabled
            value={inputValues.zone}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.address')}</InputLabel>
          <TextField
            disabled
            value={inputValues.address}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.latitude')}</InputLabel>
          <TextField
            disabled
            value={inputValues.latitude}
            variant="outlined"
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('locations.locationForm.longitude')}</InputLabel>
          <TextField
            disabled
            value={inputValues.longitude}
            variant="outlined"
            fullWidth
          />
        </div>
        <div />
      </div>
    </div >
  );
};

export default LocationInfo;

import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR, OK } from '../../utils/const/http';

const getHomeMessages = async () => {
  try {
    const response = await axios.get('/api/home-message');
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const createHomeMessage = async ({
  name,
  spanishMessage,
  englishMessage,
  active,
},
) => {
  try {
    const response = await axios.post('/api/home-message', {
      name,
      spanishMessage,
      englishMessage,
      active,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      totalTickets: response.data,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const mes = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message: mes,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getHomeMessagesPerPage = async (page, limit) => {
  try {
    const { data } = await axios.get(`/api/home-message-per-page/?page=${page}&limit=${limit}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const updateHomeMessage = async ({
  id,
  name,
  spanishMessage,
  englishMessage,
  active,
}) => {
  try {
    const response = await axios.put(`/api/home-message/${id}`, {
      name,
      spanishMessage,
      englishMessage,
      active,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const mes = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message: mes,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  getHomeMessages,
  getHomeMessagesPerPage,
  createHomeMessage,
  updateHomeMessage,
};

const descendingComparator = (a, b, ordBy) => {
  if (b[ordBy] < a[ordBy]) {
    return -1;
  }
  if (b[ordBy] > a[ordBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (ord, ordBy) => (
  ord === 'desc'
    ? (a, b) => descendingComparator(a, b, ordBy)
    : (a, b) => -descendingComparator(a, b, ordBy)
);

const handleRequestSort = (property, order, setOrder, setOrderBy) => {
  const isAsc = order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

export {
  descendingComparator,
  getComparator,
  handleRequestSort,
};

import sleep from './sleep';

const setFilterOptions = async (
  newValue, tableColumn, setColumn, setSearchText, setFilter, searchText, key,
) => {
  if (searchText) {
    setSearchText({
      ...searchText,
      [key]: newValue,
    });
  } else {
    setSearchText(newValue);
  }
  await sleep(600);
  setColumn(tableColumn);
  setFilter(newValue);
  // Clear filters
  if (!tableColumn) {
    setFilter('');

    if (searchText) {
      setSearchText({
        ...searchText,
        [key]: '',
      });
    } else {
      setSearchText('');
    }
  }
};

export default setFilterOptions;

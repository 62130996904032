import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiTableHead from '@material-ui/core/TableHead';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
    maxWidth: '100%',
  },
  header: {
    display: 'flex',
    paddingBottom: '20px',
  },
  title: {
    flexGrow: 1,
    letterSpacing: '-0.5px',
  },
  questionTitle: {
    color: '#22617B',
    paddingLeft: '1%',
    fontWeight: 'bold',
    marginLeft: '10px',
  },
  leftHeader: {
    textAlign: 'left',
  },
  centerHeader: {
    textAlign: 'center',
  },
  progress: {
    height: '610px',
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  toolbarButton: {
    flexGrow: 1,
  },
  toolbarTable: {
    alignItems: 'right',
  },
  carrousel: {
    minWidth: '600px',
    minHeight: '350px',
    borderRadius: '12px',
    '& .Carousel-root-47': {
      paddingRight: '15px',
      paddingLeft: '15px',
    },
    '& .MuiButton-root': {
      height: '50px',
      width: '100%',
      minWidth: '200px',
      backgroundColor: theme.palette.primary.blue,
      color: theme.palette.primary.white,
      borderRadius: '30px',
      marginRigth: '200px',
    },
    '& .MuiCheckbox-root': {
      color: theme.palette.secondary.gray,
    },
  },
  questionListTable: {
    '& .MuiTableCell-root': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-paddingNone': {
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
      paddingLeft: '1%',
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: 'rgb(44, 157, 201, 0.05)',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(44, 157, 201, 0.05)',
    },
  },
  image: {
    height: '500px',
  },
  rejectSelected: {
    backgroundColor: theme.palette.secondary.yellow,
  },
  rejectAll: {
    backgroundColor: theme.palette.secondary.red,
  },
  aproveAndContinue: {
    backgroundColor: theme.palette.secondary.green,
  },
  finsihRevisionForm: {
    minWidth: '900px',
    minHeight: '170px',
    borderRadius: '12px',
    '& .MuiButton-root': {
      height: '50px',
      width: '70%',
      color: theme.palette.primary.white,
      borderRadius: '30px',
      marginLeft: '10%',
      marginRigth: '10%',
      marginTop: '10%',
    },
    '& .makeStyles-rejectSelected-53': {
      backgroundColor: theme.palette.secondary.yellow,
    },
    '& .makeStyles-rejectAll-54': {
      backgroundColor: theme.palette.secondary.red,
    },
    '& .makeStyles-aproveAndContinue-55': {
      backgroundColor: theme.palette.secondary.green,
    },
  },
  carousel: {
    maxWidth: '78vw',
  },
  card: {
    padding: '10px',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backButtonQuestionResponse: {
    width: '80px',
    paddingLeft: '0px',
    color: theme.palette.secondary.middleGray,
    '& span': {
      fontSize: '13px',
    },
  },
}));

export const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.lightBluegray,
  },
}))(MuiTableHead);

export default useStyles;

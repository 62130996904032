import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';

const WeatherWidget = ({ setErr }) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const [lat, setLat] = useState('8.9936');
  const [lon, setLon] = useState('-79.51973');
  const [locationName, setLocationName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const lang = i18n.languages[1];
    const api = {
      key: '28d096b009cfcda9d11db49533feb235',
      base: 'https://api.openweathermap.org/data/2.5/weather',
    };
    const getLocationData = async (position) => {
      try {
        const latitude = position.coords.latitude.toFixed(1).toString();
        const longitude = position.coords.longitude.toFixed(1).toString();
        setLat(latitude);
        setLon(longitude);
        // eslint-disable-next-line no-undef
        const res = await fetch(
          `${api.base}?lat=${latitude}&lon=${longitude}&units=metric&lang=${lang}&appid=${api.key}`,
        );
        const result = await res.json();
        setCountryCode(result.sys.country);
        setLocationName(result.name);
        setLoading(false);
      } catch {
        setErr('Ocurrió un errror en la busqueda, intente más tarde');
      }
    };
    const getData = async () => {
      const location = window.navigator && window.navigator.geolocation;
      await location.getCurrentPosition(getLocationData);
      setLoading(false);
    };
    getData();
  }, []);

  const { data, errorMessage } = useOpenWeather({
    key: '28d096b009cfcda9d11db49533feb235',
    lat: lat || -33.4489,
    lon: lon || -70.6693, // SCL default
    lang: 'en',
    unit: 'metric', // values are (metric, standard, imperial)
  });
  const conditionalRender = () => {
    if (!loading) {
      return (
        <ReactWeather
          isLoading={loading}
          errorMessage={errorMessage}
          data={data}
          lang={i18n.languages[1]}
          locationLabel={`${locationName}, ${countryCode}`}
          unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
          showForecast={false}
        />
      );
    }
    return (
      <div className={classes.weatherContainer}>
        <CircularProgress />
      </div>
    );
  };
  return <div>{conditionalRender()}</div>;
};

export default WeatherWidget;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  questionLogicContainer: {
    padding: '30px',
    maxHeight: '80vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
  },
  questionContainer: {
    minWidth: '650px',
    borderBottom: `1px solid ${theme.palette.primary.lightGray}`,
  },
  questionAndButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addLogicButton: {
    backgroundColor: theme.palette.primary.lightGray,
    borderRadius: '10px',
    padding: '0px 15px',
    height: '35px',
  },
  questionIdentifier: {
    lineHeight: '20px',
    width: '20px',
    height: '20px',
    borderRadius: 50,
    backgroundColor: theme.palette.secondary.blue,
    color: theme.palette.primary.white,
    fontSize: '12px',
    textAlign: 'center',
    marginRight: '10px',
  },
  question: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: '15px',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
    '& button': {
      backgroundColor: theme.palette.secondary.blue,
      color: theme.palette.primary.white,
    },
    '& button:first-child': {
      backgroundColor: theme.palette.secondary.darkRed,
      color: theme.palette.primary.white,
    },
  },
  detailLogic: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: '20px',
    '& p': {
      marginRight: '10px',
      fontSize: '14px',
    },
  },
  closeAddLogicButton: {
    height: '35px',
    borderRadius: '10px',
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.secondary.darkRed,
  },
  detailConditions: {
    display: 'grid',
    gridTemplateColumns: '25% 50%',
    columnGap: '2%',
    paddingBottom: '20px',
    '& p': {
      marginTop: '40px',
      fontSize: '14px',
    },
  },
  checkbox: {
    color: theme.palette.secondary.gray,
    height: '30px',
    '& .MuiIconButton-root': {
      color: theme.palette.secondary.gray,
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& svg': {
      width: '14px',
      height: '14px',
    },
    '& span': {
      fontSize: '14px',
    },
  },
}));

export default useStyles;

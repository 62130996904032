import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  TextField,
  IconButton,
  Button,
  Grid,
  Tooltip,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

// icons
import DeleteIcon from '@material-ui/icons/Close';
import Select from '../../Select';

import useStyles from '../styles';
import ExampleImageQuestion from '../../ExampleImageQuestion';
import EmailsBackup from '../../EmailsBackup';

import {
  VIEW_MODE,
} from '../../../utils/const/surveyModes';

function NpsQuestion({
  identifier, pages, info, setUpdate, handleDeleteQuestion, mode,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openEmails, setOpenEmails] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);
  const [file, setFile] = useState({});
  const [image, setImage] = useState({});

  const [feedbackScale, setfeedbackScale] = useState('');

  useEffect(() => {
    if (info !== undefined) {
      setImage({
        ...image,
        url: info.imageUrl,
        name: info.imageKey,
      });
      if (
        info.QuestionEmails !== undefined
        && info.QuestionEmails.length !== 0) {
        setEmailsArray(info.QuestionEmails[0].emails.split(','));
      }
      setSendEmail(info.sendEmail);
    } else {
      if (
        pages[identifier - 1].questionEmails !== undefined
        && pages[identifier - 1].questionEmails.length !== 0) {
        setEmailsArray(pages[identifier - 1].questionEmails[0].emails.split(','));
      }
      setSendEmail(pages[identifier - 1].sendEmail);
    }
  }, []);

  const handleOnChange = (event) => {
    const { id, value, imageUrl } = event.target;

    setUpdate((prevState) => !prevState);

    if (id === 'title' || id === 'description' || id === 'imageKey'
      || id === 'sendEmail' || id === 'questionEmails') {
      // eslint-disable-next-line no-param-reassign
      pages[identifier - 1] = {
        ...pages[identifier - 1],
        [id]: value,
        questionChoices: null,
        questionLogic: [{
          questionIdentifier: identifier,
          questionChoiceIdentifier: null,
          nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
        }],
        imageUrl,
      };
    }
  };

  const handleFeedbackScale = (event) => {
    const { value } = event.target;
    const aux = Number.parseInt(value, 10);
    setfeedbackScale(aux);

    setUpdate((prevState) => !prevState);
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <p className={styles.identifier}>{identifier}.</p>
        <div className={styles.questionContainer}>
          <TextField
            id="title"
            className={styles.question}
            placeholder={t('survey.surveyEdition.npsQuestionText.question')}
            value={info ? info.title : pages[identifier - 1].title}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 70 }}
            multiline
            onChange={handleOnChange}
            disabled={!!info}
          />
          <TextField
            id="description"
            className={styles.description}
            placeholder={t('survey.surveyEdition.npsQuestionText.description')}
            value={info ? info.description : pages[identifier - 1].description}
            onChange={handleOnChange}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 140 }}
            multiline
            disabled={!!info}
          />
          <Select
            name="feedbackOptions"
            className={styles.description}
            items={[{ name: '0 - 10', value: '' },
              { name: '0', value: '0' },
              { name: '1', value: '1' },
              { name: '2', value: '2' },
              { name: '3', value: '3' },
              { name: '4', value: '4' },
              { name: '5', value: '5' },
              { name: '6', value: '6' },
              { name: '7', value: '7' },
              { name: '8', value: '8' },
              { name: '9', value: '9' },
              { name: '10', value: '10' }]}
            value={info ? info.feedbackScale : feedbackScale}
            onChange={(event) => handleFeedbackScale(event)}
            fullWidth
            disabled={!!info}
          />
          <Grid container direction='column'>
            <Grid item xs>
              {mode !== VIEW_MODE ? (
                <>
                  <Button
                    onClick={() => setOpen(true)}
                  >
                    {t('survey.surveyForm.addMediaTitle')}
                  </Button>
                  <Button
                    onClick={() => setOpenEmails(true)}
                  >
                    {t('survey.surveyForm.addEmails')}
                  </Button>
                </>
              ) : null
              }
            </Grid>
          </Grid>
          {image.url
            ? <>
              <Grid container>
                <Grid item xs={1}>
                  <Tooltip
                    title={
                      <img
                        alt='reference-image'
                        src={image.url}
                        width='100%'
                        height='100%'
                      />
                    }
                    arrow
                    placement='top-end'
                  >
                    <img
                      alt='reference-image'
                      src={image.url}
                      width='50px'
                      height='50px'
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={5}>
                  <List className={styles.list}>
                    <ListItem>
                      <ListItemText
                        primary={image.name}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondary={image.size ? `  (${image.size})` : null}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </>
            : null
          }
        </div>
      </div>
      {mode !== VIEW_MODE ? (
        <IconButton
          id={identifier}
          onClick={handleDeleteQuestion}
          className={styles.deleteButton}
        >
          <DeleteIcon />
        </IconButton>)
        : null}
      <ExampleImageQuestion
        open={open}
        setOpen={setOpen}
        file={file}
        setFile={setFile}
        handleOnChange={handleOnChange}
        setImage={setImage}
      />
      <EmailsBackup
        sendEmail={sendEmail}
        setSendEmails={setSendEmail}
        open={openEmails}
        setOpen={setOpenEmails}
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        handleOnChange={handleOnChange}
        disabled={!!info}
      />
    </div>
  );
}

export default NpsQuestion;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography, Grid, CircularProgress } from '@material-ui/core';

import Snackbar from '../../components/Snackbar';
import { CLIENT, SUPER_ADMIN } from '../../utils/const/user';
import {
  uploadStockFile,
  uploadSalesFile,
} from '../../requests/api/product-location';

import useStyles from './styles';

function StockAndSalesImportView() {
  const classes = useStyles();
  const { t } = useTranslation();

  const company = useSelector((state) => state.company);
  const account = useSelector((state) => state.account);

  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingEntity, setLoadingEntity] = useState('');

  if (account.user.role !== SUPER_ADMIN && account.user.role !== CLIENT) {
    window.location.href = '/home';
  }

  const onFileUpload = async (e, entity) => {
    const file = e.target.files[0];

    setLoading(true);
    setLoadingEntity(entity);
    setMessage('');

    let uploadResponse;
    // Send file object
    if (entity === 'stock') {
      uploadResponse = await uploadStockFile(file, company.companyId);
    } else if (entity === 'sales') {
      uploadResponse = await uploadSalesFile(file, company.companyId);
    }
    if (uploadResponse.success) {
      setSeverity('success');
      setMessage(uploadResponse.data.replaceAll('"', ''));
    } else {
      setSeverity('error');
      setMessage(uploadResponse.message);
    }
    setLoading(false);
    setLoadingEntity('');
  };

  return (
    <div className={classes.content}>
      <Typography className={classes.title} variant="h3" color="textPrimary">
        {t('stockAndSales.upload.title')}
      </Typography>
      <Grid container direction="row">
        {!company || !company.companyId
          ? <span className={classes.messageError}>
            *Seleccionar una empresa para poder cargar un archivo con información de producto.
          </span>
          : null}
        <Grid item className={classes.headerContainer}>
          <label htmlFor="upload-sales-file">
            <input
              style={{ display: 'none' }}
              id="upload-sales-file"
              name="upload-sales-file"
              type="file"
              onChange={(e) => onFileUpload(e, 'sales')}
              disabled={!company || !company.companyId || loading}
              accept='.csv'
            />
            <div className={`${!company || !company.companyId ? classes.disabled : ''} ${classes.uploadFile}`}>
              {loadingEntity === 'sales'
                ? <CircularProgress size="20px" />
                : `${t('stockAndSales.upload.uploadSales')} .csv`
              }
            </div>
          </label>
        </Grid>
        <Grid item className={classes.headerContainer}>
          <label htmlFor="upload-stock-file">
            <input
              style={{ display: 'none' }}
              id="upload-stock-file"
              name="upload-stock-file"
              type="file"
              onChange={(e) => onFileUpload(e, 'stock')}
              disabled={!company || !company.companyId || loading}
              accept='.csv'
            />
            <div className={`${!company || !company.companyId ? classes.disabled : ''} ${classes.uploadFile}`}>
              {loadingEntity === 'stock'
                ? <CircularProgress size="20px" />
                : `${t('stockAndSales.upload.uploadStock')} .csv`
              }
            </div>
          </label>
        </Grid>
      </Grid>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div>
  );
}

export default StockAndSalesImportView;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Switch,
  Fade, FormControlLabel,
  IconButton, List,
  ListItem, ListItemText,
  Modal, Paper, TextField,
  Container,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '../Button';
import useStyles from './styles';

function EmailsBackup({
  open,
  setOpen,
  emailsArray,
  setEmailsArray,
  sendEmail,
  setSendEmails,
  handleOnChange,
  disabled,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const validateEmail = (e) => String(e)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetSendEmails = () => {
    setSendEmails(!sendEmail);
    handleOnChange({ target: { id: 'sendEmail', value: !sendEmail, sendEmail } });
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleOnChangeAddEmail = () => {
    if (email !== '' && email !== undefined
      && email !== null && emailsArray.indexOf(email) === -1) {
      if (!validateEmail(email)) {
        setError(true);
        return;
      }
      setError(false);
      const newArray = [...emailsArray, email];
      setEmailsArray(newArray);
      setEmail('');
      handleOnChange({ target: { id: 'questionEmails', value: newArray, questionEmails: newArray } });
    }
  };

  const handleOnChangeRemoveEmail = (mail) => {
    const newEmails = emailsArray.filter((e) => e !== mail);
    setEmailsArray(newEmails);
    handleOnChange({ target: { id: 'questionEmails', value: newEmails, questionEmails: newEmails } });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.root}
    >
      <Fade in={open}>
        <Paper className={classes.paperContainer}>
          <FormControlLabel
            className={classes.switch}
            control={<Switch checked={sendEmail} onChange={() => handleSetSendEmails()} color="primary" />}
            label={t('survey.surveyForm.addEmails')}
            disabled={disabled}
          />
          {
            sendEmail ? (
              <Container>
                <TextField
                  id="emails"
                  value={email}
                  label={t('survey.surveyForm.addEmails')}
                  variant="outlined"
                  error={error}
                  helperText={error ? t('survey.surveyForm.emailError') : null}
                  fullWidth
                  multiline
                  onChange={handleChangeEmail}
                />
                <div className={classes.addButton}>
                  <Button
                    id='addEmail'
                    variant="contained"
                    color="primary"
                    onClick={handleOnChangeAddEmail}
                    text={t('survey.surveyForm.addEmail')}
                    disabled={disabled}
                  >
                  </Button>
                </div>
                <List dense className={classes.flexListContainer} role="list">
                  {emailsArray.map((e) => (
                    <ListItem key={e}>
                      <Container className={classes.emailElement}>
                        <ListItemText primary={e} />
                        <IconButton
                          onClick={() => handleOnChangeRemoveEmail(e)}
                          className={classes.deleteButton}
                          disabled={disabled}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Container>
                    </ListItem>
                  ))}
                </List>
              </Container>
            ) : null
          }
        </Paper>
      </Fade>
    </Modal>
  );
}

EmailsBackup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default EmailsBackup;

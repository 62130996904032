import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  Card,
  CardHeader,
  CardMedia,
  Checkbox,
  CircularProgress,
  Fade,
  Grid,
  Modal,
} from '@material-ui/core';
import useStyles from './styles';

import { parseDate } from '../../../utils/date';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
};

const SurveyImageCarousel = ({
  questionResponseWithImageList,
  selectedQuestionResponseImageList,
  setSelectedQuestionResponseImageList,
}) => {
  const styles = useStyles();

  const [loading, setLoading] = useState(true);
  const [listImages, setListImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState('');
  const [update, setUpdate] = useState(false);

  /**
   * Keep a list of question responses images that need to be rejected.
   * List come from parents components.
   *
   * @param {int} questionResponseImageId Question Response Image identifier
   */
  const handleSelectedQuestionResponseImage = (questionResponseImageId) => {
    const indexOf = selectedQuestionResponseImageList.indexOf(questionResponseImageId);
    const tempSelectedQuestionResponseImageList = selectedQuestionResponseImageList;

    if (indexOf !== -1) {
      tempSelectedQuestionResponseImageList.splice(indexOf, 1);
    } else {
      tempSelectedQuestionResponseImageList.push(questionResponseImageId);
    }
    setSelectedQuestionResponseImageList(tempSelectedQuestionResponseImageList);

    setUpdate(!update);
  };

  const handleOpenImage = (imageUrl) => {
    setImage(imageUrl);
    setOpen(true);
  };

  useEffect(() => {
    const findSurveyResponseImages = async () => {
      const images = questionResponseWithImageList.map((questionResponseWithImageItem) => {
        questionResponseWithImageItem.QuestionResponseImages.map((questionResponseImageItem) => {
          /* eslint-disable no-param-reassign */
          questionResponseImageItem.title = questionResponseWithImageItem.Question.title;
          return questionResponseImageItem;
        }).flat();
        return questionResponseWithImageItem.QuestionResponseImages;
      }).flat();

      setListImages(images);
      setLoading(false);
    };
    findSurveyResponseImages();
  }, []);

  function Item(props) {
    return (
      <Card className={styles.card} key={props.id}>
        <CardHeader
          title={props.title}
          subheader={parseDate(props.createdAt)}
          action={
            <Checkbox
              onClick={() => {
                handleSelectedQuestionResponseImage(props.id);
                return selectedQuestionResponseImageList.includes(props.id);
              }}
              checked={selectedQuestionResponseImageList.includes(props.id)}
            />
          }
        />
        <CardMedia
          onClick={() => handleOpenImage(props.imageUrl)}
          className={styles.image}
          component={'img'}
          src={props.imageUrl}
        />
      </Card>
    );
  }

  const content = (row) => (
    <Grid item container spacing={3} md={10} key={row.id}>
      {Item(row)}
    </Grid>
  );

  return (
    <div>
      <Grid alignItems='center' spacing={1} container className={styles.container}>
        <Grid item xs={12}>
          <Carousel
            responsive={responsive}
            className={styles.carousel}
          >
          {loading ? (
            <div>
              <CircularProgress />
              <h4>Loading</h4>
            </div>
          ) : listImages.map((row) => (
            content(row)
          ))}
          </Carousel>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={styles.modal}
      >
        <Fade in={open}>
          <img src={image} alt="Imagen de respuesta" />
        </Fade>
      </Modal>
    </div>
  );
};

export default SurveyImageCarousel;

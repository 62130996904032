import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiTableHead from '@material-ui/core/TableHead';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  projectName: {
    paddingLeft: '16px',
  },
  header: {
    display: 'flex',
    paddingBottom: '20px',
  },
  title: {
    flexGrow: 1,
    letterSpacing: '-0.5px',
  },
  taskName: {
    paddingLeft: '1%',
    marginLeft: '10px',
  },
  tableHeader: {
    textAlign: 'center',
  },
  surveyImageHeader: {
    textAlign: 'left',
  },
  logoUrl: {
    height: '45px',
  },
  questionsTitle: {
    color: '#22617B',
    paddingLeft: '1%',
    fontWeight: 'bold',
    marginLeft: '10px',
  },
  progress: {
    height: '610px',
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  taskContent: {
    display: 'flex',
    marginTop: '15px',
  },
  categoryContainer: {
    marginTop: '30px',
    borderTop: `1px solid ${theme.palette.primary.lightGray}`,
  },
  categoriesTitleContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  categoryTitle: {
    textAlign: 'left',
    color: theme.palette.secondary.blue,
  },
  formDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '10px',
    backgroundColor: 'white',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.02)',
    marginBottom: '15px',
    padding: '15px',
    color: theme.palette.primary.gray,
    '& .MuiIconButton-root': {
      padding: '0px',
      color: theme.palette.primary.lightGray,
    },
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  categoryListTable: {
    '& .MuiTableCell-root': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-paddingNone': {
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: 'rgb(44, 157, 201, 0.05)',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(44, 157, 201, 0.05)',
    },
  },
  tag: {
    borderRadius: '5px',
    padding: '5px 8px',
    maxWidth: '160px',
    margin: '0px auto',
  },
  tagCompleted: {
    color: theme.palette.secondary.green,
    backgroundColor: theme.palette.secondary.lightGreen,
  },
  tagObjected: {
    color: theme.palette.secondary.orange,
    backgroundColor: theme.palette.secondary.lightOrange,
  },
  tagPending: {
    color: theme.palette.secondary.blue,
    backgroundColor: theme.palette.secondary.lightBlue,
  },
  tagNA: {
    color: theme.palette.secondary.bluegray,
    backgroundColor: theme.palette.secondary.lightBluegray,
  },
  categoryRowTitles: {
    alignItems: 'center',
  },
  progressBarRoot: {
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
  toolbarTable: {
    paddingLeft: '0px',
    justifyContent: 'space-between',
  },
}));

export const RedBorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#E74C3C',
  },
}))(LinearProgress);

export const YellowBorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#F1C40F',
  },
}))(LinearProgress);

export const GreenBorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#2ECC71',
  },
}))(LinearProgress);

export const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.lightBluegray,
  },
}))(MuiTableHead);

export default useStyles;

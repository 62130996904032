import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';

import { updateTokens } from '../../../requests/api/project';

const UpdateTokensForm = ({
  open,
  setOpen,
  projectId,
  tokenSuccessfullModal,
  tokenFailModal,
}) => {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [tokens, setTokens] = useState(0);

  const handleChangeTokens = (event) => {
    const tokenValue = parseInt(event.target.value, 10);
    if (tokenValue > 0) {
      setTokens(tokenValue);
    }
  };

  const handleSave = () => {
    setIsAddButtonDisabled(true);
    const status = updateTokens(projectId, tokens);

    if (status.success) {
      setOpen(false);
      tokenSuccessfullModal('Agregado nuevo balance.');
    } else {
      setIsAddButtonDisabled(false);
      tokenFailModal('No se pudo agregar tokens.');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Agregar tokens</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingrese la cantidad de tokens que desea agregar.
          </DialogContentText>
          <TextField
            autoFocus
            label="Tokens"
            type="number"
            onChange={handleChangeTokens}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' disableElevation color="primary"
            onClick={handleClose} >
            Cancelar
          </Button>
          <Button variant='contained' disableElevation color="primary"
            onClick={handleSave}
            disabled={isAddButtonDisabled}
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateTokensForm;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosOriginal from 'axios';
import {
  Grid,
  InputLabel,
  Typography,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useLocation } from 'react-router';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import bytesToSize from '../../../utils/bytesToSize';
import useStyles from '../styles';
import { createNews, updateNews } from '../../../requests/api/news';

const NewsForm = ({
  reload,
  currentSelection,
  setCurrentOpen,
  setError,
  setSucc,
  update,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [inputValues, setInputValues] = useState(currentSelection);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const handleClose = () => {
    setCurrentOpen(false);
  };

  const handleUpload = async () => {
    setLoading(true);
    const fileParts = file.name.split('.');
    const timestamp = Date.now();
    const fileName = location.pathname === '/administration' ? `${fileParts[0]}-${timestamp}`
      : `${fileParts[0]} ${timestamp}`;
    const uploadData = {
      fileName,
      fileType: file.type,
    };
    const response = await axiosOriginal.post('/api/news/signS3', uploadData);
    const options = {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': 'attachment',
      },
    };
    const { returnData } = response.data.data;
    const { signedRequest } = returnData;
    try {
      const uploadResponse = await axiosOriginal.put(
        signedRequest,
        file,
        options,
      );
      if (uploadResponse.status === 200) {
        const fileUrl = uploadResponse.config.url.split('?')[0];
        setError('');
        setLoading(false);
        return fileUrl;
      }
    } catch (err) {
      setError(t('administration.company.imageError'));
      return (err);
    }
    return (false);
  };

  const handleSelect = ({ target }) => {
    setFile(target.files[0]);
    setError('');
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputValues({
      ...inputValues,
      [id]: value,
    });
  };
  const handleActive = (e) => {
    setInputValues({
      ...inputValues,
      active: e.target.checked,
    });
  };

  const uploaderRender = () => {
    if (file) {
      return (
        <Grid
        container
        item
        justifyContent='flex-end'
        direction='row'
        xs={12}
        >
          <Grid
          container
          item
          justifyContent='flex-end'
          direction='row'
          alignItems='center'
          xs={8}
          >
            <Grid item xs={12}>
              <Typography variant="body1">
                {file.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {bytesToSize(file.size)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs>
          <div className={styles.actions}>
            {loading ? (<CircularProgress />)
              : <ClearIcon onClick={() => setFile(null)} className={styles.uploadIcon} fontSize='large'/>
            }
          </div>
          </Grid>
        </Grid>
      );
    } return (
            <Grid
            container
            item
            justifyContent='flex-start'
            direction='row-reverse'
            xs={12}
            >
              <input
                className={styles.input}
                id="contained-button-file"
                accept='.jpg, .png, .jpeg'
                multiple
                type="file"
                onChange={handleSelect}
              />
              <label className={styles.uploadIcon} htmlFor="contained-button-file">
                      <CloudUploadIcon fontSize='large'/>
              </label>
         </Grid>
    );
  };

  const handleSend = async () => {
    let response;
    const url = await (file && handleUpload(file));
    if (update) {
      if (url) {
        response = await updateNews({
          ...inputValues,
          urlImage: url,
        });
      } else {
        response = await updateNews(inputValues);
      }
    } else {
      if ((Object.values(inputValues).some((value) => value === '')) || !url) {
        setError(t('administration.validation'));
        return;
      }
      response = await createNews({
        ...inputValues,
        urlImage: url,
      });
    }
    if (response.success) {
      setSucc(t('administration.success'));
      handleClose();
      reload();
    } else {
      setError(t('administration.error'));
      handleClose();
      reload();
    }
  };

  return (
        <div>
            <div className={styles.formWrapper}>
              <Grid container spacing={2} alignItems='flex-end'>
                <Grid item xs={12}>
                  <InputLabel variant='h4'>{t('administration.news.title')}</InputLabel>
                  <TextField
                    variant="outlined"
                    id="title"
                    value={inputValues.title}
                    onChange={handleChange}
                    fullWidth
                  />
                  </Grid>
                  <Grid item xs={12}>
                  <InputLabel variant='h4'>{t('administration.news.description')}</InputLabel>
                  <TextField
                    variant="outlined"
                    id="description"
                    value={inputValues.description}
                    onChange={handleChange}
                    fullWidth
                  />
                  </Grid>
                  <Grid item xs={12}>
                  <InputLabel variant='h4'>Url</InputLabel>
                  <TextField
                    variant="outlined"
                    id="urlNews"
                    value={inputValues.urlNews}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={2}
                  />
                  </Grid>
                <Grid item xs={6}>
                  <InputLabel>{t('administration.news.active')}</InputLabel>
                  <Checkbox
                    id="active"
                    checked={inputValues.active}
                    color="primary"
                    onChange={handleActive}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Grid>
                <Grid item xs= {8}>
                  {uploaderRender()}
                </Grid>
                <Grid item xs={4}>
                <Button
                  id="submit-ticket-btn"
                  onClick={handleSend}
                  variant="contained"
                  text={update ? t('administration.buttons.update') : t('administration.buttons.create')}
                  height="35px"
                  width="100%"
                  backgroundColor="#2C9DC9"
                  color="#FFFFFF"
                  borderRadius="10px"
                />
                </Grid>
                </Grid>
              </div>
        </div>
  );
};

export default NewsForm;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Paper, Typography,
} from '@material-ui/core';
// const
import { SUPER_ADMIN } from '../../utils/const/user';

import Changes from '../../components/UploadView/Changes';

import {
  updateLocationChanges,
  uploadLocationFile,
} from '../../requests/api/location';

import useStyles from './styles';

function LocationsImportView() {
  const classes = useStyles();
  const { t } = useTranslation();

  const account = useSelector((state) => state.account);
  const [uploadDifference, setUploadDifference] = useState(null);

  if (account.user.role !== SUPER_ADMIN) {
    window.location.href = '/home';
  }

  const tableHeaders = [
    {
      id: 'company',
      label: t('locations.locationForm.company'),
    },
    {
      id: 'internalCode',
      label: t('locations.locationForm.internalCode'),
    },
    {
      id: 'name',
      label: t('locations.locationForm.name'),
    },
    {
      id: 'address',
      label: t('locations.locationForm.address'),
    },
    {
      id: 'countryCode',
      label: t('locations.locationForm.countryCode'),
    },
    {
      id: 'city',
      label: t('locations.locationForm.city'),
    },
    {
      id: 'region',
      label: t('locations.locationForm.region'),
    },
    {
      id: 'sector',
      label: t('locations.locationForm.sector'),
    },
    {
      id: 'zone',
      label: t('locations.locationForm.zone'),
    },
    {
      id: 'latitude',
      label: t('locations.locationForm.latitude'),
    },
    {
      id: 'longitude',
      label: t('locations.locationForm.longitude'),
    },
    {
      id: 'chain',
      label: t('locations.locationForm.chain'),
    },
    {
      id: 'chainSubCategory',
      label: t('locations.locationForm.chainSubCategory'),
    },
    {
      id: 'deleted',
      label: t('locations.locationForm.deleted'),
    },
  ];

  const properties = ['CompanyName', 'InternalCode', 'Name', 'Address', 'CountryCode', 'City', 'Region', 'Sector', 'Zone', 'Latitude', 'Longitude', 'Chain', 'ChainSubCategory', 'Deleted'];

  return (
    <div className={classes.content}>
      <Typography className={classes.title} variant="h3" color="textPrimary">
        {t('locations.uploadFile')}
      </Typography>
      <Paper className={classes.changesTable}>
        <Changes
          properties={properties}
          tableHeaders={tableHeaders}
          uploadDifference={uploadDifference || []}
          setUploadDifference={setUploadDifference}
          updateFunction={updateLocationChanges}
          uploadFunction={uploadLocationFile}
        />
      </Paper>
    </div>
  );
}

export default LocationsImportView;

import React, { useState, useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Typography,
  Grid,
  CardMedia,
  CardActionArea,
  CircularProgress,
} from '@material-ui/core';
import { getNews } from '../../requests/api/news';
import useStyles from './styles';

import { parseDate } from '../../utils/date';

const Carrousel = () => {
  const { t } = useTranslation();
  const styles = useStyles();

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const findNews = async () => {
      const response = await getNews();
      if (response.success) {
        const allNews = response.data.data;
        const newsList = [];
        const allNewsLength = allNews.length;
        // Get quads of news
        if (allNewsLength % 4 === 0) {
          for (let i = 0; i < allNewsLength; i += 4) {
            newsList.push([allNews[i], allNews[i + 1], allNews[i + 2], allNews[i + 3]]);
          }
        } else {
          const countLastNews = allNewsLength % 4;
          for (let i = 0; i < allNewsLength - countLastNews; i += 4) {
            newsList.push([allNews[i], allNews[i + 1], allNews[i + 2], allNews[i + 3]]);
          }
          const countNewsAdded = allNewsLength - countLastNews;
          if (allNewsLength % 4 === 1) {
            if (allNewsLength === 1) {
              newsList.push([allNews[countNewsAdded], allNews[0], allNews[0], allNews[0]]);
            } else {
              newsList.push([allNews[countNewsAdded], allNews[0], allNews[1], allNews[2]]);
            }
          } else if (allNewsLength % 4 === 2) {
            if (allNewsLength === 2) {
              newsList.push([allNews[0], allNews[1], allNews[0], allNews[1]]);
            } else {
              newsList.push([allNews[countNewsAdded],
                allNews[countNewsAdded + 1], allNews[0], allNews[1]]);
            }
          } else {
            newsList.push([allNews[countNewsAdded],
              allNews[countNewsAdded + 1], allNews[countNewsAdded + 2], allNews[0]]);
          }
        }
        setNews(newsList);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    findNews();
  }, []);

  const content = (row) => {
    const firstElement = row[0];
    const secondElement = row[1];
    const thirdElement = row[2];
    const fourthElement = row[3];
    return (
        <Grid container spacing={3} key = {fourthElement.id}>
          <Grid item xs={3}>
          <Card style ={{ borderRadius: '12px' }}>
          <div style= {{ display: 'flex', justifyContent: 'center' }}>
          <CardMedia
            className={styles.image}
            component={firstElement.type}
            src={firstElement.urlImage}
          />
          </div>
          </Card>
          <Typography variant='h2' className={styles.blogTitle} onClick={() => window.open(firstElement.urlNews)} >
          {firstElement.title}
          </Typography>
          <Typography variant='h5'>
          {parseDate(firstElement.createdAt)}
          </Typography>
          </Grid>
          <Grid item xs={3}>
            <Card>
            <CardActionArea
                onClick={() => window.open(secondElement.urlNews)}
                >
            <div style= {{ display: 'flex', justifyContent: 'center' }}>
              <CardMedia
                className={styles.image}
                component={secondElement.type}
                src={secondElement.urlImage}
              />
              </div>
            </CardActionArea>
            </Card>
            <Typography variant='h2' className={styles.blogTitle} onClick={() => window.open(secondElement.urlNews)} >
            {secondElement.title}
          </Typography>
          <Typography variant='h5'>
          {parseDate(secondElement.createdAt)}
          </Typography>
          </Grid>
          <Grid item xs={3}>
            <Card>
            <CardActionArea
                onClick={() => window.open(thirdElement.urlNews)}
                >
            <div style= {{ display: 'flex', justifyContent: 'center' }}>
              <CardMedia
                className={styles.image}
                component={thirdElement.type}
                src={thirdElement.urlImage}
              />
              </div>
            </CardActionArea>
            </Card>
            <Typography variant='h2' className={styles.blogTitle} onClick={() => window.open(thirdElement.urlNews)} >
            {thirdElement.title}
          </Typography>
          <Typography variant='h5'>
          {parseDate(thirdElement.createdAt)}
          </Typography>
          </Grid>
          <Grid item xs={3}>
            <Card>
            <CardActionArea
                onClick={() => window.open(fourthElement.urlNews)}
                >
            <div style= {{ display: 'flex', justifyContent: 'center' }}>
              <CardMedia
                className={styles.image}
                component={fourthElement.type}
                src={fourthElement.urlImage}
              />
              </div>
            </CardActionArea>
            </Card>
            <Typography variant='h2' className={styles.blogTitle} onClick={() => window.open(fourthElement.urlNews)} >
            {fourthElement.title}
          </Typography>
          <Typography variant='h5'>
          {parseDate(fourthElement.createdAt)}
          </Typography>
          </Grid>
        </Grid>
    );
  };

  return (
  <div>

    <Carousel
      autoPlay={true}
      animation={'fade'}
      indicators={true}
      timeout={1500}
      interval={10000}
      cycleNavigation={true}
      navButtonsAlwaysVisible={false}
      navButtonsAlwaysInvisible={false}
    >
    {loading ? (
      <div>
        <CircularProgress />
        <h4>{t('carrousel.loading')}</h4>
      </div>
    ) : news.map((row) => (
      content(row)
    ))}
    </Carousel>
  </div>
  );
};

export default Carrousel;

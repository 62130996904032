import React from 'react';
import { useTranslation } from 'react-i18next';
// import clsx from 'clsx';

import {
  Switch,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Grid,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import useStyles from './styles';

const BinaryTable = ({
  teleauditKpis, switchValues, setSwitchValues, referenceImage,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const onSwitchChange = (event) => {
    const switchId = parseInt(event.target.name, 10);
    const value = !switchValues[switchId];
    setSwitchValues({ ...switchValues, [switchId]: value });
  };

  return (
    <>
      <List
        subheader={
          <Grid container>
            <Grid item xs={6}>
            <Typography variant='h4' className={styles.listSubheader}>
              {t('teleaudit.binaryTable.metrics')}
            </Typography>
            </Grid>
            <Grid item xs={6} align='right'>
              <Tooltip
                className={styles.referenceButton}
                title={
                  <img
                    src={referenceImage}
                    width='100%'
                    heigth='100%'
                    alt='reference-image'
                  />
                }
                placement='right-start'
                id='reference-image'
                arrow
              >
              <IconButton aria-label='add'>
                <SearchIcon/>
              </IconButton>
              </Tooltip>
            </Grid>
          </Grid>}>
        <Divider style={{ height: '2px' }}/>
          {teleauditKpis
            ? Object.keys(teleauditKpis.binary).map((item) => (
              <ListItem key={teleauditKpis.binary[item].KpiDetail.name}>
                <ListItemText
                  id={teleauditKpis.binary[item.name]}
                  align='left'
                  primary={teleauditKpis.binary[item].KpiDetail.name}
                />
                <ListItemSecondaryAction>
                  <Switch
                    defaultChecked={switchValues[item]}
                    onChange={(e) => {
                      onSwitchChange(e);
                    }}
                    name={teleauditKpis.binary[item].id.toString(10)}
                    color="primary"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))
            : null
          }
      </List>
    </>
  );
};

export default BinaryTable;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  Grid,
  InputLabel,
  IconButton,
  Typography,
} from '@material-ui/core';

import Select from 'react-select';
import SettingsIcon from '@material-ui/icons/Settings';
import TextField from '../../components/TextField';
import Button from '../../components/Button';
import { createUserReport, updateUserReport } from '../../requests/api/report';

import useStyles from './styles';

const AssociateReportForm = ({
  reload,
  currentUserReportFormData,
  setCurrentOpen,
  options,
  setError,
  setSucc,
  update,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const userId = useParams().id;
  const [inputValues, setInputValues] = useState({
    userId,
    powerbiReportId: currentUserReportFormData.reportId,
    reportName: currentUserReportFormData.reportName,
    configJson: currentUserReportFormData.configJson,
    configDescription: currentUserReportFormData.configDescription,
  });

  const selectOptions = [];
  const [advanced, setAdvanced] = useState(false);

  function logSelectOptions(element) {
    const aux = {};
    aux.label = element.reportName;
    aux.value = element.id;
    selectOptions.push(aux);
  }

  if (options) options.forEach(logSelectOptions);

  const handleClose = () => {
    setCurrentOpen(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputValues({
      ...inputValues,
      [id]: value,
    });
  };

  const selectReport = (event) => {
    setInputValues({
      ...inputValues,
      reportId: event.value,
    });
  };

  const handleSend = async () => {
    let response;
    if (update) {
      response = await updateUserReport(inputValues);
    } else {
      response = await createUserReport(inputValues);
    }
    if (response.success) {
      setSucc(t('users.userInfo.assocSuccess'));
      handleClose();
      reload();
    } else {
      setError(t('users.userInfo.error'));
      handleClose();
      reload();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const showAdvanced = () => {
    setAdvanced(!advanced);
  };

  return (
    <div>
        <div className={styles.formWrapper}>
          <Grid container spacing={2} alignItems='flex-end'>
            <Grid item xs={12}>
              <InputLabel>{t('users.userInfo.userReportForm.report')}</InputLabel>
              {!update ? <Select options={selectOptions} name="report" onChange={(event) => (
                selectReport(event)
              )}/> : <TextField
                id="report"
                type="text"
                variant="outlined"
                value={inputValues.reportName}
                fullWidth
                disabled
              />
              }
            </Grid>
            <Grid item xs={12}>
            <IconButton onClick={showAdvanced}>
              <SettingsIcon />
              <Typography>
                {t('users.userInfo.userReportForm.config')}
              </Typography>
            </IconButton>

            </Grid>
            {advanced ? <div className={styles.formWrapper}>
            <Grid item xs={12}>
                  <InputLabel>ConfigJson</InputLabel>
                  <TextField
                    variant="outlined"
                    id="configJson"
                    value={inputValues.configJson}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={3}
                    InputProps={{
                      onKeyDown: handleKeyDown,
                    }}
                  />
            </Grid>
            <Grid item xs={12}>
                  <InputLabel>ConfigDescription</InputLabel>
                  <TextField
                    variant="outlined"
                    id="configDescription"
                    value={inputValues.configDescription}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={3}
                    InputProps={{
                      onKeyDown: handleKeyDown,
                    }}
                  />
            </Grid>
            </div> : null}
            <Grid item xs= {8}/>
            <Grid item xs={4}>
            <Button
              id="submit-ticket-btn"
              onClick={handleSend}
              text={t('users.userInfo.buttons.save')}
              height="35px"
              width="100%"
              backgroundColor="#2C9DC9"
              color="#FFFFFF"
              borderRadius="10px"
            />
            </Grid>
            </Grid>
          </div>
    </div>
  );
};

export default AssociateReportForm;

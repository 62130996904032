import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MiniDrawer from './Drawer';
import useStyles from './styles';
import { getUser } from '../../requests/api/user';
import Snackbar from '../Snackbar';
import { getCompanies, getUserCompanies } from '../../requests/api/company';
import { SUPER_ADMIN } from '../../utils/const/user';
import companyActions from '../../actions/company';
import ticketActions from '../../actions/tickets';
import AuthService from '../../services/authService';

function Layout({ children }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [userData, setUserData] = useState('');
  const [companies, setCompanies] = useState([]);
  const [err, setErr] = useState('');
  const account = useSelector((state) => state.account);
  const company = useSelector((state) => state.company);
  const ticketsQuantity = useSelector((state) => state.tickets.pendingTickets);
  const dispatch = useDispatch();

  const setSessionWithCompany = async (companyId) => {
    const token = localStorage.getItem('accessToken');
    await AuthService.setSession(token, companyId);
  };

  useEffect(() => {
    const getCompaniesRequest = async () => {
      try {
        const response = await getUser();
        const { user } = response.data.data;
        setUserData(user);
        const companiesResponse = user.role === SUPER_ADMIN
          ? await getCompanies() : await getUserCompanies(user.id);
        if (companiesResponse.success) {
          const allCompanies = companiesResponse.data.data.companies;
          const companiesOptions = allCompanies.map((c) => ({ name: c.name, value: c.id }));
          setCompanies(companiesOptions);
          if (company.companyId === 0 && companiesOptions.length > 0) {
            const companyId = companiesOptions[0].value;
            await setSessionWithCompany(companyId);
            dispatch(companyActions.setCompany(companyId));
          } else {
            await setSessionWithCompany(company.companyId);
            dispatch(companyActions.refresh());
          }
        }
      } catch {
        setErr(t('layout.userError'));
      }
    };
    getCompaniesRequest();
  }, [company.companyId, dispatch, t]);

  useEffect(() => {
    const fetchPendingTickets = () => {
      dispatch(ticketActions.actionFetchPendingTickets());
    };
    fetchPendingTickets();
  }, [company]);

  useEffect(() => {
    if (userData) {
      window.intercomSettings = {
        user_id: userData.id,
        app_id: 'pv0x64b5',
        name: userData.fullName,
        email: userData.email,
        user_role: userData.role,
      };
    } else {
      window.intercomSettings = {
        app_id: 'pv0x64b5',
        user_id: account.user.email,
        name: account.user.email,
        email: account.user.email,
        user_role: account.user.role,
      };
    }
  }, [userData, account]);

  const handleCompanyChange = async (event) => {
    const companyId = event.target.value;
    setSessionWithCompany(companyId);
    dispatch(companyActions.setCompany(companyId));
    window.location.href = '/home';
  };

  return (
    <div className={classes.root}>
      <MiniDrawer
        companies={companies}
        currentCompany={company.companyId}
        handleCompanyChange={handleCompanyChange}
        ticketsQuantity={ticketsQuantity}
      />
      {children}
      <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
      />
    </div>

  );
}

export default Layout;

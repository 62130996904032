import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosOriginal from 'axios';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  Grid,
  Typography,
  CircularProgress,
  InputLabel,
} from '@material-ui/core';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import Snackbar from '../../../components/Snackbar';
import { postNewTicket } from '../../../requests/api/ticket';
import useStyles from './styles';
import bytesToSize from '../../../utils/bytesToSize';

const TicketForm = ({ sendResponse, setCurrentOpen }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const projects = useSelector((state) => state.projects.projects);
  const [optionalContent, setOptionalContent] = useState('');
  const [inputValues, setInputValues] = useState({
    subject: '',
    subjectContent: '',
    content: '',
    category: '',
    subcategory: '',
    projectId: '',
  });

  const location = useLocation();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleRemoveAll = () => {
    setFile(null);
  };

  const handleClose = () => {
    setCurrentOpen(false);
  };

  const handleUpload = async () => {
    setLoading(true);
    const fileParts = file.name.split('.');
    const timestamp = Date.now();
    const fileName = location.pathname === '/support'
      ? fileParts[0]
      : `${fileParts[0]} ${timestamp}`;
    const uploadData = {
      fileName,
      fileType: file.type,
    };
    const response = await axiosOriginal.post('/api/ticket/signS3', uploadData);
    const options = {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': 'attachment',
      },
    };
    const { returnData } = response.data.data;
    const { signedRequest } = returnData;
    try {
      const uploadResponse = await axiosOriginal.put(
        signedRequest,
        file,
        options,
      );
      if (uploadResponse.status === 200) {
        const fileUrl = uploadResponse.config.url.split('?')[0];
        setError('');
        setLoading(false);
        return fileUrl;
      }
    } catch (err) {
      setError(t('support.ticketForm.errorUpload'));
      return err;
    }
    return false;
  };

  const handleSelect = ({ target }) => {
    setFile(target.files[0]);
    setError('');
  };

  const uploaderRender = () => {
    if (file) {
      return (
        <Grid container item justifyContent='flex-end" direction="row'>
          <Grid
            container
            item
            justifyContent='flex-end'
            direction='row'
            alignItems='center'
            xs={8}
          >
            <Grid item xs={12}>
              <Typography variant='body1'>{file.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2'>{bytesToSize(file.size)}</Typography>
            </Grid>
          </Grid>
          <Grid item xs>
            <div className={styles.actions}>
              {loading ? (
                <CircularProgress />
              ) : (
                <ClearIcon
                  onClick={handleRemoveAll}
                  className={styles.uploadIcon}
                  fontSize='large'
                />
              )}
            </div>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid
        container
        item
        justifyContent='flex-start'
        alignItems='center'
        direction='row-reverse'
        xs={12}
        style={{ padding: '10px 0' }}
      >
        <button
          className={styles.buttonContainer}
          style={{
            padding: '0',
            borderRadius: '10px',
            backgroundColor: '#2C9DC9',
            color: '#FFFFFF',
            height: '35px',
            border: '0',
          }}
        >
          <input
            className={styles.input}
            id='contained-button-file'
            multiple
            type='file'
            onChange={handleSelect}
          />
          <label
            style={{ cursor: 'pointer', padding: '0 150px 150px 0' }}
            htmlFor='contained-button-file'
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
>
              <CloudUploadIcon />
              <span>{t('support.ticketForm.uploadFile')}</span>
            </div>
          </label>
        </button>
      </Grid>
    );
  };

  const handleChange = (event) => {
    setOptionalContent(event.target.value);
  };

  const selectSubject = (e) => {
    const { value } = e.target;
    setInputValues({
      ...inputValues,
      subject: value,
    });
  };

  const selectProject = (e) => {
    const { value } = e.target;
    setInputValues({
      ...inputValues,
      projectId: value,
    });
  };

  const handleSend = async () => {
    let postTicketResponse;
    if (Object.values(inputValues).some((value) => value === '')) {
      setError(t('support.ticketForm.required'));
      return;
    }
    if (file) {
      const url = await handleUpload();
      if (url) {
        postTicketResponse = await postNewTicket({
          subject: inputValues.subject,
          content: `${inputValues.subjectContent} - ${inputValues.content} - ${optionalContent}`,
          category: inputValues.category,
          subcategory: inputValues.subcategory,
          projectId: inputValues.projectId,
          fileUrl: url,
        });
      }
    } else {
      postTicketResponse = await postNewTicket({
        subject: inputValues.subject,
        content: `${inputValues.subjectContent} - ${inputValues.content} - ${optionalContent}`,
        category: inputValues.category,
        subcategory: inputValues.subcategory,
        projectId: inputValues.projectId,
      });
    }
    if (postTicketResponse) {
      handleClose();
      setInputValues({
        subject: '',
        subjectContent: '',
        content: '',
        category: '',
        subcategory: '',
        projectId: '',
      });
      sendResponse(postTicketResponse);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const ticketTitle = () => {
    const buttons = [
      {
        subjectContent: `${t('support.ticketForm.optionTitleOne')}`,
        category: 'seek',
      },
      {
        subjectContent: `${t('support.ticketForm.optionTitleTwo')}`,
        category: 'seek',
      },
      {
        subjectContent: `${t('support.ticketForm.optionTitleThree')}`,
        category: 'display',
      },
      {
        subjectContent: `${t('support.ticketForm.optionTitleFour')}`,
        category: 'qa',
      },
    ];

    return (
      <Grid container spacing={2} alignItems='flex-end'>
        <Grid item xs={12} style={{ padding: '16px' }}>
          <h3 class='makeStyles-title-59 MuiTypography-h2'>
            {t('support.ticketForm.titleTabOne')}
          </h3>
        </Grid>

        {buttons.map((buttonItem, index) => (
          <Grid item xs={3} key={index}>
            <Button
              onClick={() => {
                setInputValues({
                  ...inputValues,
                  subjectContent: buttonItem.subjectContent,
                  category: buttonItem.category,
                });
              }}
              variant='contained'
              text={buttonItem.subjectContent}
              height='90px'
              width='100%'
              backgroundColor='#2C9DC9'
              color='#FFFFFF'
              borderRadius='10px'
              weight="bold"
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const ticketManagement = () => {
    const buttons = [
      {
        content: `${t('support.ticketForm.optionManagementOne')}`,
        subcategory: `${t('support.ticketForm.subcategoryOne')}`,
      },
      {
        content: `${t('support.ticketForm.optionManagementTwo')}`,
        subcategory: `${t('support.ticketForm.subcategoryOne')}`,
      },
      {
        content: `${t('support.ticketForm.optionManagementThree')}`,
        subcategory: `${t('support.ticketForm.subcategoryOne')}`,
      },
      {
        content: `${t('support.ticketForm.optionManagementFour')}`,
        subcategory: `${t('support.ticketForm.subcategoryTwo')}`,
      },
    ];

    return (
      <Grid container spacing={2} alignItems='flex-end'>
        {buttons.map((buttonItem, index) => (
          <Grid item xs={3} key={index}>
            <Button
              onClick={() => {
                setInputValues({
                  ...inputValues,
                  content: buttonItem.content,
                  subcategory: buttonItem.subcategory,
                });
              }}
              variant='contained'
              text={buttonItem.content}
              height='90px'
              width='100%'
              backgroundColor='#2C9DC9'
              color='#FFFFFF'
              borderRadius='10px'
              weight="bold"
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const ticketSeek = () => {
    const buttons = [
      {
        content: `${t('support.ticketForm.optionSeekOne')}`,
        subcategory: `${t('support.ticketForm.subcategoryOne')}`,
      },
      {
        content: `${t('support.ticketForm.optionSeekTwo')}`,
        subcategory: `${t('support.ticketForm.subcategoryTwo')}`,
      },
      {
        content: `${t('support.ticketForm.optionSeekThree')}`,
        subcategory: `${t('support.ticketForm.subcategoryThree')}`,
      },
    ];

    return (
      <Grid container spacing={2} alignItems='flex-end'>
        {buttons.map((buttonItem, index) => (
          <Grid item xs={4} key={index}>
            <Button
              onClick={() => {
                setInputValues({
                  ...inputValues,
                  content: buttonItem.content,
                  subcategory: buttonItem.subcategory,
                });
              }}
              variant='contained'
              text={buttonItem.content}
              height='90px'
              width='100%'
              backgroundColor='#2C9DC9'
              color='#FFFFFF'
              borderRadius='10px'
              weight="bold"
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const ticketDisplay = () => {
    const buttons = [
      {
        content: `${t('support.ticketForm.optionDisplayOne')}`,
        subcategory: `${t('support.ticketForm.subcategoryFour')}`,
      },
      {
        content: `${t('support.ticketForm.optionDisplayTwo')}`,
        subcategory: `${t('support.ticketForm.subcategoryFive')}`,
      },
      {
        content: `${t('support.ticketForm.optionDisplayThree')}`,
        subcategory: `${t('support.ticketForm.subcategorySix')}`,
      },
    ];

    return (
      <Grid container spacing={2} alignItems='flex-end'>
        {buttons.map((buttonItem, index) => (
          <Grid item xs={4} key={index}>
            <Button
              onClick={() => {
                setInputValues({
                  ...inputValues,
                  content: buttonItem.content,
                  subcategory: buttonItem.subcategory,
                });
              }}
              variant='contained'
              text={buttonItem.content}
              height='90px'
              width='100%'
              backgroundColor='#2C9DC9'
              color='#FFFFFF'
              borderRadius='10px'
              weight="bold"
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const ticketTeleaudit = () => {
    const buttons = [
      {
        content: `${t('support.ticketForm.optionQaOne')}`,
        subcategory: `${t('support.ticketForm.subcategorySeven')}`,
      },
      {
        content: `${t('support.ticketForm.optionQaTwo')}`,
        subcategory: `${t('support.ticketForm.subcategoryEight')}`,
      },
      {
        content: `${t('support.ticketForm.optionQaThree')}`,
        subcategory: `${t('support.ticketForm.subcategoryNine')}`,
      },
    ];

    return (
      <Grid container spacing={2} alignItems='flex-end'>
        {buttons.map((buttonItem, index) => (
          <Grid item xs={4} key={index}>
            <Button
              onClick={() => {
                setInputValues({
                  ...inputValues,
                  content: buttonItem.content,
                  subcategory: buttonItem.subcategory,
                });
              }}
              variant='contained'
              text={buttonItem.content}
              height='90px'
              width='100%'
              backgroundColor='#2C9DC9'
              color='#FFFFFF'
              borderRadius='10px'
              weight="bold"
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const ticketDescription = () => {
    const mainTitleOne = (
      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='space-between'
        style={{ padding: '16px 8px' }}
      >
        <Grid item xs={6}>
          <h3 class='makeStyles-title-59 MuiTypography-h2'>
            {t('support.ticketForm.titleTabOne')}
          </h3>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => {
              setInputValues({
                ...inputValues,
                subjectContent: '',
              });
            }}
            variant='contained'
            text={t('support.ticketForm.backButton')}
            height='35px'
            width='100%'
            backgroundColor='#2C9DC9'
            color='#FFFFFF'
            borderRadius='10px'
          />
        </Grid>
      </Grid>
    );

    const mainTitleTwo = (
      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='space-between'
        style={{ padding: '16px 8px' }}
      >
        <Grid item xs={6}>
          <h3 class='makeStyles-title-59 MuiTypography-h2'>
            {t('support.ticketForm.titleTabTwo')}
          </h3>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => {
              setInputValues({
                ...inputValues,
                subjectContent: '',
              });
            }}
            variant='contained'
            text={t('support.ticketForm.backButton')}
            height='35px'
            width='100%'
            backgroundColor='#2C9DC9'
            color='#FFFFFF'
            borderRadius='10px'
          />
        </Grid>
      </Grid>
    );

    switch (inputValues.category) {
      case 'seek':
        if (
          inputValues.subjectContent === `${t('support.ticketForm.optionTitleOne')}`
        ) {
          return (
            <div>
              {mainTitleTwo}
              {ticketManagement()}
            </div>
          );
        }
        return (
          <div>
            {mainTitleOne}
            {ticketSeek()}
          </div>
        );
      case 'display':
        return (
          <div>
            {mainTitleOne}
            {ticketDisplay()}
          </div>
        );
      case 'qa':
        return (
          <div>
            {mainTitleOne}
            {ticketTeleaudit()}
          </div>
        );
      default:
      // return;
    }
    return (
      <div></div>
    );
  };

  return (
    <div>
      <div className={styles.formWrapper}>
        {!inputValues.subjectContent ? ticketTitle() : null}
        {inputValues.subjectContent && !inputValues.content
          ? ticketDescription()
          : null}

        {inputValues.subjectContent && inputValues.content ? (
          <Grid
            container
            spacing={2}
            alignItems='flex-end'
            style={{ padding: '16px 0 0 0' }}
          >
            <Grid
              xs={12}
              item
              container
              spacing={2}
              alignItems='center'
              justifyContent='space-between'
              style={{ padding: '0 0 0 8px' }}
            >
              <Grid item xs={4}>
                <h3 class='makeStyles-title-59 MuiTypography-h2'>
                  {t('support.ticketForm.titleTabThree')}
                </h3>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => {
                    setInputValues({
                      ...inputValues,
                      content: '',
                    });
                  }}
                  variant='contained'
                  text={t('support.ticketForm.backButton')}
                  height='35px'
                  width='100%'
                  backgroundColor='#2C9DC9'
                  color='#FFFFFF'
                  borderRadius='10px'
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputLabel variant='h4'>
                {t('support.ticketForm.title')}
              </InputLabel>
              <TextField
                id='subject'
                type='text'
                variant='outlined'
                value={inputValues.subject}
                onChange={selectSubject}
                fullWidth
                InputProps={{
                  onKeyDown: handleKeyDown,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('support.ticketForm.optionalDescription')}</InputLabel>
              <TextField
                variant='outlined'
                id='content'
                value={optionalContent}
                onChange={handleChange}
                fullWidth
                multiline
                minRows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('support.ticketForm.project')}</InputLabel>
              <TextField
                id='Project Id'
                type='text'
                variant='outlined'
                value={inputValues.projectId}
                options={projects}
                onChange={selectProject}
                select={true}
                keyId='projectId'
                fullWidth
                InputProps={{
                  onKeyDown: handleKeyDown,
                }}
              />
            </Grid>
            <Grid item xs={8}>
              {uploaderRender()}
            </Grid>
            <Grid item xs={4}>
              <Button
                id='submit-ticket-btn'
                onClick={handleSend}
                variant='contained'
                text={t('support.ticketForm.button')}
                height='35px'
                width='100%'
                backgroundColor='#2C9DC9'
                color='#FFFFFF'
                borderRadius='10px'
                weight="bold"
              />
            </Grid>
          </Grid>
        ) : null}
      </div>
      <Snackbar
        open={error !== ''}
        message={error}
        severity='error'
        onClose={() => setError('')}
      />
    </div>
  );
};

export default TicketForm;

const S3_EMOJIS_BUCKET = 'https://tcg-public.s3.sa-east-1.amazonaws.com/dev/emojis';
const emojiSchema = {
  2: ['/emoji2.png', '/emoji5.png'],
  3: ['/emoji1.png', '/emoji3.png', '/emoji5.png'],
  4: ['/emoji1.png', '/emoji2.png', '/emoji4.png', '/emoji5.png'],
  5: ['/emoji1.png', '/emoji2.png', '/emoji3.png', '/emoji4.png', '/emoji5.png'],
  6: ['/emoji1.png', '/emoji2.png', '/emoji3.png', '/emoji4.png', '/emoji5.png', '/emoji6.png'],
};

export {
  S3_EMOJIS_BUCKET, emojiSchema,
};

import React, {
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import SplashScreen from '../Loading/SplashScreen';
import accountActions from '../../actions/account';
import AuthService from '../../services/authService';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      AuthService.setAxiosInterceptors({
        onLogout: () => dispatch(accountActions.logout()),
      });

      AuthService.handleAuthentication();

      if (AuthService.isAuthenticated()) {
        const user = await AuthService.loginInWithToken();

        mixpanel.init('6c3fa426d8bb5434f2c1e532678b7540', {
          debug: true,
          track_pageview: true,
          persistence: 'localStorage',
        });
        mixpanel.identify(user.id);
        mixpanel.people.set({
          $email: user.email,
          $dni: user.dni,
          $role: user.role,
        });

        await dispatch(accountActions.setUserData(user));
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

export default Auth;

import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core';

import Routes from './Routes';
import './App.css';
import Auth from './components/Auth/Auth';
import theme from './views/theme';

const history = createBrowserHistory();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Auth>
          <Routes />
        </Auth>
      </Router>
    </ThemeProvider>
  );
}

export default App;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Paper, Typography,
} from '@material-ui/core';
// const
import { CLIENT, SUPER_ADMIN } from '../../utils/const/user';

import Changes from '../../components/UploadView/Changes';

import {
  uploadProductLocationsFile,
  updateProductLocationsChanges,
} from '../../requests/api/product-location';

import useStyles from './styles';

function ProductLocationsImportView() {
  const classes = useStyles();
  const { t } = useTranslation();

  const account = useSelector((state) => state.account);
  const [uploadDifference, setUploadDifference] = useState(null);

  if (account.user.role !== SUPER_ADMIN && account.user.role !== CLIENT) {
    window.location.href = '/home';
  }

  const tableHeaders = [
    {
      id: 'company',
      label: t('productLocations.upload.company'),
    },
    {
      id: 'location',
      label: t('productLocations.upload.location'),
    },
    {
      id: 'product',
      label: t('productLocations.upload.product'),
    },
    {
      id: 'stock',
      label: 'Stock',
    },
    {
      id: 'stockMin',
      label: 'StockMin',
    },
    {
      id: 'stockMax',
      label: 'StockMax',
    },
    {
      id: 'doh',
      label: 'DOH',
    },
    {
      id: 'dohMin',
      label: 'DOHMin',
    },
    {
      id: 'dohMax',
      label: 'DOHMax',
    },
    {
      id: 'nsd',
      label: 'NSD',
    },
    {
      id: 'nsdMin',
      label: 'NSDMin',
    },
    {
      id: 'nsdMax',
      label: 'NSDMax',
    },
    {
      id: 'minPrice',
      label: 'PriceMin',
    },
    {
      id: 'maxPrice',
      label: 'PriceMax',
    },
  ];

  const staticProperties = ['company', 'location', 'product'];
  const properties = ['Stock', 'StockMin', 'StockMax', 'Doh', 'DohMin', 'DohMax', 'Nsd', 'NsdMin', 'NsdMax', 'PriceMin', 'PriceMax'];

  return (
    <div className={classes.content}>
      <Typography className={classes.title} variant="h3" color="textPrimary">
        {t('productLocations.upload.title')}
      </Typography>
      <Paper className={classes.changesTable}>
        <Changes
          staticProperties={staticProperties}
          properties={properties}
          tableHeaders={tableHeaders}
          uploadDifference={uploadDifference || []}
          setUploadDifference={setUploadDifference}
          updateFunction={updateProductLocationsChanges}
          uploadFunction={uploadProductLocationsFile}
        />
      </Paper>
    </div>
  );
}

export default ProductLocationsImportView;

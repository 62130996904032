import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axiosOriginal from 'axios';
import { useDropzone } from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import bytesToSize from '../../utils/bytesToSize';

import { postNewDocument } from '../../requests/api/document';

import useStyles from './styles';

function FilesDropzone({ className, onClose, ...rest }) {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleDrop = useCallback((acceptedFiles) => {
    setError('');
    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].type === 'application/pdf') {
        if (['/tyc', '/administration'].includes(location.pathname)) {
          Object.defineProperty(acceptedFiles[0], 'name', {
            writable: true,
            value: 'terminosycondiciones.pdf',
          });
        }
        setFile(acceptedFiles[0]);
      } else {
        setError(t('fileDropzone.pdfRequired'));
      }
    }
  }, [location.pathname]);

  const handleRemoveAll = () => {
    setFile(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  const handleUpload = async () => {
    setLoading(true);
    const fileParts = file.name.split('.');
    const timestamp = Date.now();
    const fileName = ['/tyc', '/administration'].includes(location.pathname) ? fileParts[0] : `${fileParts[0]} ${timestamp}`;
    const uploadData = {
      fileName,
      fileType: file.type,
    };
    const response = await axiosOriginal.post('/api/documents/signS3', uploadData);

    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };

    const { returnData } = response.data.data;
    const { signedRequest } = returnData;

    try {
      const uploadResponse = await axiosOriginal.put(
        signedRequest,
        file,
        options,
      );
      if (uploadResponse.status === 200) {
        // if (location.pathname !== '/tyc') {
        await postNewDocument({ name: file.name, fileKey: fileName });
        // }
        setFile(null);
        setError('');
        onClose();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setError(t('fileDropzone.errorUpload'));
    }
    setLoading(false);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt="Select file"
            className={classes.image}
            src="/img/undraw_add_file2_gvbb.svg"
          />
        </div>
        <div>
          <Typography gutterBottom variant="h3">
            {t('fileDropzone.selectFile')}
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              {t('fileDropzone.textOne')}
              <Link underline="always">{t('fileDropzone.search')}</Link>
              {t('fileDropzone.textTwo')}
            </Typography>
          </Box>
        </div>
      </div>
      {file && (
        <>
          <List className={classes.list}>
            <ListItem>
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>
              <ListItemText
                primary={file.name}
                primaryTypographyProps={{ variant: 'h5' }}
                secondary={bytesToSize(file.size)}
              />
            </ListItem>
          </List>
          <div className={classes.actions}>
            <Button onClick={handleRemoveAll} size="small" disabled={loading}>
              {t('fileDropzone.delete')}
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              disabled={loading}
              onClick={handleUpload}
            >
              {t('fileDropzone.upload')}
            </Button>
            {loading && <CircularProgress />}
          </div>
        </>
      )}
      <p className={classes.errorMessage}>{error}</p>
    </div>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string,
};

FilesDropzone.defaultProps = {
  className: '',
};

export default FilesDropzone;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Toolbar,
  CircularProgress,
  IconButton,
  Modal as MuiModal,
  Backdrop,
  Fade,
} from '@material-ui/core';

// icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import CancelIcon from '@material-ui/icons/Cancel';
import VisibilityIcon from '@material-ui/icons/Visibility';

// components
import { useSelector } from 'react-redux';
import axios from 'axios';
import useStyles from '../styles';
import Snackbar from '../../../components/Snackbar';
import Pagination from '../../../components/Pagination';

import Button from '../../../components/Button';

// utils

// const
import FilesDropzone from '../../../components/FileDropzone/FileDropzone';
import { getTermsAndConditons } from '../../../requests/api/document';
import { SUPER_ADMIN } from '../../../utils/const/user';

// eslint-disable-next-line no-shadow
const TYCAdmin = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  // table states
  const rowsPerPage = 10;
  const account = useSelector((state) => state.account);

  const [page, setPage] = useState(0);
  const [documentsToShow, setDocumentsToShow] = useState([]);
  const [documentsQuantity, setDocumentsQuantity] = useState(0);

  // auxiliar states
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');
  const [open, setOpen] = useState(false);

  const tycTableHeaders = [
    {
      id: 'title',
      label: <b>{t('administration.news.title')}</b>,
    },
    {
      id: 'actions',
      label: <b>{t('administration.actions')}</b>,
    },
  ];

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (account.user.role !== SUPER_ADMIN) {
      window.location.href = '/home';
    }
    const requestSearch = async () => {
      const response = await getTermsAndConditons();
      if (response.success) {
        setDocumentsToShow(response.data.data.document ? [response.data.data.document] : []);
        setDocumentsQuantity(1);
      }
    };

    setLoading(true);
    requestSearch(page, rowsPerPage, '');
    setLoading(false);
  }, [page, rowsPerPage, account.user]);

  const getAmazonObject = async (fileKey) => {
    const response = await axios.get(`/api/documents/getObjectS3?fileName=${fileKey}`);
    if (response.data.success) {
      const { returnData } = response.data.data;
      const { signedRequest } = returnData;
      window.open(signedRequest);
    }
  };

  const tycToolbar = () => (
    <Toolbar className={styles.toolbarTable}>
            <Typography
            className={styles.toolbarButton}
          > </Typography>
            <Button
              id="submit-document-btn"
              onClick={handleOpen}
              text={t('terms.addButton')}
              height="50px"
              width="130px"
              backgroundColor='theme-primary-blue'
              color='theme-primary-white'
              borderRadius='30px'
              variant='text'
            />
            <MuiModal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={styles.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Paper className={styles.paperContainer} >
                  <Toolbar className={styles.modalToolbar}>
                    <IconButton onClick={handleClose}>
                      <CancelIcon align="left" fontSize="large" className={styles.loading} />
                    </IconButton>
                  </Toolbar>
                  <FilesDropzone onClose={handleClose} />
                </Paper>
              </Fade>
            </MuiModal>
          </Toolbar>
  );

  const tycTableHeader = () => (
    <TableHead>
      <TableRow>
        {tycTableHeaders.map((tableHeader) => (
          <TableCell
            key={tableHeader.id}
            className={styles.tableHeader}
            align={tableHeader.id === 'actions' ? 'center' : 'left'}
            padding="normal"
          >
            {tableHeader.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const content = () => {
    if (!documentsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={7} className={styles.users}>
            <AssignmentIcon />
            <h4>{t('terms.noContent')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
      {documentsToShow
        .map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
              >
                <TableCell
                  component="th"
                  scope="row"
                  padding="normal"
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" className={styles.viewFileButton}>
                    <VisibilityIcon
                      className={styles.button}
                      onClick={() => getAmazonObject(row.fileKey)}
                    />
                </TableCell>
              </TableRow>
        ))}
      </>
    );
  };

  const tableContent = () => {
    if (loading) {
      return (
      <TableRow>
        <TableCell colSpan={9} className={styles.progress}>
          <CircularProgress />
          <h4>{t('users.userInfo.loading')}</h4>
        </TableCell>
      </TableRow>
      );
    }
    return content();
  };

  return (
    <>
          <Paper className={styles.documentsTable}>
          <>
          {tycToolbar()}
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
               {tycTableHeader()}
                <TableBody>
                  {tableContent()}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          <Pagination
              setPage={setPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={documentsQuantity}
            />
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </>
  );
};

export default TYCAdmin;

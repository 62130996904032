/* eslint-disable no-underscore-dangle */
import FormData from 'form-data';
import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR, OK } from '../../utils/const/http';

const getUsersPerPage = async (page, limit, searchValue, column, noDeleted, projectId) => {
  try {
    const { data } = await axios.get(`/api/admin/users?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}&noDeleted=${noDeleted}&projectId=${projectId}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getUsersPerPageByCompanyId = async (page, limit, searchValue,
  column, idsInTheGroup, idsNotInTheGroup, noDeleted, projectId) => {
  try {
    const { data } = await axios.get(`/api/admin/users-company?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}&noDeleted=${noDeleted}&projectId=${projectId}&idsInTheGroup=${idsInTheGroup}&idsNotInTheGroup=${idsNotInTheGroup}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const editUser = async (
  id, updatedStatus, updatedRole, dni, fullName, password, fileUrl, phoneNumber,
) => {
  try {
    const response = await axios.put('/api/admin/users', {
      id, updatedStatus, updatedRole, dni, fullName, password, fileUrl, phoneNumber,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const editUserAvatar = async (id, fileUrl) => {
  try {
    const response = await axios.put('/api/admin/users-avatar', {
      id, fileUrl,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getUser = async () => {
  try {
    const response = await axios.get('/api/user');
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const asociateUserProject = async (userId, projectId, role, deleted) => {
  try {
    const response = await axios.post('/api/user-project', {
      userId, projectId, role, deleted,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteUser = async (id) => {
  try {
    const { data } = await axios.delete(`/api/admin/users/${id}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const uploadUserProjectsFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const { data } = await axios.post('/api/user-projects/upload', formData,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      });

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const updateUserProjectsChanges = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const { data } = await axios.post('/api/user-projects/update', formData,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      });

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

export {
  getUsersPerPage,
  getUsersPerPageByCompanyId,
  editUser,
  getUser,
  asociateUserProject,
  editUserAvatar,
  deleteUser,
  uploadUserProjectsFile,
  updateUserProjectsChanges,
};

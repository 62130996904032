import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  header: {
    display: 'flex',
    paddingBottom: '20px',
  },
  title: {
    flexGrow: 1,
    letterSpacing: '-0.5px',
  },
  locationName: {
    color: '#22617B',
    paddingLeft: '1%',
    fontWeight: 'bold',
    marginLeft: '10px',
  },
  tableHeader: {
    textAlign: 'center',
  },
  taskNameHeader: {
    textAlign: 'left',
  },
  progress: {
    height: '610px',
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  tag: {
    borderRadius: '5px',
    padding: '5px 8px',
    maxWidth: '160px',
    margin: '0px auto',
  },
  tagOpen: {
    color: theme.palette.secondary.green,
    backgroundColor: theme.palette.secondary.lightGreen,
  },
  tagWaitinfForApproval: {
    color: theme.palette.secondary.blue,
    backgroundColor: theme.palette.secondary.lightBlue,
  },
  tagApproved: {
    color: theme.palette.secondary.purple,
    backgroundColor: theme.palette.secondary.lightPurple,
  },
  tagProcessing: {
    color: theme.palette.secondary.gray,
    backgroundColor: theme.palette.secondary.lightGray,
  },
  tagClose: {
    color: theme.palette.secondary.red,
    backgroundColor: theme.palette.secondary.lightRed,
  },
  tagRejected: {
    color: theme.palette.secondary.orange,
    backgroundColor: theme.palette.secondary.lightOrange,
  },
  tagPendingDate: {
    color: theme.palette.secondary.bluegray,
    backgroundColor: theme.palette.secondary.lightBluegray,
  },
  tagNA: {
    color: theme.palette.secondary.bluegray,
    backgroundColor: theme.palette.secondary.lightBluegray,
  },
}));

export default useStyles;

import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR, OK } from '../../utils/const/http';

const getMovements = async (id, page, limit) => {
  try {
    const response = await axios.get(`/api/wallet/${id}/?page=${page}&limit=${limit}`);
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export default getMovements;

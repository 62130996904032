import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../utils/const/http';

const getUserGroupById = async (id, userGroupsPage, usersPage, limit) => {
  try {
    const { data } = await axios.get(`/api/user-group/${id}?userGroupsPage=${userGroupsPage}&usersPage=${usersPage}&limit=${limit}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const editUserGroup = async (id, name, userIds) => {
  try {
    const { data } = await axios.put(`/api/user-group/${id}`, {
      name,
      userIds,
    });

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postNewUserGroup = async (values) => {
  try {
    const { data } = await axios.post('/api/user-group', values);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const deleteUserGroup = async (id) => {
  try {
    const { data } = await axios.delete(`/api/user-group/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const getFilteredUsersInGroup = async (
  id, usersInGroupPage, limit, searchValue, column, idsNotInTheGroup, idsInTheGroup, noDeleted,
) => {
  try {
    const { data } = await axios.get(`/api/user-group/filtered-users-in/${id}?page=${usersInGroupPage}&limit=${limit}&searchValue=${searchValue}&column=${column}&noDeleted=${noDeleted}&idsNotInTheGroup=${idsNotInTheGroup}&idsInTheGroup=${idsInTheGroup}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getFilteredUsersNotInGroup = async (id, usersNotInGroupPage,
  limit, searchValue, column, idsInTheGroup, idsNotInTheGroup) => {
  try {
    const { data } = await axios.get(`/api/user-group/filtered-users-out/${id}?page=${usersNotInGroupPage}&limit=${limit}&searchValue=${searchValue}&column=${column}&idsInTheGroup=${idsInTheGroup}&idsNotInTheGroup=${idsNotInTheGroup}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  getUserGroupById,
  editUserGroup,
  postNewUserGroup,
  deleteUserGroup,
  getFilteredUsersInGroup,
  getFilteredUsersNotInGroup,
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';

// Components
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import Snackbar from '../../components/Snackbar';

import useStyles from './styles';
import authService from '../../services/authService';

function Login() {
  const styles = useStyles();
  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [inputValues, setInputValues] = useState({
    email: '',
    password: '',
  });

  const [remember, setRemember] = useState(false);

  const handleCheck = (event) => {
    setRemember(event.target.checked);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    setInputValues({
      ...inputValues,
      [id]: value,
    });
  };

  const handleSend = async () => {
    if (!inputValues.email) {
      setError(t('login.errors.wrongEmail'));
      return;
    }

    if (!inputValues.password) {
      setError(t('login.errors.passwordRequired'));
      return;
    }

    const loginResponse = await authService.login(
      inputValues.email, inputValues.password, remember,
    );

    if (!loginResponse.success) {
      setError(loginResponse.errorMessage);
      return;
    }

    window.location.href = '/home';
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.login}>
        <img className={styles.image} src="/TCG_logo.png" alt="TCG logo" />
        <Typography className={styles.title} variant="h5" align="center">{t('login.title')}</Typography>
        <div>
          <TextField
            id="email"
            type="text"
            variant="outlined"
            label={t('login.labels.mail')}
            value={inputValues.email}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <div>
          <TextField
            id="password"
            type="password"
            variant="outlined"
            label={t('login.labels.password')}
            value={inputValues.password}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <Button
          id="submit-btn"
          onClick={handleSend}
          backgroundColor="#1b5d77"
          color="#FFFFFF"
          text={t('login.button')}
          height="40px"
          width="100%"
        />
        <FormControlLabel
          control={<Checkbox checked={remember} onChange={handleCheck} color="primary" name="remember" />}
          label={t('login.checkbox')}
        />
        <br />
        <a href="/recover-password">{t('login.links.forgottenPassword')}</a>
      </div>
      <div>
        <Snackbar
          open={error !== ''}
          message={error}
          severity="error"
          onClose={() => setError('')}
        />
      </div>
    </div>
  );
}

export default Login;

import { getUserLocations } from '../requests/api/user-locations';

const LOCATIONS_REQUEST = '@locations/fetch-locations';
const LOCATIONS_SUCCESS = '@locations/fetch-success';
const LOCATIONS_ERROR = '@locations/fetch-error';

const actionFetchLocations = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: LOCATIONS_REQUEST });

    const response = await getUserLocations(companyId);
    if (response.success) {
      const { locations } = response.data.data;
      dispatch({
        type: LOCATIONS_SUCCESS,
        payload: {
          locations,
        },
      });
    }
  } catch (error) {
    dispatch({ type: LOCATIONS_ERROR });
    throw error;
  }
};

export default {
  actionFetchLocations,
  LOCATIONS_SUCCESS,
  LOCATIONS_ERROR,
  LOCATIONS_REQUEST,
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box, IconButton, Paper, Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { SUPER_ADMIN } from '../../utils/const/user';
import Changes from '../../components/UploadView/Changes';

import { updateLocationProjectsChanges, uploadLocationProjectsFile } from '../../requests/api/location-projects';

import useStyles from './styles';

function LocationProjectsImportView() {
  const classes = useStyles();
  const { t } = useTranslation();

  const account = useSelector((state) => state.account);
  const [uploadDifference, setUploadDifference] = useState(null);

  if (account.user.role !== SUPER_ADMIN) {
    window.location.href = '/home';
  }

  const tableHeaders = [
    {
      id: 'location',
      label: t('locations.importViews.locationProjects.location'),
    },
    {
      id: 'branchCode',
      label: t('locations.importViews.locationProjects.branchCode'),
    },
    {
      id: 'project',
      label: t('locations.importViews.locationProjects.project'),
    },
  ];

  const properties = ['Location', 'BranchCode', 'Project'];

  return (
    <div className={classes.content}>
      <Box className={classes.backButton}>
        <IconButton component={Link} to={'../../../locations'}>
          <ArrowBackIcon/>
        </IconButton>
        <Typography className={classes.title} variant="h3" color="textPrimary">
          {t('locations.importViews.locationProjects.uploadFile')}
        </Typography>
      </Box>
      <Box className={classes.noteContainer}>
        <Typography variant="p" color="textPrimary">
          {t('locations.importViews.locationProjects.fileNote')}
          <br />
          {t('locations.importViews.references')}
        </Typography>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceNew} /> {t('locations.importViews.new')}
        </Box>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceEqual} /> {t('locations.importViews.equal')}
        </Box>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceDelete} /> {t('locations.importViews.delete')}
        </Box>
      </Box>
      <Paper className={classes.changesTable}>
        <Changes
          properties={properties}
          templateColumns={['location', 'project']}
          templateName="location-projects"
          tableHeaders={tableHeaders}
          uploadDifference={uploadDifference || []}
          setUploadDifference={setUploadDifference}
          updateFunction={updateLocationProjectsChanges}
          uploadFunction={uploadLocationProjectsFile}
        />
      </Paper>
    </div>
  );
}

export default LocationProjectsImportView;

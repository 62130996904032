import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Toolbar,
} from '@material-ui/core';
// icons
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import TicketForm from './ticketForm';

import useStyles, { StyledTab, StyledTabs } from './styles';

// Filters area, table header
export default function TableToolbar({
  open, setFilterOptions, setOpen, sendResponse,
}) {
  const styles = useStyles();
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();
  const states = ['clear', 'open', 'waitingForApproval', 'approved', 'processing', 'close', 'rejected'];
  const [openCreateTicket, setOpenCreateTicket] = useState(false);
  const [tab, setTab] = useState(0);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCreateTicket = () => {
    setOpenCreateTicket(true);
  };

  // Filters the data in the Role and Active columns
  const handleSelectFilter = (event) => {
    const { id } = event.currentTarget;
    let column = 'state';
    let searchValue;
    if (id === 'clear') {
      // Clear filters
      searchValue = '';
      column = false;
    } else {
      // state filter
      searchValue = id;
      column = 'state';
    }
    setFilterOptions(searchValue, column);
    setTab(states.indexOf(id));
    handleClose(event);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Toolbar className={styles.toolbarTable}>
        <StyledTabs value={tab} variant="scrollable" onChange={handleSelectFilter}>
            <StyledTab id='clear' label={t('support.all')} />
            <StyledTab id='open' label={t('support.open')} />
            <StyledTab id='waitingForApproval' label={t('support.waitingForApproval')} />
            <StyledTab id='approved' label={t('support.approved')} />
            <StyledTab id='processing' label={t('support.processing')} />
            <StyledTab id='close' label={t('support.close')} />
            <StyledTab id='rejected' label={t('support.rejected')} />
          </StyledTabs>
        <Button
          id="create-user-btn"
          text={'+ Ticket'}
          variant="contained"
          height="40px"
          width="130px"
          backgroundColor='#2C9DC9'
          color='white'
          borderRadius='10px'
          onClick = {handleCreateTicket}
        />
      </Toolbar>
      {openCreateTicket ? (
        <Modal title={t('support.ticketForm.modalTitle')} defaultOpen={openCreateTicket} setCurrentOpen={setOpenCreateTicket}>
          <TicketForm sendResponse={sendResponse} setCurrentOpen={setOpenCreateTicket} />
        </Modal>
      ) : null}
      </>
  );
}

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  taskForm: {
    display: 'grid',
    gridTemplateColumns: '47% 47%',
    columnGap: '5%',
    padding: '30px 60px 55px',
    width: '70vw',
    borderRadius: '10px',
    '& label': {
      color: theme.palette.primary.darkGray,
      fontSize: '13px',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'none',
    },
    '& .MuiInput-root': {
      border: `1px solid ${theme.palette.primary.lightGray}`,
      borderRadius: '5px',
      marginTop: '27px',
      padding: '5px',
      paddingLeft: '10px',
    },
    '& .MuiInput-root.Mui-focused': {
      border: `1px solid ${theme.palette.secondary.blue}`,
      borderRadius: '5px',
    },
    '& .MuiInput-input': {
      textAlign: 'left',
      fontSize: '14px',
      paddingLeft: '7px',
    },
    '& label.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.secondary.blue,
    },
  },
  notice: {
    gridColumn: '1/3',
    color: theme.palette.secondary.blue,
    fontSize: '13px',
    marginTop: '20px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  buttonsContainer: {
    gridColumn: 2,
    gridRow: 2,
    marginTop: '25px',
    '& button': {
      fontWeight: 'bold',
      width: '160px',
      borderRadius: '10px',
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.secondary.blue,
    },
    '& button:first-child': {
      marginRight: '15px',
      color: theme.palette.primary.middleGray,
      backgroundColor: theme.palette.primary.white,
      border: `0.8px solid ${theme.palette.secondary.gray}`,
      '&:hover': {
        backgroundColor: theme.palette.secondary.lightGray,
      },
    },
  },
  buttonsModal: {
    display: 'flex',
    justifyContent: 'end',
  },
  displacedButtons: {
    gridRow: 3,
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '80% 20%',
    alignItems: 'center',
    '& p': {
      fontSize: '16px',
      marginTop: '0px',
      color: theme.palette.secondary.gray,
    },
    '& .MuiTextField-root': {
      '& input': {
        fontSize: '24px',
      },
    },
  },
  createTaskButton: {
    fontWeight: 'bold',
    width: '160px',
    heigth: '40px',
    borderRadius: '10px',
    color: theme.palette.secondary.blue,
    border: `1px solid ${theme.palette.secondary.blue}`,
    backgroundColor: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.lightGray,
    },
  },
  coloredSpan: {
    color: theme.palette.secondary.blue,
    borderBottom: `1px solid ${theme.palette.secondary.blue}`,
  },
  sectionTitle: {
    color: theme.palette.secondary.blue,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  button: {
    fontWeight: 'bold',
    width: '160px',
    borderRadius: '8px',
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.secondary.blue,
    marginTop: '30px',
    '&.Mui-disabled': {
      color: theme.palette.primary.white,
      opacity: 0.5,
    },
  },
  taskContent: {
    display: 'flex',
    marginTop: '15px',
  },
  toolsContainer: {
    maxWidth: '300px',
    marginLeft: '60px',
    '& .MuiPaper-root': {
      height: '75vh',
    },
  },
  toolsContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'calc(100% - 85px)',
    padding: '10px 50px',
    '& em': {
      fontSize: '14px',
      color: theme.palette.primary.lightGray,
    },
  },
  editionArea: {
    flexGrow: 1,
    textAlign: 'center',
  },
  formDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '10px',
    backgroundColor: 'white',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.02)',
    marginBottom: '15px',
    padding: '15px',
    color: theme.palette.primary.gray,
    '& .MuiIconButton-root': {
      padding: '0px',
      color: theme.palette.primary.lightGray,
    },
  },
  categoryTitle: {
    textAlign: 'left',
    color: theme.palette.secondary.blue,
  },
  backButton: {
    width: '80px',
    paddingLeft: '0px',
    color: theme.palette.secondary.middleGray,
    '& span': {
      fontSize: '13px',
    },
  },
  modalCreateTask: {
    padding: '0px 20px',
    '& .MuiTableCell-root': {
      maxHeight: '35px',
      cursor: 'pointer',
      padding: '7px',
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& span': {
      padding: '0px',
    },
    '& button': {
      float: 'right',
      margin: '10px 20px',
    },
  },
  title: {
    fontSize: '24px',
    marginBottom: '15px',
  },
  buttonForm: {
    fontWeight: 'bold',
    width: '180px',
    borderRadius: '8px',
    color: theme.palette.secondary.blue,
    backgroundColor: theme.palette.primary.white,
    border: `1px solid ${theme.palette.secondary.blue}`,
    '&.Mui-disabled': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
  },
  expandButton: {
    color: theme.palette.secondary.blue,
  },
  associationContainer: {
    margin: '10px 0px',
    borderRadius: '10px',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.02)',
    padding: '0px 30px',
    paddingLeft: '0px',
  },
  associationDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    '& div:first-child': {
      display: 'flex',
    },
  },
  hide: {
    display: 'none',
  },
  active: {
    color: theme.palette.secondary.green,
  },
  inactive: {
    color: theme.palette.secondary.orange,
  },
  taskInstanceDetail: {
    padding: '0px 20px 20px 50px',
    '& p': {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '12px',
    },
  },
  location: {
    textAlign: 'left',
    '& span': {
      color: theme.palette.primary.gray,
      lineHeight: '1.7',
    },
  },
  categoriesTitleContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  categoryContainer: {
    marginTop: '30px',
    borderTop: `1px solid ${theme.palette.primary.lightGray}`,
  },
}));

export default useStyles;

/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';

const CarouselRow = ({
  triplet, setCurrentImage, setLines, lineList, currentImage,
}) => {
  const styles = useStyles();

  useEffect(() => {
    setCurrentImage(triplet[1]);
  }, []);

  useEffect(() => {
    if (lineList) {
      setLines(lineList[currentImage.id]);
    }
  }, [currentImage]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={1}>
        <>
        </>
      </Grid>
      <Grid item xs={3}>
        <Paper
          elevation={0}
          className={clsx({
            [styles.nonPrimaryPaper]: true,
            [styles.leftPaper]: true,
          })}>
          { triplet[0] !== undefined
            ? <img
                src={triplet[0].imageUrl}
                width='100%'
                height='100%'
                alt='image-1'
              />
            : null }
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper elevation={0} className={styles.primaryPaper}>
          <img
            src={triplet[1].imageUrl}
            width='100%'
            height='100%'
            alt='image-2'
          />
        </Paper>
      </Grid>
      <Grid item xs={2}>
        <Paper
          elevation={0}
          className={clsx({
            [styles.nonPrimaryPaper]: true,
            [styles.rightPaper]: true,
          })} >
          { triplet[2] !== undefined
            ? <img
                src={triplet[2].imageUrl}
                width='100%'
                height='100%'
                alt='image-3'
                />
            : null }
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <>
        </>
      </Grid>
    </Grid>

  );
};

export default CarouselRow;

import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import useStyles from './styles';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SurveyQuestionBox = ({ data, questionResponsesDone, reaudit }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();

  const [value, setValue] = useState(0);
  const [surveyResponse, setSurveyResponse] = useState(data[0]);
  const { taskResponseId } = useParams();

  const handleState = (state) => {
    switch (state) {
      case true:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagCompleted}`}><b>{t('qa.status.completed')}</b></p>
        );
      case false:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagPending}`}><b>{t('qa.status.pending')}</b></p>
        );
      default:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagNA}`}>O <b>N/A</b></p>
        );
    }
  };

  const handleSurveyState = (completed, total) => {
    switch (true) {
      case completed === 0:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagNotStarted}`}>
            O <b>{completed}/{total}{t('teleaudit.surveyExpandableTable.done')}</b>
          </p>
        );
      case completed < total && completed !== 0:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagIncomplete}`}>
            O <b>{completed}/{total}{t('teleaudit.surveyExpandableTable.done')}</b>
          </p>
        );
      case completed === total:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagComplete}`}>
            O <b>{completed}/{total}{t('teleaudit.surveyExpandableTable.done')}</b>
          </p>
        );
      default:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagNA}`}>O <b>N/A</b></p>
        );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeSurvey = (event) => {
    const surveyFinder = data.filter((el) => el.Survey.id === event.target.value);
    if (surveyFinder) {
      setSurveyResponse(surveyFinder[0]);
    }
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={styles.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          aria-label="action tabs example"
        >
          <Tab label={t('teleaudit.surveyQuestionBox.surveys')} />
          <Tab label={t('teleaudit.surveyQuestionBox.questions')} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Table>
            {data
              ? data.map((row) => (
                  <TableRow key={row.Survey.id}>
                    <TableCell
                      key={row.Survey.title}
                      align="left"
                      width='30%'
                    >
                      <b>{row.Survey.title}</b>
                    </TableCell>
                    {row.Survey.type === 'product' ? <TableCell width='30%' align="left"><b>{row.Product.name}</b></TableCell> : null}
                    <TableCell width='20%' align="left">
                      <b>
                        {row.QuestionResponses.length}
                        {row.QuestionResponses.length === 1
                          ? t('teleaudit.surveyQuestionBox.body.question')
                          : t('teleaudit.surveyQuestionBox.body.questions')
                        }
                      </b>
                    </TableCell>
                    <TableCell align="center" width='20%'>
                      { reaudit
                        ? null
                        : handleSurveyState(
                          questionResponsesDone[row.id].length,
                          row.QuestionResponses.length,
                        )}
                    </TableCell>
                  </TableRow>
              ))
              : null
            }
          </Table>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <FormControl className={styles.formControl}>
            <InputLabel id="demo-simple-select-label">{t('teleaudit.surveyQuestionBox.survey')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={surveyResponse.Survey ? surveyResponse.Survey.id : null}
                onChange={handleChangeSurvey}
              >
                { data
                  ? data.map((row) => (
                    <MenuItem key={row.Survey.id} value={row.Survey.id}>
                      {row.Survey.title}
                    </MenuItem>
                  ))
                  : null
                }
              </Select>
          </FormControl>
          <Table>
          {surveyResponse.QuestionResponses
            ? surveyResponse.QuestionResponses.map((questionResponse) => (
              <TableRow key={questionResponse.id}>
                    <TableCell align="left" width='25%'><b>{questionResponse.Question.title}</b></TableCell>
                    <TableCell align="center" width='20%'>
                      <b>
                        {questionResponse.questionResponseImageQuantity}
                        { questionResponse.questionResponseImageQuantity === 1
                          ? t('teleaudit.surveyQuestionBox.body.image')
                          : t('teleaudit.surveyQuestionBox.body.images') }
                      </b>
                    </TableCell>

                    <TableCell align="center" width='30%'>
                      { reaudit
                        ? (<p align="left" className={`${styles.tag} ${styles.tagReaudit}`}>
                              <b>
                                Reaudit
                              </b>
                            </p>
                        )
                        : handleState(questionResponsesDone[surveyResponse.id]
                          .includes(questionResponse.id))
                      }
                    </TableCell>

                    <TableCell align="right" width='25%'>
                      { reaudit
                        ? <Button
                            component={Link}
                            to={`/teleaudit/${taskResponseId}/${questionResponse.id}`}
                            variant="contained"
                            className={styles.enableButton}
                            align='center'
                          >
                            {t('teleaudit.surveyQuestionBox.body.toTeleaudit')}
                          </Button>
                        : <Button
                            component={Link}
                            to={`/teleaudit/${taskResponseId}/${questionResponse.id}`}
                            variant="contained"
                            className={clsx({
                              [styles.enableButton]: true,
                            })}
                            align='center'
                              >
                            {t('teleaudit.surveyQuestionBox.body.toTeleaudit')}
                          </Button>
                    }
                    </TableCell>
                  </TableRow>
            ))
            : null
          }
          </Table>
        </TabPanel>
      </SwipeableViews>

    </div>
  );
};

export default SurveyQuestionBox;

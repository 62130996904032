import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Box,
  IconButton,
} from '@material-ui/core';
import { useParams, Link } from 'react-router-dom';

// icons
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// components
import useStyles from './styles';
import Snackbar from '../../../components/Snackbar';
import Pagination from '../../../components/Pagination';
import getMovements from '../../../requests/api/wallet';
import { getProject } from '../../../requests/api/project';
import TicketInformationCard from './ticketInformationCard';
import Modal from '../../../components/Modal';
import WalletMovementsInformation from './walletInformationCard';
import { parseDate } from '../../../utils/date';

// import UserInformation from './UserInformation';
// const
import { UNAUTHORIZED } from '../../../utils/const/http';
import { CLIENT_EMPLOYEE } from '../../../utils/const/user';

// eslint-disable-next-line no-shadow
const WalletDetail = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  const account = useSelector((state) => state.account);

  // table states
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [walletQuantity, setWalletQuantity] = useState(1);
  const [projectName, setProjectName] = useState('');
  // auxiliar states

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');
  const { id } = useParams();

  const tableHeaders = [
    {
      id: 'ticketId',
      label: <b>{t('wallet.columnNames.ticketId')}</b>,
    },
    {
      id: 'openDate',
      label: <b>{t('wallet.columnNames.openDate')}</b>,
    },
    {
      id: 'closeDate',
      label: <b>{t('wallet.columnNames.closeDate')}</b>,
    },
    {
      id: 'amount',
      label: <b>{t('wallet.columnNames.amount')}</b>,
    },
    {
      id: 'tokensLeft',
      label: <b>{t('wallet.columnNames.currentTokens')}</b>,
    },
    {
      id: 'actions',
      label: <b>{t('wallet.columnNames.actions')}</b>,
    },
  ];
  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (account.user.role === CLIENT_EMPLOYEE) window.location.href = '/home';
  }, []);

  useEffect(() => {
    load();
  }, [rowsToShow]);

  useEffect(() => {
    const requestSearch = async (pageOfTable, limit) => {
      const response = await getMovements(id, pageOfTable, limit);

      if (response.success) {
        setRowsToShow(response.data.data.walletMovements);
        setWalletQuantity(response.data.data.totalWalletMovements);
      }
      const projectInfo = await getProject(id);
      setProjectName(projectInfo.data.name);
      if (response.status === UNAUTHORIZED.status) {
        window.location.href = '/home';
      }
    };
    requestSearch(page, rowsPerPage);
  }, [page, rowsPerPage, walletQuantity, projectName, id]);

  const content = () => {
    if (!walletQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={9} className={styles.users}>
            <ConfirmationNumberIcon />
            <h4>{t('wallet.noWallets')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row) => (
            <TableRow
              hover
              key = {row.id}
            >
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.subjectName}
                >{row.Ticket ? row.Ticket.crmTicketId : row.comments}</TableCell>
              <TableCell align="center">{row.Ticket ? parseDate(row.Ticket.createdAt) : parseDate(row.createdAt)}</TableCell>
              <TableCell align="center">{parseDate(row.createdAt)}</TableCell>
              <TableCell>
                <div className={styles.tokensColumn}>
                  {row.amount > 0 ? (
                      <KeyboardArrowUpIcon style={{ color: 'green' }}/>
                  ) : (
                      <KeyboardArrowDownIcon style={{ color: 'red' }}/>
                  )}
                  {row.amount > 0 ? (
                      <b style={{ color: 'green' }}>{row.amount}</b>
                  ) : (
                      <b style={{ color: 'red' }}>{row.amount}</b>
                  )}
                </div>
              </TableCell>
              <TableCell align="center">
                {row.tokensLeft > 0 ? (
                    <b style={{ color: 'green' }}>{row.tokensLeft}</b>
                ) : (
                    <b style={{ color: 'red' }}>{row.tokensLeft}</b>
                )}
              </TableCell>
              {row.Ticket
                ? <>
              <TableCell align="center" className={styles.iconsCell}>
                <Modal view={true}>
                  <TicketInformationCard
                    data={row.Ticket}
                    />
                </Modal>
              </TableCell>
              </>
                : <TableCell align="center">
                    <Modal view={true}>
                      <WalletMovementsInformation
                        data={row}
                        />
                    </Modal>
                  </TableCell>
              }
            </TableRow>
          ))}
      </>
    );
  };
  return (
    <>
    <div className={styles.content}>
      <div className={styles.header}>
        <Box sx={{ width: '100%' }}>
          <IconButton className={styles.backItem} component={Link} to={'../wallet'}>
              <ArrowBackIcon/>
          </IconButton>
          <Typography variant='h1' className={styles.title} gutterBottom display="inline">
            {t('wallet.title')} {projectName}
          </Typography>
          <br>
          </br>
          <Typography variant='h4' gutterBottom display="inline">
          {t('wallet.subtitle')}
          </Typography>
          <Typography variant='subtitle1' className={styles.underlined} gutterBottom display="inline">
          {walletQuantity} {t('wallet.movements')}
          </Typography>
        </Box>
    </div>
      <Paper className={styles.walletTable}>
          <>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((tableHeader) => (

                      <TableCell
                        key={tableHeader.id}
                        className={clsx({
                          [styles.tableHeader]: true,
                          [styles.ticketIdHeader]: tableHeader.id === 'ticketId',
                        })}
                        padding="normal"
                      >
                        {tableHeader.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={9} className={styles.progress}>
                        <CircularProgress />
                        <h4>{t('support.loading')}</h4>
                      </TableCell>
                    </TableRow>
                  ) : content()}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              setPage={setPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={walletQuantity}
            />
          </>
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </div>
    </>
  );
};

export default WalletDetail;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Toolbar,
  CircularProgress,
  IconButton,
  Grid,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';

// icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

// components
import Modal from '../../components/Modal';
import useStyles from './styles';
import Snackbar from '../../components/Snackbar';
import Pagination from '../../components/Pagination';
import TableToolbarTabs from './userDetailToolbar';
import {
  getNotUserProjects,
  getUserIdProjects,
  deleteUserProject,
} from '../../requests/api/project';
import {
  getNotUserReports,
  getUserReports,
  deleteUserReport,
} from '../../requests/api/report';

import Button from '../../components/Button';
import AssociateProjectForm from './associateForm';
import AssociateReportForm from './associateReportForm';

// import UserInformation from './UserInformation';
// const
import { UNAUTHORIZED } from '../../utils/const/http';

// eslint-disable-next-line no-shadow
const UserDetail = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  // table states
  const [userName, setUserName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [totalRowsQuantity, setTotalRowsQuantity] = useState(0);
  const [notUSerProjects, setNotUserProjects] = useState([]);
  const [notUserReports, setNotUserReports] = useState([]);
  const [currentSelection, setCurrentSelection] = useState({
    projectName: '',
    projectId: '',
    role: '',
    userId: id,
    userProjectId: '',
  });
  const [currentUserReportFormData, setCurrentUserReportFormData] = useState({
    reportId: '',
    reportName: '',
    configJson: '',
    configDescription: '',
    userId: id,
    userPowerbiReportId: '',
  });
  // auxiliar states
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteReportDialog, setOpenDeleteReportDialog] = useState(false);
  const [openAssociateProject, setOpenAssociateProject] = useState(false);
  const [openAssociateReport, setOpenAssociateReport] = useState(false);
  const [openUpdateProject, setOpenUpdateProject] = useState(false);
  const [openUpdateReport, setOpenUpdateReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');
  const toolbarTabs = ['projects', 'reports'];
  const [tab, setTab] = useState(0);

  const userProjectsTableHeaders = [
    {
      id: 'projectName',
      label: <b>{t('users.userInfo.projectName')}</b>,
    },
    {
      id: 'role',
      label: <b>{t('users.userInfo.projectRole')}</b>,
    },
    {
      id: 'actions',
      label: <b>{t('users.userInfo.actions')}</b>,
    },
  ];

  const userReportsTableHeaders = [
    {
      id: 'reportName',
      label: <b>{t('reports.columnNames.reportName')}</b>,
    },
    {
      id: 'configJson',
      label: <b>{t('reports.columnNames.configDescription')}</b>,
    },
    {
      id: 'actions',
      label: <b>{t('users.userInfo.actions')}</b>,
    },
  ];

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const handleOpenDialog = (row) => {
    if (tab === 0) {
      setCurrentSelection({
        ...currentSelection,
        projectName: row.name,
        userProjectId: row.id,
      });
      setOpenDialog(true);
    } else if (tab === 1) {
      setCurrentUserReportFormData({
        ...currentUserReportFormData,
        reportName: row.reportName,
        userPowerbiReportId: row.Users[0].UserPowerbiReport.id,
      });
      setOpenDeleteReportDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setCurrentSelection({
      projectName: '',
      projectId: '',
      role: '',
      userId: id,
      userProjectId: '',
    });
    setCurrentUserReportFormData({
      reportId: '',
      configJson: '',
      configDescription: '',
      userId: id,
      userPowerbiReportId: '',
    });
    setOpenDialog(false);
    setOpenDeleteReportDialog(false);
  };

  const handleUpdateAssociation = (row) => {
    setCurrentSelection(row);
    setOpenUpdateProject(true);
  };

  const handleUpdateReportAssociation = (row) => {
    setCurrentUserReportFormData({
      reportId: row.id,
      reportName: row.reportName,
      configJson: row.Users[0].UserPowerbiReport.configJson,
      configDescription: row.Users[0].UserPowerbiReport.configDescription,
      userId: id,
      userPowerbiReportId: row.Users[0].UserPowerbiReport.configJson,
    });
    setOpenUpdateReport(true);
  };

  const requestSearch = async (pageOfTable, limit) => {
    const getProjectsResponse = await getUserIdProjects(id, pageOfTable, limit);
    if (getProjectsResponse.success) {
      setRowsToShow(getProjectsResponse.projects);
      setTotalRowsQuantity(getProjectsResponse.totalProjects);
      setUserName(getProjectsResponse.user.fullName);
    }
    if (getProjectsResponse.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }

    const getNotUserProjectsResponse = await getNotUserProjects(id);
    if (getNotUserProjectsResponse.success) {
      setNotUserProjects(getNotUserProjectsResponse.data.notUserProjects);
    }
  };

  const reportsRequestSearch = async (pageOfTable, limit) => {
    const getReportsResponse = await getUserReports(id, pageOfTable, limit);
    if (getReportsResponse.success) {
      setTotalRowsQuantity(getReportsResponse.data.data.powerbiReports.count);
      setRowsToShow(getReportsResponse.data.data.powerbiReports.rows);
    }
    if (getReportsResponse.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }

    const getNotUserReportsResponse = await getNotUserReports(id);
    if (getNotUserReportsResponse.success) {
      const notUserReportsWithName = getNotUserReportsResponse.data.notUserPowerbiReports.map(
        (report) => {
          const reportWithName = report;
          reportWithName.name = reportWithName.reportName;
          if (reportWithName.reportDescription) {
            reportWithName.name = `${reportWithName.name} - ${reportWithName.reportDescription}`;
          }
          return reportWithName;
        },
      );
      setNotUserReports(notUserReportsWithName);
    }
  };

  const reload = async () => {
    load();
    if (tab === 0) {
      requestSearch(page, rowsPerPage);
    } else if (tab === 1) {
      reportsRequestSearch(page, rowsPerPage);
    }
  };

  const handleDelete = async () => {
    const response = await deleteUserProject(currentSelection);
    if (response.success) {
      setSucc(t('users.userInfo.deleteSuccess'));
      handleCloseDialog();
      setPage(0);
      reload(page, rowsPerPage);
    } else {
      setSucc(t('users.userInfo.error'));
      handleCloseDialog();
    }
  };

  const handleDeleteReport = async () => {
    const response = await deleteUserReport(currentUserReportFormData);
    if (response.success) {
      setSucc(t('users.userInfo.deleteSuccess'));
      handleCloseDialog();
      setPage(0);
      reload(page, rowsPerPage);
    } else {
      setSucc(t('users.userInfo.error'));
      handleCloseDialog();
    }
  };

  const handleAssociateProject = () => {
    setCurrentSelection({ project: '', role: '' });
    setOpenAssociateProject(true);
  };

  const handleAssociateReport = () => {
    setCurrentUserReportFormData({
      reportId: '',
      configJson: '',
      configDescription: '',
      userId: id,
      userPowerbiReportId: '',
    });
    setOpenAssociateReport(true);
  };

  useEffect(() => {
    reload();
    // eslint-disable-next-line
  }, [page, rowsPerPage, totalRowsQuantity, tab]);

  const projectToolbar = () => (
    <Toolbar className={styles.toolbarTable}>
            <Typography
            className={styles.toolbarButton}
          > </Typography>
            <Button
            id="create-user-btn"
            text='Asociar proyecto'
            variant="contained"
            height="40px"
            width="100%"
            backgroundColor='#2C9DC9'
            color='white'
            borderRadius='10px'
            onClick = {handleAssociateProject}
          />
          {openAssociateProject ? (
          <Modal title={t('users.userInfo.titleNewAssoc')} defaultOpen={openAssociateProject} setCurrentOpen={setOpenAssociateProject}>
            <AssociateProjectForm
            setCurrentOpen={setOpenAssociateProject}
            currentSelection={currentSelection}
            options={notUSerProjects}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={false}
            />
          </Modal>
          ) : null}
          </Toolbar>
  );

  const userReportsToolbar = () => (
    <Toolbar className={styles.toolbarTable}>
            <Typography
            className={styles.toolbarButton}
          > </Typography>
            <Button
            id="create-user-btn"
            text='Asociar reporte'
            variant="contained"
            height="40px"
            width="100%"
            backgroundColor='#2C9DC9'
            color='white'
            borderRadius='10px'
            onClick = {handleAssociateReport}
          />
          {openAssociateReport ? (
          <Modal title={t('users.userInfo.titleNewReportAssoc')} defaultOpen={openAssociateReport} setCurrentOpen={setOpenAssociateReport}>
            <AssociateReportForm
            setCurrentOpen={setOpenAssociateReport}
            currentUserReportFormData={currentUserReportFormData}
            options={notUserReports}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={false}
            />
          </Modal>
          ) : null}
          </Toolbar>
  );

  const projectsTableHeader = () => (
    <TableHead>
      <TableRow>
        {userProjectsTableHeaders.map((tableHeader) => (
          <TableCell
            key={tableHeader.id}
            className={styles.tableHeader}
            align={tableHeader.id === 'role'
                  || tableHeader.id === 'actions' ? 'center' : 'left'}
            padding="normal"
          >
            {tableHeader.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const reportsTableHeader = () => (
    <TableHead>
      <TableRow>
        {userReportsTableHeaders.map((tableHeader) => (
          <TableCell
            key={tableHeader.id}
            className={styles.tableHeader}
            align={tableHeader.id === 'configJson'
                  || tableHeader.id === 'actions' ? 'center' : 'left'}
            padding="normal"
          >
            {tableHeader.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const contentProjects = () => {
    if (!totalRowsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={3} className={styles.users}>
            <AssignmentIcon />
            <h4>{t('users.userInfo.noProjects')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row) => (
            <TableRow
              hover
              key = {row.id}
            >
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.name}</TableCell>
              <TableCell align="center">
                {row.role ? (
                  row.role) : (
                  t('users.userInfo.noRole')
                )}
              </TableCell>
              <TableCell align="center">
                <IconButton
                onClick = {() => handleUpdateAssociation(row)}
                >
                  <EditIcon/>
                </IconButton>
                <IconButton
                  onClick = {() => handleOpenDialog(row)}>
                  <DeleteIcon/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {openUpdateProject ? (
          <Modal title={t('users.userInfo.titleNewAssoc')} defaultOpen={openUpdateProject} setCurrentOpen={setOpenUpdateProject}>
            <AssociateProjectForm
            setCurrentOpen={setOpenUpdateProject}
            currentSelection={currentSelection}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={true}
            />
          </Modal>
          ) : null}
          {openDialog ? (
                  <Modal title={t('users.userInfo.titleDeleteAssoc')} defaultOpen={openDialog} setCurrentOpen={setOpenDialog}>
                    <div className={styles.dialogWrapper}>
                      <Grid container spacing={2} alignItems='flex-end'>
                        <Grid item xs={12}>
                          <Typography className={styles.dialogText} variant="h2" color="textSecondary" component="p">
                            {t('users.userInfo.deleteContent')} {currentSelection.projectName} ?
                          </Typography>
                        </Grid>
                        <Grid item xs= {6}/>
                        <Grid item xs={3}>
                        <Button
                          id="submit-ticket-btn"
                          onClick={handleCloseDialog}
                          variant="contained"
                          text={t('users.userInfo.buttons.cancel')}
                          height="35px"
                          width="100%"
                          backgroundColor="#FFFFFF"
                          color="#2C9DC9"
                          borderRadius="10px"
                        />
                        </Grid>
                        <Grid item xs={3}>
                        <Button
                          id="delete-association-btn"
                          onClick={handleDelete}
                          variant="contained"
                          text={t('users.userInfo.buttons.delete')}
                          height="35px"
                          width="100%"
                          backgroundColor='#F5B7B1'
                          color='#E74C3C'
                          borderRadius="10px"
                        />
                        </Grid>
                        </Grid>
                      </div>
                </Modal>
          ) : null}
      </>
    );
  };

  const contentReports = () => {
    if (!totalRowsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={3} className={styles.users}>
            <AssignmentIcon />
            <h4>{t('users.userInfo.noReports')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row) => (
            <TableRow
              hover
              key = {row.id}
            >
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.reportName}</TableCell>
              <TableCell align="center">
                {row.Users ? row.Users[0].UserPowerbiReport.configDescription : (
                  '-'
                )}
              </TableCell>
              <TableCell align="center">
                <IconButton
                onClick = {() => handleUpdateReportAssociation(row)}
                >
                  <EditIcon/>
                </IconButton>
                <IconButton
                  onClick = {() => handleOpenDialog(row)}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton component={Link} to={`../users/${id}/report/${row.id}`}>
                  <VisibilityIcon/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {openUpdateReport ? (
          <Modal title={t('users.userInfo.titleNewAssoc')} defaultOpen={openUpdateReport} setCurrentOpen={setOpenUpdateReport}>
            <AssociateReportForm
            setCurrentOpen={setOpenUpdateReport}
            currentUserReportFormData={currentUserReportFormData}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={true}
            options={notUserReports}
            />
          </Modal>
          ) : null}
          {openDeleteReportDialog ? (
                  <Modal title='Delete Relation' defaultOpen={openDeleteReportDialog} setCurrentOpen={setOpenDeleteReportDialog}>
                    <div className={styles.dialogWrapper}>
                      <Grid container spacing={2} alignItems='flex-end'>
                        <Grid item xs={12}>
                          <Typography className={styles.dialogText} variant="h2" color="textSecondary" component="p">
                            {t('users.userInfo.deleteReportRelationContent')} {currentUserReportFormData.reportName} ?
                          </Typography>
                        </Grid>
                        <Grid item xs= {6}/>
                        <Grid item xs={3}>
                        <Button
                          id="submit-ticket-btn"
                          onClick={handleCloseDialog}
                          variant="contained"
                          text={t('users.userInfo.buttons.cancel')}
                          height="35px"
                          width="100%"
                          backgroundColor="#FFFFFF"
                          color="#2C9DC9"
                          borderRadius="10px"
                        />
                        </Grid>
                        <Grid item xs={3}>
                        <Button
                          id="delete-association-btn"
                          onClick={handleDeleteReport}
                          variant="contained"
                          text={t('users.userInfo.buttons.delete')}
                          height="35px"
                          width="100%"
                          backgroundColor='#F5B7B1'
                          color='#E74C3C'
                          borderRadius="10px"
                        />
                        </Grid>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                      </div>
                </Modal>
          ) : null}
      </>
    );
  };

  const tableContent = () => {
    if (loading) {
      return (
      <TableRow>
        <TableCell colSpan={9} className={styles.progress}>
          <CircularProgress />
          <h4>{t('users.userInfo.loading')}</h4>
        </TableCell>
      </TableRow>
      );
    }
    if (tab === 0) {
      return contentProjects();
    }
    return contentReports();
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
          <IconButton component={Link} to={'../users'}>
              <ArrowBackIcon/>
          </IconButton>
            <Typography variant='h1' className={styles.title}>
            {userName}
            </Typography>
      </div>
      <TableToolbarTabs className={styles.toolbarTable}
            toolbarTabs={toolbarTabs}
            tab={tab}
            setTab={setTab}
            >
          </TableToolbarTabs>
          <Paper className={styles.documentsTable}>
          <>
          {tab === 0 ? projectToolbar() : userReportsToolbar()}
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                {tab === 0 ? projectsTableHeader() : reportsTableHeader()}
                <TableBody>
                  {tableContent()}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          <Pagination
              setPage={setPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={totalRowsQuantity}
            />
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </div>
  );
};

export default UserDetail;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TextField as MuiTextField,
  IconButton,
} from '@material-ui/core';

import {
  Droppable, Draggable,
} from 'react-beautiful-dnd';

// icons
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
// components
import Pagination from '../../components/Pagination';

// functions
import { handleSelectAllClickGroups, handleSelect, isSelected } from '../../utils/selectFunctions';
import setFilterOptions from '../../utils/filterFunctions';

import useStyles from './styles';

function TableGroupComponent({
  attributes,
  elementType,
  tableHeaders,
  selected,
  setSelected,
  groupInfo,
  page,
  setPage,
  rowsPerPage,
  inOrOut,
  quantityElements,
  tableSubtitle,
  setInOrOut,
  searchText,
  setSearchText,
  setColumn,
  setFilter,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const handleFilter = async (event, column) => {
    let { value } = event.target;
    let columnChanged = column;
    if (!column) {
      value = '';
      columnChanged = 'name';
    }
    setInOrOut(inOrOut);
    setFilterOptions(value, columnChanged, setColumn,
      setSearchText, setFilter, searchText, inOrOut);
  };

  useEffect(() => {
    setInOrOut(inOrOut);
  }, [page]);

  return (
    <Paper className={styles.groupTable}>
      <>
        <TableContainer>
            <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            >
            <TableHead>
                <TableRow className={styles.divider}>
                <TableCell colSpan={12}>
                  <p>{tableSubtitle}</p>
                  <MuiTextField
                    id={inOrOut}
                    variant="outlined"
                    value={searchText[inOrOut]}
                    fullWidth
                    className={styles.searchBar}
                    onChange={(e) => handleFilter(e, 'name')}
                    placeholder={t('groups.tableToolbar.filterUserPlaceholder')}
                    InputProps={{
                      startAdornment: <SearchIcon fontSize="small" />,
                      endAdornment: (
                        <IconButton
                          id="clear"
                          title="Clear"
                          aria-label="Clear"
                          size="small"
                          onClick={(e) => handleFilter(e, false)}
                          style={{ visibility: searchText ? 'visible' : 'hidden' }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell className={styles.tableHeader} padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0
                      && selected.length < groupInfo[inOrOut].length
                    }
                    checked={
                      groupInfo[inOrOut] && groupInfo[inOrOut].length > 0
                      && selected.length === groupInfo[inOrOut].length
                    }
                    onChange={(event) => (
                      handleSelectAllClickGroups(event, groupInfo[inOrOut], inOrOut, setSelected)
                    )}
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
                {tableHeaders.map((tableHeader) => (
                  <TableCell
                    key={tableHeader.id}
                    className={styles.tableHeader}
                    align={
                        tableHeader.id === 'createdAt'
                          ? 'center'
                          : 'left'
                    }
                    padding="normal"
                  >
                  {tableHeader.id === 'name' && selected.length
                    ? `Selected ${selected.length} groups`
                    : tableHeader.label}
                  </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <Droppable droppableId={inOrOut}>
                {(provided) => (
                <TableBody
                  ref={provided.innerRef} {...provided.droppableProps}
                >
                  {groupInfo[inOrOut] && !groupInfo[inOrOut].length ? (
                    <Draggable
                      draggableId="noContent"
                      index={0}
                      key="noContent"
                    >
                    {(prov) => (
                      <TableRow
                        id="noContent"
                        ref={prov.innerRef}
                        {...prov.draggableProps}
                        {...prov.dragHandleProps}
                        tabIndex={-1}
                        key="noContent"
                      >
                        <TableCell colSpan={12} align="center">
                          <p className={styles.emptySpace}>{t('groups.emptyElements', { elementType })}</p>
                        </TableCell>
                      </TableRow>
                    )}
                    </Draggable>
                  ) : (
                    groupInfo[inOrOut] && groupInfo[inOrOut].map((row, index) => {
                      const isItemSelected = isSelected(row.id, selected);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <Draggable
                          draggableId={`${row.id}`}
                          index={index}
                          key={row.id}
                        >
                          {(prov) => (
                            <TableRow
                              id={row.id}
                              ref={prov.innerRef}
                              {...prov.draggableProps}
                              {...prov.dragHandleProps}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) => {
                                  handleSelect(event, {
                                    column: inOrOut, id: row.id, index,
                                  }, selected, setSelected);
                                }}
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            {attributes.map((attribute) => {
                              if (Array.isArray(attribute)) {
                                return (
                                  <TableCell key={attribute} align="left">
                                    {row[attribute[0]] ? row[attribute[0]][attribute[1]] : 'N/A'}
                                  </TableCell>
                                );
                              }

                              if (typeof row[attribute] === 'boolean') {
                                return (
                                  <TableCell key={attribute} align="left">
                                    {row[attribute] ? (
                                      <DoneIcon fontSize="small" />
                                    ) : (
                                      <ClearIcon fontSize="small" />
                                    )}
                                  </TableCell>
                                );
                              }

                              return (
                                <TableCell key={attribute} align="left">
                                  {row[attribute]}
                                </TableCell>
                              );
                            })}
                            <TableCell align="center">
                              {row.deleted ? (
                                <DoneIcon fontSize="small" />
                              ) : (
                                <ClearIcon fontSize="small" />
                              )}
                            </TableCell>
                          </TableRow>
                          )}
                        </Draggable>
                      );
                    })
                  )
                  }
                {provided.placeholder}
                </TableBody>
                )}
            </Droppable>
        </Table>
        </TableContainer>
        <Pagination
          setPage={setPage}
          page={page}
          rowsPerPage={rowsPerPage}
          quantity={quantityElements}
        />
      </>
    </Paper>
  );
}

export default TableGroupComponent;

import { getTickets } from '../requests/api/ticket';

const TICKETS_REQUEST = '@tickets/fetch-tickets';
const TICKETS_SUCCESS = '@tickets/fetch-success';
const TICKETS_ERROR = '@tickets/fetch-error';

const actionFetchPendingTickets = () => async (dispatch) => {
  try {
    dispatch({ type: TICKETS_REQUEST });

    const response = await getTickets(0, 100, 'waitingForApproval', 'state', false);
    if (response.success) {
      const { totalTickets } = response.data.data;
      dispatch({
        type: TICKETS_SUCCESS,
        payload: {
          pendingTickets: totalTickets,
        },
      });
    }
  } catch (error) {
    dispatch({ type: TICKETS_ERROR });
  }
};

export default {
  actionFetchPendingTickets,
  TICKETS_REQUEST,
  TICKETS_SUCCESS,
  TICKETS_ERROR,
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GroupsTable from './groupsTable';

// requests
import {
  editListing, getFilteredProductsInGroup, getFilteredProductsNotInGroup, getListingById,
} from '../../requests/api/listing';
import { getProductsPerPageByCompanyId } from '../../requests/api/product';

import { IN_THE_GROUP, NOT_IN_THE_GROUP } from '../../utils/const/groups';

function ListingTable({
  listingId,
  setGroupToView,
  create,
  groupData,
  setGroupData,
  groupInfo,
  setGroupInfo,
  elementType,
  inputValuesGroup,
  setInputValuesGroup,
  handleCreate,
  handleChangeTab,
  setCreate,
  selected,
  setSelected,
  onlyView,
}) {
  const { t } = useTranslation();

  // table states
  const rowsPerPage = 10;
  const [listingsPage, setListingsPage] = useState(0);
  const [productsPage, setProductsPage] = useState(0);

  // Filter states
  const [searchText, setSearchText] = useState({
    inTheGroup: '',
    notInTheGroup: '',
  });
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState('name');
  const [inOrOut, setInOrOut] = useState(null);

  // quantities
  const [productsInTheGroupQ, setProductsInTheGroupQ] = useState(0);
  const [productsNotInTheGroupQ, setProductsNotInTheGroupQ] = useState(0);
  const [dragChange, setDragChange] = useState(false);

  // backup all elements in the group without filters, only changes in drag and drop
  const [allElementsInTheGroup, setAllElementsInTheGroup] = useState([]);
  const [allElementsNotInTheGroup, setAllElementsNotInTheGroup] = useState([]);

  const tableHeaders = [
    {
      id: 'productName',
      label: t('products.columnNames.name'),
    },
    {
      id: 'distributor',
      label: t('products.columnNames.distributor'),
    },
    {
      id: 'category',
      label: t('products.columnNames.category'),
    },
    {
      id: 'size',
      label: t('products.columnNames.size'),
    },
    {
      id: 'deleted',
      label: t('products.columnNames.deleted'),
    },
  ];

  const attributes = ['productName', 'distributor', ['Category', 'name'], 'size'];

  const findListing = async () => {
    const response = await getListingById(listingId, listingsPage, productsPage, rowsPerPage);

    if (response.success) {
      setGroupData(response.data.data.listing.data);

      setGroupInfo({
        inTheGroup: response.data.data.listing.productsRows.data,
        notInTheGroup: response.data.data.products.rows,
      });

      setProductsInTheGroupQ(response.data.data.listing.productsRows.data.length);
      setProductsNotInTheGroupQ(response.data.data.products.count);
    }
  };

  const findProducts = async () => {
    const responseListing = await getListingById(
      listingId, listingsPage, productsPage, rowsPerPage,
    );
    const responseAllListing = await getListingById(
      listingId, listingsPage, productsPage, 10000000,
    );
    if (responseListing.success && responseAllListing.success) {
      const productsInGroup = responseListing.data.data.listing.productsRows.data;
      const allProductsInGroup = responseAllListing.data.data.listing.productsRows.data;
      const totalProductsInGroup = responseListing.data.data.listing.productsRows.count;
      const productsNotInGroup = responseListing.data.data.products.rows;
      const allProductsNotInGroup = responseAllListing.data.data.products.rows;
      const totalProductsNotInGroup = responseListing.data.data.products.count;
      setGroupInfo({
        inTheGroup: productsInGroup,
        notInTheGroup: productsNotInGroup,
      });
      setProductsInTheGroupQ(totalProductsInGroup);
      setProductsNotInTheGroupQ(totalProductsNotInGroup);
      setAllElementsInTheGroup(allProductsInGroup);
      setAllElementsNotInTheGroup(allProductsNotInGroup);
    } else {
      const response = await getProductsPerPageByCompanyId(productsPage, rowsPerPage, '', false, []);
      const responseAllProducts = await getProductsPerPageByCompanyId(productsPage, 100000000, '', false, []);
      if (response.success) {
        const { products } = response.data.data;
        setGroupInfo({
          ...groupInfo,
          notInTheGroup: products,
        });
        setProductsNotInTheGroupQ(response.data.data.totalProducts);
        setAllElementsNotInTheGroup(responseAllProducts.data.data.products);
      }
    }
  };

  const requestFilter = async (id, pageOfTable, limit, searchValue, columnName) => {
    let response;
    const idsNotInTheGroup = allElementsNotInTheGroup.map((user) => user.id);
    const idsInTheGroup = allElementsInTheGroup.map((user) => user.id);

    if (inOrOut === IN_THE_GROUP) {
      response = await getFilteredProductsInGroup(
        id, pageOfTable, limit, searchValue.inTheGroup, columnName, idsInTheGroup, idsNotInTheGroup,
      );
      if (response.success) {
        const { products } = response.data.data;
        const filteredProducts = products.filter(
          (product) => allElementsNotInTheGroup.find(
            (productNotInGroup) => productNotInGroup.id === product.id,
          ) === undefined && allElementsInTheGroup.find(
            (productInGroup) => productInGroup.id === product.id,
          ) !== undefined,
        );
        setGroupInfo({
          ...groupInfo,
          inTheGroup: filteredProducts,
        });
        if (searchValue.InTheGroup === '') {
          setProductsInTheGroupQ(allElementsInTheGroup.length);
        } else {
          setProductsInTheGroupQ(filteredProducts.length);
        }
        setProductsNotInTheGroupQ(allElementsNotInTheGroup.length);
      }
    } else {
      response = await getFilteredProductsNotInGroup(
        id, pageOfTable, limit, searchValue.notInTheGroup,
        columnName, idsInTheGroup, idsNotInTheGroup,
      );
      if (response.success) {
        const { products } = response.data.data;
        const filteredProducts = products.filter(
          (product) => allElementsInTheGroup.find(
            (productInGroup) => productInGroup.id === product.id,
          ) === undefined && allElementsNotInTheGroup.find(
            (productNotInGroup) => productNotInGroup.id === product.id,
          ) !== undefined,
        );
        setGroupInfo({
          ...groupInfo,
          notInTheGroup: filteredProducts,
        });
        if (searchValue.notInTheGroup === '') {
          setProductsNotInTheGroupQ(allElementsNotInTheGroup.length);
        } else {
          setProductsNotInTheGroupQ(filteredProducts.length);
        }
        setProductsInTheGroupQ(allElementsInTheGroup.length);
      }
    }
  };

  useEffect(() => {
    if (inOrOut) {
      const pageToLoad = inOrOut === IN_THE_GROUP ? listingsPage : productsPage;
      requestFilter(listingId, pageToLoad, rowsPerPage, searchText, column);
    } else {
      findProducts();
      setInOrOut(NOT_IN_THE_GROUP);
    }
  }, [
    filter,
    column,
    inOrOut,
    setGroupInfo,
    listingId,
    listingsPage,
    productsPage,
    dragChange,
  ]);

  useEffect(() => {
    setProductsPage(0);
    if (inOrOut) {
      const pageToLoad = 0;
      requestFilter(listingId, pageToLoad, rowsPerPage, searchText, column);
    }
  }, [
    searchText,
  ]);

  return (
    <>
      <GroupsTable
        tab={1}
        attributes={attributes}
        groupId={listingId}
        tableHeaders={tableHeaders}
        findGroup={findListing}
        findElements={findProducts}
        editFunction={editListing}
        quantityElementsIn={productsInTheGroupQ}
        quantityElementsOut={productsNotInTheGroupQ}
        groupData={groupData}
        groupInfo={groupInfo}
        setGroupInfo={setGroupInfo}
        groupsPage={listingsPage}
        setGroupsPage={setListingsPage}
        elementsPage={productsPage}
        setElementsPage={setProductsPage}
        elementType={elementType}
        create={create}
        inputValuesGroup={inputValuesGroup}
        setInputValuesGroup={setInputValuesGroup}
        setGroupToView={setGroupToView}
        handleCreate={handleCreate}
        handleChangeTab={handleChangeTab}
        setCreate={setCreate}
        selected={selected}
        setSelected={setSelected}
        setColumn={setColumn}
        searchText={searchText}
        setSearchText={setSearchText}
        setInOrOut={setInOrOut}
        setFilter={setFilter}
        onlyView={onlyView}
        setDragChange={setDragChange}
        dragChange={dragChange}
        allElementsIn={allElementsInTheGroup}
        setAllElementsIn={setAllElementsInTheGroup}
        allElementsOut={allElementsNotInTheGroup}
        setAllElementsOut={setAllElementsNotInTheGroup}
      />
    </>
  );
}

export default ListingTable;

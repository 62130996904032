import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: '35vw',
    paddingTop: '10px',
    // paddingBottom: '20px',
    // '& .MuiFormLabel-root': {
    //   color: '#808080',
    //   margin: '15px auto 8px',
    //   fontSize: '12px',
    // }
  },
  switch: {
    paddingLeft: '20px',
    paddingTop: '27px',
  },
  switchVerticalOption: {
    paddingTop: '0px',
    paddingBottom: '20px',
  },
  select: {
    paddingBottom: '33px',
  },
  requiresRevision: {
    color: theme.palette.primary.darkGray,
    textAlign: 'left',
    fontSize: '16px',
    fontFamily: 'Mulish',
    paddingLeft: '25px',
    paddingRight: '5px',
    paddingTop: '20px',
  },
  revisionPolicy: {
    textAlign: 'center',
  },
}));

export const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.primary.lightGray}`,
  },
  indicator: {
    backgroundColor: theme.palette.secondary.blue,
  },
}))(Tabs);

export const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: '140px',
    textTransform: 'none',
    padding: '0px 8px',
    color: theme.palette.primary.gray,
    '&$selected': {
      color: 'black',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default useStyles;

/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import FormData from 'form-data';
import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../utils/const/http';

const getLocationsPerPage = async (page, limit, searchValue, column, noDeleted) => {
  try {
    const { data } = await axios.get(`/api/locations?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}&noDeleted=${noDeleted}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getLocationsPerPageByCompanyId = async (page, limit, searchValue,
  column, idsInTheGroup, noDeleted, projectId) => {
  try {
    const { data } = await axios.get(`/api/locations?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}&noDeleted=${noDeleted}&projectId=${projectId}&idsInTheGroup=${idsInTheGroup}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getLocation = async (id) => {
  try {
    const { data } = await axios.get(`/api/locations/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postNewLocation = async (inputValues) => {
  try {
    const { data } = await axios.post('/api/locations', inputValues);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const putEditLocation = async (id, inputValues) => {
  try {
    const { data } = await axios.put(`/api/locations/${id}`, inputValues);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const deleteLocation = async (id) => {
  try {
    const { data } = await axios.delete(`/api/locations/${id}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const updateLocationChanges = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const { data } = await axios.post('/api/locations/update', formData,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      });

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const uploadLocationFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const { data } = await axios.post('/api/locations/upload', formData,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      });

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const putNewDistributionCenter = async (locationId) => {
  try {
    const { data } = await axios.put(`/api/locations/distribution-center/${locationId}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

export {
  getLocationsPerPage,
  getLocationsPerPageByCompanyId,
  postNewLocation,
  getLocation,
  putEditLocation,
  deleteLocation,
  updateLocationChanges,
  uploadLocationFile,
  putNewDistributionCenter,
};

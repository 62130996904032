import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  verificationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '50px',
    minHeight: '100vh',
    '& h3': {
      color: theme.palette.secondary.silver,
      fontWeight: '400',
      marginTop: '60px',
    },
    '& h5': {
      color: theme.palette.secondary.silver,
      fontWeight: '400',
    },
    '& img': {
      display: 'block',
      margin: 'auto',
    },
    '& .MuiTextField-root': {
      marginBottom: '10px',
    },
  },
  verification: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '280px',
  },
  checkbox: {
    color: 'white',
    '& .MuiIconButton-root': {
      color: 'white',
    },
    '& span': {
      fontSize: '13px',
      fontWeight: '100',
    },
  },
  image: {
    maxWidth: '280px',
    maxHeight: '100%',
  },
  buttonsPDFViewer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
}));

export default useStyles;

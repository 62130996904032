/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import accountAction from '../actions/account';

const initialState = {
  user: null,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountAction.LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case accountAction.LOGIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case accountAction.LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case accountAction.LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case accountAction.SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
// core
import {
  Toolbar,
  Typography,
  Tooltip,
  TextField,
  Button as MuiButton,
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Grid,
} from '@material-ui/core';
// icons
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';

import Modal from '../Modal';
import Button from '../Button';

import ProductInformation from '../../views/Products/ProductInformation';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  toolbarTable: {
    '& .MuiOutlinedInput-root': {
      height: '35px',
      fontSize: '13px',
      width: '350px',
    },
    '& .Mui-focused': {
      border: `1px solid ${theme.palette.secondary.gray}`,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.gray,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '2px',
    },
    '& button': {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.secondary.gray,
      fontSize: '13.5px',
      '& svg': {
        color: theme.palette.secondary.gray,
      },
    },
    '& .MuiMenuItem-root': {
      color: 'black',
    },
  },
  popper: {
    zIndex: 1,
    width: '110px',
    '& ul': {
      paddingBottom: '0px',
    },
  },
  titleOption: {
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
  },
  roleOption: {
    fontSize: '12px',
  },
  activeOption: {
    fontSize: '13.5px',
  },
  clearOption: {
    fontSize: '13.5px',
    borderTop: `1px solid ${theme.palette.secondary.gray}`,
  },
}));

function TableToolbar({
  filterButton,
  filterOptions,
  filterPlaceholder,
  info,
  handleEditShow,
  handleDelete,
  open,
  elementId,
  numSelected,
  searchBar,
  searchText,
  setFilterOptions,
  setOpen,
  component,
  createModalTitle,
  buttonTitle,
  disabledEditDelete,
  table,
  getExcel,
  isProduct,
  page,
  rowsPerPage,
  filter,
  column,
  setUpdate,
}) {
  const styles = useStyles();
  const anchorRef = useRef(null);
  const { t } = useTranslation();

  const [openCreate, setOpenCreate] = useState(false);

  // Form create
  const createForm = {
    product: <ProductInformation setOpenCreate={setOpenCreate} setUpdate={setUpdate} />,
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCreate = () => {
    setOpenCreate(true);
  };

  // Filters the data in the deleted column
  const handleSelectFilter = (event) => {
    const { id } = event.target;
    // eslint-disable-next-line no-shadow
    let column;

    let searchValue;
    if (id === 'deleted' || id === 'active') {
      // Active filter
      searchValue = id === 'deleted' ? 'true' : 'false';
      column = 'deleted';
    } else if (id === 'clear') {
      // Clear filters
      searchValue = '';
      column = false;
    }
    setFilterOptions(searchValue, column);

    handleClose(event);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Toolbar className={styles.toolbarTable}>
      <Typography
        className={styles.title}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {filterButton && (
          <MuiButton
            id="filter-button"
            ref={anchorRef}
            aria-controls={open ? 'menu-list' : undefined}
            aria-haspopup="true"
            variant="contained"
            onClick={handleToggle}
          >
            <svg style={{ width: '20px', height: '20px' }} viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M14,12V19.88C14.04,20.18 13.94,20.5 13.71,20.71C13.32,21.1 12.69,21.1 12.3,20.71L10.29,18.7C10.06,18.47 9.96,18.16 10,17.87V12H9.97L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L14.03,12H14Z"
              />
            </svg>
            {t('tableToolbar.button')}
          </MuiButton>
        )}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          className={styles.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list">
                    {info.map((i) => (
                      <>
                        <MenuItem disabled className={styles.titleOption}>
                          {i.titleOption}
                        </MenuItem>
                        {i.options.map((option) => (
                          <MenuItem
                            key={option.id}
                            id={option.id}
                            onClick={handleSelectFilter}
                            className={
                              option.className && styles[option.className]
                            }
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </>
                    ))}
                    <MenuItem
                      id="clear"
                      onClick={handleSelectFilter}
                      className={styles.clearOption}
                    >
                      {t('tableToolbar.clear')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {searchBar && <TextField
          id="search"
          variant="outlined"
          value={searchText}
          onChange={(event) => setFilterOptions(event.target.value, filterOptions)
          }
          placeholder={filterPlaceholder}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                id="clear"
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: searchText ? 'visible' : 'hidden' }}
                onClick={() => setFilterOptions('', false)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />}
      </Typography>
      {numSelected === 1 ? (
        <Tooltip title="Edit">
          <IconButton
            id={elementId}
            aria-label="edit"
            onClick={() => handleEditShow(elementId, true)}
            disabled={disabledEditDelete}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : null}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            id={elementId}
            aria-label="delete"
            onClick={handleDelete}
            disabled={disabledEditDelete}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
        {table ? null : (
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
              id="create-btn"
              variant="outlined"
              text={buttonTitle}
              height="40px"
              width="150px"
              backgroundColor='theme-secondary-blue'
              color='white'
              borderRadius='10px'
              onClick={handleCreate}
              />
            </Grid>
            <Grid item>
              {isProduct ? <Button
              id="get-excel"
              text={t('excel.getExcel')}
              height="40px"
              width="130px"
              backgroundColor='theme-secondary-blue'
              color='theme-primary-white'
              borderRadius='10px'
              onClick={ (event) => {
                getExcel(event, page, rowsPerPage, filter, column);
              }}
              /> : null }
            </Grid>
          </Grid>

        )}
        {openCreate ? (
          <Modal
            title={createModalTitle}
            defaultOpen={openCreate}
            setCurrentOpen={setOpenCreate}
          >
            {createForm[component]}
          </Modal>
        ) : null}
    </Toolbar>
  );
}

export default TableToolbar;

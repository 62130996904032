import React from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Toolbar,
  Grid,
} from '@material-ui/core';
// icons
import Button from '../../../components/Button';

import useStyles from './styles';

// Filters area, table header
export default function TableToolbar({ taskResponse }) {
  const styles = useStyles();
  const { t } = useTranslation();

  const handleContinueToTeleaudit = async () => {
    window.location.href = `/teleaudit/${taskResponse || ''}`;
  };

  return (
    <>
      <Toolbar className={styles.toolbarTable}>
        <Grid container justifyContent="flex-end">
          <Button
            id="create-user-btn"
            text={t('qa.taskResponseView.continueToTeleaudit')}
            variant="contained"
            height="40px"
            width="250px"
            backgroundColor='#2C9DC9'
            color='white'
            borderRadius='10px'
            align='right'
            onClick = {handleContinueToTeleaudit}
          />
        </Grid>
      </Toolbar>
      </>
  );
}

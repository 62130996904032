import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Toolbar,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  Button,
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  MenuList,
} from '@material-ui/core';
// icons
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';

import Modal from '../../components/Modal';
import LocationForm from './locationForm';

import useStyles from './styles';

// Filters area, table header
export default function TableToolbar({
  openCreateModal, setOpenCreateModal, handleEdit, handleWatch, handleDelete, open, locationId,
  numSelected, searchText, setFilterOptions, setOpen,
}) {
  const styles = useStyles();
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // Filters the data in the deleted column
  const handleSelectFilter = (event) => {
    const { id } = event.target;
    let column;

    let searchValue;
    if (id === 'deleted' || id === 'active') {
      // Active filter
      searchValue = id === 'deleted' ? 'true' : 'false';
      column = 'deleted';
    } else if (id === 'clear') {
      // Clear filters
      searchValue = '';
      column = false;
    }
    setFilterOptions(searchValue, column);

    handleClose(event);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Toolbar className={styles.toolbarTable}>
      <Typography
        className={styles.title}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        <Button
          id="filter-button"
          ref={anchorRef}
          aria-controls={open ? 'menu-list' : undefined}
          aria-haspopup="true"
          variant="contained"
          onClick={handleToggle}
        >
          <svg style={{ width: '20px', height: '20px' }} viewBox="0 0 24 24">
            <path fill="currentColor" d="M14,12V19.88C14.04,20.18 13.94,20.5 13.71,20.71C13.32,21.1 12.69,21.1 12.3,20.71L10.29,18.7C10.06,18.47 9.96,18.16 10,17.87V12H9.97L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L14.03,12H14Z" />
          </svg>
          {t('locations.tableToolbar.button')}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          className={styles.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list">
                    <MenuItem disabled className={styles.titleOption}>{t('locations.tableToolbar.deleted')}</MenuItem>
                    <MenuItem
                      id="deleted"
                      onClick={handleSelectFilter}
                      className={styles.activeOption}
                    >
                      {t('locations.tableToolbar.deleted')}
                    </MenuItem>
                    <MenuItem
                      id="active"
                      onClick={handleSelectFilter}
                      className={styles.activeOption}
                    >
                      {t('locations.tableToolbar.active')}
                    </MenuItem>
                    <MenuItem
                      id="clear"
                      onClick={handleSelectFilter}
                      className={styles.clearOption}
                    >
                      {t('locations.tableToolbar.clear')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <TextField
          id="search"
          variant="outlined"
          value={searchText}
          onChange={(event) => setFilterOptions(event.target.value, ['id', 'name', 'chain', 'internal_code'])}
          placeholder={t('locations.tableToolbar.filterPlaceholder')}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                id="clear"
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: searchText ? 'visible' : 'hidden' }}
                onClick={() => setFilterOptions('', false)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Typography>
      {numSelected === 1 ? (
        <>
          <Tooltip title="See">
            <IconButton id={locationId} aria-label="edit" onClick={handleWatch}>
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton id={locationId} aria-label="edit" onClick={handleEdit}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ) : null}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      <Button
        id="add-location-projects"
        onClick={() => { window.location.href = '/location-projects'; }}
        className={styles.button}
      >
        {t('locations.addLocationProjects')}
      </Button>
      <Button
        id="add-location-users"
        onClick={() => { window.location.href = '/location-users'; }}
        className={styles.button}
      >
        {t('locations.addLocationUsers')}
      </Button>
      <Modal title={t('locations.locationForm.modalTitle')} titleButton={t('locations.addButton')} defaultOpen={openCreateModal} setOpenEditModal={setOpenCreateModal}>
        <LocationForm />
      </Modal>
    </Toolbar>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Button as MuiButton } from '@material-ui/core';

import getColor from '../../utils/color';

const useStyles = makeStyles(
  (theme) => ({
    buttonContainer: {
      '& .MuiButton-root': {
        textTransform: 'none',
        padding: '0 5px',
        lineHeight: '16px',
      },
      '& .MuiButtonBase-root': {
        backgroundColor: (props) => getColor(theme, props.backgroundColor),
        width: (props) => props.width,
        height: (props) => props.height,
        marginBottom: '10px',
        color: (props) => getColor(theme, props.color),
        borderRadius: (props) => props.borderRadius,
        borderColor: (props) => getColor(theme, props.borderColor),
        fontWeight: (props) => props.weight,
      },
      '& button:hover': {
        opacity: '0.8',
        backgroundColor: (props) => getColor(theme, props.backgroundColor),
      },
    },
  }),
);

function Button({
  id,
  onClick,
  text,
  disabled,
  variant,
  component,
  to,
  children,
  ...props
}) {
  const styles = useStyles(props);

  return (
    <div className={styles.buttonContainer}>
      <MuiButton
        variant = {variant}
        id={id}
        onClick={onClick}
        disabled={disabled}
        startIcon={children}
        component={component}
        to={to}
      >
        <span
          id={id}
        >
          {text}
        </span>
      </MuiButton>
    </div>
  );
}

Button.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.string,
  borderColor: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  id: '',
  variant: '',
  text: '',
  width: '100px',
  height: '30px',
  backgroundColor: 'theme-secondary-silver',
  color: 'theme-primary-black',
  borderColor: 'theme-secondary-blue',
  borderRadius: '5px',
  disabled: false,
  startIcon: false,
};

export default Button;

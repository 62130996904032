import React, { useState, useEffect } from 'react';
import axiosOriginal from 'axios';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Avatar,
  Divider,
} from '@material-ui/core';

import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '../../../components/Button';
import { updateTicketInfo } from '../../../requests/api/ticket';

import bytesToSize from '../../../utils/bytesToSize';
import { parseDate } from '../../../utils/date';
import useStyles from './styles';

function InformationCard({
  data, projects, reload, setMessage, stateBadge, sendResponse,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [canApprove, setCanApprove] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [inputValues, setInputValues] = useState({
    id: data.id,
    subject: data.subject,
    content: data.content,
    category: data.category,
    subcategory: data.subcategory,
    challengeResponse: data.challengeResponse,
  });

  const handleDownload = (fileUrl) => {
    if (fileUrl) {
      window.open(fileUrl);
    }
  };

  useEffect(() => {
    for (let i = 0; i < projects.length; i += 1) {
      if (projects[i].projectId === data.projectId && projects[i].role === 'qa') {
        setCanApprove(true);
      }
    }
  }, [data, projects]);

  const handleRemoveAll = () => {
    setFile(null);
  };

  const handleSelect = ({ target }) => {
    setFile(target.files[0]);
  };

  const handleUpload = async () => {
    setLoading(true);
    const fileParts = file.name.split('.');
    const timestamp = Date.now();
    const fileName = `${fileParts[0]} ${timestamp}`;
    const uploadData = {
      fileName,
      fileType: file.type,
    };
    const response = await axiosOriginal.post('/api/ticket/signS3', uploadData);
    const options = {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': 'attachment',
      },
    };
    const { returnData } = response.data.data;
    const { signedRequest } = returnData;
    try {
      const uploadResponse = await axiosOriginal.put(
        signedRequest,
        file,
        options,
      );
      if (uploadResponse.status === 200) {
        const fileUrl = uploadResponse.config.url.split('?')[0];
        setInputValues({
          ...inputValues,
          fileUrl,
        });
        setLoading(false);
        return (fileUrl);
      }
    } catch (err) {
      setMessage({ type: 'error', text: err });
      return (err);
    }
    return (false);
  };

  const handleClose = () => {
    reload();
  };

  const handleSend = async () => {
    let response;
    if ((Object.values(inputValues).some((value) => value === ''))) {
      return;
    }

    if (file) {
      const url = await handleUpload();
      if (url) {
        response = await updateTicketInfo({
          id: inputValues.id,
          subject: inputValues.subject,
          content: inputValues.content,
          category: inputValues.category,
          subcategory: inputValues.subcategory,
          challengesResponseFile: url,
        });
      }
    }

    if (response !== undefined && response.success) {
      setMessage({ type: 'success', text: t('challenges.uploadFileSuccess') });
      handleClose();
      setInputValues({
        subject: '',
        content: '',
        category: '',
        subcategory: '',
      });
      sendResponse(response);
    } else {
      handleClose();
    }
  };

  const uploaderRender = () => {
    if (file) {
      return (
        <Grid
        container
        item
        justifyContent='flex-end'
        direction='row'
        xs={12}
        >
          <Grid
          container
          item
          justifyContent='flex-end'
          direction='row'
          alignItems='center'
          xs={8}
          >
            <Grid item xs={12}>
              <Typography variant="body1">
                {file.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {bytesToSize(file.size)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs>
          <div className={styles.actions}>
            {loading ? (<CircularProgress />)
              : <ClearIcon onClick={handleRemoveAll} className={styles.uploadIcon} fontSize='large'/>
            }
          </div>
          </Grid>
        </Grid>
      );
    } return (
            <Grid
            container
            item
            justifyContent='flex-start'
            direction='row-reverse'
            xs={12}
            >
              <input
                className={styles.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleSelect}
              />
              <label className={styles.uploadIcon} htmlFor="contained-button-file">
                      <CloudUploadIcon fontSize='large'/>
              </label>
         </Grid>
    );
  };

  return (
    <Card className={styles.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="ticket" className={styles.avatar}>
              {data.User.full_name ? data.User.full_name[0] : '?'}
          </Avatar>
        }
        action={
          stateBadge
      }
        title={data.subject}
        subheader= {[t('support.ticketDetail.createdAt'), parseDate(data.createdAt), t('support.ticketDetail.by'), data.User.full_name].join('')}
      />
      <CardContent>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.ticketId')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.crmTicketId}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.description')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.content}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.comments')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.challengeResponse ? data.challengeResponse : t('support.ticketDetail.noComments')}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        {data.fileUrl
          ? <>
          <br/>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="h6" align="left" color="textSecondary" component="p">
              {t('challenges.downloadFileAskingForChallenges')}
            </Typography>
              <IconButton align='right' className={styles.icon} onClick={() => handleDownload(data.fileUrl)} disabled={data.fileUrl === null}>
                  <CloudDownloadRoundedIcon fontSize="large"/>
                </IconButton>
          </Grid>
          <br/>
          <Divider variant="middle"/>
          <br/>
          </>
          : ''
        }
        {data.challengesResponseFile
          ? <>
          <br/>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="h6" align="left" color="textSecondary" component="p">
            {t('challenges.downloadFileAsnwerChallenges')}
            </Typography>
              <IconButton align='right' className={styles.icon} onClick={() => handleDownload(data.challengesResponseFile)} disabled={data.challengesResponseFile === null}>
                  <CloudDownloadRoundedIcon fontSize="large"/>
                </IconButton>
          </Grid>
          <br/>
          <Divider variant="middle"/>
          <br/>
          </>
          : ''
        }
        {canApprove
          ? <>
              <Grid container alignItems="center" justifyContent="space-between">
                <Typography variant="h6" align="left" color="textSecondary" component="p">
                  {t('challenges.uploadFileAsnwerChallenges')}
                </Typography>
                <Grid item xs= {2}>
                  {uploaderRender()}
                </Grid>
              </Grid>
              <br></br>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs= {4}>
                </Grid>
                <Grid item xs= {8}>
                  <Button
                    id="submit-ticket-btn"
                    onClick={handleSend}
                    text={t('challenges.uploadFileAsnwerChallenges')}
                    height="35px"
                    width="100%"
                    backgroundColor="#2C9DC9"
                    color="#FFFFFF"
                    borderRadius="10px"
                  />
                </Grid>
              </Grid>
          </>
          : ''
        }
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ projects: state.projects.projects });

export default connect(mapStateToProps)(InformationCard);

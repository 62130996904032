import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import Home from './views/Home';
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import Support from './views/Support';
import Challenges from './views/Challenges';
import ReportsList from './views/Reports/ReportsList';
import ReportView from './views/Reports/ReportView';
import Users from './views/Users';
import AuthGuard from './components/Auth/AuthGuard';
import AuthGuardAdmin from './components/Auth/AuthGuardAdmin';
import Layout from './components/Layout';
import WalletList from './views/Wallet/WalletList';
import WalletDetail from './views/Wallet/WalletDetail';
import ResetPassword from './views/ForgottenPassword/resetPassword';
import RecoverPassword from './views/ForgottenPassword/recoverPassword';
import Terms from './views/Terms';
import Gantt from './views/Gantt';
import Register from './views/Register';
import UserVerification from './views/UserVerification';
import Documents from './views/Documents';
import Locations from './views/Locations';
import Products from './views/Products';
import Surveys from './views/Surveys';
import ProductsImportView from './views/ProductsImportView';
import LocationsImportView from './views/LocationsImportView';
import Groups from './views/Groups';
import Categories from './views/Categories';
import UserDetail from './views/Users/userDetailView';
import Tasks from './views/Tasks';
import Administration from './views/Administration';
import Teleaudit from './views/Teleaudit/ImageTeleaudit';
import QA from './views/QA';
import SurveyResponsesView from './views/QA/surveyResponsesView';
import QuestionResponsesView from './views/QA/questionResponsesView';
import PreTeleaudit from './views/Teleaudit';
import SurveyResponsesTeleauditView from './views/Teleaudit/surveyResponsesView';
import UserReportView from './views/Users/userReportView';
import ProductLocations from './views/ProductLocationsImportView';
import StockAndSales from './views/StockAndSalesImportView';
import UserProjectsImportView from './views/UserProjectsImportView';
import LocationProjectsImportView from './views/LocationProjectsImportView';
import LocationUsersImportView from './views/LocationUsersImportView';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Home />,
  },
  {
    id: 2,
    exact: true,
    path: '/login',
    component: () => <Login />,
  },
  {
    id: 3,
    exact: true,
    path: '/recover-password',
    component: () => <RecoverPassword />,
  },
  {
    id: 4,
    exact: true,
    path: '/reset-password',
    component: () => <ResetPassword />,
  },
  {
    id: 5,
    exact: true,
    path: '/user-verification',
    component: () => <UserVerification />,
  },
  {
    id: 100,
    path: '/',
    guard: AuthGuard,
    layout: Layout,
    routes: [
      {
        id: 101,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/home',
        component: () => <Dashboard />,
      },
      {
        id: 102,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/support',
        component: () => <Support />,
      },
      {
        id: 103,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/users',
        component: () => <Users />,
      },
      {
        id: 104,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/reports',
        component: () => <ReportsList />,
      },
      {
        id: 105,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/reports/:id',
        component: () => <ReportView />,
      },
      {
        id: 106,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/wallet',
        component: () => <WalletList />,
      },
      {
        id: 107,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/tyc',
        component: () => <Terms />,
      },
      {
        id: 108,
        exact: true,
        path: '/wallet/:id',
        component: () => <WalletDetail />,
      },
      {
        id: 109,
        exact: true,
        path: '/register',
        component: () => <Register />,
      },
      {
        id: 110,
        exact: true,
        path: '/gantts',
        component: () => <Gantt />,
      },
      {
        id: 111,
        exact: true,
        path: '/documents',
        component: () => <Documents />,
      },
      {
        id: 112,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/locations',
        component: () => <Locations />,
      },
      {
        id: 113,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/products',
        component: () => <Products />,
      },
      {
        id: 114,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/upload-products',
        component: () => <ProductsImportView />,
      },
      {
        id: 115,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/upload-locations',
        component: () => <LocationsImportView />,
      },
      {
        id: 116,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/surveys',
        component: () => <Surveys />,
      },
      {
        id: 117,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/groups',
        component: () => <Groups />,
      },
      {
        id: 118,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/categories',
        component: () => <Categories />,
      },
      {
        id: 119,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/users/:id',
        component: () => <UserDetail />,
      },
      {
        id: 120,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/tasks',
        component: () => <Tasks />,
      },
      {
        id: 121,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/administration',
        component: () => <Administration />,
      },
      {
        id: 122,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/qa',
        component: () => <QA />,
      },
      {
        id: 123,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/qa/:taskResponseId',
        component: () => <SurveyResponsesView />,
      },
      {
        id: 124,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/qa/:id/:surveyResponseId',
        component: () => <QuestionResponsesView />,
      },
      {
        id: 125,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/challenges',
        component: () => <Challenges />,
      },
      {
        id: 126,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/users/:userId/report/:reportId',
        component: () => <UserReportView />,
      },
      {
        id: 127,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/teleaudit',
        component: () => <PreTeleaudit />,
      },
      {
        id: 128,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/teleaudit/:taskResponseId',
        component: () => <SurveyResponsesTeleauditView />,
      },
      {
        id: 129,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/teleaudit/:taskResponseId/:questionResponseId',
        component: () => <Teleaudit />,
      },
      {
        id: 130,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/upload-product-locations',
        component: () => <ProductLocations />,
      },
      {
        id: 131,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/upload-stock-and-sales-information',
        component: () => <StockAndSales />,
      },
      {
        id: 132,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/user-projects',
        component: () => <UserProjectsImportView />,
      },
      {
        id: 133,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/location-projects',
        component: () => <LocationProjectsImportView />,
      },
      {
        id: 133,
        exact: true,
        guard: AuthGuardAdmin,
        path: '/location-users',
        component: () => <LocationUsersImportView />,
      },
    ],
  },
];

const renderRoutes = (routes) => (routes ? (
    <Switch>
      {routes.map((route) => {
        const Guard = route.guard || Fragment;
        const DefaultLayout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={route.id}
            path={route.path}
            exact={route.exact}
            render={() => (
              <Guard>
                <DefaultLayout>
                  {route.routes ? renderRoutes(route.routes) : <Component />}
                </DefaultLayout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;

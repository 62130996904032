import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField as MuiTextField,
  Paper,
  CircularProgress,
  Box,
  IconButton,
  Toolbar,
  InputLabel,
  Grid,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
// icons
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import TicketForm from './ticketEditForm';

// components
import useStyles from './styles';
import { getTickets } from '../../../requests/api/ticket';
import Modal from '../../../components/Modal';
import InformationCard from './informationCard';
import Snackbar from '../../../components/Snackbar';
import TableToolbar from './TableToolbar';
import Pagination from '../../../components/Pagination';
import projectActions from '../../../actions/projects';
// import UserInformation from './UserInformation';
// const
import { UNAUTHORIZED } from '../../../utils/const/http';
import { parseDate } from '../../../utils/date';

// eslint-disable-next-line no-shadow
const SupportTickets = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // table states
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [ticketsQuantity, setTicketsQuantity] = useState(0);

  // auxiliar states

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');
  const [open, setOpen] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const account = useSelector((state) => state.account);

  const location = useLocation();

  // Filter states
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState(location.state || '');
  const [column, setColumn] = useState(location.column || false);
  // Edit state
  const [openUpdateTicket, setOpenUpdateTicket] = useState(false);
  const [currentTicket, setCurrentTicket] = useState({
    state: '',
    id: '',
    subject: '',
    content: '',
    category: '',
    subcategory: '',
    pipelineName: '',
  });

  const tableHeaders = [
    {
      id: 'project',
      label: <b>{t('support.columnNames.project')}</b>,
    },
    {
      id: 'title',
      label: <b>{t('support.columnNames.title')}</b>,
    },
    {
      id: 'created_at',
      label: <b>{t('support.columnNames.createdAt')}</b>,
    },
    {
      id: 'estimated_closing',
      label: <b>{t('support.columnNames.estimatedClosing')}</b>,
    },
    {
      id: 'state',
      label: <b>{t('support.columnNames.status')}</b>,
    },
    {
      id: 'closed_at',
      label: <b>{t('support.columnNames.closedAt')}</b>,
    },
    {
      id: 'details',
      label: <b>{t('support.columnNames.details')}</b>,
    },
    {
      id: 'edit',
      label: <b>{t('support.columnNames.edit')}</b>,
    },
  ];
  const subtitleState = [
    'open',
    'evaluation',
    'quoted',
    'waitingForApproval',
    'approved',
    'processing',
    'close',
    'rejected',
  ];

  const company = useSelector((state) => state.company);

  const requestSearch = async (pageOfTable, limit, searchValue, columnName) => {
    const response = await getTickets(pageOfTable, limit, searchValue,
      columnName, false, sortBy, orderBy, false);
    if (response.success) {
      setRowsToShow(response.data.data.tickets);
      setTicketsQuantity(response.data.data.totalTickets);
    }
    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const setFilterOptions = (newValue, tableColumn) => {
    if (Array.isArray(tableColumn)) {
      setSearchText(newValue);
    } else {
      setSearchText('');
    }
    setColumn(tableColumn);
    setFilter(newValue);
    setPage(0);
    // Clear filters
    if (!tableColumn) {
      setSearchText('');
      setFilter('');
    }
  };

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const handleFilter = (event) => {
    const {
      target: { value },
    } = event;
    if (value === 'createdAt' || value === 'closed_date') {
      setSortBy(value);
    } else if (value === 'ASC' || value === 'DESC') {
      setOrderBy(value);
    }
  };

  const sendResponseHandler = async (response) => {
    if (response.success) {
      requestSearch(page, rowsPerPage, filter, column);
      setSucc(t('support.ticketCreated'));
    } else {
      setErr(t('support.serverError'));
    }
  };

  //
  const handleTicketSuccessfullModal = async (message) => {
    requestSearch(page, rowsPerPage, filter, column);
    setSucc(message);
  };

  //
  const handleTicketFailModal = async (message) => {
    requestSearch(page, rowsPerPage, filter, column);
    setErr(message);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const reload = async () => {
    load();
    return requestSearch(page, rowsPerPage);
  };

  const handleUpdateTicket = (row) => {
    setCurrentTicket({
      state: row.state,
      id: row.id,
      subject: row.subject,
      content: row.content,
      category: row.category,
      subcategory: row.subcategory,
      pipelineName: row.pipelineName,
    });
    setOpenUpdateTicket(true);
  };

  const handleState = (state) => {
    switch (state) {
      case 'open':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagOpen}`}><b>- {t('support.open')}</b></p>
        );
      case 'evaluation':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagProcessing}`}><b>O {t('support.evaluation')}</b></p>
        );
      case 'quoted':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagProcessing}`}><b>O {t('support.quoted')}</b></p>
        );
      case 'approved':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagApproved}`}><b>O {t('support.approved')}</b></p>
        );
      case 'processing':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagProcessing}`}><b>O {t('support.processing')}</b></p>
        );
      case 'waitingForApproval':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagWaitinfForApproval}`}><b>! {t('support.waitingForApproval')}</b></p>
        );
      case 'finished':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagApproved}`}><b>O {t('support.finished')}</b></p>
        );
      case 'objected':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagApproved}`}><b>O {t('support.objected')}</b></p>
        );
      case 'adjust':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagApproved}`}><b>O {t('support.adjust')}</b></p>
        );
      case 'rejected':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagRejected}`}><b>X {t('support.rejected')}</b></p>
        );
      case 'close':
        return (
          <p align="center" className={`${styles.tag} ${styles.tagClose}`}><b>✔ {t('support.close')}</b></p>
        );
      default:
        return (
          <p align="center" className={`${styles.tag} ${styles.tagNA}`}>O <b>N/A</b></p>
        );
    }
  };

  useEffect(() => {
    load();
  }, [rowsToShow]);

  useEffect(() => {
    const getData = async () => {
      await dispatch(projectActions.actionFetchProjects());
      requestSearch(page, rowsPerPage, filter, column);
    };
    getData();
  }, [page, rowsPerPage, filter, column, ticketsQuantity,
    account, dispatch, company, sortBy, orderBy]);

  const content = () => {
    if (!ticketsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={9} className={styles.users}>
            <ConfirmationNumberIcon />
            <h4>{t('support.noTickets')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row) => (
            <TableRow
              hover
              key={row.id}
            >
              <TableCell
                align="center"
                component="th"
                scope="row"
                padding="none"
                className={styles.projectName}
              >{row.Project.name}</TableCell>
              <TableCell align="center" width="10%">{row.subject}</TableCell>
              <TableCell align="center">
                {parseDate(row.createdAt)}
              </TableCell>
              <TableCell align="center">
                {row.estimatedClosingDate ? parseDate(row.estimatedClosingDate)
                  : <p align="center" className={`${styles.tag} ${styles.tagPendingDate}`}>O <b>{t('support.pending')}</b></p>

                }
              </TableCell>
              <TableCell align="center">
                {handleState(row.state)}
              </TableCell>
              <TableCell align="center">
                {row.closedDate ? parseDate(row.closedDate)
                  : <p align="center" className={`${styles.tag} ${styles.tagPendingDate}`}>O <b>{t('support.pending')}</b></p>

                }
              </TableCell>
              <TableCell align="center" className={styles.iconsCell}>
                <Modal view={true}>
                  <InformationCard
                    ticketSuccessfullModal={handleTicketSuccessfullModal}
                    ticketFailModal={handleTicketFailModal}
                    stateBadge={handleState(row.state)}
                    data={row}
                  />
                </Modal>
              </TableCell>
              <TableCell align="center">
                <IconButton
                  onClick={() => handleUpdateTicket(row)}
                  disabled={!['open', 'waitingForApproval'].includes(row.state)}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        {openUpdateTicket ? (
          <Modal title={t('support.editTicket')} defaultOpen={openUpdateTicket} setCurrentOpen={setOpenUpdateTicket}>
            <TicketForm
              setCurrentOpen={setOpenUpdateTicket}
              currentSelection={currentTicket}
              setSucc={setSucc}
              setError={setErr}
              reload={reload}
              update={true}
              sendResponse={sendResponseHandler}
            />
          </Modal>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className={styles.header}>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h1' className={styles.title} gutterBottom>
            {t('support.title')}
          </Typography>
          <Typography variant='h4' gutterBottom display="inline">
            {t('support.subtitle')}
          </Typography>
          <Typography variant='subtitle1' className={styles.underlined} gutterBottom display="inline">
            {ticketsQuantity} tickets {subtitleState.includes(filter) ? t(`support.subtitleState.${filter}`) : ''}
          </Typography>
        </Box>
      </div>
      <Toolbar className={styles.searchToolbar} position='fixed' disableGutters>
        <Box className={styles.boxTitle}>
          <MuiTextField
            className={styles.searchField}
            id="search"
            variant="outlined"
            value={searchText}
            onChange={(event) => setFilterOptions(event.target.value, ['crmTicketId', 'subject'])}
            placeholder={t('support.tableToolbar.filterPlaceholder')}
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
              endAdornment: (
                <IconButton
                  id="clear"
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: searchText ? 'visible' : 'hidden' }}
                  onClick={() => setFilterOptions('', false)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Box>

      </Toolbar>
      <div className={styles.formWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl className={styles.formControl}>
              <InputLabel id="demo-simple-select-label">{t('support.filterBy')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sortBy}
                onChange={handleFilter}
              >
                <MenuItem key={'createdAt'} value={'createdAt'}>
                  {t('support.columnNames.createdAt')}
                </MenuItem>
                <MenuItem key={'closed_date'} value={'closed_date'}>
                  {t('support.columnNames.closedAt')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={styles.formControl}>
              <InputLabel id="demo-simple-select-label">{t('support.sortBy')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={orderBy}
                onChange={handleFilter}
              >
                <MenuItem key={'ASC'} value={'ASC'}>
                  {t('support.oldest')}
                </MenuItem>
                <MenuItem key={'DESC'} value={'DESC'}>
                  {t('support.mostRecent')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

      </div>
      <Paper className={styles.supportTable}>
        <>
          <TableToolbar
            open={open}
            setOpen={setOpen}
            setFilterOptions={setFilterOptions}
            sendResponse={sendResponseHandler}
          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  {tableHeaders.map((tableHeader) => (
                    <TableCell
                      key={tableHeader.id}
                      className={styles.tableHeader}
                      align={'center'}
                      padding="normal"
                    >
                      {tableHeader.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={9} className={styles.progress}>
                      <CircularProgress />
                      <h4>{t('support.loading')}</h4>
                    </TableCell>
                  </TableRow>
                ) : content()}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            setPage={setPage}
            handleChangePage={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            quantity={ticketsQuantity}
          />
        </>
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </>
  );
};

export default SupportTickets;

/* eslint-disable no-param-reassign */
import jwtDecode from 'jwt-decode';
import {
  postLogin, postRecoverPassword, updatePassword, postRegister, userVerification,
} from '../requests/api/auth';
import axios from '../utils/axios';
import {
  UNKNOWN_ERROR,

} from '../utils/const/errors';

const getAccessToken = () => localStorage.getItem('accessToken');

const setSession = async (accessToken, companyId) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }

  if (companyId) {
    axios.defaults.params = {};
    axios.defaults.params.companyId = companyId;
  }
};

const setAxiosInterceptors = ({ onLogout }) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        if (onLogout) {
          setSession(null, null);
          onLogout();
        }
      }

      return Promise.reject(error);
    },
  );
};

// eslint-disable-next-line no-unused-vars
const login = async (email, password, remember) => {
  const ipAddress = 'No user ip';

  // Temporarily disabled because the service appears to be unresponsive or disabled
  // try {
  //   // eslint-disable-next-line no-undef
  //   const res = await axios.get('https://geolocation-db.com/json/');
  //   ipAddress = (res.data.IPv4);
  // } catch {
  //   ipAddress = 'No user ip';
  // }
  const userAgentFull = window.navigator.userAgent;
  const userAgentShort = 'No user agent short';

  try {
    const response = await postLogin({
      email,
      password,
      ipAddress,
      userAgentFull,
      userAgentShort,
    });

    let user;
    let token;
    if (response.data.success) {
      ({ user, token } = response.data.data);
      setSession(token, null);
    } else {
      return {
        success: false,
        errorMessage: response.data.errorMessage,
      };
    }
    return {
      user,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
      errorMessage: UNKNOWN_ERROR,
    };
  }
};

const loginInWithToken = async () => {
  const accessToken = getAccessToken();
  const decoded = jwtDecode(accessToken);

  return decoded.user;
};

const logout = () => {
  setSession(null, null);
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleAuthentication = () => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return;
  }

  if (isValidToken(accessToken)) {
    setSession(accessToken, null);
  } else {
    setSession(null, null);
  }
};

const isAuthenticated = () => !!getAccessToken();

// RECOVER PASSWORD
const recoverPassword = async (email) => {
  try {
    const response = await postRecoverPassword(email);
    if (response.data.success) {
      return {
        success: true,
      };
    }
    return {
      success: false,
      errorMessage: response.message,
    };
  } catch (err) {
    return {
      success: false,
      errorMessage: UNKNOWN_ERROR,
    };
  }
};

// RESET PASSWORD
const resetPassword = async (token, password, confirmPassword) => {
  try {
    const response = await updatePassword({
      token,
      password,
      confirmPassword,
    });
    if (response.success) {
      return {
        success: true,
      };
    }
    return {
      success: false,
      errorMessage: response.message,
    };
  } catch (err) {
    return {
      success: false,
      errorMessage: UNKNOWN_ERROR,
    };
  }
};

const register = async (fullName, dni, countryCode, email, customPassword, role, phoneNumber) => {
  const password = customPassword || Math.random().toString(36).slice(-8);

  try {
    const response = await postRegister({
      fullName, dni, countryCode, email, password, role, phoneNumber,
    });

    if (response.success) {
      return {
        success: true,
      };
    }
    return {
      success: false,
      errorMessage: response.message,
    };
  } catch (err) {
    return {
      success: false,
      errorMessage: UNKNOWN_ERROR,
    };
  }
};

// USER VERIFICATION
const verification = async (token, password, confirmPassword) => {
  try {
    const response = await userVerification({
      token,
      password,
      confirmPassword,
    });
    if (response.success) {
      return {
        success: true,
      };
    }
    return {
      success: false,
      errorMessage: response.message,
    };
  } catch (err) {
    return {
      success: false,
      errorMessage: UNKNOWN_ERROR,
    };
  }
};

export default {
  setAxiosInterceptors,
  login,
  loginInWithToken,
  logout,
  handleAuthentication,
  isAuthenticated,
  recoverPassword,
  resetPassword,
  register,
  verification,
  setSession,
};

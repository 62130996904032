const getColor = (theme, color) => {
  if (color.includes('theme')) {
    const colorSplit = color.split('-');
    const themeLevel = colorSplit[1];
    const themeColor = colorSplit[2];
    return `${theme.palette[themeLevel][themeColor]} !important`;
  }

  return color;
};

export default getColor;

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CLIENT, CLIENT_EMPLOYEE, OWNER, SUPER_ADMIN,
} from '../../utils/const/user';

function AuthGuardAdmin({ children }) {
  const account = useSelector((state) => state.account);

  if (account.user.role !== SUPER_ADMIN
    && account.user.role !== CLIENT
    && account.user.role !== CLIENT_EMPLOYEE
    && account.user.role !== OWNER) {
    window.location.href = '/';
  }

  return children;
}

AuthGuardAdmin.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuardAdmin;

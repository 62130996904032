import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TasksTable from './table';
import CreateTaskEditView from './CreateTask/createEditView';
import TaskDetail from './taskDetail';
import TaskAssociation from './TaskAssociation';
// requests
import { getTask } from '../../requests/api/task';
import { getListingCategories } from '../../requests/api/listing';
// const
import { CHECK_IN } from '../../utils/const/formTypes';
// components
import Snackbar from '../../components/Snackbar';

import useStyles from './styles';

function Tasks() {
  const styles = useStyles();
  const { t } = useTranslation();

  // snackbar status
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const emptyValues = {
    name: '',
    cluster: '',
    project: '',
    forms: {},
  };
  const [editCreateView, setEditCreateView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [values, setValues] = useState(emptyValues);
  const [associate, setAssociate] = useState(false);
  const [editTask, setEditTask] = useState(false);
  const [allowCheckInForm, setAllowCheckInForm] = useState(true);
  const [categoriesProductsInListing, setCategoriesProductsInListing] = useState([]);

  const [taskId, setTaskId] = useState(null);

  useEffect(() => {
    const getTaskById = async () => {
      const { data, success } = await getTask(taskId);
      if (success) {
        const { info } = data.data.task;
        const project = info.Project;
        const cluster = info.Cluster;
        // Save the forms sorted by category.
        const formsInCategories = {};
        const surveys = data.data.task.surveysDetail;

        surveys.forEach((survey) => {
          const property = survey.Category ? `${survey.Category.categoryName} (${survey.Category.code})` : null;

          if (survey.Survey.type === CHECK_IN) {
            setAllowCheckInForm(false);
          }

          if (Object.prototype.hasOwnProperty.call(formsInCategories, property)) {
            formsInCategories[property] = [...formsInCategories[property], survey.Survey];
          } else {
            formsInCategories[property] = [survey.Survey];
          }
        });

        let clusterValue = '';
        if (cluster) {
          clusterValue = { name: cluster.clusterName || cluster.name, value: `${cluster.id}-${cluster.listingId}` };

          if (cluster.listingId) {
            const categoriesResponse = await getListingCategories(cluster.listingId);

            if (categoriesResponse.success) {
              setCategoriesProductsInListing(categoriesResponse.data.data);
            } else {
              setMessage(t('task.messages.listingCategoriesError'));
              setSeverity('error');
            }
          }
        }

        setValues({
          name: info.name,
          project: { name: project.projectName || project.name, value: project.id },
          cluster: clusterValue,
          forms: formsInCategories,
          taskInstances: info.TaskInstances,
        });
      }
    };
    setValues(emptyValues);
    if (taskId) {
      getTaskById();
    }
    // eslint-disable-next-line
  }, [taskId]);

  return (
    <>
      {editCreateView || editTask ? (
        <div className={styles.content}>
          <CreateTaskEditView
            setEdit={setEdit}
            inputValues={values}
            setEditCreateView={setEditCreateView}
            setInputValues={setValues}
            emptyValues={emptyValues}
            setTaskId={setTaskId}
            editTask={editTask}
            setEditTask={setEditTask}
            taskId={taskId}
            allowCheckInForm={allowCheckInForm}
            categoriesProductsInListing={categoriesProductsInListing}
            setCategoriesProductsInListing={setCategoriesProductsInListing}
          />
        </div>
      ) : null}
      {!editCreateView && !taskId && !associate ? (
        <TasksTable
          setEdit={setEdit}
          setEditTask={setEditTask}
          setTaskId={setTaskId}
          setAssociate={setAssociate}
          setEditCreateView={setEditCreateView}
        />
      ) : null}
      {taskId && !associate && !edit && !editTask ? (
        <div className={styles.content}>
          <TaskDetail
            name={values.name}
            project={values.project}
            cluster={values.cluster}
            forms={values.forms}
            setTaskId={setTaskId}
            taskInstances={values.taskInstances}
          />
        </div>
      ) : null}
      {associate || (edit && taskId) ? (
        <TaskAssociation
          taskId={taskId}
          setTaskId={setTaskId}
          setAssociate={setAssociate}
          name={values.name}
          project={values.project}
          cluster={values.cluster}
          edit={edit}
          taskInstances={values.taskInstances}
          setEdit={setEdit}
        />
      ) : null}
      <Snackbar
        open={message !== ''}
        message={message}
        severity={severity}
        onClose={() => setMessage('')}
      />
    </>
  );
}

export default Tasks;

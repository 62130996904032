import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import {
  Grid,
  Typography,
  CircularProgress,
  InputLabel,
} from '@material-ui/core';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ClearIcon from '@material-ui/icons/Clear';

import axiosOriginal from 'axios';
import bytesToSize from '../../../utils/bytesToSize';

import { updateTicketInfo } from '../../../requests/api/ticket';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import useStyles from './styles';

import {
  categories,
  displayES,
  displayEN,
  qaES,
  qaEN,
  seekES,
  seekEN,
  noCatES,
  noCatEN,
} from './const';

const TicketForm = ({
  reload,
  currentSelection,
  setCurrentOpen,
  setError,
  setSucc,
  update,
  sendResponse,
}) => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  // const projects = useSelector((state) => state.projects.projects);
  const location = useLocation();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subOptions, setSubOptions] = useState(
    i18n.languages[1] === 'es' ? [noCatES] : [noCatEN],
  );
  const [inputValues, setInputValues] = useState({
    id: currentSelection.id,
    state: currentSelection.state,
    subject: currentSelection.subject,
    content: currentSelection.content,
    category: currentSelection.category,
    subcategory: currentSelection.subcategory,
  });

  const handleRemoveAll = () => {
    setFile(null);
  };

  const handleClose = () => {
    setCurrentOpen(false);
  };

  const handleUpload = async () => {
    setLoading(true);
    const fileParts = file.name.split('.');
    const timestamp = Date.now();
    const fileName = location.pathname === '/support'
      ? fileParts[0]
      : `${fileParts[0]} ${timestamp}`;
    const uploadData = {
      fileName,
      fileType: file.type,
    };
    const response = await axiosOriginal.post('/api/ticket/signS3', uploadData);
    const options = {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': 'attachment',
      },
    };
    const { returnData } = response.data.data;
    const { signedRequest } = returnData;
    try {
      const uploadResponse = await axiosOriginal.put(
        signedRequest,
        file,
        options,
      );
      if (uploadResponse.status === 200) {
        const fileUrl = uploadResponse.config.url.split('?')[0];
        setInputValues({
          ...inputValues,
          fileUrl,
        });
        setError('');
        setLoading(false);
        return fileUrl;
      }
    } catch (err) {
      setError(t('support.ticketForm.errorUpload'));
      return err;
    }
    return false;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputValues({
      ...inputValues,
      [id]: value,
    });
  };

  const handleSelect = ({ target }) => {
    setFile(target.files[0]);
    setError('');
  };

  const handleSend = async () => {
    let response;
    if (Object.values(inputValues).some((value) => value === '')) {
      setError(t('administration.validation'));
      return;
    }

    if (file) {
      const url = await handleUpload();
      if (url) {
        response = await updateTicketInfo({
          id: inputValues.id,
          state: inputValues.state,
          subject: inputValues.subject,
          content: inputValues.content,
          category: inputValues.category,
          subcategory: inputValues.subcategory,
          url,
        });
      }
    } else {
      response = await updateTicketInfo({
        id: inputValues.id,
        state: inputValues.state,
        subject: inputValues.subject,
        content: inputValues.content,
        category: inputValues.category,
        subcategory: inputValues.subcategory,
      });
    }

    if (response.success) {
      setSucc(t('administration.success'));
      handleClose();
      setInputValues({
        subject: '',
        content: '',
        category: '',
        subcategory: '',
      });
      sendResponse(response);
      reload();
    } else {
      setError(t('administration.error'));
      handleClose();
      reload();
    }
  };

  const selectCategory = (e) => {
    const { value } = e.target;
    if (value === 'display') {
      setSubOptions(i18n.languages[1] === 'es' ? displayES : displayEN);
    } else if (value === 'qa') {
      setSubOptions(i18n.languages[1] === 'es' ? qaES : qaEN);
    } else if (value === 'seek') {
      setSubOptions(i18n.languages[1] === 'es' ? seekES : seekEN);
    }
    setInputValues({
      ...inputValues,
      category: value,
    });
  };

  useEffect(() => {
    const updateSubCategory = () => {
      if (inputValues.category === 'display') {
        setSubOptions(i18n.languages[1] === 'es' ? displayES : displayEN);
      } else if (inputValues.category === 'qa') {
        setSubOptions(i18n.languages[1] === 'es' ? qaES : qaEN);
      } else if (inputValues.category === 'seek') {
        setSubOptions(i18n.languages[1] === 'es' ? seekES : seekEN);
      }
    };
    updateSubCategory();
  }, [inputValues]);

  const selectSubcategory = (e) => {
    const { value } = e.target;
    setInputValues({
      ...inputValues,
      subcategory: value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const uploaderRender = () => {
    if (file) {
      return (
        <Grid container item justifyContent='flex-end" direction="row' xs={12}>
          <Grid
            container
            item
            justifyContent='flex-end'
            direction='row'
            alignItems='center'
            xs={8}
          >
            <Grid item xs={12}>
              <Typography variant='body1'>{file.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2'>{bytesToSize(file.size)}</Typography>
            </Grid>
          </Grid>
          <Grid item xs>
            <div className={styles.actions}>
              {loading ? (
                <CircularProgress />
              ) : (
                <ClearIcon
                  onClick={handleRemoveAll}
                  className={styles.uploadIcon}
                  fontSize='large'
                />
              )}
            </div>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid
        container
        item
        justifyContent='flex-start'
        alignItems='center'
        direction='row-reverse'
        xs={12}
        style={{ padding: '10px 0' }}
      >
        <button
          className={styles.buttonContainer}
          style={{
            padding: '0',
            borderRadius: '10px',
            backgroundColor: '#2C9DC9',
            color: '#FFFFFF',
            height: '35px',
            border: '0',
          }}
        >
          <input
            className={styles.input}
            id='contained-button-file'
            multiple
            type='file'
            onChange={handleSelect}
          />
          <label
            style={{ cursor: 'pointer', padding: '0 150px 150px 0' }}
            htmlFor='contained-button-file'
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
>
              <CloudUploadIcon />
              <span>{t('support.ticketForm.uploadFile')}</span>
            </div>
          </label>
        </button>
      </Grid>
    );
  };

  return (
    <div>
      <div className={styles.formWrapper}>
        <Grid container spacing={2} alignItems='flex-end'>
          <Grid item xs={12}>
            <InputLabel variant='h4'>
              {t('support.ticketForm.title')}
            </InputLabel>
            <TextField
              id='subject'
              type='text'
              variant='outlined'
              value={inputValues.subject}
              onChange={handleChange}
              fullWidth
              InputProps={{
                onKeyDown: handleKeyDown,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('support.ticketForm.description')}</InputLabel>
            <TextField
              variant='outlined'
              id='content'
              value={inputValues.content}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel variant='h4'>
              {t('support.ticketForm.category')}
            </InputLabel>
            <TextField
              id='category'
              type='text'
              variant='outlined'
              value={inputValues.category}
              options={categories}
              onChange={selectCategory}
              select={true}
              fullWidth
              InputProps={{
                onKeyDown: handleKeyDown,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>{t('support.ticketForm.subcategory')}</InputLabel>
            <TextField
              id='subcategory'
              type='text'
              variant='outlined'
              value={inputValues.subcategory}
              options={subOptions}
              onChange={selectSubcategory}
              select={true}
              fullWidth
              InputProps={{
                onKeyDown: handleKeyDown,
              }}
            />
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={8}>
            {uploaderRender()}
          </Grid>
          <Grid item xs={4}>
            <Button
              id='submit-ticket-btn'
              onClick={handleSend}
              text={
                update
                  ? t('administration.buttons.update')
                  : t('administration.buttons.create')
              }
              height='35px'
              width='100%'
              backgroundColor='#2C9DC9'
              color='#FFFFFF'
              borderRadius='10px'
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TicketForm;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  locationTable: {
    '& .MuiTableCell-root': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-paddingNone': {
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: 'rgb(44, 157, 201, 0.05)',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(44, 157, 201, 0.05)',
    },
  },
  iconsCell: {
    '& svg': {
      color: theme.palette.primary.main,
    },
    '& svg:hover': {
      color: theme.palette.primary.blue,
    },
  },
  title: {
    flexGrow: 1,
    marginBottom: '20px',
  },
  addressTitle: {
    margin: '14px 0',
    fontWeight: '600',
  },
  button: {
    width: '140px',
    height: '40px',
    backgroundColor: `${theme.palette.secondary.blue} !important`,
    color: `${theme.palette.primary.white} !important`,
    borderRadius: '10px',
    marginBottom: '10px',
    marginRight: '10px',
  },
  toolbarTable: {
    '& .MuiOutlinedInput-root': {
      height: '35px',
      fontSize: '13px',
      width: '370px',
    },
    '& .Mui-focused': {
      border: `1px solid ${theme.palette.secondary.gray}`,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.gray,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '2px',
    },
    '& button': {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.secondary.gray,
      fontSize: '13.5px',
      '& svg': {
        color: theme.palette.secondary.gray,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.secondary.gray,
      },
    },
    '& .MuiMenuItem-root': {
      color: 'black',
    },
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  popper: {
    zIndex: 2,
    width: '110px',
    '& ul': {
      paddingBottom: '0px',
    },
  },
  titleOption: {
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
  },
  activeOption: {
    fontSize: '13.5px',
  },
  clearOption: {
    fontSize: '13.5px',
    borderTop: `1px solid ${theme.palette.secondary.gray}`,
  },
  progress: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  locationInfo: {
    display: 'grid',
    gridTemplateColumns: '45% 45%',
    columnGap: '5%',
    padding: '30px',
    '& .MuiFormLabel-root': {
      color: '#808080',
    },
    '& .MuiFormControl-root': {
      margin: '10px auto',
    },
    '& input': {
      padding: '10px',
    },
    '& .MuiSelect-root': {
      padding: '10px',
      fontSize: '13.5px',
    },
  },
  itemList: {
    fontSize: '13.5px',
    color: 'black',
  },
  role: {
    gridColumn: 1,
  },
  select: {
    '& .MuiInputLabel-formControl': {
      transform: 'none',
    },
  },
  selectOulined: {
    marginTop: '26px',
  },
  buttonsContainer: {
    '& button': {
      width: '100%',
      borderRadius: '10px',
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.secondary.blue,
    },
  },
  locations: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& svg': {
      width: '40px',
      height: '40px',
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  header: {
    display: 'flex',
  },
  tableHeader: {
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
    '& .MuiCheckbox-indeterminate ': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
  },
  formWrapper: {
    width: '85vw',
  },
  noContent: {
    color: theme.palette.primary.main,
  },
  required: {
    color: 'red',
  },
  modalBox: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    width: '270px',
    '& span': {
      margin: 0,
    },
  },
}));

export default useStyles;

const PRODUCT = 'product';
const CHECK_IN = 'checkin';
const GENERAL = 'general';
const ENTRENAMIENTO = 'entrenamiento';

export {
  PRODUCT,
  CHECK_IN,
  GENERAL,
  ENTRENAMIENTO,
};

/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import {
  Collapse,
  Box,
} from '@material-ui/core';
// import { Link } from 'react-router-dom';

// import useStyles from './styles';
// import { parseDate } from '../../../utils/date';

import SurveyQuestionBox from './SurveyQuestionBox';

const ExpandableSurveyRow = ({
  children, expandComponents, questionResponsesDone,
  reaudit,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  // const styles = useStyles();
  // const currentPath = window.location.pathname;
  return (
    <>
      <TableRow onClick={() => setIsExpanded(!isExpanded)}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
          {children}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table>
                <TableBody>
                  <SurveyQuestionBox
                    data={expandComponents}
                    questionResponsesDone={questionResponsesDone}
                    reaudit={reaudit}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ExpandableSurveyRow;

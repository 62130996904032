import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import CoreSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const ColorFormControl = withStyles((theme) => ({
  root: {
    '& label': {
      color: theme.palette.primary.darkGray,
      fontSize: '13px',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'none',
    },
    '& .MuiInput-root': {
      border: `1px solid ${theme.palette.primary.lightGray}`,
      borderRadius: '5px',
      marginTop: '27px',
      marginBottom: '10px',
      padding: '5px',
      paddingLeft: '10px',
    },
    '& .MuiInput-root.Mui-focused': {
      border: `1px solid ${theme.palette.secondary.blue}`,
      borderRadius: '5px',
    },
    '& .MuiInput-input': {
      color: theme.palette.primary.darkGray,
      textAlign: 'left',
      fontSize: '14px',
      paddingLeft: '7px',
    },
    '& label.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.secondary.blue,
    },
  },
}))(FormControl);

const useStyles = makeStyles(() => ({
  selectContainer: {
    minWidth: (props) => props.width,
  },
}));

/**
 * @deprecated dropped in favor MUI Select component
 */
function Select({
  items,
  label,
  value,
  onChange,
  fullWidth,
  name,
  disabled,
  ...props
}) {
  const styles = useStyles(props);

  const menuItems = items.map((i) => (
    <MenuItem key={i.value} value={i.value} disabled={!i.value}>{`${i.name[0].toUpperCase()}${i.name.slice(1).toLowerCase()}`}</MenuItem>
  ));

  return (
    <div>
      <ColorFormControl
        fullWidth={fullWidth}
        className={styles.selectContainer}
      >
        <InputLabel>{label}</InputLabel>
        <CoreSelect
          labelId={name}
          name={name}
          value={value}
          fullWidth={fullWidth}
          onChange={onChange}
          displayEmpty
          disabled={disabled}
          disableUnderline
        >
          {menuItems}
        </CoreSelect>
      </ColorFormControl>
    </div>
  );
}

Select.defaultProps = {
  label: '',
  value: '',
  name: '',
  fullWidth: false,
  disabled: false,
  width: 'auto',
};

Select.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string,
};

export default Select;

import AuthService from '../services/authService';

const LOGIN_REQUEST = '@account/login-request';
const LOGIN_SUCCESS = '@account/login-success';
const LOGIN_FAILURE = '@account/login-failure';
const SILENT_LOGIN = '@account/silent-login';
const LOGOUT = '@account/logout';

const login = (rut, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const user = await AuthService.loginWithRutAndPassword(rut, password);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user,
      },
    });
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE });
    throw error;
  }
};

const setUserData = (user) => (dispatch) => dispatch({
  type: SILENT_LOGIN,
  payload: {
    user,
  },
});

const logout = () => async (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export default {
  login,
  setUserData,
  logout,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SILENT_LOGIN,
  LOGOUT,
};

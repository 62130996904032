import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  FormControl, InputLabel, TextField, Button,
} from '@material-ui/core';

import useStyles from './styles';

import OutlinedSelect from '../../components/OutlinedSelect';
import Snackbar from '../../components/Snackbar';

import { putEditCategory, postNewCategory } from '../../requests/api/category';

function CategoryForm({
  categoryInfo, setCategoryInfo, readOnly, setUpdate, setOpen,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [inputValues, setInputValues] = useState({
    name: categoryInfo ? categoryInfo.name : '',
    code: categoryInfo ? categoryInfo.code : '',
    deleted: categoryInfo ? categoryInfo.deleted : false,
  });

  // Edit category info
  const handleChangeCategoryInfo = async (event) => {
    const { id, name, value } = event.target;

    if (!id) {
      setInputValues({
        ...inputValues,
        [name]: value,
      });
    } else {
      setInputValues({
        ...inputValues,
        [id]: value,
      });
    }
  };

  // Saves the new data
  const handleSave = async () => {
    try {
      let response = null;
      if (categoryInfo) {
        response = await putEditCategory(categoryInfo.id, inputValues);
      } else {
        if (inputValues.name === '') {
          setSeverity('error');
          setMessage(t('categories.categoryForm.nameError'));
          return null;
        }
        if (inputValues.code === '') {
          setSeverity('error');
          setMessage(t('categories.categoryForm.codeError'));
          return null;
        }
        // A category that is being created should not be eliminated.
        // This is why the deleted property is removed.
        delete inputValues.deleted;

        // If any of the inputs is not filled, it is set
        // to null so that it is correctly stored in the db.
        let cleanValues = {};
        Object.entries(inputValues).forEach(([key, inputValue]) => {
          let newValue;
          if (inputValue === '') {
            newValue = null;
          } else {
            newValue = inputValue;
          }
          cleanValues = { ...cleanValues, [key]: newValue };
        });
        response = await postNewCategory(cleanValues);
      }
      if (response.status === 200) {
        setSeverity('success');
        setMessage(t('categories.categoryForm.successMessage'));

        setUpdate((prevState) => !prevState);
        if (categoryInfo) {
          setCategoryInfo(null);
        }
        setOpen(false);
      } else {
        setSeverity('error');
        setMessage(response.data.errorMessage);
      }
    } catch (err) {
      setSeverity('error');
      setMessage('Error');
      return (err);
    }
    return (false);
  };

  return (
    <div className={styles.categoryInfo}>
      <FormControl fullWidth>
        <InputLabel>{t('categories.categoryForm.name')}</InputLabel>
        <TextField
          id="name"
          value={inputValues.name}
          variant="outlined"
          fullWidth
          onChange={handleChangeCategoryInfo}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('categories.categoryForm.code')}</InputLabel>
        <TextField
          id="code"
          value={inputValues.code}
          variant="outlined"
          fullWidth
          onChange={handleChangeCategoryInfo}
          disabled={readOnly}
        />
      </FormControl>
      {categoryInfo && (
        <FormControl fullWidth>
          <InputLabel>{t('categories.categoryForm.deleted')}</InputLabel>
          <OutlinedSelect
            name="deleted"
            items={[
              { name: 'Eliminado', value: true },
              { name: 'No eliminado', value: false },
            ]}
            value={inputValues.deleted}
            onChange={handleChangeCategoryInfo}
            fullWidth
            disabled
          />
        </FormControl>
      )}
      <div className={`${styles.buttonsContainer} ${!categoryInfo ? styles.buttonColumn : ''}`}>
        {readOnly ? null : (
          <Button onClick={handleSave}>
            {categoryInfo
              ? t('categories.categoryForm.editButton')
              : t('categories.categoryForm.createButton')}
          </Button>
        )}
      </div>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div>
  );
}

export default CategoryForm;

import React, { useEffect, useRef } from 'react';

function SignatureCanvas({ data }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    let maxX = 0;
    let maxY = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].x > maxX) {
        maxX = data[i].x;
      }
      if (data[i].y > maxY) {
        maxY = data[i].y;
      }
    }
    const scaleX = canvas.width / maxX;
    const scaleY = canvas.height / maxY;
    ctx.beginPath();
    ctx.moveTo(data[0].x * scaleX, data[0].y * scaleY);
    for (let i = 1; i < data.length; i += 1) {
      ctx.lineTo(data[i].x * scaleX, data[i].y * scaleY);
      ctx.stroke();
    }
  }, [data]);

  return <canvas ref={canvasRef} width={300} height={180}/>;
}

export default SignatureCanvas;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// MUI components
import {
  Box,
  Typography,
} from '@material-ui/core';

// components
import useStyles from './styles';
import { OK, UNAUTHORIZED, CONFLICT } from '../../../utils/const/http';
import ExpandableRowTable from './surveyExpandableTable';

// API calls
import { getSurveyResponses } from '../../../requests/api/survey-response';
import { startTaskResponseReview } from '../../../requests/api/task-response';

const SurveyResponsesView = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const page = 0;
  const rowsPerPage = 10;
  const [categoriesQuantity, setCategoriesQuantity] = useState(0);
  const [categories, setCategories] = useState([]);
  const [locationName, setLocationName] = useState('');
  const { taskResponseId } = useParams();
  const account = useSelector((state) => state.account);

  const requestSearch = async (pageOfTable, limit) => {
    const response = await getSurveyResponses({ taskResponseId, page: pageOfTable, limit });
    if (response.success) {
      setCategories(response.data.data.categories);
      setCategoriesQuantity(Object.keys(response.data.data.categories).length);
      if (response.data.data.taskResponse.TaskInstance.Location) {
        setLocationName(response.data.data.taskResponse.TaskInstance.Location.name);
      }
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  useEffect(() => {
    requestSearch(page, rowsPerPage);
  }, [page, rowsPerPage, categoriesQuantity]);

  /**
   * Set a task as "in review" with Pending status.
   * Only viewable by current session user,
   *
   * @param {type} taskResponseId
   * @returns {undefined}
   */
  /* eslint-disable no-shadow */
  const markStartTaskResponseReview = async (taskResponseId) => {
    const a = await startTaskResponseReview(taskResponseId);

    if (a.status !== OK.status) {
      if (a.status === CONFLICT.status
        && parseInt(account.user.id, 10) !== a.data.taskResponseReview.reviewedBy) {

        // Already started
        // TODO: Change alert to modal or snack bar
        //        alert(a.data.errorMessage);
      }
    }
  };

  useEffect(() => {
    markStartTaskResponseReview(taskResponseId);
  }, []);

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h1' className={styles.title} gutterBottom>
          {locationName}
          </Typography>
          <Typography variant='h4' gutterBottom>
          {t('qa.taskResponseView.title')}
          </Typography>
          <Typography variant='h4' gutterBottom display="inline">
          {t('qa.taskResponseView.subtitle1')}{categoriesQuantity}{t('qa.taskResponseView.subtitle2')}
          </Typography>
        </Box>
      </div>
    <ExpandableRowTable
      data={categories}
      categoriesQuantity={categoriesQuantity}
      taskResponseId={taskResponseId}
    />
    </div>
  );
};

export default SurveyResponsesView;

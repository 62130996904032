import React from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Toolbar,
} from '@material-ui/core';

import useStyles, { StyledTab, StyledTabs } from './styles';

// Filters area, table header
export default function TableToolbarTabs({ toolbarTabs, tab, setTab }) {
  const styles = useStyles();
  const { t } = useTranslation();

  // Filters the data in the Role and Active columns
  const handleSelectTab = (event) => {
    const { id } = event.currentTarget;
    setTab(toolbarTabs.indexOf(id));
  };

  return (
    <>
      <Toolbar className={styles.toolbarTable}>
        <StyledTabs value={tab} variant="scrollable" onChange={handleSelectTab}>
            <StyledTab id='projects' label={t('users.userInfo.projects')} />
            <StyledTab id='reports' label={t('users.userInfo.reports')} />
          </StyledTabs>
      </Toolbar>
      </>
  );
}

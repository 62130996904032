import React from 'react';
import SupportTickets from './SupportTickets';
import useStyles from './styles';

function Support() {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <SupportTickets/>
    </div>
  );
}

export default Support;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import { parseDate } from '../../../utils/date';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '60vh',
    maxWidth: 500,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.blue,
  },
  success: {
    color: theme.palette.primary.success,
    borderColor: theme.palette.primary.success,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  error: {
    color: theme.palette.primary.error,
    borderColor: theme.palette.primary.error,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  warning: {
    color: theme.palette.primary.warning,
    borderColor: theme.palette.primary.warning,
    borderWidth: '2px',
    borderRadius: '6px',
    backgroundColor: theme.palette.transparent.warning,
  },
  processing: {
    color: theme.palette.primary.blue,
    borderColor: theme.palette.primary.blue,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  align: {
    textAlign: 'center',
    paddingTop: '15px',
  },
}));

function InformationCard({
  data, stateBadge, projects,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [category, setCategory] = useState('');

  const handleDownload = () => {
    if (data.fileUrl) {
      window.open(data.fileUrl);
    }
  };

  useEffect(() => {
    switch (data.category) {
      case 'display':
        setCategory('Display');
        break;
      case 'qa':
        setCategory('QA');
        break;
      case 'seek':
        setCategory('Seek');
        break;
      default:
        break;
    }
  }, [data, projects]);

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="ticket" className={classes.avatar}>
              {data.User.full_name ? data.User.full_name[0] : '?'}
          </Avatar>
        }
        action={
            stateBadge
        }
        title={data.subject}
        subheader= {[t('support.ticketDetail.createdAt'), parseDate(data.createdAt), t('support.ticketDetail.by'), data.User.full_name].join('')}
      />
      <CardContent style={{ maxHeight: 500, overflow: 'auto' }}>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.ticketId')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.crmTicketId}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.description')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.content}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.category')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {category}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.subcategory')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.subcategory}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
          {t('support.ticketDetail.comments')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.comments ? data.comments : t('support.ticketDetail.noComments')}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
        {t('support.columnNames.estimatedClosing')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.estimatedClosingDate ? parseDate(data.estimatedClosingDate) : '-'}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
        {t('support.columnNames.updatedAt')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.lastModifiedDate ? parseDate(data.lastModifiedDate) : '-'}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        <Typography variant="h6" color="textSecondary" component="p">
        {t('support.columnNames.closedAt')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            {data.closedDate ? parseDate(data.closedDate) : '-'}
        </Typography>
        <br/>
        <Divider variant="middle"/>
        <br/>
        {data.Approver
          ? <>
            <Typography variant="h6" color="textSecondary" component="p">
                {t('support.ticketDetail.approver')}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                  {data.Approver.fullName}
              </Typography>
              <br/>
        <Divider variant="middle"/>
          </>
          : ''
        }
        {data.fileUrl
          ? <>
          <br/>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="h6" align="left" color="textSecondary" component="p">
              {t('support.ticketDetail.file')}
            </Typography>
              <IconButton align='right' onClick={handleDownload} disabled={data.fileUrl === null}>
                  <CloudDownloadRoundedIcon fontSize="large" className={classes.icon}/>
                </IconButton>
          </Grid>
          <br/>
          <Divider variant="middle"/>
          <br/>
          </>
          : ''
        }
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ projects: state.projects.projects });

export default connect(mapStateToProps)(InformationCard);

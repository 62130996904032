import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

import axiosOriginal from 'axios';
import {
  Button,
  CircularProgress,
  Grid, InputLabel, MenuItem,
  TextField, Typography, IconButton,
  Dialog, DialogTitle, DialogContent,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';

import { postNewTicket } from '../../requests/api/ticket';
import locationActions from '../../actions/locations';
import bytesToSize from '../../utils/bytesToSize';
import Snackbar from '../../components/Snackbar';

import useStyles from './styles';

const NewChallengeModal = ({ open, setOpenNewChallengeModal, sendResponse }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [error, setError] = useState('');
  const [inputValues, setInputValues] = useState({
    subject: 'Challenge',
    content: '',
    category: 'Challenge',
    subcategory: 'Challenge',
    projectId: '',
    locationId: '',
  });

  const projects = useSelector((state) => state.projects.projects);
  const locations = useSelector((state) => state.locations.locations);
  const company = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(locationActions.actionFetchLocations(company.companyId));
  }, []);

  const handleClose = () => {
    setOpenNewChallengeModal(false);
  };

  const handleRemoveAll = () => {
    setFile(null);
  };

  const handleSelect = ({ target }) => {
    setFile(target.files[0]);
    setError('');
  };

  const selectProject = (e) => {
    const { value } = e.target;
    setInputValues({
      ...inputValues,
      projectId: value,
    });
  };

  const selectLocation = (e) => {
    const { value } = e.target;
    setInputValues({
      ...inputValues,
      locationId: value,
    });
  };

  const handleUpload = async () => {
    setLoading(true);
    const fileParts = file.name.split('.');
    const timestamp = Date.now();
    const fileName = location.pathname === '/support'
      ? fileParts[0]
      : `${fileParts[0]} ${timestamp}`;
    const uploadData = {
      fileName,
      fileType: file.type,
    };
    const response = await axiosOriginal.post('/api/ticket/signS3', uploadData);
    const options = {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': 'attachment',
      },
    };
    const { returnData } = response.data.data;
    const { signedRequest } = returnData;
    try {
      const uploadResponse = await axiosOriginal.put(
        signedRequest,
        file,
        options,
      );
      if (uploadResponse.status === 200) {
        const fileUrl = uploadResponse.config.url.split('?')[0];
        setError('');
        setLoading(false);
        return fileUrl;
      }
    } catch (err) {
      setError(t('support.ticketForm.errorUpload'));
      return err;
    }
    return false;
  };

  const handleSend = async () => {
    let postTicketResponse;
    if (Object.values(inputValues).some((value) => value === '')) {
      setError(t('support.ticketForm.required'));
      return;
    }
    if (file) {
      const url = await handleUpload();
      if (url) {
        postTicketResponse = await postNewTicket({
          subject: inputValues.subject,
          content: inputValues.content,
          category: inputValues.category,
          subcategory: inputValues.subcategory,
          projectId: inputValues.projectId,
          locationId: inputValues.locationId,
          fileUrl: url,
        });
      }
    } else {
      postTicketResponse = await postNewTicket({
        subject: inputValues.subject,
        content: inputValues.content,
        category: inputValues.category,
        subcategory: inputValues.subcategory,
        projectId: inputValues.projectId,
        locationId: inputValues.locationId,
      });
    }
    if (postTicketResponse) {
      handleClose();
      setInputValues({
        subject: '',
        content: '',
        category: '',
        subcategory: '',
        projectId: '',
        locationId: '',
      });
      sendResponse(postTicketResponse);
    }
  };

  const uploaderRender = () => {
    if (file) {
      return (
        <Grid container item className={styles.uploadContainer}>
          <Grid item className={styles.fileInfo}>
            <Typography variant='body1' className={styles.fileName}>{file.name}</Typography>
            <Typography variant='body2' className={styles.fileSize}>{bytesToSize(file.size)}</Typography>
          </Grid>
          <Grid item>
            <div className={styles.actions}>
              {loading ? (
                <CircularProgress />
              ) : (
                <ClearIcon
                  onClick={handleRemoveAll}
                  className={styles.uploadIcon}
                  fontSize='large'
                />
              )}
            </div>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid
        container
        item
        justifyContent='center'
        alignItems='center'
        className={styles.buttonContainer}
      >
        <input
          className={styles.input}
          id='contained-button-file'
          multiple
          type='file'
          onChange={handleSelect}
        />
        <label htmlFor='contained-button-file'>
          <Button variant='contained' component='span' className={styles.uploadButton}>
            <CloudUploadIcon />
            <span>{t('support.ticketForm.uploadFile')}</span>
          </Button>
        </label>
      </Grid>
    );
  };

  const challengeForm = () => (
    <Grid
      container
      spacing={2}
      alignItems='flex-end'
      style={{ padding: '16px 0 0 0' }}
    >
      <Grid
        xs={12}
        item
        container
        spacing={2}
        alignItems='center'
        justifyContent='space-between'
        style={{ padding: '0 0 0 8px' }}
      >
      </Grid>
      <Grid item xs={12}>
        <InputLabel>{t('support.challengeForm.description')}</InputLabel>
        <TextField
          variant='outlined'
          id='content'
          value={inputValues.content}
          onChange={(e) => setInputValues({ ...inputValues, content: e.target.value })}
          fullWidth
          multiline
          minRows={3}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>{t('support.ticketForm.project')}</InputLabel>
        <TextField
          id='projectId'
          type='text'
          variant='outlined'
          value={inputValues.projectId}
          onChange={selectProject}
          select={true}
          fullWidth
        >
          {projects.map((project) => (
            <MenuItem key={project.projectId} value={project.projectId}>
              {project.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <InputLabel>{t('support.ticketForm.location')}</InputLabel>
        <TextField
          id='locationId'
          type='text'
          variant='outlined'
          value={inputValues.locationId}
          onChange={selectLocation}
          select={true}
          fullWidth
        >
          {locations && locations.filter(
            (l) => l.projectId === inputValues.projectId,
          ).map((l) => (
            <MenuItem key={l.locationId} value={l.locationId}>
              {l.Location.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={8}>
        {uploaderRender()}
      </Grid>
      <Grid item xs={4}>
        <Button
          id='submit-ticket-btn'
          onClick={handleSend}
          variant='contained'
          color='primary'
          fullWidth
        >
          {t('support.ticketForm.button')}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
      <DialogTitle className={styles.dialogTitle}>
        {t('support.challengeForm.title')}
        <IconButton
          aria-label='close'
          onClick={handleClose}
          className={styles.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={styles.dialogContent}>
        {projects && challengeForm()}
      </DialogContent>
      <Snackbar
        open={error !== ''}
        message={error}
        severity='error'
        onClose={() => setError('')}
      />
    </Dialog>
  );
};

export default NewChallengeModal;

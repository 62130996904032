import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import CoreSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const ColorFormControl = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      paddingLeft: '7px',
      backgroundColor: theme.palette.primary.white,
    },
    '& .MuiInputLabel-formControl': {
      transform: 'none',
      color: 'white',
      fontWeight: '100',
    },
  },
}))(FormControl);

function OutlinedSelect({
  items,
  value,
  onChange,
  fullWidth,
  name,
  disabled,
}) {
  const menuItems = items.map((i) => (
    <MenuItem key={i.value} value={i.value} disabled={!i.value}>{`${i.name}`}</MenuItem>
  ));

  return (
    <div>
      <ColorFormControl
        fullWidth={fullWidth}
      >
        <CoreSelect
          variant="outlined"
          labelId={name}
          name={name}
          value={value}
          fullWidth={fullWidth}
          onChange={onChange}
          displayEmpty
          disabled={disabled}
        >
          {menuItems}
        </CoreSelect>
      </ColorFormControl>
    </div>
  );
}

OutlinedSelect.defaultProps = {
  value: '',
  name: '',
  fullWidth: false,
  disabled: false,
};

OutlinedSelect.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default OutlinedSelect;

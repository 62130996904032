import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import GroupsTable from './groupsTable';

// requests
import {
  editCluster, getClusterById, getFilteredLocationsInGroup, getFilteredLocationsNotInGroup,
} from '../../requests/api/cluster';
import { getLocationsPerPageByCompanyId } from '../../requests/api/location';

import { IN_THE_GROUP, NOT_IN_THE_GROUP } from '../../utils/const/groups';

function ClusterTable({
  clusterId,
  setGroupToView,
  create,
  groupData,
  setGroupData,
  groupInfo,
  setGroupInfo,
  elementType,
  inputValuesGroup,
  setInputValuesGroup,
  handleCreate,
  handleChangeTab,
  setCreate,
  selected,
  setSelected,
  listing,
  setListing,
  onlyView,
}) {
  const { t } = useTranslation();

  // table states
  const rowsPerPage = 10;
  const [clustersPage, setClustersPage] = useState(0);
  const [locationsPage, setLocationsPage] = useState(0);

  // Filter states
  const [searchText, setSearchText] = useState({
    inTheGroup: '',
    notInTheGroup: '',
  });
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState('name');
  const [inOrOut, setInOrOut] = useState(null);

  // quantities
  const [locationsInTheGroupQ, setLocationsInTheGroupQ] = useState(0);
  const [locationsNotInTheGroupQ, setLocationsNotInTheGroupQ] = useState(0);
  const [dragChange, setDragChange] = useState(false);

  const [allElementsInTheGroup, setAllElementsInTheGroup] = useState([]);
  const [allElementsNotInTheGroup, setAllElementsNotInTheGroup] = useState([]);

  const company = useSelector((state) => state.company);

  const tableHeaders = [
    {
      id: 'name',
      label: t('locations.columnNames.name'),
    },
    {
      id: 'internal_code',
      label: t('locations.columnNames.internal_code'),
    },
    {
      id: 'address',
      label: t('locations.columnNames.address'),
    },
    {
      id: 'city',
      label: t('locations.columnNames.city'),
    },
    {
      id: 'chain',
      label: t('locations.columnNames.chain'),
    },
    {
      id: 'deleted',
      label: t('locations.columnNames.deleted'),
    },
  ];

  const attributes = ['name', 'internalCode', ['Address', 'address'], ['Address', 'city'], 'chain'];

  const findCluster = async () => {
    const response = await getClusterById(clusterId, clustersPage, locationsPage, rowsPerPage);

    if (response.success) {
      setGroupData(response.data.data.cluster.data);

      setGroupInfo({
        inTheGroup: response.data.data.cluster.locationsRows.data,
        notInTheGroup: response.data.data.locations.rows,
      });

      setLocationsInTheGroupQ(response.data.data.cluster.locationsRows.count);
      setLocationsNotInTheGroupQ(response.data.data.locations.count);
    }
  };

  const findLocations = async () => {
    const responseCluster = await getClusterById(
      clusterId, clustersPage, locationsPage, rowsPerPage,
    );
    const responseAllCluster = await getClusterById(
      clusterId, clustersPage, locationsPage, 10000000,
    );
    if (responseCluster.success && responseAllCluster.success) {
      const locationsInGroup = responseCluster.data.data.cluster.locationsRows.data;
      const allLocationsInGroup = responseAllCluster.data.data.cluster.locationsRows.data;
      const totalLocationsInGroup = responseCluster.data.data.cluster.locationsRows.count;
      const locationsNotInGroup = responseCluster.data.data.locations.rows;
      const allLocationsNotInGroup = responseAllCluster.data.data.locations.rows;
      const totalLocationsNotInGroup = responseCluster.data.data.locations.count;
      setGroupInfo({
        inTheGroup: locationsInGroup,
        notInTheGroup: locationsNotInGroup,
      });
      setLocationsInTheGroupQ(totalLocationsInGroup);
      setLocationsNotInTheGroupQ(totalLocationsNotInGroup);
      setAllElementsInTheGroup(allLocationsInGroup);
      setAllElementsNotInTheGroup(allLocationsNotInGroup);
    } else {
      const response = await getLocationsPerPageByCompanyId(locationsPage, rowsPerPage, '', false, []);
      const responseAllLocations = await getLocationsPerPageByCompanyId(locationsPage, 100000000, '', false, []);
      if (response.success) {
        const { locations } = response.data.data;
        setGroupInfo({
          ...groupInfo,
          notInTheGroup: locations,
        });
        setLocationsNotInTheGroupQ(response.data.data.totalLocations);
        setAllElementsNotInTheGroup(responseAllLocations.data.data.locations);
      }
    }
  };

  const requestFilter = async (id, pageOfTable, limit, searchValue, columnName) => {
    let response;
    const idsNotInTheGroup = allElementsNotInTheGroup.map((location) => location.id);
    const idsInTheGroup = allElementsInTheGroup.map((location) => location.id);

    if (inOrOut === IN_THE_GROUP) {
      response = await getFilteredLocationsInGroup(
        id, pageOfTable, limit, searchValue.inTheGroup, columnName, idsNotInTheGroup, idsInTheGroup,
      );
      if (response.success) {
        const { locations } = response.data.data;
        const filteredLocations = locations.filter(
          (location) => allElementsNotInTheGroup.find(
            (locationNotInGroup) => locationNotInGroup.id === location.id,
          ) === undefined && allElementsInTheGroup.find(
            (locationInGroup) => locationInGroup.id === location.id,
          ) !== undefined,
        );
        setGroupInfo({
          ...groupInfo,
          inTheGroup: filteredLocations,
        });
        if (searchValue.InTheGroup === '') {
          setLocationsInTheGroupQ(allElementsInTheGroup.length);
        } else {
          setLocationsInTheGroupQ(filteredLocations.length);
        }
        setLocationsNotInTheGroupQ(allElementsNotInTheGroup.length);
      }
    } else {
      response = await getFilteredLocationsNotInGroup(
        id, pageOfTable, limit, searchValue.notInTheGroup,
        columnName, idsInTheGroup, idsNotInTheGroup,
      );
      if (response.success) {
        const { locations } = response.data.data;
        const filteredLocations = locations.filter(
          (location) => allElementsInTheGroup.find(
            (locationInGroup) => locationInGroup.id === location.id,
          ) === undefined && allElementsNotInTheGroup.find(
            (locationNotInGroup) => locationNotInGroup.id === location.id,
          ) !== undefined,
        );
        setGroupInfo({
          ...groupInfo,
          notInTheGroup: filteredLocations,
        });
        if (searchValue.notInTheGroup === '') {
          setLocationsNotInTheGroupQ(allElementsNotInTheGroup.length);
        } else {
          setLocationsNotInTheGroupQ(filteredLocations.length);
        }
        setLocationsInTheGroupQ(allElementsInTheGroup.length);
      }
    }
  };

  useEffect(() => {
    if (inOrOut) {
      const pageToLoad = inOrOut === IN_THE_GROUP ? clustersPage : locationsPage;
      requestFilter(clusterId, pageToLoad, rowsPerPage, searchText, column);
    } else {
      findLocations();
      setInOrOut(NOT_IN_THE_GROUP);
    }
  }, [
    filter,
    column,
    inOrOut,
    setGroupInfo,
    clusterId,
    clustersPage,
    locationsPage,
    company,
    dragChange,
  ]);

  useEffect(() => {
    setLocationsPage(0);
    if (inOrOut) {
      const pageToLoad = 0;
      requestFilter(clusterId, pageToLoad, rowsPerPage, searchText, column);
    }
  }, [
    searchText,
  ]);

  return (
    <>
      <GroupsTable
        tab={2}
        attributes={attributes}
        groupId={clusterId}
        tableHeaders={tableHeaders}
        findGroup={findCluster}
        findElements={findLocations}
        editFunction={editCluster}
        quantityElementsIn={locationsInTheGroupQ}
        quantityElementsOut={locationsNotInTheGroupQ}
        groupData={groupData}
        groupInfo={groupInfo}
        setGroupInfo={setGroupInfo}
        groupsPage={clustersPage}
        setGroupsPage={setClustersPage}
        elementsPage={locationsPage}
        setElementsPage={setLocationsPage}
        elementType={elementType}
        create={create}
        inputValuesGroup={inputValuesGroup}
        setInputValuesGroup={setInputValuesGroup}
        setGroupToView={setGroupToView}
        handleCreate={handleCreate}
        handleChangeTab={handleChangeTab}
        setCreate={setCreate}
        selected={selected}
        setSelected={setSelected}
        setColumn={setColumn}
        searchText={searchText}
        setSearchText={setSearchText}
        setInOrOut={setInOrOut}
        setFilter={setFilter}
        listing={listing}
        setListing={setListing}
        onlyView={onlyView}
        setDragChange={setDragChange}
        dragChange={dragChange}
        allElementsIn={allElementsInTheGroup}
        setAllElementsIn={setAllElementsInTheGroup}
        allElementsOut={allElementsNotInTheGroup}
        setAllElementsOut={setAllElementsNotInTheGroup}
      />
    </>
  );
}

export default ClusterTable;

/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import locationsAction from '../actions/locations';

const initialState = {
  locations: null,
};

const locationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case locationsAction.LOCATIONS_SUCCESS: {
      const { locations } = action.payload;
      return produce(state, (draft) => {
        draft.locations = locations;
      });
    } default: {
      return state;
    }
  }
};

export default locationsReducer;

/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
// core
import {
  Button, Divider, Paper, IconButton, Checkbox, FormControlLabel,
} from '@material-ui/core';
// icons
import BackIcon from '@material-ui/icons/ReplyOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from './styles';
// components
import Snackbar from '../../../components/Snackbar';
import TextField from '../../../components/TextField';
import Modal from '../../../components/Modal';
import Select from '../../../components/Select';

// requests
import { postNewTask, putEditTask } from '../../../requests/api/task';
import { getProjects } from '../../../requests/api/project';
import { getCompanyClusters } from '../../../requests/api/cluster';
import { getListingCategories } from '../../../requests/api/listing';

import CategoriesTable from '../../Categories/CategoriesTable';
import SurveysTable from '../../Surveys/table';
// cosnt
import { CHECK_IN, PRODUCT } from '../../../utils/const/formTypes';

function CreateTaskView({
  inputValues,
  setEdit,
  setEditCreateView,
  setInputValues,
  emptyValues,
  setTaskId,
  setEditTask,
  editTask,
  taskId,
  allowCheckInForm,
  categoriesProductsInListing,
  setCategoriesProductsInListing,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  // snackbar status
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [checkInForms, setCheckInForms] = useState([]);
  const [addedCheckIn, setAddedCheckIn] = useState(false);
  const [isTraining, setIsTraining] = useState(false);

  const [projects, setProjects] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [section, setSection] = useState(true);
  const [categorySelected, setCategorySelected] = useState('');
  const [formsSelected, setFormsSelected] = useState([]);

  const tableHeadersSurvey = [
    {
      id: 'name',
      label: t('survey.columnNames.title'),
    },
    {
      id: 'type',
      label: t('survey.columnNames.type'),
    },
    {
      id: 'requiredAppVersion',
      label: t('survey.columnNames.requiredAppVersion'),
    },
  ];

  const tableHeadersCategory = [
    {
      id: 'name',
      label: t('categories.columnNames.name'),
    },
    {
      id: 'Code',
      label: t('categories.columnNames.code'),
    },
  ];

  const handleBack = () => {
    setEdit(false);
    setEditTask(false);
    setEditCreateView(false);
    setTaskId(null);
  };

  // Title textfield, project and cluster Id
  const handleOnChange = async (event) => {
    const { id, name, value } = event.target;

    if (id) {
      setInputValues({
        ...inputValues,
        [id]: value,
      });
      return;
    }

    // If the cluster is changed, it is checked whether the product type forms are allowed or not.
    if (name === 'cluster') {
      // When the cluster goes from having an associated listing to not having one,
      // all product type forms are filtered out.
      if (value.split('-')[1] === 'null') {
        const filteredForms = {};
        Object.entries(inputValues.forms).forEach(([category, formsInCategory]) => {
          const filteredFormsInCategory = formsInCategory.filter((form) => form.type !== PRODUCT);
          filteredForms[category] = filteredFormsInCategory;
        });

        setInputValues({
          ...inputValues,
          forms: filteredForms,
          cluster: { value },
        });
        return;
      }
      const { data, success } = await getListingCategories(value.split('-')[1]);

      if (success) {
        const categories = data.data;
        setCategoriesProductsInListing(categories);

        const filteredForms = {};
        Object.entries(inputValues.forms).forEach(([category, formsInCategory]) => {
          if (!categories.includes(category)) {
            const filteredFormsInCategory = formsInCategory.filter((form) => form.type !== PRODUCT);
            filteredForms[category] = filteredFormsInCategory;
            return;
          }
          filteredForms[category] = formsInCategory;
        });

        setInputValues({
          ...inputValues,
          forms: filteredForms,
          cluster: { value },
        });
        return;
      }
    }

    setInputValues({
      ...inputValues,
      [name]: { value },
    });
  };

  const handleSection = () => {
    setOpen(true);
    setSection(true);
  };

  // Adds a new section/category
  const handleAddSection = (e) => {
    const { id } = e.currentTarget;
    const formsCopy = inputValues.forms;

    if (!Object.prototype.hasOwnProperty.call(formsCopy, id)) {
      formsCopy[id] = [];
    }

    setInputValues({
      ...inputValues,
      forms: formsCopy,
    });

    setOpen(false);
  };

  const handleForm = (event) => {
    const { id } = event.currentTarget;
    setCategorySelected(id);
    setOpen(true);
    setSection(false);
  };

  // Adds a new form
  const handleAddForm = () => {
    const formsCopy = inputValues.forms;
    const formsIds = formsSelected.map((formSelected) => formSelected.surveyId);
    const addingCheckIn = formsIds.some((f) => checkInForms.includes(f));

    if (addingCheckIn) {
      setAddedCheckIn(true);
    }

    if (formsCopy[categorySelected]) {
      formsCopy[categorySelected] = [...formsCopy[categorySelected], ...formsSelected];
    } else {
      formsCopy[categorySelected] = [...formsSelected];
    }
    setFormsSelected([]);
    setOpen(false);
  };

  const handleSaveTask = async () => {
    const formsCopy = inputValues.forms;
    const noCategory = `${t('task.noCategory')} (N/A)`;

    if (Object.keys(formsCopy).includes(noCategory)) {
      formsCopy.null = formsCopy[noCategory];
      delete formsCopy[noCategory];
    }

    const payload = {
      name: inputValues.name,
      surveysInfo: Object.entries(formsCopy),
      projectId: inputValues.project.value,
      clusterId: inputValues.cluster ? inputValues.cluster.value.split('-')[0] : null,
    };

    if (!inputValues.name) {
      setSeverity('error');
      setMessage(t('task.messages.titleEmpty'));
      return;
    }

    if (!inputValues.cluster || !inputValues.cluster.value) {
      setSeverity('error');
      setMessage(t('task.messages.clusterEmpty'));
      return;
    }

    if (editTask) {
      const editedTask = await putEditTask(taskId, payload);

      if (editedTask.success) {
        setSeverity('success');
        setMessage(t('task.messages.saveChangesSuccessfully'));
        setInputValues(emptyValues);
        setEditTask(false);
        setTaskId(null);
      } else {
        setSeverity('error');
        setMessage(t('task.messages.saveChangesError'));
      }
    } else {
      const createTaskResponse = await postNewTask(payload);

      if (createTaskResponse.success) {
        setSeverity('success');
        setMessage(t('task.messages.created'));
        setInputValues(emptyValues);
        setEditCreateView(false);
      } else {
        setSeverity('error');
        setMessage(t('task.messages.createError'));
      }
    }
  };
  const handleIsTraining = (event) => {
    if (event.target.checked) {
      setIsTraining(true);
    } else {
      setIsTraining(false);
    }
  };

  // Removes form from the category
  const handleDeleteForm = (event) => {
    const { id } = event.currentTarget;

    const [category, surveyId] = id.split('-');

    if (category === 'Sin categoría (N/A)') {
      // If a check-in form is deleted, forms of this type are enabled to be added again.
      const deletingCheckIn = inputValues.forms[category].filter((form) => form.type === CHECK_IN);

      if (addedCheckIn && deletingCheckIn) setAddedCheckIn(false);
    }

    inputValues.forms[category] = inputValues.forms[category].filter(
      (s) => `${s.surveyId || s.id}` !== surveyId,
    );

    setUpdate((prevState) => !prevState);
  };

  // Removes the property = category in inputValues.forms
  const handleDeleteCategory = (event) => {
    const { id } = event.currentTarget;
    inputValues.forms[id].forEach((form) => {
      if (form.type === CHECK_IN) {
        setAddedCheckIn(false);
      }
    });
    delete inputValues.forms[id];
    setUpdate((prevState) => !prevState);
  };

  useEffect(() => {
    if (inputValues.cluster && inputValues.cluster.name) {
      setClusters([{
        name: inputValues.cluster.name,
        value: inputValues.cluster.value,
      }, ...clusters]);
    }
  }, [inputValues]);

  useEffect(() => {
    setAddedCheckIn(taskId ? allowCheckInForm : false);

    const getAllProjects = async () => {
      const { data, success } = await getProjects();
      if (success) {
        const formatedProjects = data.data.projects.map(
          ({ projectId, name }) => ({ name, value: projectId }),
        );
        setProjects([{ name: t('task.projectSelectPlaceholder'), value: '' }, ...formatedProjects]);
      } else {
        setSeverity('error');
        setMessage(t('task.messages.projectsError'));
      }
    };

    const getAllClusters = async () => {
      const { data, success } = await getCompanyClusters();

      if (success) {
        const formatedClusters = data.data.clusters.map(
          ({ id, name, listingId }) => ({ name, value: `${id}-${listingId}` }),
        );
        setClusters([{ name: t('task.clusterSelectPlaceholder'), value: '' }, ...formatedClusters, ...clusters]);
      } else {
        setSeverity('error');
        setMessage(t('task.messages.clustersError'));
      }
    };

    getAllProjects();
    getAllClusters();
  }, [allowCheckInForm, t, taskId]);

  useEffect(() => {}, [update]);

  return (
    <>
      <div className={styles.headerContainer}>
        <IconButton
          id="backButton"
          aria-label="back"
          className={styles.backButton}
          onClick={handleBack}
        >
          <BackIcon fontSize="small" />
          <span>{t('groups.actionButtons.back')}</span>
        </IconButton>
        <div className={styles.header}>
          <TextField
            id="name"
            className={styles.titleCreateTask}
            placeholder={t('task.taskForm.inputTitle')}
            value={inputValues.name}
            InputProps={{ disableUnderline: true }}
            onChange={handleOnChange}
            fullWidth
          />
          <Button
            onClick={handleSaveTask}
            className={styles.createTaskButton}
            disabled={!Object.entries(inputValues.forms).length}
          >
            {editTask ? t('task.taskEdition.saveChanges') : t('task.taskEdition.manageButton')}
          </Button>
        </div>
          <Select
            name="project"
            items={projects}
            value={inputValues.project.value || ''}
            onChange={handleOnChange}
          />
          <Select
            name="cluster"
            items={clusters}
            value={inputValues.cluster ? inputValues.cluster.value : ''}
            onChange={handleOnChange}
          />
      </div>
      <FormControlLabel
          control={
            <Checkbox
            color='blue'
            onClick={handleIsTraining}
            />
          }
          label="¿Es de entrenamiento?"
      />
      <Divider />
      <div className={styles.taskContent}>
        <div className={styles.editionArea}>
          {Object.entries(inputValues.forms).map(([category, formsInCategory], index) => {
            const fixedCategory = category === null || category === 'null' ? `${t('task.noCategory')} (N/A)` : category;
            return (
            <div
              key={category}
              className={clsx({
                [styles.categoryContainer]: index,
              })}
            >
              <div className={styles.categoriesTitleContainer}>
                <p className={styles.categoryTitle}>
                  {fixedCategory}
                </p>
                <IconButton
                  id={category}
                  onClick={handleDeleteCategory}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <div>
              {formsInCategory.length ? (
                formsInCategory.map((formInCategory) => (
                  <div
                    className={styles.formDetail}
                    key={formInCategory.surveyId || formInCategory.id}
                  >
                    <p>{formInCategory.title}</p>
                    <IconButton
                      id={`${category}-${formInCategory.surveyId || formInCategory.id}`}
                      onClick={handleDeleteForm}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))) : null}
                <Button
                  id={category}
                  className={styles.buttonForm}
                  onClick={handleForm}
                >
                  {t('task.addForm')}
                </Button>
              </div>
            </div>
            );
          })}
          <Button
            className={styles.button}
            onClick={handleSection}
          >
            {t('task.taskEdition.addPageButton')}
          </Button>
        </div>
        <div className={styles.toolsContainer}>
          <p className={styles.sectionTitle}>
            {t('task.taskEdition.tools')}
          </p>
          <Paper>
            <div className={styles.toolsContent}>
            </div>
          </Paper>
        </div>
      </div>
      <Modal
        table
        title={section ? t('task.modalTitle.category') : t('task.modalTitle.form')}
        defaultOpen={open}
        setCurrentOpen={setOpen}
      >
        <div className={styles.modalCreateTask}>
          {section ? (
            <CategoriesTable
              handleSelectCategory={handleAddSection}
              tableHeaders={tableHeadersCategory}
              table
            />
          ) : (
            <>
              <SurveysTable
                selectedForms={formsSelected}
                setSelectToAdd={setFormsSelected}
                tableHeaders={tableHeadersSurvey}
                category={categorySelected}
                checkInForms={checkInForms}
                setCheckInForms={setCheckInForms}
                addedCheckIn={addedCheckIn}
                isTraining={isTraining}
                allowCheckIn={categorySelected === `${t('task.noCategory')} (N/A)`
                  || categorySelected === null
                  || categorySelected === 'null'}
                categoriesProductsInListing={categoriesProductsInListing}
                table
                edit={editTask}
              />
              <Button
                className={styles.button}
                onClick={handleAddForm}
              >
                {t('task.confirmForms')}
              </Button>
            </>
          )}
        </div>
      </Modal>
      <Snackbar
        open={message !== ''}
        message={message}
        severity={severity}
        onClose={() => setMessage('')}
      />
    </>
  );
}

export default CreateTaskView;

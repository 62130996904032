import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    color: theme.palette.primary.white,
    padding: '0px',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  companySelector: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.secondary.blue,
      color: theme.palette.primary.white,
      borderRadius: '5px',
      textAlign: 'center',
      height: '45px',
      marginBottom: '10px',
    },
  },
  drawerOpen: {
    fill: theme.palette.primary.white,
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.mainBlue,
    width: drawerWidth,
    padding: '17px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.mainBlue,
    padding: '17px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '80px',
    '& .MuiListItemIcon-root': {
      marginLeft: '8px',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
    marginTop: '15px',
  },
  toolbarClose: {
    margin: '15px auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  iconOpen: {
    color: theme.palette.secondary.middleGray,
  },
  iconClose: {
    color: theme.palette.secondary.gray,
    '&:hover': {
      color: theme.palette.primary.white,
    },
  },
  iconSelected: {
    color: theme.palette.primary.white,
  },
  redIcon: {
    color: theme.palette.primary.red,
  },
  image: {
    height: '50px',
  },
  imageClose: {
    margin: '0 auto',
  },
  tabTitle: {
    color: theme.palette.secondary.middleGray,
    textTransform: 'uppercase',
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: '800',
    marginLeft: '10px',
  },
  tabTitleOpen: {
    color: theme.palette.secondary.middleGray,
  },
  tabTitleClose: {
    color: theme.palette.secondary.gray,
    textAlign: 'center',
    marginLeft: '0px',
  },
  dividerClose: {
    backgroundColor: theme.palette.primary.middleGray,
  },
  dividerOpen: {
    backgroundColor: theme.palette.secondary.middleGray,
  },
  containerTabItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .MuiListItemText-primary': {
      fontSize: '0.875rem',
    },
    '& .MuiSvgIcon-root': {
      height: '17px',
    },
  },
  listItemSelected: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.secondary.blue,
    borderRadius: '5px',
    '& svg': {
      color: theme.palette.primary.white,
    },
  },
  listSubItemSelected: {
    '& .MuiListItemText-primary': {
      fontWeight: '600',
    },
    '& svg': {
      color: theme.palette.primary.white,
    },
  },
  listSubItem: {
    paddingLeft: '25px',
    '& .MuiListItemText-primary': {
      fontSize: '12px',
    },
  },
  bottomContainer: {
    marginBottom: '10vh',
  },
}));

export default useStyles;

import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  supportTable: {
    '& .MuiTableCell-root': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-paddingNone': {
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: 'rgb(44, 157, 201, 0.05)',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(44, 157, 201, 0.05)',
    },
  },
  tag: {
    borderRadius: '5px',
    padding: '5px 8px',
    maxWidth: '160px',
    margin: '0px auto',
  },
  tagOpen: {
    color: theme.palette.secondary.green,
    backgroundColor: theme.palette.secondary.lightGreen,
  },
  tagWaitinfForApproval: {
    color: theme.palette.secondary.blue,
    backgroundColor: theme.palette.secondary.lightBlue,
  },
  tagApproved: {
    color: theme.palette.secondary.purple,
    backgroundColor: theme.palette.secondary.lightPurple,
  },
  tagProcessing: {
    color: theme.palette.secondary.gray,
    backgroundColor: theme.palette.secondary.lightGray,
  },
  tagClose: {
    color: theme.palette.secondary.red,
    backgroundColor: theme.palette.secondary.lightRed,
  },
  tagRejected: {
    color: theme.palette.secondary.orange,
    backgroundColor: theme.palette.secondary.lightOrange,
  },
  tagPendingDate: {
    color: theme.palette.secondary.bluegray,
    backgroundColor: theme.palette.secondary.lightBluegray,
  },
  tagNA: {
    color: theme.palette.secondary.bluegray,
    backgroundColor: theme.palette.secondary.lightBluegray,
  },
  iconsCell: {
    '& svg': {
      color: theme.palette.primary.main,
    },
    '& svg:hover': {
      color: theme.palette.primary.blue,
    },
  },
  iconButton: {
    display: 'table-cell',
    textAlign: 'center',
    '& svg': {
      size: 'large',
      color: theme.palette.primary.main,
    },
  },
  roleCells: {
    fontSize: '11px',
  },
  title: {
    flexGrow: 1,
    letterSpacing: '-0.5px',
  },
  input: {
    display: 'none',
  },
  toolbarTable: {
    paddingLeft: '0px',
    justifyContent: 'space-between',
  },
  searchToolbar: {
    '& .MuiOutlinedInput-root': {
      height: '35px',
      fontSize: '13px',
      width: '100vh',
      flexGrow: 1,
    },
    '& .Mui-focused': {
      border: `1px solid ${theme.palette.secondary.gray}`,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.gray,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '2px',
    },
    '& button': {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.secondary.gray,
      fontSize: '13.5px',
      '& svg': {
        color: theme.palette.secondary.gray,
      },
    },
    '& .MuiMenuItem-root': {
      color: 'black',
    },
  },
  popper: {
    zIndex: 2,
    width: '110px',
    '& ul': {
      paddingBottom: '0px',
    },
  },
  titleOption: {
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
  },
  roleOption: {
    fontSize: '12px',
  },
  activeOption: {
    fontSize: '13.5px',
  },
  clearOption: {
    fontSize: '13.5px',
    borderTop: `1px solid ${theme.palette.secondary.gray}`,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    '& svg': {
      color: '#E74C3C',
      margin: '0px',
    },
    '& svg:hover': {
      opacity: '0.8',
    },
  },
  progress: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  gridRight: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  userInfo: {
    display: 'grid',
    gridTemplateColumns: '45% 45%',
    columnGap: '5%',
    padding: '30px',
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiFormControl-root': {
      margin: '10px auto',
    },
    '& input': {
      padding: '10px',
    },
    '& .MuiSelect-root': {
      padding: '10px',
      fontSize: '13.5px',
    },
  },
  itemList: {
    fontSize: '13.5px',
    color: 'black',
  },
  formWrapper: {
    width: '50vw',
    padding: '56px',
    paddingTop: '10px',
    '& .MuiFormLabel-root': {
      color: '#808080',
      margin: '15px auto 8px',
      fontSize: '12px',
    },
    '& input': {
      backgroundColor: 'white',
      padding: '10px',
    },
    '& .MuiSelect-root': {
      padding: '10px',
      fontSize: '13.5px',
    },
  },
  role: {
    gridColumn: 1,
  },
  select: {
    '& .MuiInputLabel-formControl': {
      transform: 'none',
    },
  },
  selectOulined: {
    marginTop: '26px',
  },
  buttonsContainer: {
    '& button': {
      width: '100%',
      borderRadius: '10px',
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.secondary.blue,
    },
  },
  users: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& svg': {
      width: '40px',
      height: '40px',
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  header: {
    display: 'flex',
    paddingBottom: '20px',
  },
  underlined: {
    textDecoration: 'underline',
  },
  buttonSelected: {
    padding: '5px',
    '& .MuiButton-label': {
      fontSize: '16px',
      fontWeight: '800',
      color: '#2C9DC9',
      textDecoration: 'underline',
      textUnderlineOffset: '5px',
    },
  },
  button: {
    padding: '5px',
    '& .MuiButton-label': {
      fontSize: '16px',
      fontWeight: '800',
      color: '#BDBDBD',
    },
  },
  tableHeader: {
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
    '& .MuiCheckbox-indeterminate ': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
  },
  success: {
    color: theme.palette.primary.success,
    borderColor: theme.palette.primary.success,
    borderWidth: '2px',
    borderRadius: '6px',
    backgroundColor: theme.palette.transparent.success,
  },
  warning: {
    color: theme.palette.primary.warning,
    borderColor: theme.palette.primary.warning,
    borderWidth: '2px',
    borderRadius: '6px',
    backgroundColor: theme.palette.transparent.warning,
  },
  processing: {
    color: theme.palette.primary.blue,
    borderColor: theme.palette.primary.blue,
    borderWidth: '2px',
    borderRadius: '6px',
  },
  error: {
    color: theme.palette.primary.error,
    borderColor: theme.palette.primary.error,
    borderWidth: '2px',
    borderRadius: '6px',
    backgroundColor: theme.palette.transparent.error,
  },
  layout: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  errorMessage: {
    color: 'red',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    color: theme.palette.primary.main,
  },
  paperContainer: {
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  modalToolbar: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  modalTitle: {
    flexGrow: 1,
    paddingLeft: '69px',
  },
  icon: {
    '& svg': {
      color: theme.palette.primary.main,
    },
    '& svg:hover': {
      color: theme.palette.primary.blue,
    },
  },
  uploadIcon: {
    '& svg': {
      paddingTop: '11px',
      height: '50px',
      width: '100%',
      color: '#2C9DC9',
      margin: '0px',
    },
    '& svg:hover': {
      opacity: '0.8',
    },
  },
  projectName: {
    paddingLeft: '1%',
    marginLeft: '10px',
  },
  boxTitle: {
    flexGrow: 1,
  },
  imageTest: {
    height: '100%',
    width: '100%',
  },
  rejectAll: {
    backgroundColor: theme.palette.secondary.red,
  },
  aproveAndContinue: {
    backgroundColor: theme.palette.secondary.green,
  },
  modalForm: {
    minWidth: '900px',
    minHeight: '170px',
    borderRadius: '12px',
    '& .MuiButton-root': {
      height: '50px',
      width: '70%',
      color: theme.palette.primary.white,
      borderRadius: '30px',
      marginLeft: '10%',
      marginRigth: '10%',
      marginTop: '10%',
    },
    '& .makeStyles-rejectSelected-53': {
      backgroundColor: theme.palette.secondary.yellow,
    },
    '& .makeStyles-rejectAll-54': {
      backgroundColor: theme.palette.secondary.red,
    },
    '& .makeStyles-aproveAndContinue-55': {
      backgroundColor: theme.palette.secondary.green,
    },
  },
  secondModalForm: {
    minWidth: '900px',
    minHeight: '170px',
    borderRadius: '12px',
    '& .MuiButton-root': {
      height: '50px',
      width: '70%',
      color: theme.palette.primary.white,
      borderRadius: '30px',
    },
  },
  subheader: {
    paddingBottom: '5px',
    paddingTop: '10px',
    paddingLeft: '5px',
  },
  subDivider: {
    paddingBottom: '5px',
    paddingTop: '15px',
    paddingLeft: '5px',
    '& img': {
      paddingLeft: '10px',
    },
  },
  confirmModal: {
    paddingTop: '2%',
  },
}));

export const StyledTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.secondary.blue,
  },
}))(Tabs);

export const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: '75px',
    textTransform: 'none',
    padding: '0px 8px',
    margin: '0px 10px',
    color: theme.palette.primary.gray,
  },
}))((props) => <Tab disableRipple {...props} />);

export default useStyles;

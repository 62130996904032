import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Toolbar,
  CircularProgress,
  IconButton,
  TextField as MuiTextField,
  Box,
  Checkbox,
  Grid,
  Button as MuiButton,
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  MenuList,
} from '@material-ui/core';

// icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import WarningIcon from '@material-ui/icons/Warning';

// components
import Modal from '../../../components/Modal';
import useStyles from '../styles';
import Snackbar from '../../../components/Snackbar';
import Pagination from '../../../components/Pagination';
import {
  getReportsPerPage,
  deleteReport,
} from '../../../requests/api/report';
import Button from '../../../components/Button';
import sleep from '../../../utils/sleep';
import ReportForm from './reportForm';
// const
import { UNAUTHORIZED } from '../../../utils/const/http';
// import UserInformation from './UserInformation';

// eslint-disable-next-line no-shadow
const ReportsAdmin = () => {
  const anchorRef = React.useRef(null);
  const styles = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  // table states
  const [selected, setSelected] = useState([]);
  const [openDeleteReportDialog, setOpenDeleteReportDialog] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [totalRowsQuantity, setTotalRowsQuantity] = useState(0);
  const [currentReport, setCurrentReport] = useState({
    reportName: '',
    reportId: '',
    reportDescription: '',
    workspaceId: '',
    fileUrl: '',
    deleted: '',
    warningStatus: '',
    warningMessage: '',
  });
  // auxiliar states
  const [openCreateReport, setOpenCreateReport] = useState(false);
  const [openUpdateReport, setOpenUpdateReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');

  // filter states

  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  const handleDeleteDialog = (e) => {
    setOpenDeleteReportDialog(e);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const setFilterOptions = async (newValue, tableColumn) => {
    if (tableColumn === 'deleted') {
      const options = { 0: 'active', 1: 'inactive' };
      setSearchText(options[newValue]);
    } else {
      setSearchText(newValue);
    }
    await sleep(100);
    setColumn(tableColumn);
    setFilter(newValue);
    setPage(0);
    // Clear filters
    if (!tableColumn) {
      setSearchText('');
      setFilter('');
    }
  };

  // Filters the data in the Role and Active columns
  const handleSelectFilter = (event) => {
    const { id } = event.target;
    // eslint-disable-next-line no-shadow
    let column;

    let searchValue;
    if (id === 'active' || id === 'inactive') {
      // Active filter
      searchValue = id === 'active' ? 0 : 1;
      column = 'deleted';
    } else if (id === 'clear') {
      // Clear filters
      searchValue = '';
      column = false;
    } else if (id === 'lowWarning' || id === 'highWarning') {
      searchValue = id === 'lowWarning' ? 'low' : 'high';
      column = 'warningStatus';
    }
    setFilterOptions(searchValue, column);

    handleClose(event);
  };

  const reportsRequestSearch = async (pageOfTable, limit, searchValue, columnName) => {
    const getReportsResponse = await getReportsPerPage(pageOfTable, limit, searchValue, columnName);
    if (getReportsResponse.success) {
      setTotalRowsQuantity(
        getReportsResponse.data.data.powerbiReports.totalReports,
      );
      setRowsToShow(getReportsResponse.data.data.powerbiReports.powerbiReports);
    }
    if (getReportsResponse.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const reload = async () => {
    load();
    return reportsRequestSearch(page, rowsPerPage, filter, column);
  };

  // Select this row
  const handleSelect = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    // setUserId(name);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleDelete = async () => {
    for (let i = 0; i < selected.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await deleteReport(selected[i]);
    }

    setSelected([]);
    setSucc(t('administration.success'));
    setOpenDeleteReportDialog(false);
    reload();
  };

  const reportsTableHeaders = [
    {
      id: 'select',
      label: <b>{t('administration.report.select')}</b>,
    },
    {
      id: 'fileUrl',
      label: <b>{t('administration.report.image')}</b>,
    },
    {
      id: 'reportName',
      label: <b>{t('administration.report.name')}</b>,
    },
    {
      id: 'reportDescription',
      label: <b>{t('administration.report.description')}</b>,
    },
    {
      id: 'reportId',
      label: <b>{t('administration.report.reportId')}</b>,
    },
    {
      id: 'deleted',
      label: <b>{t('administration.report.active')}</b>,
    },
    {
      id: 'actions',
      label: <b>{t('administration.actions')}</b>,
    },
  ];

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const handleUpdateReportAssociation = (row) => {
    setCurrentReport({
      id: row.id,
      reportName: row.reportName,
      reportId: row.reportId,
      reportDescription: row.reportDescription,
      workspaceId: row.workspaceId,
      fileUrl: row.fileUrl,
      deleted: row.deleted,
      warningStatus: row.warningStatus,
      warningMessage: row.warningMessage,
    });
    setOpenUpdateReport(true);
  };

  const handleCreateReport = () => {
    setCurrentReport({
      reportName: '',
      reportId: '',
      reportDescription: '',
      workspaceId: '',
      warningStatus: 'normal',
      warningMessage: null,
    });
    setOpenCreateReport(true);
  };

  useEffect(() => {
    reload();
  }, [page, rowsPerPage, totalRowsQuantity, filter, column]);

  const reportsToolbar = () => (
    <Toolbar className={styles.toolbarTable}>
       <MuiButton
            id="filter-button"
            ref={anchorRef}
            aria-controls={open ? 'menu-list' : undefined}
            aria-haspopup="true"
            variant="contained"
            onClick={handleToggle}
          >
            <svg style={{ width: '20px', height: '20px' }} viewBox="0 0 24 24">
              <path fill="currentColor" d="M14,12V19.88C14.04,20.18 13.94,20.5 13.71,20.71C13.32,21.1 12.69,21.1 12.3,20.71L10.29,18.7C10.06,18.47 9.96,18.16 10,17.87V12H9.97L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L14.03,12H14Z" />
            </svg>
            {t('users.tableToolbar.button')}
          </MuiButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
            className={styles.popper}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list">
                      <MenuItem disabled className={styles.titleOption}>{t('users.tableToolbar.active')}</MenuItem>
                      <MenuItem
                        id="active"
                        onClick={handleSelectFilter}
                        className={styles.activeOption}
                      >
                        {t('users.tableToolbar.active')}
                      </MenuItem>
                      <MenuItem
                        id="inactive"
                        onClick={handleSelectFilter}
                        className={styles.activeOption}
                      >
                        {t('users.tableToolbar.inactive')}
                      </MenuItem>
                      <MenuItem disabled className={styles.titleOption}>{t('administration.report.warnings')}</MenuItem>
                      <MenuItem
                        id="lowWarning"
                        onClick={handleSelectFilter}
                        className={styles.activeOption}
                      >
                        {t('administration.report.low')}
                      </MenuItem>
                      <MenuItem
                        id="highWarning"
                        onClick={handleSelectFilter}
                        className={styles.activeOption}
                      >
                        {t('administration.report.high')}
                      </MenuItem>
                      <MenuItem
                        id="clear"
                        onClick={handleSelectFilter}
                        className={styles.clearOption}
                      >
                        {t('users.tableToolbar.clear')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
      <Box className={styles.boxTitle}>
          <MuiTextField
                className={styles.searchField}
                id="search"
                variant="outlined"
                value={searchText}
                onChange={(event) => setFilterOptions(event.target.value, 'reportName')}
                placeholder={t('reports.searchPlaceHolder')}
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" />,
                  endAdornment: (
                    <IconButton
                      id="clear"
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      onClick={() => setFilterOptions('', false)}
                      style={{ visibility: searchText ? 'visible' : 'hidden' }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
          </Box>
            <Typography
            className={styles.toolbarButton}
          > </Typography>
          <Grid container xs={6} spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
            id="create-reports-btn"
            text={t('administration.buttons.createReport')}
            variant="contained"
            height="40px"
            width="100%"
            backgroundColor='#2C9DC9'
            color='white'
            borderRadius='10px'
            onClick = {handleCreateReport}
          />
        </Grid>
        <Grid item>
          {selected.length > 0 ? (
              <Button
              id="delete-user-btn"
              text={t('administration.report.deleteReportTitle')}
              height="40px"
              width="130px"
              backgroundColor='theme-secondary-blue'
              color='theme-primary-white'
              borderRadius='10px'
              onClick = {() => handleDeleteDialog(true)}
              />
          ) : <Button
              id="delete-user-btn"
              text={t('administration.report.deleteReportTitle')}
              height="40px"
              width="130px"
              backgroundColor='theme-secondary-blue'
              color='theme-primary-white'
              borderRadius='10px'
              onClick={() => handleDeleteDialog(true)}
              disabled
            />}
        </Grid>
        </Grid>
          {openCreateReport ? (
          <Modal title={t('administration.report.formCreate')} defaultOpen={openCreateReport} setCurrentOpen={setOpenCreateReport}>
            <ReportForm
            setCurrentOpen={setOpenCreateReport}
            currentReport={currentReport}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={false}
            />
          </Modal>
          ) : null}
          </Toolbar>
  );

  const reportsTableHeader = () => (
    <TableHead>
      <TableRow>
        {reportsTableHeaders.map((tableHeader) => (
          <TableCell
            key={tableHeader.id}
            className={styles.tableHeader}
            align={ tableHeader.id === 'actions' ? 'center' : 'left'}
            padding="normal"
          >
            {tableHeader.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const contentReports = () => {
    if (!totalRowsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={3} className={styles.users}>
            <AssignmentIcon />
            <h4>{t('administration.report.noReports')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
              <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleSelect(event, row.id)}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    checkedIcon={<CheckBoxIcon style={{ color: 'green' }}/>}
                  />
              </TableCell>
               {row.fileUrl ? (<TableCell
              component="th"
              scope="row"
              padding="none"
              className={styles.projectName}
            >{
              row.fileUrl
              && <img
              className={styles.logoUrl}
              src={row.fileUrl}
              alt="N/A"
              />
            }</TableCell>)
                 : <TableCell
              component="th"
              scope="row"
              padding="none"
              className={styles.projectName}
            >{
              <img className={styles.logoUrl} src="/rounded_tcg.png" alt="TCG logo" />
            }</TableCell>
            }
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.reportName}
              </TableCell>
              <TableCell align="left">
                {row.reportDescription}
              </TableCell>
              <TableCell align="left">
                {row.reportId}
              </TableCell>
              <TableCell align="left">
                  {!row.deleted ? (
                    <>
                    {row.warningStatus === 'normal' ? (<p align="center" className={`${styles.tag} ${styles.tagActive}`}><b>{t('users.tableToolbar.active')}</b></p>
                    )
                      : <> {row.warningStatus === 'low' ? <p align="center" className={`${styles.tagIcon} ${styles.tagWarning}`}>
                       <Grid container direction="row" justifyContent="center"alignItems="center">
                         <Grid item><WarningIcon fontSize='small'/></Grid>
                         <Grid item><b>ACTIVE</b></Grid>
                       </Grid>
                       </p> : <p align="center" className={`${styles.tagIcon} ${styles.tagInactive}`}>
                       <Grid container direction="row" justifyContent="center"alignItems="center">
                         <Grid item><WarningIcon fontSize='small'/></Grid>
                         <Grid item><b>ACTIVE</b></Grid>
                       </Grid>
                       </p>} </>
                       }
                    </>
                  ) : (
                    <p align="center" className={`${styles.tag} ${styles.tagInactive}`}><b>{t('users.tableToolbar.inactive')}</b></p>
                  )}
              </TableCell>
              <TableCell align="center">
                <IconButton
                onClick = {() => handleUpdateReportAssociation(row)}
                >
                  <EditIcon/>
                </IconButton>
              </TableCell>
            </TableRow>
            );
          })}
          {openUpdateReport ? (
          <Modal title={t('administration.report.formUpdate')} defaultOpen={openUpdateReport} setCurrentOpen={setOpenUpdateReport}>
            <ReportForm
            setCurrentOpen={setOpenUpdateReport}
            currentReport={currentReport}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={true}
            />
          </Modal>
          ) : null}
          {openDeleteReportDialog ? (
            <Modal title={t('administration.report.deleteReportTitle')} defaultOpen={openDeleteReportDialog} setCurrentOpen={setOpenDeleteReportDialog}>
              <div className={styles.dialogWrapper}>
                <Grid container spacing={2} alignItems='flex-end'>
                  <Grid item xs={12}>
                    <Typography className={styles.dialogText} variant="h2" color="textSecondary" component="p">
                      {t('administration.report.deleteReports')} ?
                    </Typography>
                  </Grid>
                  <Grid item xs= {6}/>
                  <Grid item xs={3}>
                  <Button
                    id="submit-ticket-btn"
                    onClick={() => handleDeleteDialog(false)}
                    variant="contained"
                    text={t('users.userInfo.buttons.cancel')}
                    height="35px"
                    width="100%"
                    backgroundColor="#FFFFFF"
                    color="#2C9DC9"
                    borderRadius="10px"
                  />
                  </Grid>
                  <Grid item xs={3}>
                  <Button
                    id="delete-association-btn"
                    onClick={handleDelete}
                    variant="contained"
                    text={t('users.userInfo.buttons.delete')}
                    height="35px"
                    width="100%"
                    backgroundColor='#F5B7B1'
                    color='#E74C3C'
                    borderRadius="10px"
                  />
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                </div>
          </Modal>
          ) : null}
      </>
    );
  };

  const tableContent = () => {
    if (loading) {
      return (
      <TableRow>
        <TableCell colSpan={9} className={styles.progress}>
          <CircularProgress />
          <h4>{t('users.userInfo.loading')}</h4>
        </TableCell>
      </TableRow>
      );
    }
    return contentReports();
  };

  return (
    <>
          <Paper className={styles.documentsTable}>
          <>
          {reportsToolbar()}
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
               {reportsTableHeader()}
                <TableBody>
                  {tableContent()}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          <Pagination
              setPage={setPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={totalRowsQuantity}
            />
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </>
  );
};

export default ReportsAdmin;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  table: {
    '& .Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  title: {
    flexGrow: 1,
  },
  loading: {
    color: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '200px',
  },
  popper: {
    zIndex: 2,
    width: '110px',
    '& ul': {
      paddingBottom: '0px',
    },
  },
  progress: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  itemList: {
    fontSize: '13.5px',
    color: 'black',
  },
  documents: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& svg': {
      width: '40px',
      height: '40px',
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainer: {
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  modalToolbar: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  modalTitle: {
    flexGrow: 1,
    paddingLeft: '69px',
  },
  viewFileButton: {
    '& svg': {
      color: theme.palette.primary.main,
    },
    '& svg:hover': {
      color: theme.palette.primary.blue,
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.transparent.main,
  },
}));

export default useStyles;

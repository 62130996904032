import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Paper,
  CircularProgress,
  Box,
  IconButton,
  Toolbar,
  InputLabel,
  Grid,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
// icons
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
// components
import Modal from '../../../components/Modal';
import CustomButton from '../../../components/Button';
import Snackbar from '../../../components/Snackbar';
import Pagination from '../../../components/Pagination';

import { getTickets } from '../../../requests/api/ticket';
import projectActions from '../../../actions/projects';
import Header from './header';
// const
import { UNAUTHORIZED } from '../../../utils/const/http';
import { CLIENT_EMPLOYEE } from '../../../utils/const/user';
import InformationCard from './informationCard';
import { getUsersByCompany } from '../../../requests/api/company';

import useStyles from './styles';
import { parseDate } from '../../../utils/date';

const ChallengesList = ({ setOpenNewChallengeModal }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const locations = useSelector((state) => state.locations.locations);

  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [ticketsQuantity, setTicketsQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: 'info',
    text: '',
  });
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState(location.state || '');
  const [column, setColumn] = useState(location.column || false);
  const [filterChallengeStatus, setFilterChallengeStatus] = useState('');
  const [filterUser, setFilterUser] = useState('');
  const [filterLocation, setFilterLocation] = useState('');
  const [nextUpdateTimeUTC, setNextUpdateTimeUTC] = useState('');
  const [lastUpdateTimeUTC, setLastUpdateTimeUTC] = useState('');

  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  // Format dates as YYYY-MM-DD
  const formattedToday = today.toISOString().split('T')[0];
  const formattedOneWeekAgo = oneWeekAgo.toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(formattedOneWeekAgo);
  const [endDate, setEndDate] = useState(formattedToday);

  const company = useSelector((state) => state.company);
  const account = useSelector((state) => state.account);
  const projects = useSelector((state) => state.projects);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (account.user.role === CLIENT_EMPLOYEE) {
      setFilterUser(account.user.id);
    }
  }, [account.user.role, account.user.id]);

  const tableHeaders = useMemo(() => ([
    { id: 'project', label: <b>{t('challenges.tableHeaders.project')}</b> },
    { id: 'created_at', label: <b>{t('challenges.tableHeaders.createdAt')}</b> },
    { id: 'estimatedClosingDate', label: <b>{t('challenges.tableHeaders.estimatedClosingDate')}</b> },
    { id: 'tokensConsumed', label: <b>{t('challenges.tableHeaders.tokensConsumed')}</b> },
    { id: 'challenge_state', label: <b>{t('challenges.tableHeaders.challengeStatus')}</b> },
    { id: 'challengeResponse', label: <b>{t('challenges.tableHeaders.challengeResponse')}</b> },
    { id: 'user', label: <b>{t('challenges.tableHeaders.user')}</b> },
    { id: 'location', label: <b>{t('challenges.tableHeaders.location')}</b> },
    { id: 'filesInformation', label: <b>{t('challenges.tableHeaders.filesInfo')}</b> },
  ]), [t]);

  const subtitleState = [
    'open',
    'waitingForApproval',
    'approved',
    'processing',
    'close',
    'rejected',
  ];

  const requestUsers = useCallback(async () => {
    const response = await getUsersByCompany();
    if (response.success) {
      setUsers(response.data.data.users);
    }
  }, [filterUser]);

  const requestSearch = useCallback(async (pageOfTable, limit, searchValue, columnName) => {
    setLoading(true);

    // Format the start date to the beginning of the day in UTC.
    const startDateString = `${startDate}T00:00:00.000Z`;

    // Format the end date to the end of the day in UTC.
    const endDateString = `${endDate}T23:59:59.999Z`;

    // Send the request to get tickets with the specified parameters.
    const response = await getTickets(
      pageOfTable,
      limit,
      searchValue,
      columnName,
      true,
      sortBy,
      orderBy,
      false,
      filterChallengeStatus,
      filterUser,
      filterLocation,
      startDateString,
      endDateString,
    );

    if (response.success) {
      setRowsToShow(response.data.data.tickets);
      setTicketsQuantity(response.data.data.totalTickets);
      setNextUpdateTimeUTC(response.data.data.nextUpdateTimeUTC);
      setLastUpdateTimeUTC(response.data.data.lastUpdateTimeUTC);
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }

    setLoading(false);
  }, [
    sortBy,
    orderBy,
    filterChallengeStatus,
    filterUser,
    filterLocation,
    startDate,
    endDate,
  ]);

  const sendResponseHandler = useCallback(async (response) => {
    if (response.success) {
      await requestSearch(page, rowsPerPage, filter, column);
      setMessage({ type: 'success', text: t('challenges.uploadFileSuccess') });
    } else {
      setMessage({ type: 'error', text: t('challenges.serverError') });
    }
  }, [page, rowsPerPage, filter, column]);

  const setFilterOptions = useCallback((newValue, tableColumn) => {
    if (Array.isArray(tableColumn)) {
      setSearchText(newValue);
    } else {
      setSearchText('');
    }
    setColumn(tableColumn);
    setFilter(newValue);
    setPage(0);
    if (!tableColumn) {
      setSearchText('');
      setFilter('');
    }
  }, []);

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const handleFilter = useCallback((event) => {
    const { name, value } = event.target;
    if (name === 'challengeStatus') {
      setFilterChallengeStatus(value);
    } else if (name === 'user') {
      setFilterUser(value);
    } else if (name === 'location') {
      setFilterLocation(value);
    } else if (value === 'estimatedClosingDate' || value === 'lastModifiedDate') {
      setSortBy(value);
    } else if (value === 'ASC' || value === 'DESC') {
      setOrderBy(value);
    }
  }, []);

  const load = useCallback(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1500);
  }, []);

  const reload = useCallback(async () => {
    load();
    await requestSearch(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChallengeState = (challengeStatus) => {
    switch (challengeStatus) {
      case 'Pending':
        return (
          <p align="left" className={`${styles.tag} ${styles.tagWaitinfForApproval}`}><b>- {t('challenges.pending')}</b></p>
        );
      case 'Explained':
        return (
          <p align="left" className={`${styles.tag} ${styles.tagProcessing}`}><b>O {t('challenges.explained')}</b></p>
        );
      case 'Solved':
        return (
          <p align="left" className={`${styles.tag} ${styles.tagClose}`}><b>✔ {t('challenges.solved')}</b></p>
        );
      default:
        return (
          <p align="left" className={`${styles.tag} ${styles.tagNA}`}>O <b>N/A</b></p>
        );
    }
  };

  useEffect(() => {
    const getData = async () => {
      await dispatch(projectActions.actionFetchProjects());
      await requestSearch(page, rowsPerPage, filter, column);
    };
    getData();

    if ((filterUser === '' || filterUser[0] === 'user') && account.user.role !== CLIENT_EMPLOYEE) {
      requestUsers();
    }
  }, [
    page,
    rowsPerPage,
    filter,
    column,
    ticketsQuantity,
    account,
    dispatch,
    company,
    sortBy,
    orderBy,
    filterChallengeStatus,
    filterUser,
    filterLocation,
    startDate,
    endDate,
  ]);

  const content = useMemo(() => {
    if (!ticketsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={9} className={styles.noChallenges}>
            <ConfirmationNumberIcon />
            <h4>{t('challenges.noChallenges')}</h4>
          </TableCell>
        </TableRow>
      );
    }

    return rowsToShow
      .map((row) => (
        <TableRow hover key={row.id}>
          <TableCell
            component="th"
            scope="row"
            padding="none"
            className={styles.projectName}
          >{row.Project.name}</TableCell>
          <TableCell align="left">{parseDate(row.createdAt)}</TableCell>
          <TableCell align="left">{parseDate(row.estimatedClosingDate) || '-'}</TableCell>
          <TableCell align="left">{row.tokensConsumed || '-'}</TableCell>
          <TableCell align="left">{handleChallengeState(row.challengeStatus)}</TableCell>
          <TableCell align="left">{row.challengeResponse || '-'}</TableCell>
          <TableCell align="left">{row.User.fullName}</TableCell>
          <TableCell align="left">{row.Location ? row.Location.name : '-'}</TableCell>
          <TableCell align="left" className={styles.iconsCell}>
            <Modal view={true}>
              <InformationCard
                data={row}
                projects={projects}
                reload={reload}
                setMessage={setMessage}
                stateBadge={handleChallengeState(row.challengeStatus)}
                sendResponse={sendResponseHandler}
              />
            </Modal>
          </TableCell>
        </TableRow>
      ));
  }, [
    ticketsQuantity,
    rowsToShow,
    styles.noChallenges,
    t,
    styles.projectName,
    styles.iconsCell,
    projects,
    reload,
    sendResponseHandler,
  ]);

  return (
    <>
      <Header
        ticketsQuantity={ticketsQuantity}
        subtitleState={subtitleState}
        filter={filter}
        setOpenNewChallengeModal={setOpenNewChallengeModal}
        nextUpdateTimeUTC={nextUpdateTimeUTC}
        lastUpdateTimeUTC={lastUpdateTimeUTC}
      />
      <Toolbar className={styles.searchToolbar} position='fixed' disableGutters>
        <Box className={styles.boxTitle}>
          <MuiTextField
            className={styles.searchField}
            id="search"
            variant="outlined"
            value={searchText}
            onChange={(event) => setFilterOptions(event.target.value, ['crmTicketId', 'subject'])}
            placeholder={t('support.tableToolbar.filterPlaceholder')}
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
              endAdornment: (
                <IconButton
                  id="clear"
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: searchText ? 'visible' : 'hidden' }}
                  onClick={() => setFilterOptions('', false)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Box>

      </Toolbar>
      <div className={styles.formWrapper}>
        <Grid container spacing={2} className={styles.optionsContainer}>
          <Grid item>
            <InputLabel id="startDate" className={styles.labelDates}>{t('support.startDate')}</InputLabel>
            <FormControl className={styles.formControlDates}>
              <MuiTextField
                id="startDate"
                type="date"
                variant="standard"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <InputLabel id="endDate" className={styles.labelDates}>{t('support.endDate')}</InputLabel>
            <FormControl className={styles.formControlDates}>
              <MuiTextField
                id="endDate"
                type="date"
                variant="standard"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={styles.formControl}>
              <InputLabel id="challengeStatus">{t('support.filterByChallengeStatus')}</InputLabel>
              <Select
                labelId="challengeStatus"
                name="challengeStatus"
                value={filterChallengeStatus || ''}
                onChange={handleFilter}
              >
                <MenuItem key={'all'} value={''}>
                  {t('home.tickets.all')}
                </MenuItem>
                <MenuItem key={'pending'} value={'pending'}>
                  {t('home.tickets.pending')}
                </MenuItem>
                <MenuItem key={'explained'} value={'explained'}>
                  {t('home.tickets.explained')}
                </MenuItem>
                <MenuItem key={'solved'} value={'solved'}>
                  {t('home.tickets.solved')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={styles.formControl}>
              <InputLabel id="location">{t('support.filterByLocation')}</InputLabel>
              <Select
                labelId="location"
                name="location"
                value={filterLocation || ''}
                onChange={handleFilter}
              >
                <MenuItem key={'all'} value={''}>
                  {t('home.tickets.all')}
                </MenuItem>
                {locations && locations.map((l) => (
                  <MenuItem key={l.locationId} value={l.locationId}>
                    {l.Location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {account.user.role === CLIENT_EMPLOYEE ? null : (
            <Grid item>
              <FormControl className={styles.formControl}>
                <InputLabel id="user-label">{t('support.filterByUser')}</InputLabel>
                <Select
                  labelId="user-label"
                  name="user"
                  value={filterUser || ''}
                  onChange={handleFilter}
                >
                  <MenuItem key={'all'} value={''}>
                    {t('home.tickets.all')}
                  </MenuItem>
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <FormControl className={styles.formControl}>
              <InputLabel id="demo-simple-select-label">{t('support.filterBy')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sortBy}
                onChange={handleFilter}
              >
                <MenuItem key={'lastModifiedDate'} value={'lastModifiedDate'}>
                  {t('support.lastModifiedDate')}
                </MenuItem>
                <MenuItem key={'estimatedClosingDate'} value={'estimatedClosingDate'}>
                  {t('support.closingDate')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={styles.formControl}>
              <InputLabel id="demo-simple-select-label">{t('support.sortBy')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={orderBy}
                onChange={handleFilter}
              >
                <MenuItem key={'ASC'} value={'ASC'}>
                  {t('support.oldest')}
                </MenuItem>
                <MenuItem key={'DESC'} value={'DESC'}>
                  {t('support.mostRecent')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <CustomButton
            id="create-user-btn"
            text={'+ Challenge'}
            variant="contained"
            height="40px"
            width="208px"
            backgroundColor='#2C9DC9'
            color='white'
            borderRadius='10px'
            onClick={() => setOpenNewChallengeModal(true)}
          />
        </Grid>
      </div>
      <Paper className={styles.challengeTable}>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {tableHeaders.map((tableHeader) => (
                  <TableCell
                    key={tableHeader.id}
                    className={styles.tableHeader}
                    align={tableHeader.id === 'total_challenge'
                      || tableHeader.id === 'challengeExplained'
                      || tableHeader.id === 'challengeSolved'
                      || tableHeader.id === 'percentageExplained'
                      || tableHeader.id === 'action1'
                      || tableHeader.id === 'action2' ? 'center' : 'left'}
                    padding="normal"
                  >
                    {tableHeader.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={9} className={styles.progress}>
                    <CircularProgress />
                    <h4>{t('support.loading')}</h4>
                  </TableCell>
                </TableRow>
              ) : content}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          quantity={ticketsQuantity}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          handleChangePage={handleChangePage}
        />
      </Paper>
      <div>
        <Snackbar
          open={message.text !== ''}
          message={message.text}
          severity={message.type}
          onClose={() => setMessage({ type: 'info', text: '' })}
        />
      </div>
    </>
  );
};

export default ChallengesList;

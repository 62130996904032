import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// UI components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// API calls
import {
  postApproveSurvey,
} from '../../../requests/api/survey-response';

const ApproveSurveyDialog = ({
  surveyResponseId,
  openDialog,
  setOpenDialog,
  setFinishReview,
}) => {
  const [uiDisabled, setUiDisabled] = useState(false);
  const { t } = useTranslation();

  const handleApprove = () => {
    setUiDisabled(true);

    const responsePromise = postApproveSurvey(surveyResponseId);

    responsePromise.then((response) => {
      if (response.status === 200) {
        setUiDisabled(false);
        setOpenDialog(false);
        setFinishReview(true); // signal to return to survey list categories.
      } else {
        setUiDisabled(false);
        //        TODO: Add user message
      }
    });
  };

  /**
   * Close dialog.
   */
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle>{t('qa.approveForm')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('qa.approveFormMessage')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={uiDisabled} onClick={handleClose}>{t('qa.cancel')}</Button>
        <Button variant="contained" disabled={uiDisabled} onClick={handleApprove}>{t('qa.approve')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveSurveyDialog;

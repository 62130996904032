import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import CancelIcon from '@material-ui/icons/Cancel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Paper,
  Checkbox,
  CircularProgress,
  Modal as MuiModal,
  Typography,
  Backdrop,
  Fade,
} from '@material-ui/core';
// icons
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import Button from '../../components/Button';
import Snackbar from '../../components/Snackbar';
import { SUPER_ADMIN } from '../../utils/const/user';

import FilesDropzone from '../../components/FileDropzone/FileDropzone';
import { getTermsAndConditons } from '../../requests/api/document';

import useStyles from './styles';

const Terms = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [documentsToShow, setDocumentsToShow] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [documentsQuantity, setDocumentsQuantity] = useState(0);

  const tableHeaders = ['ID', t('terms.documentName'), t('terms.actions')];
  const account = useSelector((state) => state.account);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (account.user.role !== SUPER_ADMIN) {
      window.location.href = '/home';
    }
    const requestSearch = async () => {
      const response = await getTermsAndConditons();
      if (response.success) {
        setDocumentsToShow(response.data.data.document ? [response.data.data.document] : []);
        setDocumentsQuantity(1);
      }
    };

    setLoading(true);
    requestSearch(page, rowsPerPage, '');
    setLoading(false);
  }, [page, rowsPerPage, account.user]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Select all rows
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = documentsToShow.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Select this row
  const handleSelect = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const getAmazonObject = async (fileKey) => {
    const response = await axios.get(`/api/documents/getObjectS3?fileName=${fileKey}`);
    if (response.data.success) {
      const { returnData } = response.data.data;
      const { signedRequest } = returnData;
      window.open(signedRequest);
    }
  };

  const content = () => {
    if (!documentsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={7} className={classes.documents}>
            <PeopleAltIcon />
            <h4>{t('terms.noContent')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {documentsToShow
          .map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleSelect(event, row.id)}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                >
                  {row.id}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="normal"
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.viewFileButton}>
                    <VisibilityIcon
                      className={classes.button}
                      onClick={() => getAmazonObject(row.fileKey)}
                    />
                </TableCell>
              </TableRow>
            );
          })}
      </>
    );
  };

  const conditionalRender = () => {
    if (loading) {
      return (
        <div className={classes.container}>
          <CircularProgress className={classes.loading} />
        </div>
      );
    }
    return (
      <>
        <Toolbar disableGutters={true}>
          <Typography className={classes.title} variant='h1' >{t('terms.title')}</Typography>
          <div>
            <Button
              id="submit-document-btn"
              onClick={handleOpen}
              text={t('terms.addButton')}
              height="50px"
              width="130px"
              backgroundColor='theme-primary-blue'
              color='theme-primary-white'
              borderRadius='30px'
            />
            <MuiModal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Paper className={classes.paperContainer} >
                  <Toolbar className={classes.modalToolbar}>
                    <IconButton onClick={handleClose}>
                      <CancelIcon align="left" fontSize="large" className={classes.loading} />
                    </IconButton>
                  </Toolbar>
                  <FilesDropzone onClose={handleClose} />
                </Paper>
              </Fade>
            </MuiModal>
          </div>
        </Toolbar>
        {/* TABLE */}
        <Paper className={classes.table}>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader} padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < documentsToShow.length
                      }
                      checked={
                        documentsToShow.length > 0 && selected.length === documentsToShow.length}
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  {tableHeaders.map((tableHeader) => (
                    <TableCell
                      key={tableHeader}
                      align="left"
                      padding="normal"
                      className={classes.tableHeader}
                    >
                      {tableHeader === 'name' && selected.length
                        ? `${t('terms.selected')} ${selected.length} ${t('terms.items')}`
                        : tableHeader}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} className={classes.progress}>
                      <CircularProgress />
                      <h4>{t('terms.loading')}</h4>
                    </TableCell>
                  </TableRow>
                ) : content()}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className={classes.itemList}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={documentsQuantity}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </>
    );
  };

  return (
    <div className={classes.content}>
      {conditionalRender()}
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
      </div>
    </div>
  );
};

export default Terms;

import { useTranslation } from 'react-i18next';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  Typography,
  Grid,
  Link,
  IconButton,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Report from '../../../components/Report';
import useStyles from '../styles';
import { getReport } from '../../../requests/api/report';

const DrawerTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.blue,
    color: theme.palette.primary.white,
    textAlign: 'center',
    width: '95px',
    maxWidth: '100%',
    fontSize: '1em',
  },
  arrow: {
    color: theme.palette.primary.main,
    border: '0px',
  },
}))(Tooltip);

function ReportView() {
  const styles = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const [reportName, setReportName] = useState('');
  const handle = useFullScreenHandle();

  useEffect(() => {
    const findReport = async () => {
      const response = await getReport(id);
      if (response.success) {
        const powerbiData = response.data.data.powerbiReport;
        setReportName(powerbiData.reportName);
      }
    };
    findReport();
  }, [id, t]);

  return (
    <>
    <br />
    <div className={styles.content}>
      <div >
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
          <Typography
            className={styles.title}
            variant="h1"
            noWrap
            component={Link}
            gutterBottom
            display="inline"
          >
            <Link href="/reports" underline="none">
              {t('reports.title')}
            </Link>
          </Typography>
          <Typography variant='subtitle1' className={styles.underlined} gutterBottom display="inline">
            {' / '} {reportName}
          </Typography>
          </Grid>
          <Grid item>
          <DrawerTooltip TransitionComponent={Zoom} placement='right' title={t('reports.fullScreen')} arrow>
            <IconButton onClick={handle.enter}>
              <FullscreenIcon fontSize='large'/>
            </IconButton>
          </DrawerTooltip>
          </Grid>
        </Grid>
    </div>
    <br />
    <FullScreen
    handle={handle}
    >
      <Report fullScreen={handle.active} />
    </FullScreen>
    </div></>
  );
}

export default ReportView;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// core
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
// icons
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  pagesContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0px 70px',
    marginRight: '65px',
    color: theme.palette.primary.gray,
    fontSize: '13px',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& button': {
      padding: '0px',
      backgroundColor: theme.palette.primary.snow,
    },
    '& p': {
      margin: '15px',
    },
  },
}));

function Pagination({
  setPage, page, rowsPerPage, quantity,
}) {
  const { t } = useTranslation();
  const styles = useStyles();

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const lastPage = Math.ceil(quantity / rowsPerPage) - 1;

  const calcNextPage = () => {
    if (page === 0 && lastPage === 1) {
      return 'none';
    }

    if (page === 0 && lastPage === 2) {
      return '';
    }

    if (page >= lastPage) {
      return 'none';
    }
    return '';
  };

  return (
    <div className={styles.pagesContainer}>
      <p>
        {t('pagination.paginationShow')}{' '}
        {`${page === 0 ? '1' : rowsPerPage * page + 1}-${
          (page + 1) * rowsPerPage < quantity
            ? rowsPerPage * (page + 1)
            : quantity
        }`}{' '}
        {t('pagination.paginationNexus')} {quantity}
      </p>
      <div>
        <IconButton
          disabled={page === 0}
          onClick={() => handleChangePage(page - 1)}
        >
          <ChevronLeft />
        </IconButton>
        <p>
          <span
            style={{
              color: page === 0 ? '#2C9DC9' : '',
              cursor: 'pointer',
            }}
            onClick={() => (page === 0 ? null : handleChangePage(page - 1))}
          >
            {page === 0 ? page + 1 : page}
          </span>

          <span
            style={{
              color: page > 0 ? '#2C9DC9' : '',
              margin: '0px 10px',
              display: (page === 0 ? page + 1 : page) * rowsPerPage >= quantity ? 'none' : '',
              cursor: 'pointer',
            }}
            onClick={() => (page === 0 ? handleChangePage(page + 1) : null)}
          >
            {page === 0 ? page + 2 : page + 1 }
          </span>

          <span
            style={{
              display: calcNextPage(),
              cursor: 'pointer',
            }}
            onClick={() => (page === 0 ? handleChangePage(page + 2) : handleChangePage(page + 1))}
          >
            {page === 0 ? page + 3 : page + 2 }
          </span>
        </p>
        <IconButton
          disabled={page === lastPage}
          onClick={() => handleChangePage(page + 1)}
        >
          <ChevronRight />
        </IconButton>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default Pagination;

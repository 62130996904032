import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Checkbox,
  CircularProgress,
  Popper,
  Box,
  Fade,
} from '@material-ui/core';
import generateExcel from 'zipcelx';

// icons
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '../../components/Button';
// components
import TableToolbar from '../../components/TableToolbar';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Snackbar from '../../components/Snackbar';

import useStyles from './styles';
import { deleteProduct, getProduct, getProductsPerPage } from '../../requests/api/product';

import ProductInformation from './ProductInformation';
// const
import { UNAUTHORIZED } from '../../utils/const/http';
import { SUPER_ADMIN } from '../../utils/const/user';

function Products() {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [productsQuantity, setProductsQuantity] = useState(0);

  // auxiliar states
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  // Filter states
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  // Edit product states
  const [productId, setProductId] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [currentOpen, setCurrentOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [readOnly, setReadOnly] = useState(false);

  // snackbar
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  // popper
  const openPopper = Boolean(anchorEl);
  const idPopper = openPopper ? 'simple-popper' : undefined;

  const account = useSelector((state) => state.account);
  const company = useSelector((state) => state.company);

  const tableHeaders = [
    {
      id: 'name',
      label: t('products.columnNames.name'),
    },
    {
      id: 'company',
      label: t('products.columnNames.company'),
    },
    {
      id: 'distributor',
      label: t('products.columnNames.distributor'),
    },
    {
      id: 'category',
      label: t('products.columnNames.category'),
    },
    {
      id: 'size',
      label: t('products.columnNames.size'),
    },
    {
      id: 'deleted',
      label: t('products.columnNames.deleted'),
    },
    {
      id: 'actions',
      label: '',
    },
  ];

  const filterPlaceholder = t('products.tableToolbar.filterPlaceholder');

  const requestSearch = async (pageOfTable, limit, searchValue, columnName) => {
    const response = await getProductsPerPage(pageOfTable, limit, searchValue, columnName);

    if (response.success) {
      setRowsToShow(response.data.data.products);
      setProductsQuantity(response.data.data.totalProducts);
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }

    if (Array.isArray(columnName)) setSearchText(searchValue);
  };

  const setFilterOptions = (newValue, tableColumn) => {
    setPage(0);
    if (Array.isArray(tableColumn)) setSearchText(newValue);

    setColumn(tableColumn);
    setFilter(newValue);

    // Clear filters
    if (!tableColumn) {
      setSearchText('');
      setFilter('');
    }
  };

  // Select all rows
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rowsToShow.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // Select this row
  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    setProductId(id);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Saves the product information to be edited
  const handleEditShow = async (id, edit) => {
    const response = await getProduct(id);

    if (edit) {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }

    if (response.status === 200) {
      setProductInfo(response.data.data.product);
      setCurrentOpen(true);
    } else {
      setSeverity('error');
      setMessage(response.data.errorMessage);
    }

    setAnchorEl(null);
  };

  // Delete product
  const handleDelete = async (event) => {
    const { id } = event.currentTarget;
    const response = await deleteProduct(id);

    if (response.status === 200) {
      setSeverity('success');
      setMessage(t('products.deleteSuccess'));
    }
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getRowData = (data) => {
    const rowData = [];

    const auxId = {};
    auxId.value = data.id;
    auxId.type = 'number';
    rowData.push(auxId);

    const auxName = {};
    auxName.value = data.productName;
    auxName.type = 'string';
    rowData.push(auxName);

    const auxCompanyName = {};
    auxCompanyName.value = data.Company ? data.Company.companyName : data.companyName;
    auxCompanyName.type = 'string';
    rowData.push(auxCompanyName);

    const auxDistributor = {};
    auxDistributor.value = data.distributor ? data.distributor : 'N/A';
    auxDistributor.type = 'string';
    rowData.push(auxDistributor);

    const auxCategory = {};
    auxCategory.value = data.Category ? data.Category.name : 'N/A';
    auxCategory.type = 'string';
    rowData.push(auxCategory);

    const auxSize = {};
    auxSize.value = data.size ? data.size : 'N/A';
    auxSize.type = 'string';
    rowData.push(auxSize);

    const auxDeleted = {};
    auxDeleted.value = data.deleted;
    auxDeleted.type = 'string';
    rowData.push(auxDeleted);

    return rowData;
  };

  const getExcel = async (event, pageOfTable, limit, searchValue, columnName) => {
    // https://codesandbox.io/s/react-table-excel-export-g7hoe?file=/src/App.js:5286-6268
    const config = {
      filename: 'TCG-products',
      sheet: {
        data: [],
      },
    };

    const headers = [];
    // añadimos header 'id':
    const idHeader = { value: 'id', type: 'string' };
    headers.push(idHeader);
    // añadimos headers mostrados en la tabla:
    tableHeaders.forEach((item) => {
      const rowCell = { value: '', type: '' };
      rowCell.value = item.label;
      rowCell.type = 'string';
      headers.push(rowCell);
    });

    const response = await getProductsPerPage(pageOfTable, limit, searchValue, columnName);

    if (response.success) {
      const allProducts = await
      getProductsPerPage(0, response.data.data.totalProducts, searchValue, columnName);
      const rows = [];
      if (allProducts.success) {
        const data = allProducts.data.data.products;
        rows.push(headers);
        data.forEach((item) => {
          const rowData = getRowData(item);
          rows.push(rowData);
        });

        config.sheet.data = rows;
      }
    }

    return generateExcel(config);
  };

  useEffect(() => {
  }, [productInfo]);

  useEffect(() => {
    load();
  }, [rowsToShow]);

  useEffect(() => {
    if (account.user.role !== SUPER_ADMIN) {
      window.location.href = '/home';
    }

    requestSearch(page, rowsPerPage, filter, column);
  }, [column, filter, page, rowsPerPage, account.user.role, company, update]);
  const content = () => {
    if (!productsQuantity) {
      return (
        <TableRow className={styles.noContent}>
          <TableCell colSpan={7} className={styles.products}>
            <PeopleAltIcon />
            <h4>{t('products.noContent')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleSelect(event, row.id)}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  {row.productName}
                </TableCell>
                <TableCell align="left">{row.Company ? row.Company.companyName : row.companyName}</TableCell>
                <TableCell align="left">{row.distributor ? row.distributor : 'N/A'}</TableCell>
                <TableCell align="left">{row.Category ? row.Category.name : 'N/A'}</TableCell>
                <TableCell align="center">{row.size ? row.size : 'N/A'}</TableCell>
                <TableCell align="center">
                  {row.deleted ? (
                    <DoneIcon fontSize="small" />
                  ) : (
                    <ClearIcon fontSize="small" />
                  )}
                </TableCell>
                <TableCell align="right" className={styles.iconsCell}>
                  <Box className={styles.info}>
                    <Popper id={idPopper} open={openPopper} anchorEl={anchorEl} transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                            <Button onClick={() => handleEditShow(anchorEl.id, false)} text={t('products.detail')} />
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </Box>
                  <Button id={`${row.id}`} aria-describedby={idPopper} onClick={handleClick}>
                    <MoreHoriz />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
      </>
    );
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Typography variant='h1' className={styles.title}>{t('products.title')}</Typography>
      </div>
      <Paper className={styles.productTable}>
        {productInfo ? (
          <Modal title={t('products.productForm.editModalTitle')} defaultOpen={currentOpen} setCurrentOpen={setCurrentOpen}>
            <ProductInformation
              productInfo={productInfo}
              setProductInfo={setProductInfo}
              readOnly={readOnly}
              setUpdate={setUpdate}
            />
          </Modal>
        ) : null}
        <>
          <TableToolbar
            filterButton={false}
            filterOptions={['name']}
            filterPlaceholder={filterPlaceholder}
            info={[]}
            numSelected={selected.length}
            open={open}
            searchBar
            searchText={searchText}
            setOpen={setOpen}
            setFilterOptions={setFilterOptions}
            handleEditShow={handleEditShow}
            handleDelete={handleDelete}
            elementId={productId && productId}
            component="product"
            createModalTitle={t('products.productForm.modalTitle')}
            buttonTitle={t('products.addButton')}
            getExcel={getExcel}
            page={page}
            rowsPerPage={rowsPerPage}
            filter={filter}
            column={column}
            isProduct={true}
            setUpdate={setUpdate}
          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHeader} padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < rowsToShow.length
                      }
                      checked={rowsToShow.length > 0 && selected.length === rowsToShow.length}
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                  {tableHeaders.map((tableHeader) => (
                    <TableCell
                      key={tableHeader.id}
                      className={styles.tableHeader}
                      align={tableHeader.id === 'active' ? 'center' : 'left'}
                      padding="normal"
                    >
                      {tableHeader.id === 'name' && selected.length
                        ? `Selected ${selected.length} products`
                        : tableHeader.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} className={styles.progress}>
                      <CircularProgress />
                      <h4>{t('products.loading')}</h4>
                    </TableCell>
                  </TableRow>
                ) : content()}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            setPage={setPage}
            page={page}
            rowsPerPage={rowsPerPage}
            quantity={productsQuantity}
          />
        </>
      </Paper>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div>
  );
}

export default Products;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Typography,
  Paper,
} from '@material-ui/core';
// components
import useStyles from './styles';
import TableToolbarTabs from './administrationToolbar';
import CompaniesAdmin from './Companies';
import ReportsAdmin from './Reports';
import ProjectsAdmin from './Projects';
import NewsAdmin from './News';
import TYCAdmin from './TyC';
import HomeMessage from './HomeMessage';

// eslint-disable-next-line no-shadow
const Administration = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  // auxiliar states
  const toolbarTabs = ['companies', 'projects', 'reports', 'news', 'tyc', 'home message'];
  const [tab, setTab] = useState(0);

  const conditionalRender = () => {
    switch (tab) {
      case (5): {
        return <HomeMessage/>;
      }
      case (4): {
        return <TYCAdmin/>;
      }
      case (3): {
        return <NewsAdmin/>;
      }
      case (2): {
        return <ReportsAdmin/>;
      }
      case (1):
        return <ProjectsAdmin/>;
      default:
        return <CompaniesAdmin/>;
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
          <Typography variant='h1' className={styles.title}>
          {t('administration.title')}
          </Typography>
      </div>
      <TableToolbarTabs className={styles.toolbarTable}
            toolbarTabs={toolbarTabs}
            tab={tab}
            setTab={setTab}
            >
        </TableToolbarTabs>
          <Paper className={styles.documentsTable}>
          {conditionalRender()}
      </Paper>
    </div>
  );
};

export default Administration;

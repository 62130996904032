import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// UI components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// API calls
import {
  postRejectSurvey,
} from '../../../requests/api/survey-response';
import {
  getFeedbackLabelsList,
} from '../../../requests/api/feedback-label';

const RejectSurveyDialog = ({
  surveyResponseId,
  openDialog,
  setOpenDialog,
  setFinishReview,
}) => {
  const { t } = useTranslation();
  const [uiDisabled, setUiDisabled] = useState(false);

  const [feedbackLabelsList, setFeedbackLabelsList] = useState([]);
  const [feedbackLabelId, setFeedbackLabelId] = useState(0);
  const [feedbackComments, setFeedbackComments] = useState('');

  useEffect(() => {
    async function getFeedbackLabels() {
      const a = await getFeedbackLabelsList();
      setFeedbackLabelsList(a.data.data.feedbackLabelsList);
    }

    getFeedbackLabels();
  }, []);

  const handleReject = () => {
    const responsePromise = postRejectSurvey(surveyResponseId, feedbackLabelId, feedbackComments);

    responsePromise.then((response) => {
      if (response.status === 200) {
        setUiDisabled(false);
        setOpenDialog(false);
        setFinishReview(true); // signal to return to survey list categories.
      } else {
        setUiDisabled(false);
        //        TODO: Add user message
      }
    });
  };

  /**
   * Close dialog.
   */
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle>{t('qa.rejectForm')}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <FormLabel component="legend">{t('qa.chooseLabel')}</FormLabel>
          <Autocomplete
            options={feedbackLabelsList}
            getOptionLabel={(option) => option.name}
            fullWidth
            renderInput={(params) => <TextField {...params} variant="outlined" />}
            onChange={
              (event, newValue) => {
                setFeedbackLabelId((newValue === null) ? 0 : newValue.id);
              }
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel component="legend">{t('qa.comments')}</FormLabel>
          <TextField
            id="comments"
            variant="outlined"
            fullWidth
            onChange={(event) => { setFeedbackComments(event.target.value); }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={uiDisabled} onClick={handleClose}>{t('qa.cancel')}</Button>
        <Button variant="contained" disabled={uiDisabled} onClick={handleReject}>{t('qa.reject')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectSurveyDialog;

import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR, OK } from '../../utils/const/http';

const createProject = async ({
  name,
  companyId,
  tokens,
},
) => {
  try {
    const response = await axios.post('/api/project', {
      name,
      companyId,
      tokens,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      totalTickets: response.data,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProjects = async (page, limit, searchValue, column) => {
  try {
    const newLimit = limit === undefined ? '' : limit;
    const newSearchValue = searchValue === undefined ? '' : searchValue;
    const newColumn = column === undefined ? '' : column;
    const newPage = page === undefined ? '' : page;

    const response = await axios.get(`/api/project/?page=${newPage}&limit=${newLimit}&searchValue=${newSearchValue}&column=${newColumn}`);
    const { projects } = response.data.data;
    const totalTicketsInfo = {
      total: 0,
      open: 0,
      waitingForApproval: 0,
      approved: 0,
      processing: 0,
      close: 0,
      rejected: 0,
      explained: 0,
      solved: 0,
      challenges: 0,
    };
    for (let i = 0; i < projects.length; i += 1) {
      const thisProject = projects[i];
      for (let j = 0; j < thisProject.ticketCounts.length; j += 1) {
        const thisState = thisProject.ticketCounts[j].state;
        const thisCount = thisProject.ticketCounts[j].count;
        switch (thisState) {
          case 'open': {
            totalTicketsInfo.open += thisCount;
            totalTicketsInfo.total += thisCount;
            break;
          }
          case 'waitingForApproval': {
            totalTicketsInfo.waitingForApproval += thisCount;
            totalTicketsInfo.total += thisCount;
            break;
          }
          case 'approved': {
            totalTicketsInfo.approved += thisCount;
            totalTicketsInfo.total += thisCount;
            break;
          }
          case 'processing': {
            totalTicketsInfo.processing += thisCount;
            totalTicketsInfo.total += thisCount;
            break;
          }
          case 'close': {
            totalTicketsInfo.close += thisCount;
            totalTicketsInfo.total += thisCount;
            break;
          }
          case 'solved': {
            totalTicketsInfo.solved += thisCount;
            break;
          }
          case 'explained': {
            totalTicketsInfo.explained += thisCount;
            break;
          }
          case 'total': {
            totalTicketsInfo.challenges += thisCount;
            break;
          }
          default:
            totalTicketsInfo.rejected += thisCount;
            totalTicketsInfo.total += thisCount;
        }
      }
    }
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
      totalTickets: totalTicketsInfo,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProject = async (id) => {
  try {
    const response = await axios.get(`/api/project/${id}`);
    const { project } = response.data.data;
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: project,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getNotUserProjects = async (userId) => {
  try {
    const response = await axios.get(`/api/not-user-projects/${userId}`);
    const { notUserProjects } = response.data.data;
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: notUserProjects,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getUserIdProjects = async (userId, page, limit) => {
  try {
    const response = await axios.get(`/api/user-projects/${userId}/?page=${page}&limit=${limit}`);
    const { projects, totalProjects, user } = response.data.data;
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      projects,
      totalProjects,
      user,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const createUserProject = async ({ userId, projectId, role }) => {
  try {
    const response = await axios.post('/api/user-project/', {
      userId, projectId, role,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const updateUserProject = async ({ userId, projectId, role }) => {
  try {
    const response = await axios.put('/api/user-project/', {
      userId, projectId, role,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteUserProject = async ({ userProjectId }) => {
  try {
    const response = await axios.delete(`/api/user-project/${userProjectId}`);
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProjectsPerPage = async (page, limit, searchValue, column) => {
  try {
    const { data } = await axios.get(`/api/projects/?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const updateProject = async ({
  id, companyId, name, tokens,
}) => {
  try {
    const response = await axios.put('/api/project/', {
      id, companyId, name, tokens,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const updateTokens = (projectId, tokens) => {
  try {
    const response = axios.put(`/api/projects/${projectId}/tokens`, {
      tokens,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  createProject,
  getProjects,
  getProject,
  getNotUserProjects,
  getUserIdProjects,
  getProjectsPerPage,
  createUserProject,
  updateUserProject,
  deleteUserProject,
  updateProject,
  updateTokens,
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Checkbox,
  CircularProgress,
  Grid,
} from '@material-ui/core';

import generateExcel from 'zipcelx';

// icons
import { Link } from 'react-router-dom';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
// components
import IconButton from '@material-ui/core/IconButton';
import Button from '../../components/Button';
import { deleteUser, getUsersPerPage } from '../../requests/api/user';

import TableToolbar from './TableToolbar';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';

import useStyles from './styles';

import UserInformation from './UserInformation';
// const
import { UNAUTHORIZED } from '../../utils/const/http';
import { SUPER_ADMIN } from '../../utils/const/user';

function Users() {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [usersQuantity, setUsersQuantity] = useState(0);

  // auxiliar states
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Filter states
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  // Edit User states
  const [userId, setUserId] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [userEdited, setUserEdited] = useState(false);
  const [currentOpen, setCurrentOpen] = useState(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);

  const [update, setUpdate] = useState(false);

  const account = useSelector((state) => state.account);

  const tableHeaders = [
    {
      id: 'fullName',
      label: t('users.columnNames.user'),
    },
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'email',
      label: t('users.columnNames.email'),
    },
    {
      id: 'role',
      label: t('users.columnNames.role'),
    },
    {
      id: 'active',
      label: t('users.columnNames.active'),
    },
    {
      id: 'actions',
      label: '',
    },
  ];

  const handleDeleteDialog = (e) => {
    setOpenDeleteUserDialog(e);
  };

  const requestSearch = async (pageOfTable, limit, searchValue, columnName) => {
    const response = await getUsersPerPage(pageOfTable, limit, searchValue, columnName);
    if (response.success) {
      setRowsToShow(response.data.data.users);
      setUsersQuantity(response.data.data.totalUsers);
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const setFilterOptions = (newValue, tableColumn) => {
    if (Array.isArray(tableColumn)) {
      setSearchText(newValue);
    } else {
      setSearchText('');
    }

    setColumn(tableColumn);
    setFilter(newValue);
    setPage(0);

    // Clear filters
    if (!tableColumn) {
      setSearchText('');
      setFilter('');
    }
  };

  // Select all rows
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rowsToShow.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // Select this row
  const handleSelect = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    setUserId(name);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Saves the user information to be edited
  const handleEdit = async (event) => {
    const { id } = event.currentTarget;
    const numberId = Number.parseInt(id, 10);

    const userToEdit = rowsToShow.filter((row) => row.id === numberId);

    if (userToEdit.length) setUserInfo(userToEdit[0]);

    setCurrentOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    for (let i = 0; i < selected.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await deleteUser(selected[i]);
    }
    setSelected([]);
    setOpenDeleteUserDialog(false);
    requestSearch(page, rowsPerPage, filter, column);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  useEffect(() => {
  }, [userInfo]);

  useEffect(() => {
    load();
  }, [rowsToShow]);

  useEffect(() => {
    if (account.user.role !== SUPER_ADMIN) {
      window.location.href = '/home';
    }

    requestSearch(page, rowsPerPage, filter, column);
  }, [column, userEdited, filter, page, rowsPerPage, account.user.role, update]);

  const getRowData = (data) => {
    const rowData = [];

    const auxFullName = {};
    auxFullName.value = data.fullName;
    auxFullName.type = 'string';
    rowData.push(auxFullName);

    const auxId = {};
    auxId.value = data.id;
    auxId.type = 'number';
    rowData.push(auxId);

    const auxEmail = {};
    auxEmail.value = data.email;
    auxEmail.type = 'string';
    rowData.push(auxEmail);

    const auxRole = {};
    auxRole.value = data.role;
    auxRole.type = 'string';
    rowData.push(auxRole);

    const auxActive = {};
    auxActive.value = data.active;
    auxActive.type = 'string';
    rowData.push(auxActive);

    return rowData;
  };

  const getExcel = async (event, pageOfTable, limit, searchValue, columnName) => {
    // https://codesandbox.io/s/react-table-excel-export-g7hoe?file=/src/App.js:5286-6268
    const config = {
      filename: 'TCG-users',
      sheet: {
        data: [],
      },
    };

    const headers = [];
    // añadimos headers mostrados en la tabla:
    tableHeaders.forEach((item) => {
      const rowCell = { value: '', type: '' };
      rowCell.value = item.label;
      rowCell.type = 'string';
      headers.push(rowCell);
    });

    const response = await getUsersPerPage(pageOfTable, limit, searchValue, columnName);

    if (response.success) {
      const allUsers = await getUsersPerPage(
        0, response.data.data.totalUsers, searchValue, columnName,
      );
      const rows = [];
      if (allUsers.success) {
        const data = allUsers.data.data.users;
        rows.push(headers);
        data.forEach((item) => {
          const rowData = getRowData(item);
          rows.push(rowData);
        });
      }
      config.sheet.data = rows;
    }

    return generateExcel(config);
  };

  const content = () => {
    if (!usersQuantity) {
      return (
        <TableRow className={styles.noContent}>
          <TableCell colSpan={7} className={styles.users}>
            <PeopleAltIcon />
            <h4>{t('users.noContent')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleSelect(event, row.id)}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  {row.fullName}
                </TableCell>
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left" className={styles.roleCells}>
                  {row.role.toUpperCase()}
                </TableCell>
                <TableCell align="center">
                  {row.active ? (
                    <p align="center" className={`${styles.tag} ${styles.tagActive}`}><b>{t('users.tableToolbar.active')}</b></p>
                  ) : (
                    <p align="center" className={`${styles.tag} ${styles.tagInactive}`}><b>{t('users.tableToolbar.inactive')}</b></p>
                  )}
                </TableCell>
                <TableCell align="right" className={styles.iconsCell}>
                  <IconButton component={Link} to={`users/${row.id}`}>
                    <MoreHoriz/>
                  </IconButton>
                  <IconButton id={row.id} onClick={handleEdit}>
                    <EditIcon/>
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
          {openDeleteUserDialog ? (
            <Modal title={t('users.userInfo.titleDeleteUser')} defaultOpen={openDeleteUserDialog} setCurrentOpen={setOpenDeleteUserDialog}>
              <div className={styles.dialogWrapper}>
                <Grid container spacing={2} alignItems='flex-end'>
                  <Grid item xs={12}>
                    <Typography className={styles.dialogText} variant="h2" color="textSecondary" component="p">
                      {t('users.userInfo.deleteUsers')} ?
                    </Typography>
                  </Grid>
                  <Grid item xs= {6}/>
                  <Grid item xs={3}>
                  <Button
                    id="submit-ticket-btn"
                    onClick={() => handleDeleteDialog(false)}
                    variant="contained"
                    text={t('users.userInfo.buttons.cancel')}
                    height="35px"
                    width="100%"
                    backgroundColor="#FFFFFF"
                    color="#2C9DC9"
                    borderRadius="10px"
                  />
                  </Grid>
                  <Grid item xs={3}>
                  <Button
                    id="delete-association-btn"
                    onClick={handleDelete}
                    variant="contained"
                    text={t('users.userInfo.buttons.delete')}
                    height="35px"
                    width="100%"
                    backgroundColor='#F5B7B1'
                    color='#E74C3C'
                    borderRadius="10px"
                  />
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                </div>
          </Modal>
          ) : null}
      </>
    );
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Typography variant='h1' className={styles.title}>{t('users.title')}</Typography>
      </div>
      <Paper className={styles.userTable}>
        {userInfo ? (
          <Modal title={t('users.userInfo.titleEditUser')} defaultOpen={currentOpen} setCurrentOpen={setCurrentOpen}>
            <UserInformation
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              userEdited={userEdited}
              setUserEdited={setUserEdited}
            />
          </Modal>
        ) : null}
          <>
            <TableToolbar
              numSelected={selected.length}
              open={open}
              searchText={searchText}
              setOpen={setOpen}
              setFilterOptions={setFilterOptions}
              userId={userId && userId}
              getExcel={getExcel}
              page={page}
              rowsPerPage={rowsPerPage}
              filter={filter}
              column={column}
              handleDelete={handleDelete}
              handleDeleteDialog={handleDeleteDialog}
              setUpdate={setUpdate}
            />
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.tableHeader} padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selected.length > 0 && selected.length < rowsToShow.length
                        }
                        checked={rowsToShow.length > 0 && selected.length === rowsToShow.length}
                        onChange={handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all' }}
                      />
                    </TableCell>
                    {tableHeaders.map((tableHeader) => (
                      <TableCell
                        key={tableHeader.id}
                        className={styles.tableHeader}
                        align={tableHeader.id === 'active' ? 'center' : 'left'}
                        padding="normal"
                      >
                        {tableHeader.id === 'full_name' && selected.length
                          ? `${selected.length} ${t('users.selectedUsers')}`
                          : tableHeader.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={7} className={styles.progress}>
                        <CircularProgress />
                        <h4>{t('users.loading')}</h4>
                      </TableCell>
                    </TableRow>
                  ) : content()}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              setPage={setPage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={usersQuantity}
            />
          </>
      </Paper>
    </div>
  );
}

export default Users;

import React, { useState, useEffect } from 'react';

import Carousel from 'react-material-ui-carousel';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  Grid,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import CarouselRow from './carouselRow';

import useStyles from './styles';

// import { parseDate } from '../../../utils/date';

const TeleauditCarousel = ({
  listImages, loading, setCurrentImage, lineList, setLines,
  currentImage,
}) => {
  const styles = useStyles();
  const [tripletsList, setTripletsList] = useState([]);

  useEffect(() => {
    const triplets = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < listImages.length; i++) {
      const oneTriplet = [];
      oneTriplet[0] = listImages[i - 1];
      oneTriplet[1] = listImages[i];
      oneTriplet[2] = listImages[i + 1];
      triplets[i] = oneTriplet;
    }
    setTripletsList(triplets);
  }, [listImages]);

  const content = (triplet) => (
    <CarouselRow
      triplet={triplet}
      setCurrentImage={setCurrentImage}
      setLines={setLines}
      lineList={lineList}
      currentImage={currentImage}
    />
  );
  return (
  <div>
    <Grid container>
      <Grid item xs={11}>
        <Carousel
          autoPlay={false}
          indicators={false}
          timeout={0}
          cycleNavigation={false}
          navButtonsAlwaysVisible={true}
          navButtonsAlwaysInvisible={false}
          PrevIcon={<ArrowBackIosIcon/>}
          NextIcon={<ArrowForwardIosIcon/>}
        >
        {loading ? (
          <Table>
            <TableBody>
              <TableCell colSpan={9} className={styles.progress}>
                <CircularProgress />
                <h4>Loading</h4>
              </TableCell>
            </TableBody>
          </Table>
        ) : tripletsList.map((triplet) => (
          content(triplet)
        ))}
        </Carousel>
      </Grid>
    </Grid>
  </div>
  );
};

export default TeleauditCarousel;

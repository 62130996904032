/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import ticketsAction from '../actions/tickets';

const initialState = {
  pendingTickets: null,
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ticketsAction.TICKETS_SUCCESS: {
      const { pendingTickets } = action.payload;
      return produce(state, (draft) => {
        draft.pendingTickets = pendingTickets;
      });
    } default: {
      return state;
    }
  }
};

export default ticketsReducer;

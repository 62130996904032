import axios from '../../utils/axios';
import { BAD_REQUEST, INTERNAL_SERVER_ERROR } from '../../utils/const/http';

const getTasksPerPage = async (page, limit, searchValue, column) => {
  try {
    const { data } = await axios.get(`/api/tasks?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postNewTask = async (taskInfo) => {
  try {
    const { data } = await axios.post('/api/tasks', taskInfo);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    let status;
    if (err.response.status === BAD_REQUEST.status) {
      status = BAD_REQUEST.status;
    } else {
      status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    }
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getTask = async (id) => {
  try {
    const { data } = await axios.get(`/api/tasks/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const deleteTask = async (id) => {
  try {
    const { data } = await axios.delete(`/api/tasks/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const putEditTask = async (id, payload) => {
  try {
    const { data } = await axios.put(`/api/tasks/${id}`, payload);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

export {
  getTasksPerPage,
  postNewTask,
  getTask,
  deleteTask,
  putEditTask,
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Typography,
  IconButton,
  Tooltip,
  LinearProgress,
} from '@material-ui/core';

import {
  DragDropContext,
} from 'react-beautiful-dnd';

// icons
import EditIcon from '@material-ui/icons/Edit';
import BackIcon from '@material-ui/icons/ReplyOutlined';
// components
import Snackbar from '../../components/Snackbar';
import TextField from '../../components/TextField';
import Select from '../../components/Select';

import TableGroupComponent from './tableGroupComponent';
import MainToolbar from './mainToolbar';

import useStyles from './styles';

import { IN_THE_GROUP, NOT_IN_THE_GROUP } from '../../utils/const/groups';
// requests
import { getGroupsPerPage } from '../../requests/api/group';

import setFilterOptions from '../../utils/filterFunctions';

function GroupTable({
  tab,
  create,
  groupId,
  setGroupToView,
  attributes,
  tableHeaders,
  findGroup,
  findElements,
  editFunction,
  quantityElementsIn,
  quantityElementsOut,
  groupInfo,
  setGroupInfo,
  groupData,
  groupsPage,
  setGroupsPage,
  elementsPage,
  setElementsPage,
  elementType,
  inputValuesGroup,
  setInputValuesGroup,
  handleCreate,
  handleChangeTab,
  setCreate,
  selected,
  setSelected,
  setColumn,
  searchText,
  setSearchText,
  setInOrOut,
  setFilter,
  listing,
  setListing,
  onlyView,
  setDragChange,
  dragChange,
  setAllElementsIn,
  allElementsIn,
  setAllElementsOut,
  allElementsOut,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const rowsPerPage = 10;
  // eslint-disable-next-line no-unused-vars
  const [update, setUpdate] = useState(false);

  // snackbar states
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  // edit states
  const [edit, setEdit] = useState(false);

  const [listingList, setListingList] = useState([]);

  const handleOnChange = (event) => {
    const { id, name, value } = event.target;
    if (name) {
      setInputValuesGroup({
        ...inputValuesGroup,
        company: value,
      });
    }

    if (id) {
      setInputValuesGroup({
        ...inputValuesGroup,
        name: value,
      });
    }
  };

  const saveChanges = async (id, name, elements, isEdit, isButton, listingId) => {
    let elementsIds = elements;
    if (isEdit) {
      // elementsIds = elements.map((element) => element.id);
      elementsIds = allElementsIn.map((element) => element.id);
    }
    const response = await editFunction(id, name, elementsIds, listingId);
    setFilterOptions(searchText, 'name', setColumn, setSearchText, setFilter, '', 'notInTheGroup');
    if (isEdit) {
      setEdit(false);
    }
    if (!response.success) {
      setSeverity('error');
      setMessage(t('groups.errors.savingGroup'));
    } else {
      setSeverity('success');
      setMessage(t('groups.success.savingGroup'));
    }
    setCreate(false);
    setInputValuesGroup({
      name: '',
    });
    setGroupToView(null);
    handleChangeTab(null, tab);
  };

  const onDragEnd = async ({ destination, source }) => {
    if (onlyView) return;
    if (!destination) return;

    const itemSelected = selected.find((object) => object.index === source.index);

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = groupInfo[source.droppableId];
      const destColumn = groupInfo[destination.droppableId];

      let sourceItems = [...sourceColumn];
      const destItems = [...destColumn];

      const removedItems = [];

      let newSourceItemsArray = sourceItems;
      if (itemSelected) {
        selected.forEach((item) => {
          sourceItems = [...sourceColumn];
          // eslint-disable-next-line no-param-reassign
          item.column = destination.droppableId;
          removedItems.push(...sourceItems.splice(item.index, 1));
        });
        // I have saved the index when the array is complete, so I always remove
        // the elements of the initial array and at the end I filter the moved elements.
        sourceItems = [...sourceColumn];
        const itemsToRemove = removedItems.map((rmi) => rmi.id);
        newSourceItemsArray = sourceItems.filter((item) => !itemsToRemove.includes(item.id));
      } else {
        removedItems.push(...sourceItems.splice(source.index, 1));
      }

      destItems.splice(destination.index, 0, ...removedItems);
      if (destination.droppableId === IN_THE_GROUP) {
        const destItemsIds = destItems.map((element) => element.id);
        const newAllElementsOut = allElementsOut.filter(
          (element) => !destItemsIds.includes(element.id),
        );
        const newAllElementsIn = allElementsIn.concat(removedItems);
        setAllElementsIn(newAllElementsIn);
        setAllElementsOut(newAllElementsOut);
      } else {
        const destItemsIds = destItems.map((element) => element.id);
        const newAllElementsIn = allElementsIn.filter(
          (element) => !destItemsIds.includes(element.id),
        );
        const newAllElementsOut = allElementsOut.concat(removedItems);
        setAllElementsOut(newAllElementsOut);
        setAllElementsIn(newAllElementsIn);
      }
      setGroupInfo({
        ...groupInfo,
        [source.droppableId]: newSourceItemsArray,
        [destination.droppableId]: destItems,
      });

      // eslint-disable-next-line no-unused-vars
      let elementsToSave;

      if (source.droppableId === IN_THE_GROUP) {
        elementsToSave = newSourceItemsArray;
      } else {
        // eslint-disable-next-line no-unused-vars
        elementsToSave = destItems;
      }
      if (!dragChange) {
        setDragChange(true);
      } else {
        setDragChange(false);
      }
      setSelected([]);
    }
  };

  const handleBack = () => {
    setInputValuesGroup({
      name: '',
    });
    setGroupInfo({
      inTheGroup: [],
      notInTheGroup: [],
    });
    setGroupToView(null);
    setCreate(false);
  };

  useEffect(() => {
    if (!create) {
      findGroup();
    } else {
      findElements();
    }

    if (tab === 2) {
      const getListings = async () => {
        const listingsResponse = await getGroupsPerPage('listing', 0, 100000, '', false, true);

        if (listingsResponse.success) {
          const listingOptions = listingsResponse.data.data.groups.map(
            (l) => ({ name: l.name, value: l.id }),
          );
          setListingList([{ name: t('groups.selectListingPlaceholder'), value: '' }, ...listingOptions]);
        } else {
          setSeverity('error');
          setMessage(t('groups.errors.loadingListings'));
        }
      };

      getListings();
    }
    // eslint-disable-next-line
  }, [create, update]);

  return (
    <>
    {groupData || create ? (
      <div className={styles.content}>
        <div className={styles.header}>
          <IconButton
            id="backButton"
            aria-label="back"
            className={styles.backButton}
            onClick={handleBack}
          >
            <BackIcon fontSize="small" />
            <span>{t('groups.actionButtons.back')}</span>
          </IconButton>
          {!onlyView || create ? (
            <>
              <div className={styles.headerTitle}>
                <TextField
                  id="groupName"
                  className={styles.question}
                  placeholder={create ? t('groups.newName') : groupData.name}
                  value={inputValuesGroup.name || ''}
                  InputProps={{ disableUnderline: true }}
                  multiline
                  onChange={handleOnChange}
                />
                {!create && !onlyView && <Tooltip title="Edit">
                  <IconButton
                    id="id"
                    aria-label="edit"
                    onClick={() => setEdit(!edit)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>}
              </div>
              {tab === 2 ? (
                <Select
                  name="listing"
                  items={listingList}
                  value={groupData ? groupData.listingId : listing}
                  onChange={(e) => setListing(e.target.value)}
                  width="300px"
                />
              ) : null}
            </>
          ) : (
            <div>
                <div className={styles.headerTitle}>
                    <Typography variant="h4" className={styles.title}>
                        {create ? '' : inputValuesGroup.name || groupData.name}
                    </Typography>
                </div>
                {tab === 2 ? (
                    <Select
                        name="listing"
                        items={listingList}
                        value={groupData ? groupData.listingId : listing}
                        width="300px"
                        disabled
                    />
                ) : null}
            </div>
          )}
          {!create ? (
            <p>
              Creado el {new Date(groupData.createdAt).toLocaleDateString('es', {
              weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric',
            })}
            </p>
          ) : null}
        </div>
        {!onlyView ? (
        <MainToolbar
          tab={tab}
          create={create}
          setCreate={setCreate}
          groupToView={groupId}
          setGroupToView={setGroupToView}
          elementType={elementType}
          groupInfo={groupInfo}
          groupData={groupData}
          inputValuesGroup={inputValuesGroup}
          setInputValuesGroup={setInputValuesGroup}
          setMessage={setMessage}
          setSeverity={setSeverity}
          handleCreate={handleCreate}
          handleChangeTab={handleChangeTab}
          saveChanges={saveChanges}
          listing={listing}
          setListing={setListing}
          setGroupInfo={setGroupInfo}
        />
        ) : null }
        <DragDropContext onDragEnd={onDragEnd}>
          <TableGroupComponent
            groupId={groupId}
            attributes={attributes}
            elementType={elementType}
            tableHeaders={tableHeaders}
            selected={selected}
            setSelected={setSelected}
            groupInfo={groupInfo}
            setPage={setGroupsPage}
            page={groupsPage}
            rowsPerPage={rowsPerPage}
            inOrOut={IN_THE_GROUP}
            tableSubtitle={t('groups.groupIn', { elementType })}
            quantityElements={quantityElementsIn}
            setColumn={setColumn}
            searchText={searchText}
            setSearchText={setSearchText}
            setInOrOut={setInOrOut}
            setFilter={setFilter}
          />
        {!onlyView ? (
          <TableGroupComponent
            groupId={groupId}
            attributes={attributes}
            elementType={elementType}
            tableHeaders={tableHeaders}
            selected={selected}
            setSelected={setSelected}
            groupInfo={groupInfo}
            setPage={setElementsPage}
            page={elementsPage}
            rowsPerPage={rowsPerPage}
            inOrOut={NOT_IN_THE_GROUP}
            tableSubtitle={t('groups.groupOut', { elementType })}
            quantityElements={quantityElementsOut}
            setColumn={setColumn}
            searchText={searchText}
            setSearchText={setSearchText}
            setInOrOut={setInOrOut}
            setFilter={setFilter}
          />
        ) : null}
        </DragDropContext>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    ) : (
      <div className={styles.loadingGroupContainer}>
        <LinearProgress className={styles.linearProgress} />
        <p>{t('groups.loadingGroupInfo')}</p>
      </div>
    )}
    </>
  );
}

export default GroupTable;

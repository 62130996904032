import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR, OK } from '../../utils/const/http';

const createReport = async ({
  reportDescription,
  workspaceId,
  reportId,
  reportName,
  fileUrl,
},
) => {
  try {
    const response = await axios.post('/api/powerbi-report', {
      reportDescription,
      workspaceId,
      reportId,
      reportName,
      fileUrl,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      totalTickets: response.data,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getReports = async (page, limit, searchValue, column) => {
  try {
    const response = await axios.get(
      `/api/report/?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}`,
    );
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getReportsPerPage = async (page, limit, searchValue, column) => {
  try {
    const response = await axios.get(
      `/api/powerbi-report-per-page/?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}`,
    );
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getReport = async (id) => {
  try {
    const response = await axios.get(`/api/reports/${id}`);
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAdminReport = async (reportId, userId) => {
  try {
    const response = await axios.get(`/api/admin-reports/${reportId}/user/${userId}`);
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getUserReports = async (userId, page, limit) => {
  try {
    const response = await axios.get(
      `/api/user-reports/${userId}/?page=${page}&limit=${limit}`,
    );
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getNotUserReports = async (userId, page, limit) => {
  try {
    const response = await axios.get(
      `/api/not-user-reports/${userId}/?page=${page}&limit=${limit}`,
    );
    const { notUserPowerbiReports } = response.data.data;
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: notUserPowerbiReports,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const createUserReport = async ({
  userId,
  reportId,
  configJson,
  configDescription,
}) => {
  try {
    const response = await axios.post('/api/user-report/', {
      userId,
      powerbiReportId: reportId,
      configJson,
      configDescription,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const updateUserReport = async ({
  userId,
  powerbiReportId,
  configJson,
  configDescription,
}) => {
  try {
    const response = await axios.put('/api/user-report/', {
      userId,
      powerbiReportId,
      configJson,
      configDescription,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};
const updateReport = async ({
  id, reportName, reportId, reportDescription, workspaceId, fileUrl, deleted,
  warningStatus, warningMessage,
}) => {
  try {
    const response = await axios.put('/api/powerbi-report/', {
      id,
      reportName,
      reportId,
      reportDescription,
      workspaceId,
      fileUrl,
      deleted,
      warningStatus,
      warningMessage,
    });
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteUserReport = async ({ userPowerbiReportId }) => {
  try {
    const response = await axios.delete(
      `/api/user-report/${userPowerbiReportId}`,
    );
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getLastReport = async () => {
  try {
    const response = await axios.get('/api/endpoint-stat/last-report');
    return {
      success: true,
      status: OK.status,
      message: OK.message,
      data: response.data,
    };
  } catch (err) {
    const status = err.response
      ? err.response.status
      : INTERNAL_SERVER_ERROR.status;
    const message = err.response
      ? err.response.statusText
      : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage:
          'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteReport = async (id) => {
  try {
    const { data } = await axios.delete(`/api/report/${id}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

export {
  createReport,
  getReports,
  getReport,
  getAdminReport,
  getUserReports,
  getNotUserReports,
  createUserReport,
  updateUserReport,
  deleteUserReport,
  getReportsPerPage,
  updateReport,
  getLastReport,
  deleteReport,
};

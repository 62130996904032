import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Typography,
  Paper,
} from '@material-ui/core';
// components
import Modal from '../../components/Modal';
import Button from '../../components/Button';

import useStyles from './styles';

import CategoryInformation from './CategoryInformation';
import CategoriesTable from './CategoriesTable';

function Categories() {
  const styles = useStyles();
  const { t } = useTranslation();

  // auxiliar states
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);

  // Edit category states
  const [categoryInfo, setCategoryInfo] = useState(null);
  const [currentOpen, setCurrentOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  const tableHeaders = [
    {
      id: 'name',
      label: t('categories.columnNames.name'),
    },
    {
      id: 'Code',
      label: t('categories.columnNames.code'),
    },
    {
      id: 'deleted',
      label: t('categories.columnNames.deleted'),
    }, {
      id: 'actions',
      label: '',
    },
  ];

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Typography variant='h4' className={styles.title}>{t('categories.title')}</Typography>
      </div>
      <Button
        id="save-btn"
        variant="outlined"
        className={styles.createButton}
        text={t('categories.categoryForm.createButton')}
        height="40px"
        width="220px"
        backgroundColor='theme-secondary-blue'
        color='white'
        borderRadius='10px'
        onClick={() => setOpen(true)}
      />
      <Paper className={styles.categoryTable}>
        {categoryInfo ? (
          <Modal title={t('categories.categoryForm.editModalTitle')} defaultOpen={currentOpen} setCurrentOpen={setCurrentOpen}>
            <CategoryInformation
              categoryInfo={categoryInfo}
              setCategoryInfo={setCategoryInfo}
              readOnly={readOnly}
              setUpdate={setUpdate}
            />
          </Modal>
        ) : null}
        {open && <Modal title={t('categories.categoryForm.modalTitle')} defaultOpen={open} setCurrentOpen={setOpen}>
          <CategoryInformation
            setCategoryInfo={setCategoryInfo}
            setUpdate={setUpdate}
            setOpen={setOpen}
          />
        </Modal>}
        <CategoriesTable
          categoryInfo={categoryInfo}
          setCategoryInfo={setCategoryInfo}
          setCurrentOpen={setCurrentOpen}
          setReadOnly={setReadOnly}
          update={update}
          setUpdate={setUpdate}
          tableHeaders={tableHeaders}
        />
      </Paper>
    </div>
  );
}

export default Categories;

import { createTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 320,
      xs: 400,
      sm: 768,
      md: 1048,
      lg: 1280,
      xl: 1430,
      xxl: 1920,
    },
  },
  // TODO: choose typpos and other configs
  typography: {
    fontFamily: ['Mulish'],
    h1: {
      fontWeight: '800',
      color: '#22617B',
      fontSize: '24px',
    },
    h2: {
      fontWeight: '800',
      color: '#22617B',
      fontSize: '18px',
    },
    h3: {
      fontWeight: '800',
      color: '#22617B',
      fontSize: '16px',
    },
    h4: {
      fontWeight: '600',
      color: '#828282',
      fontSize: '16px',
    },
    h5: {
      fontWeight: '600',
      color: '#828282',
      fontSize: '14px',
    },
    h6: {
      fontWeight: '700',
      color: '#666666',
      fontSize: '20px',
    },
    subtitle1: {
      fontWeight: '800',
      color: '#2C9DC9',
      fontSize: '16px',
      // textDecoration: 'underline',
    },
    body1: {
      fontWeight: '500',
    },
    body2: {
      fontWeight: '400',
    },
  },
  sidebar: {
    drawerWidth: 267,
    drawerMobileWidth: '88%',
  },
  shadow: {
    main: '0px 5px 10px rgba(0, 0, 0, 0.05)',
  },
  palette: {
    primary: {
      main: '#1b5d77',
      white: '#FFFFFF',
      snow: '#F2F2F2',
      red: '#EB5757',
      blue: '#2196F3',
      gray: '#828282',
      lightGray: '#E0E0E0',
      middleGray: '#4F4F4F',
      darkGray: '#666666',
      success: '#52C851',
      error: '#F03738',
      warning: '#F3C41C',
      mainBlue: '#22617B',
    },
    secondary: {
      main: '#FFFFFF',
      gray: '#808B96',
      lightGray: '#EAEDED',
      blue: '#2C9DC9',
      lightBlue: '#D6EAF8',
      green: '#28B463',
      lightGreen: '#ABEBC6',
      orange: '#F2994A',
      lightOrange: '#FAEEEC',
      yellow: '#F1C40F',
      lightYellow: '#FCF3CF',
      purple: '#7D3C98',
      lightPurple: '#D2B4DE',
      bluegray: '#283747',
      lightBluegray: '#EBEDEF',
      red: '#E74C3C',
      lightRed: '#F5B7B1',
      darkRed: '#BC0000',
    },
    transparent: {
      main: '#1b5d7730',
      success: '#52C85130',
      warning: '#F3C41C30',
      error: '#F0373830',
    },
    background: {
      default: '#FCFCFC',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      iconSizeMedium: {
        '& > *:first-child': {
          fontSize: '1.5rem',
        },
      },
      containedSecondary: {
        color: 'white',
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: '12px 0',
        '&.Mui-expanded': {
          margin: '12px 0',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        margin: '5px',
        fontSize: '1.5rem',
      },
    },
  },
});

export default theme;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loginWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '100vh',
    '& a': {
      color: theme.palette.primary.main,
      fontSize: '13px',
    },
    '& img': {
      display: 'block',
      margin: 'auto',
    },
    '& .MuiTextField-root': {
      marginBottom: '10px',
    },
  },
  login: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '400px',
  },
  title: {
    paddingBottom: '20px',
    paddingTop: '40px',
    fontSize: '20px',
  },
  error: {
    color: 'white',
    minHeight: '10px',
    fontSize: '10px',
  },
  image: {
    maxWidth: '280px',
    maxHeight: '100%',
  },
  loginContent: {
    padding: '50px 150px 50px 150px',
  },
}));

export default useStyles;

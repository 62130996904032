import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../utils/const/http';

const getShareOfShelfRecord = async ({ questionId }) => {
  try {
    const { data } = await axios.get(`/api/question/${questionId}/record`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const deleteQuestion = async ({ questionId }) => {
  try {
    const { data } = await axios.delete(`/api/question/${questionId}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const putUpdateQuestion = async ({
  questionId, position, requiresTeleaudit, title, description,
}) => {
  try {
    const { data } = await axios.put(`/api/question/${questionId}`, {
      position,
      requiresTeleaudit,
      title,
      description,
    });
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const createQuestion = async ({
  surveyId,
  title,
  description,
  type,
  imageKey,
  imageQuality,
  required,
  deleted,
  emojiScale,
  emojiSet,
  requiresTeleaudit,
  imageUrl,
  sendEmail,
  questionEmails,
  questionLogic,
  position,
  binaryKpis,
  shareKpi,
}) => {
  try {
    const { data } = await axios.post('/api/question', {
      surveyId,
      title,
      description,
      type,
      imageKey,
      imageQuality,
      required,
      deleted,
      emojiScale,
      emojiSet,
      requiresTeleaudit,
      imageUrl,
      sendEmail,
      questionEmails,
      questionLogic,
      position,
      binaryKpis,
      shareKpi,
    });
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

export {
  getShareOfShelfRecord,
  deleteQuestion,
  putUpdateQuestion,
  createQuestion,
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Toolbar,
  CircularProgress,
  IconButton,
  Checkbox,
} from '@material-ui/core';

// icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from '@material-ui/icons/Edit';

// components
import Modal from '../../../components/Modal';
import useStyles from '../styles';
import Snackbar from '../../../components/Snackbar';
import Pagination from '../../../components/Pagination';

import { getHomeMessagesPerPage } from '../../../requests/api/home-message';
import Button from '../../../components/Button';
import HomeMessageForm from './homeMessageForm';

// utils

import { parseDate } from '../../../utils/date';
// const
import { UNAUTHORIZED } from '../../../utils/const/http';

// eslint-disable-next-line no-shadow
const HomeMessage = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  // table states
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [totalRowsQuantity, setTotalRowsQuantity] = useState(0);
  const [currentHomeMessage, setCurrentHomeMessage] = useState({
    active: '',
    message: '',
    name: '',
  });
  // auxiliar states
  const [openCreateHomeMessage, setOpenCreateHomeMessage] = useState(false);
  const [openUpdateHomeMessage, setOpenUpdateHomeMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');

  const newsTableHeaders = [
    {
      id: 'name',
      label: <b>{t('administration.homeMessage.name')}</b>,
    },
    {
      id: 'spanishMessage',
      label: <b>{t('administration.homeMessage.spanishMessage')}</b>,
    },
    {
      id: 'englishMessage',
      label: <b>{t('administration.homeMessage.englishMessage')}</b>,
    },
    {
      id: 'active',
      label: <b>{t('administration.homeMessage.active')}</b>,
    },
    {
      id: 'actions',
      label: <b>{t('administration.actions')}</b>,
    },
  ];

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const handleCreateHomeMessage = () => {
    setCurrentHomeMessage(
      {
        active: false,
        spanishMessage: '',
        englishMessage: '',
        name: '',
      },
    );
    setOpenCreateHomeMessage(true);
  };

  const handleUpdateHomeMessage = (row) => {
    setCurrentHomeMessage({
      active: row.active,
      spanishMessage: row.spanishMessage,
      englishMessage: row.englishMessage,
      name: row.name,
      id: row.id,
    });
    setOpenUpdateHomeMessage(true);
  };

  const newsRequestSearch = async (pageOfTable, limit) => {
    const getHomeMessagesResponse = await getHomeMessagesPerPage(pageOfTable, limit);
    if (getHomeMessagesResponse.success) {
      setRowsToShow(getHomeMessagesResponse.data.data.homeMessages);
      setTotalRowsQuantity((getHomeMessagesResponse.data.data.totalHomeMessages));
    }
    if (getHomeMessagesResponse.status === UNAUTHORIZED.status) {
      window.location.href = '/dashboard';
    }
  };

  const reload = async () => {
    load();
    return newsRequestSearch(page, rowsPerPage);
  };

  useEffect(() => {
    reload();
  }, [page, rowsPerPage, totalRowsQuantity]);

  const newsToolbar = () => (
    <Toolbar className={styles.toolbarTable}>
            <Typography
            className={styles.toolbarButton}
          > </Typography>
            <Button
            id="create-project-btn"
            text={t('administration.homeMessage.formCreate')}
            variant="contained"
            height="40px"
            width="100%"
            backgroundColor='#2C9DC9'
            color='white'
            borderRadius='10px'
            onClick = {handleCreateHomeMessage}
          />
          {openCreateHomeMessage ? (
          <Modal title={t('administration.homeMessage.formCreate')} defaultOpen={openCreateHomeMessage} setCurrentOpen={setOpenCreateHomeMessage}>
            <HomeMessageForm
            setCurrentOpen={setOpenCreateHomeMessage}
            currentSelection={currentHomeMessage}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={false}
            />
          </Modal>
          ) : null}
          </Toolbar>
  );

  const newsTableHeader = () => (
    <TableHead>
      <TableRow>
        {newsTableHeaders.map((tableHeader) => (
          <TableCell
            key={tableHeader.id}
            className={styles.tableHeader}
            align={tableHeader.id === 'actions' ? 'center' : 'left'}
            padding="normal"
          >
            {tableHeader.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const contentNews = () => {
    if (!totalRowsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={3} className={styles.users}>
            <AssignmentIcon />
            <h4>{t('administration.news.noNews')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row) => (
            <TableRow
              hover
              key = {row.id}
            >
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.name}</TableCell>
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.spanishMessage}</TableCell>
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.englishMessage}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{parseDate(row.createdAt)}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >
                  <Checkbox
                  checked={row.active}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled
                />
                </TableCell>
              <TableCell align="center">
                <IconButton
                onClick = {() => handleUpdateHomeMessage(row)}
                >
                  <EditIcon/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {openUpdateHomeMessage ? (
          <Modal title={t('administration.homeMessage.formUpdate')} defaultOpen={openUpdateHomeMessage} setCurrentOpen={setOpenUpdateHomeMessage}>
            <HomeMessageForm
            setCurrentOpen={setOpenUpdateHomeMessage}
            currentSelection={currentHomeMessage}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={true}
            />
          </Modal>
          ) : null}
      </>
    );
  };

  const tableContent = () => {
    if (loading) {
      return (
      <TableRow>
        <TableCell colSpan={9} className={styles.progress}>
          <CircularProgress />
          <h4>{t('users.userInfo.loading')}</h4>
        </TableCell>
      </TableRow>
      );
    }
    return contentNews();
  };

  return (
    <>
          <Paper className={styles.documentsTable}>
          <>
          {newsToolbar()}
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
               {newsTableHeader()}
                <TableBody>
                  {tableContent()}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          <Pagination
              setPage={setPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={totalRowsQuantity}
            />
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </>
  );
};

export default HomeMessage;

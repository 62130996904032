import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  users: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& svg': {
      width: '40px',
      height: '40px',
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  reportName: {
    paddingLeft: '1%',
    marginLeft: '10px',
  },
  icon: {
    '& svg': {
      color: theme.palette.primary.main,
    },
    '& svg:hover': {
      color: theme.palette.primary.blue,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  toolbarTable: {
    '& .MuiOutlinedInput-root': {
      height: '35px',
      fontSize: '13px',
      width: '100vh',
      flexGrow: 1,
    },
    '& .Mui-focused': {
      border: `1px solid ${theme.palette.secondary.gray}`,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.gray,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '2px',
    },
    '& button': {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.secondary.gray,
      fontSize: '13.5px',
      '& svg': {
        color: theme.palette.secondary.gray,
      },
    },
    '& .MuiMenuItem-root': {
      color: 'black',
    },
  },
  boxTitle: {
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    paddingBottom: '20px',
  },
  title: {
    flexGrow: 1,
    letterSpacing: '-0.5px',
  },
  underlined: {
    textDecoration: 'underline',
  },
  progress: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  cardProgress: {
    display: 'flex',
    justifyContent: 'center',
  },
  tokensColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableHeader: {
    textAlign: 'center',
  },
  ticketIdHeader: {
    textAlign: 'left',
  },
  noWallets: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '50px',
    '& svg': {
      width: '40px',
      height: '40px',
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  walletCard: {
    height: '100%',
  },
  walletTable: {
    marginTop: '10px',
    '& .MuiPaper-root': {
      paddingTop: '50px',
    },
    '& .MuiTableCell-root': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-paddingNone': {
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: 'rgb(44, 157, 201, 0.05)',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(44, 157, 201, 0.05)',
    },
  },
  divider: {
    margin: '10px',
  },
  tokens: {
    height: '10%',
    width: '100%',
    minWidth: '350px',
    maxWidth: '350px',
    borderRadius: '20px',
    boxShadow: '0 8px 16px 0 #BDC9D7',
    background: '#000000',
    '& .MuiTypography-h2': {
      paddingBottom: '9px',
      color: 'white',
    },
    '& .MuiTypography-h4': {
      paddingBottom: '5px',
      color: 'white',
      fontSize: '15px',
    },
    '& .MuiTypography-h5': {
      paddingBottom: '10px',
      color: 'white',
      fontSize: '10px',
    },
  },
  chip: {
    width: 60,
    marginBottom: '10px',
  },
  cardFontName: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  cardNumber: {
    paddingTop: '1px',
    fontFamily: 'monaco',
    fontSize: '15px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;

import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../utils/const/http';

const getGroupsPerPage = async (group, page, limit, searchValue,
  column, noDeleted, groupsIdIn, groupsIdOut, IsOut) => {
  try {
    const { data } = await axios.get(`/api/${group}?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}&noDeleted=${noDeleted}&groupsIdIn=${groupsIdIn}&groupsIdOut=${groupsIdOut}&isOut=${IsOut}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getGroupsPerPage,
};

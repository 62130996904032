import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

import { Document, Page, pdfjs } from 'react-pdf';

// Components
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import Snackbar from '../../components/Snackbar';

import useStyles from './styles';
import authService from '../../services/authService';
import axios from '../../utils/axios';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function UserVerification() {
  const styles = useStyles();
  const { t } = useTranslation();

  // Get query info
  const location = useLocation();
  // eslint-disable-next-line max-len
  const widthPDF = window.screen.width > 600
    ? window.screen.width * 0.5
    : window.screen.width * 0.8;
  const query = queryString.parse(location.search);
  const { token } = query;

  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [accept, setAccept] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [inputValues, setInputValues] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleChange = (element) => {
    const { id, value } = element.target;

    setInputValues({
      ...inputValues,
      [id]: value,
    });
  };

  const handleSend = async () => {
    if (!token) {
      setSeverity('error');
      setMessage(t('userVerification.messages.error'));
      return;
    }

    if (!accept) {
      setSeverity('error');
      setMessage(t('userVerification.messages.requiredTerms'));
      return;
    }

    if (!inputValues.password) {
      setSeverity('error');
      setMessage(t('userVerification.messages.requiredPassword'));
      return;
    }

    if (!inputValues.confirmPassword) {
      setSeverity('error');
      setMessage(t('userVerification.messages.confirmPassword'));
      return;
    }

    if (inputValues.password !== inputValues.confirmPassword) {
      setSeverity('error');
      setMessage(t('userVerification.messages.matchPasswords'));
      return;
    }
    const verificationResponse = await authService.verification(
      token,
      inputValues.password,
      inputValues.confirmPassword,
    );

    if (!verificationResponse.success) {
      setSeverity('error');
      setMessage(verificationResponse.errorMessage);
      return;
    }

    window.location.href = '/login';
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  useEffect(() => {
    // Get Terms and Conditions
    const getAmazonObject = async (fileKey) => {
      const response = await axios.get(
        `/api/documents/getObjectS3?fileName=${fileKey}`,
      );
      if (response.data.success) {
        const { returnData } = response.data.data;
        const { signedRequest } = returnData;
        setTermsAndConditions(signedRequest);
      } else {
        setSeverity('error');
        setMessage(t('userVerification.messages.errorLoadingTerms'));
      }
    };

    if (!token) {
      window.location.href = '/';
    }
    getAmazonObject('terminosycondiciones');
  }, [token, t]);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const goToPrevPage = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (pageNumber !== numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div className={styles.verificationWrapper}>
      <div className={styles.verification}>
        <img className={styles.image} src="/TCG_logo.png" alt="TCG logo" />
        <br />
        <Typography variant="h1" align="center">
          {t('userVerification.title')}
        </Typography>
        {termsAndConditions && (
          <div style={{ width: widthPDF }}>
            <Document
              file={termsAndConditions}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <div>
                  <h5 align="center">
                    {t('userVerification.messages.loading')}
                  </h5>
                </div>
              }
              error={
                <div>
                  <h5 align="center">
                    {t('userVerification.messages.fileLoadingError')}
                  </h5>
                </div>
              }
            >
              <Page pageNumber={pageNumber} width={widthPDF} />
              <nav className={styles.buttonsPDFViewer}>
                <Button
                  onClick={goToPrevPage}
                  backgroundColor="#1b5d77"
                  color="#FFFFFF"
                  text={t('userVerification.buttons.prev')}
                  disabled={pageNumber === 1}
                />
                <Button
                  onClick={goToNextPage}
                  backgroundColor="#1b5d77"
                  color="#FFFFFF"
                  text={t('userVerification.buttons.next')}
                  disabled={pageNumber === numPages}
                />
              </nav>
            </Document>
          </div>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={accept}
              onChange={() => setAccept(!accept)}
              color="primary"
              name="accept"
            />
          }
          label={t('userVerification.termsCheckbox')}
        />
        <Typography variant="h2" align="center">
          {t('userVerification.passwordSubtitle')}
        </Typography>
        <br />
        <div>
          <TextField
            id="password"
            type="password"
            variant="outlined"
            label={t('userVerification.labels.password')}
            value={inputValues.password}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <div>
          <TextField
            id="confirmPassword"
            type="password"
            variant="outlined"
            label={t('userVerification.labels.confirmPassword')}
            value={inputValues.confirmPassword}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <Button
          id="submit-btn"
          backgroundColor="#1b5d77"
          color="#FFFFFF"
          onClick={handleSend}
          text={t('userVerification.buttons.send')}
          height="40px"
          width="100%"
        />
        <br />
      </div>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div>
  );
}

export default UserVerification;

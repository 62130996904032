import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme) => ({
  surveyForm: {
    display: 'grid',
    gridTemplateColumns: '47% 47%',
    columnGap: '5%',
    padding: '30px 60px 55px',
    width: '70vw',
    borderRadius: '10px',
    '& label': {
      color: theme.palette.primary.darkGray,
      fontSize: '13px',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'none',
    },
    '& .MuiInput-root': {
      border: `1px solid ${theme.palette.primary.lightGray}`,
      borderRadius: '5px',
      marginTop: '27px',
      padding: '5px',
      paddingLeft: '10px',
    },
    '& .MuiInput-root.Mui-focused': {
      border: `1px solid ${theme.palette.secondary.blue}`,
      borderRadius: '5px',
    },
    '& .MuiInput-input': {
      textAlign: 'left',
      fontSize: '14px',
      paddingLeft: '7px',
    },
    '& label.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.secondary.blue,
    },
  },
  notice: {
    gridColumn: '1/3',
    color: theme.palette.secondary.blue,
    fontSize: '13px',
    marginTop: '20px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    gridColumn: 2,
    gridRow: 2,
    marginTop: '25px',
    '& button': {
      fontWeight: 'bold',
      width: '160px',
      borderRadius: '10px',
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.secondary.blue,
    },
    '& button:first-child': {
      marginRight: '15px',
      color: theme.palette.primary.middleGray,
      backgroundColor: theme.palette.primary.white,
      border: `0.8px solid ${theme.palette.secondary.gray}`,
      '&:hover': {
        backgroundColor: theme.palette.secondary.lightGray,
      },
    },
  },
  buttonsModal: {
    display: 'flex',
    justifyContent: 'end',
  },
  displacedButtons: {
    gridRow: 3,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      fontSize: '16px',
      marginTop: '0px',
      color: theme.palette.secondary.gray,
    },
  },
  title: {
    flexGrow: 1,
    fontSize: '24px',
    width: '700px',
    marginBottom: '8px',
    fontWeight: 'bold',
    color: theme.palette.primary.mainBlue,
  },
  pageTitle: {
    color: theme.palette.primary.gray,
    fontSize: '14px',
  },
  sectionTitle: {
    color: theme.palette.secondary.blue,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  button: {
    fontWeight: 'bold',
    width: '160px',
    borderRadius: '8px',
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.secondary.blue,
    marginTop: '30px',
    '&.Mui-disabled': {
      color: theme.palette.primary.white,
      opacity: 0.5,
    },
  },
  surveyContent: {
    display: 'flex',
    marginTop: '15px',
  },
  pages: {
    paddingRight: '50px',
  },
  toolsContainer: {
    maxWidth: '300px',
    marginLeft: '60px',
    '& .MuiPaper-root': {
      height: '75vh',
    },
  },
  toolsContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'calc(100% - 85px)',
    padding: '10px 35px',
    '& em': {
      fontSize: '14px',
      color: theme.palette.primary.lightGray,
    },
  },
  selectedPageStyle: {
    borderRadius: '10px',
    border: `2px dotted ${theme.palette.secondary.blue}`,
  },
  switch: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '& .MuiSwitch-thumb': {
      width: 16,
      height: 16,
      borderRadius: 8,
      boxShadow: 'none',
    },
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.snow,
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      color: theme.palette.primary.white,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.primary.snow,
    },
  },
  pageInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        marginRight: '10px',
      },
    },
    '& svg': {
      color: theme.palette.primary.lightGray,
    },
  },
  hideContent: {
    display: 'none',
  },
  generalViewPageTitle: {
    backgroundColor: theme.palette.primary.white,
    borderRadius: '10px',
    marginBottom: '10px',
  },
  selectedPageGeneralView: {
    borderRadius: '10px',
    border: `2px dotted ${theme.palette.secondary.blue}`,
    marginLeft: '-18px',
    marginBottom: '0px',
  },
  selectedPageArrows: {
    display: 'block',
    color: theme.palette.secondary.blue,
    marginLeft: '-30px',
  },
  editionArea: {
    textAlign: 'center',
    flexGrow: '1',
    '& div:first-child': {
      flexGrow: '1',
    },
  },
  viewQuestion: {
    color: `${theme.palette.secondary.blue} !important`,
    marginRight: '10px',
  },
  buttonsToolsBar: {
    display: 'flex',
    alignItems: 'center',
    // '& button:first-child': {
    //   minWidth: '40px',
    //   height: '40px',
    //   padding: '0px',
    //   marginRight: '10px',
    //   borderRadius: '10px',
    //   backgroundColor: theme.palette.primary.snow,
    //   '& svg': {
    //     color: theme.palette.secondary.blue,
    //   },
    // },
    '& button:last-child': {
      marginTop: '0px',
    },
  },
  connectionButton: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '1.50',
    fontSize: '14px',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.primary.lightGray}`,
  },
  backButton: {
    width: '80px',
    paddingLeft: '0px',
    color: theme.palette.secondary.middleGray,
    '& span': {
      fontSize: '13px',
    },
  },
  formWrapper: {
    width: '30vw',
    padding: '56px',
    paddingTop: '10px',
    paddingBottom: '20px',
    '& .MuiFormLabel-root': {
      color: '#808080',
      margin: '15px auto 8px',
      fontSize: '12px',
    },
    '& input': {
      backgroundColor: 'white',
      padding: '10px',
    },
    '& .MuiSelect-root': {
      padding: '10px',
      fontSize: '13.5px',
    },
  },
}));

export const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.primary.lightGray}`,
  },
  indicator: {
    backgroundColor: theme.palette.secondary.blue,
  },
}))(Tabs);

export const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: '140px',
    textTransform: 'none',
    padding: '0px 8px',
    color: theme.palette.primary.gray,
    '&$selected': {
      color: 'black',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default useStyles;

/* eslint-disable no-param-reassign */
const elementsInFunction = async (
  info, elementIn, elementNotIn, groupIn, draggableId,
  CapitalizedGroup, groupType, changes, getFunction, rows,
) => {
  let groupIds;
  const element = info[elementNotIn].find((eNotIn) => `${eNotIn.id}` === draggableId);
  if (element && element[CapitalizedGroup]) {
    // Gets the groups this element belongs to.
    groupIds = element[CapitalizedGroup].map((g) => g.id);
  } else {
    groupIds = [];
  }

  const groupInIds = info[groupIn].map((gIn) => gIn.id);
  const filteredGroupsToAssign = [];
  await Promise.all(groupIds.map(async (groupId) => {
    // Search for the elements associated with this groupId.
    const { data, success } = await getFunction(groupId, 0, 0, 0);

    if (success) {
      const elementsInGroup = data.data[groupType][rows].data;
      if (!elementsInGroup.length) return;

      const elmentsInIds = info[elementIn].map((eIn) => eIn.id);
      // Check that all elements in the group are in elementsIn.
      const allElementsIn = elementsInGroup.every(
        (id) => [...elmentsInIds, Number.parseInt(draggableId, 10)].includes(id),
      );
      // Check that the group to associate is not in groupIn.
      if (allElementsIn && !groupInIds.includes(groupId)) {
        filteredGroupsToAssign.push(data.data[groupType].data);
      }
    }
  }));
  changes[groupIn] = [...info[groupIn], ...filteredGroupsToAssign];
};

const elementsNotInFunction = (
  info, elementIn, draggableId, capitalizedGroup, changes, groupIn,
) => {
  let groupIds;
  const element = info[elementIn].find((eIn) => `${eIn.id}` === draggableId);
  if (element) {
    if (!element[capitalizedGroup]) return;
    groupIds = element[capitalizedGroup].map((group) => group.id);
  }

  const filteredGroupsIn = info[groupIn].filter(
    (gIn) => !groupIds.includes(gIn.id),
  );

  changes[groupIn] = filteredGroupsIn;
};

const groupsInFunction = (
  info, groupNotIn, draggableId, changes, element, elementsIn, elementsNotIn,
) => {
  const groupFound = info[groupNotIn].find(
    (group) => `${group.id}` === draggableId,
  );

  if (groupFound) {
    const elementsToAssing = groupFound[element];

    const elementsInIds = info[elementsIn].map((eIn) => eIn.id);
    const elementsArray = [...info[elementsIn]];

    const elementsToKeepInNotIn = [];

    elementsToAssing.forEach((elementToAssign) => {
      if (!elementsInIds.includes(elementToAssign.id)) {
        elementsArray.push(elementToAssign);
      }
    });

    info[elementsNotIn].forEach((elementNotIn) => {
      const index = elementsToAssing.findIndex((e) => elementNotIn.id === e.id);
      if (index !== -1) {
        return;
      }
      elementsToKeepInNotIn.push(elementNotIn);
    });

    changes[elementsIn] = elementsArray;
    changes[elementsNotIn] = elementsToKeepInNotIn;
  }
};

const groupsNotInFunction = (info, groupsIn, draggableId, element, elementsIn, changes) => {
  const groupFound = info[groupsIn].find(
    (group) => `${group.id}` === draggableId,
  );

  if (groupFound) {
    const groupsInArray = info[groupsIn].filter((group) => `${group.id}` !== draggableId);

    let elementsToKeepAssociated = [];
    groupsInArray.forEach((group) => {
      elementsToKeepAssociated = [...elementsToKeepAssociated, ...group[element]];
    });

    const elementsIdsToKeepAssociated = elementsToKeepAssociated.map(
      (elementToKeepAssociated) => elementToKeepAssociated.id,
    );

    const elementsToDelete = groupFound[element].map((e) => {
      if (!elementsIdsToKeepAssociated.includes(e.id)) {
        return e.id;
      }
      return null;
    });
    const filteredElementsIn = info[elementsIn].filter(
      (elementIn) => !elementsToDelete.includes(elementIn.id),
    );

    changes[elementsIn] = filteredElementsIn;
  }
};

export {
  elementsInFunction,
  groupsInFunction,
  elementsNotInFunction,
  groupsNotInFunction,
};

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(20),
    minWidth: '300px',
  },
  infoWidget: {
    minHeight: '160px',
    minWidth: '300px',
    borderRadius: '12px',
    backgroundColor: '#2C9DC910',
  },
  knowledge: {
    paddingTop: '10px',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  knowImg: {
    width: 'auto',
    maxHeight: '90px',
  },
  centerTypo: {
    flexGrow: 1,
    textAlign: 'center',
    padding: '10px',
  },
  actionArea: {
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    paddingBottom: '20px',
  },
  title: {
    flexGrow: 1,
    letterSpacing: '-0.5px',
  },
  headerContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default useStyles;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '../Snackbar';
import { getReport } from '../../requests/api/report';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '85vh',
  },
  rootFullScreen: {
    height: '100vh',
  },
  report: {
    height: '100%',
    borderWidth: '0px',
  },
  title: {
    flexGrow: 1,
  },
  loading: {
    color: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '200px',
  },
}));

const Report = ({ fullScreen }) => {
  const { t } = useTranslation();

  const [reportId, setReportId] = useState();
  const [url, setUrl] = useState();
  const [embedToken, setEmbedToken] = useState();
  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState('');
  const { id } = useParams();
  const styles = useStyles();

  useEffect(() => {
    const parseFilter = (string) => {
      try {
        const parsed = JSON.parse(string);
        setFilters(parsed);
      } catch (e) {
        setErr(t('reports.messages.filterError'));
      }
    };

    const findReport = async () => {
      const response = await getReport(id);
      if (response.success) {
        const powerbiData = response.data.data.powerbiReport;
        if (powerbiData.userRelation.configJson) {
          parseFilter(powerbiData.userRelation.configJson);
        }
        setReportId(powerbiData.reportId);
        setUrl(powerbiData.embedUrl);
        setEmbedToken(powerbiData.embedToken);
        setLoading(false);
      } else {
        setErr(response.data.errorMessage);
        setLoading(false);
      }
    };
    findReport();
  }, [id, t]);

  const conditionalRender = () => {
    if (loading) {
      return (
        <div className={styles.container}>
            <CircularProgress className={styles.loading}/>
        </div>
      );
    }
    return (
      <PowerBIEmbed
          embedConfig = {{
            type: 'report', // Supported types: report, dashboard, tile, visual and qna
            id: reportId,
            embedUrl: url,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed,
            filters: [filters],
            settings: {
              panes: {
                filters: {
                  expanded: true,
                  visible: false,
                },
              },
            },
          }}
          cssClassName = {
            styles.report
        }
        />
    );
  };
  return (
    <div>
      <div className={clsx({
        [styles.root]: !fullScreen,
        [styles.rootFullScreen]: fullScreen,
      })}>
        {conditionalRender()}
      </div>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
      </div>
    </div>
  );
};

export default Report;

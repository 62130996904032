import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbarTable: {
    '& .MuiOutlinedInput-root': {
      height: '35px',
      fontSize: '13px',
      width: '100vh',
      flexGrow: 1,
    },
    '& .Mui-focused': {
      border: `1px solid ${theme.palette.secondary.gray}`,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.gray,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '2px',
    },
    '& button': {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.secondary.gray,
      fontSize: '13.5px',
      '& svg': {
        color: theme.palette.secondary.gray,
      },
    },
    '& .MuiMenuItem-root': {
      color: 'black',
    },
  },
  boxTitle: {
    flexGrow: 1,
  },
  iconButton: {
    height: '120px',
    width: '120px',
    minWidth: '120px',
  },
  avatar: {
    height: '110px',
    width: '110px',
    minWidth: '100px',
  },
  challengePercentage: {
    color: theme.palette.secondary.gray,
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: '12px',
  },
  uploadIcon: {
    '& svg': {
      paddingTop: '11px',
      height: '50px',
      width: '100%',
      color: '#2C9DC9',
      margin: '0px',
    },
    '& svg:hover': {
      opacity: '0.8',
    },
  },
  input: {
    display: 'none',
  },
  formWrapper: {
    width: '50vw',
    padding: '56px',
    paddingTop: '10px',
    paddingBottom: '20px',
    '& .MuiFormLabel-root': {
      color: '#808080',
      margin: '15px auto 8px',
      fontSize: '12px',
    },
    '& input': {
      backgroundColor: 'white',
      padding: '10px',
    },
    '& .MuiSelect-root': {
      padding: '10px',
      fontSize: '13.5px',
    },
  },
  content: {
    flexGrow: 1,
    minWidth: '960px',
    minHeight: '690px',
    padding: theme.spacing(3),
  },
  greetingText: {
    maxHeight: '14vh',
    minWidth: '250px',
    overflowY: 'scroll',
  },
  greetingCard: {
    height: '200px',
    minHeight: '160px',
    minWidth: '300px',
    borderRadius: '12px',
    backgroundColor: '#2C9DC910',
    '& .MuiTypography-body1': {
      fontWeight: '400',
      fontSize: '16px',
      color: theme.palette.primary.main,
    },
    '& .MuiButton-contained': {
      backgroundColor: '#FFFFFF',
      borderRadius: '68px',
      height: '35px',
      width: '130px',
      color: theme.palette.primary.main,
    },
  },
  infoWidget: {
    height: '200px',
    minWidth: '140px',
    borderRadius: '12px',
    backgroundColor: '#2C9DC910',
    // use all height with content
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  carrousel: {
    minWidth: '450px',
    minHeight: '400px',
    height: '400x',
    borderRadius: '5px',
    backgroundColor: '#2C9DC910',
    '& .Carousel-root-47': {
      paddingRight: '15px',
      paddingLeft: '15px',
    },
  },
  tickets: {
    height: '25vh',
    minHeight: '255px',
    minWidth: '200px',
    borderRadius: '12px',
    backgroundColor: '#2C9DC910',
  },
  container: {
    display: 'flex',
    height: '200px',
    minHeight: '160px',
    minWidth: '150px',
  },
  weatherContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  tokens: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '200px',
    minHeight: '160px',
    borderRadius: '12px',
    backgroundColor: 'white',
    '& .MuiTypography-h2': {
      paddingBottom: '10px',
    },
  },
  tokensDown: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '25vh',
    minHeight: '255px',
    minWidth: '260px',
    borderRadius: '12px',
    backgroundColor: 'white',
    '& .MuiTypography-h2': {
      paddingBottom: '20px',
    },
  },
  ticketsWidget: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '25vh',
    minHeight: '255px',
    minWidth: '230px',
    borderRadius: '12px',
    backgroundColor: 'white',
    '& .MuiTypography-h2': {
      paddingBottom: '20px',
    },
  },
  tooltipWidget: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.blue,
    padding: '10px',
  },
  blogTitle: {
    paddingTop: '10px',
    fontWeight: '700',
    fontSize: '16px',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  knowImg: {
    width: 'auto',
    maxHeight: '90px',
  },
  reportImg: {
    width: 'auto',
    maxHeight: '180px',
  },
  report: {
    paddingTop: '10px',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
  },
  centerTypo: {
    flexGrow: 1,
    textAlign: 'center',
  },
  knowledge: {
    paddingTop: '10px',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
  },
  image: {
    height: '180px',
    width: '100%',
  },
  ticketListText: {
    color: theme.palette.primary.main,
    fontSize: '20px',
  },
  tooltipText: {
    color: theme.palette.primary.white,
    fontSize: '20px',
  },
  tooltipTextPending: {
    color: theme.palette.primary.darkGray,
    fontSize: '20px',
  },
  ticketContainer: {
    paddingTop: '10px',
  },
  ticketListTitle: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  challengesTitle: {
    textDecoration: 'none',
  },
  gaugeChart: {
    maxHeight: '65px',
    width: '70%',
  },
  tooltipPercentage: {
    textDecoration: 'none',
  },
  ticketBarClosed: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '30px',
  },
  ticketBarSucc: {
    textDecoration: 'none',
    color: theme.palette.primary.success,
    fontSize: '30px',
  },
  balanceBar: {
    textDecoration: 'none',
    color: theme.palette.primary.success,
    fontSize: '50px',
  },
  balanceNegative: {
    textDecoration: 'none',
    color: theme.palette.primary.error,
    fontSize: '20px',
  },
  balancePositive: {
    textDecoration: 'none',
    color: theme.palette.primary.success,
    fontSize: '20px',
  },
  ticketBarPending: {
    textDecoration: 'none',
    color: theme.palette.primary.warning,
    fontSize: '30px',
  },
  tooltipBar: {
    textDecoration: 'none',
    color: theme.palette.primary.white,
    fontSize: '30px',
  },
  tooltipBarPending: {
    textDecoration: 'none',
    color: theme.palette.primary.darkGray,
    fontSize: '30px',
  },
  ticketListNumber: {
    textDecoration: 'none',
    color: theme.palette.primary.blue,
    fontSize: '30px',
  },
  tooltipNumber: {
    color: theme.palette.primary.white,
    fontSize: '30px',
  },
  tooltipNumberPending: {
    color: theme.palette.primary.darkGray,
    fontSize: '30px',
  },
  scrollable: {
    minHeight: '45px',
    maxHeight: '60px',
    overflowY: 'scroll',
  },
  widgetButton: {
    paddingLeft: '10px',
    '& .MuiButton-root': {
      fontWeight: '800',
    },
  },
  challengeButton: {
    paddingLeft: '10px',
    paddingRight: '10px',
    '& .MuiButton-root': {
      fontWeight: '800',
    },
  },
  alignBalance: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Checkbox,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@material-ui/core';
// icons
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import Arrow from '@material-ui/icons/CompareArrowsOutlined';
import EditIcon from '@material-ui/icons/Edit';
import EditTaskIcon from '@material-ui/icons/BorderColor';
// components
import TableToolbarActions from '../../components/TableToolbarActions';
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import Snackbar from '../../components/Snackbar';

import useStyles from './styles';

// requests
import { getTasksPerPage, deleteTask } from '../../requests/api/task';
// const
import { UNAUTHORIZED } from '../../utils/const/http';
import { SUPER_ADMIN } from '../../utils/const/user';
// utils
import { isSelected, handleSelect } from '../../utils/selectFunctions';
import load from '../../utils/load';
import { parseDate } from '../../utils/date';

function TasksTable({
  setEdit, setEditCreateView, setTaskId, setAssociate, setEditTask,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [tasksQuantity, setTasksQuantity] = useState(0);

  // auxiliar states
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  // Filter states
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  // snackbar
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const account = useSelector((state) => state.account);

  const tableHeaders = [
    {
      id: 'name',
      label: t('task.columnNames.name'),
    },
    {
      id: 'cluster',
      label: t('task.columnNames.cluster'),
    },
    {
      id: 'project',
      label: t('task.columnNames.project'),
    },
    {
      id: 'createdAt',
      label: t('task.columnNames.createdAt'),
    },
    {
      id: 'deleted',
      label: t('task.columnNames.deleted'),
    },
    {
      id: 'actions',
      label: '',
    },
  ];

  const requestSearch = async (pageOfTable, limit, searchValue, columnName) => {
    const response = await getTasksPerPage(pageOfTable, limit, searchValue, columnName);
    if (response.success) {
      setRowsToShow(response.data.data.tasks);
      setTasksQuantity(response.data.data.totalTasks);
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const handleEditTask = (event) => {
    const { id } = event.currentTarget;
    setEditTask(true);
    setTaskId(id);
  };

  const handleEdit = (event) => {
    const { id } = event.currentTarget;
    setTaskId(id);
    setEdit(true);
  };

  const handleView = (event) => {
    const { id } = event.currentTarget;
    setSelected([]);
    setTaskId(id);
  };

  const handleAssociate = (event) => {
    const { id } = event.currentTarget;
    setTaskId(id);
    setAssociate(true);
  };

  useEffect(() => {
    load(200, setLoading);
  }, [rowsToShow]);

  useEffect(() => {
    if (account.user.role !== SUPER_ADMIN) {
      window.location.href = '/home';
    }

    requestSearch(page, rowsPerPage, filter, column);
  }, [column, filter, page, rowsPerPage, account.user.role, update]);

  const content = () => {
    if (!tasksQuantity) {
      return (
        <TableRow className={styles.noContent}>
          <TableCell colSpan={7} className={styles.tasks}>
            <h4>{t('task.noContent')}</h4>
            <Button
              id="create-btn"
              variant="outlined"
              text={t('task.createButton')}
              height="40px"
              width="150px"
              backgroundColor='theme-secondary-blue'
              color='theme-primary-white'
              borderRadius='10px'
              onClick={() => setEditCreateView(true)}
            />
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row, index) => {
            const isItemSelected = isSelected(row.id, selected);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleSelect(event, row.id, selected, setSelected)}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.Cluster ? row.Cluster.clusterName : 'N/A'}</TableCell>
                <TableCell align="left">{row.Project ? row.Project.projectName : 'N/A' }</TableCell>
                <TableCell align="left">{row.createdAt ? parseDate(row.createdAt) : ''}</TableCell>
                <TableCell align="center">
                  {row.deleted ? (
                    <DoneIcon fontSize="small" />
                  ) : (
                    <ClearIcon fontSize="small" />
                  )}
                </TableCell>
                <TableCell align="right" className={styles.iconsCell}>
                  <Tooltip title="Editar tarea">
                    <IconButton
                      id={row.id}
                      aria-label="edit-task"
                      onClick={handleEditTask}
                    >
                      <EditTaskIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver">
                    <IconButton
                      id={row.id}
                      aria-label="view"
                      onClick={handleView}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar asociaciones">
                    <IconButton
                      id={row.id}
                      aria-label="edit"
                      onClick={handleEdit}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Asociar">
                    <IconButton
                      id={row.id}
                      aria-label="associate"
                      onClick={handleAssociate}
                    >
                      <Arrow fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
      </>
    );
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Typography variant='h4' className={styles.title}>{t('task.title')}</Typography>
        <Button
          id="save-btn"
          variant="outlined"
          className={styles.createButton}
          text={t('task.createButton')}
          height="40px"
          width="220px"
          backgroundColor='theme-secondary-blue'
          color='white'
          borderRadius='10px'
          onClick={() => setEditCreateView(true)}
        />
      </div>
      <Paper className={styles.taskTable}>
        <>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableToolbarActions
                  selected={selected}
                  setSelected={setSelected}
                  rowsToShow={rowsToShow}
                  setUpdate={setUpdate}
                  setMessage={setMessage}
                  setSeverity={setSeverity}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  setColumn={setColumn}
                  setFilter={setFilter}
                  placeholder={t('task.tableToolbarPlaceholder')}
                  deleteFunction={deleteTask}
                  onlyDelete
                />
                <TableRow>
                  <TableCell className={styles.tableHeader} padding="checkbox">
                  </TableCell>
                  {tableHeaders.map((tableHeader) => (
                    <TableCell
                      key={tableHeader.id}
                      className={styles.tableHeader}
                      align="left"
                      padding="normal"
                    >
                      {tableHeader.id === 'title' && selected.length
                        ? `Selected ${selected.length} tasks`
                        : tableHeader.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} className={styles.progress}>
                      <CircularProgress />
                      <h4>{t('task.loading')}</h4>
                    </TableCell>
                  </TableRow>
                ) : content()}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            setPage={setPage}
            page={page}
            rowsPerPage={rowsPerPage}
            quantity={tasksQuantity}
          />
        </>
      </Paper>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div>
  );
}

export default TasksTable;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Toolbar,
  CircularProgress,
  IconButton,
  TextField as MuiTextField,
  Box,
} from '@material-ui/core';

// icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

// components
import Modal from '../../../components/Modal';
import useStyles from '../styles';
import Snackbar from '../../../components/Snackbar';
import Pagination from '../../../components/Pagination';

import { getCompaniesPerPage } from '../../../requests/api/company';
import Button from '../../../components/Button';
import CompanyForm from './companyForm';

import sleep from '../../../utils/sleep';

// const
import { UNAUTHORIZED } from '../../../utils/const/http';

// eslint-disable-next-line no-shadow
const CompaniesAdmin = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  // table states
  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState([]);
  const rowsPerPage = 10;
  const [totalRowsQuantity, setTotalRowsQuantity] = useState(0);
  const [currentCompany, setCurrentCompany] = useState({
    name: '',
    logoUrl: '',
    countryCode: '',
    taxId: '',
    whatsappLink: '',
  });
  // auxiliar states
  const [openCreateCompany, setOpenCreateCompany] = useState(false);
  const [openUpdateCompany, setOpenUpdateCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  const companiesTableHeaders = [
    {
      id: 'imageUrl',
      label: <b>Logo</b>,
    },
    {
      id: 'companyName',
      label: <b>{t('administration.company.name')}</b>,
    },
    {
      id: 'countryCode',
      label: <b>{t('administration.company.formCountry')}</b>,
    },
    {
      id: 'taxId',
      label: <b>{t('administration.company.formTax')}</b>,
    },
    {
      id: 'actions',
      label: <b>{t('administration.actions')}</b>,
    },
  ];

  const setFilterOptions = async (newValue, tableColumn) => {
    setSearchText(newValue);
    await sleep(100);
    setColumn(tableColumn);
    setFilter(newValue);
    setPage(0);
    // Clear filters
    if (!tableColumn) {
      setSearchText('');
      setFilter('');
    }
  };

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const handleCreateCompany = () => {
    setCurrentCompany(
      {
        name: '',
        countryCode: '',
        taxId: '',
        whatsappLink: '',
      },
    );
    setOpenCreateCompany(true);
  };

  const handleUpdateCompany = (row) => {
    setCurrentCompany({
      name: row.name,
      logoUrl: row.logoUrl,
      countryCode: row.countryCode,
      taxId: row.taxId,
      id: row.id,
      whatsappLink: row.whatsappLink,
    });
    setOpenUpdateCompany(true);
  };

  const companiesRequestSearch = async (pageOfTable, limit, searchValue, columnName) => {
    const getCompaniesResponse = await getCompaniesPerPage(pageOfTable, limit,
      searchValue, columnName);
    if (getCompaniesResponse.success) {
      setRowsToShow(getCompaniesResponse.data.data.companies.Companies);
      setTotalRowsQuantity((getCompaniesResponse.data.data.companies.totalCompanies));
    }
    if (getCompaniesResponse.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const reload = async () => {
    load();
    return companiesRequestSearch(page, rowsPerPage, filter, column);
  };

  useEffect(() => {
    reload();
  }, [page, rowsPerPage, totalRowsQuantity, filter, column]);

  const companiesToolbar = () => (
    <Toolbar className={styles.toolbarTable}>
              <Box className={styles.boxTitle}>
          <MuiTextField
                className={styles.searchField}
                id="search"
                variant="outlined"
                value={searchText}
                onChange={(event) => setFilterOptions(event.target.value, 'name')}
                placeholder={t('administration.buttons.searchCompanyPlaceHolder')}
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" />,
                  endAdornment: (
                    <IconButton
                      id="clear"
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      onClick={() => setFilterOptions('', false)}
                      style={{ visibility: searchText ? 'visible' : 'hidden' }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
          </Box>

            <Typography
            className={styles.toolbarButton}
          > </Typography>
            <Button
            id="create-project-btn"
            text={t('administration.buttons.createCompany')}
            variant="contained"
            height="40px"
            width="100%"
            backgroundColor='#2C9DC9'
            color='white'
            borderRadius='10px'
            onClick = {handleCreateCompany}
          />
          {openCreateCompany ? (
          <Modal title={t('administration.company.formCreate')} defaultOpen={openCreateCompany} setCurrentOpen={setOpenCreateCompany}>
            <CompanyForm
            setCurrentOpen={setOpenCreateCompany}
            currentSelection={currentCompany}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={false}
            />
          </Modal>
          ) : null}
          </Toolbar>
  );

  const companiesTableHeader = () => (
    <TableHead>
      <TableRow>
        {companiesTableHeaders.map((tableHeader) => (
          <TableCell
            key={tableHeader.id}
            className={styles.tableHeader}
            align={tableHeader.id === 'actions' ? 'center' : 'left'}
            padding="normal"
          >
            {tableHeader.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const contentCompanies = () => {
    if (!totalRowsQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={3} className={styles.users}>
            <AssignmentIcon />
            <h4>{t('administration.company.noCompanies')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row) => (
            <TableRow
              hover
              key = {row.id}
            ><TableCell
            component="th"
            scope="row"
            padding="none"
            className={styles.projectName}
          >{
            row.logoUrl
            && <img
            className={styles.logoUrl}
            src={row.logoUrl}
            alt="N/A"
            />
          }
          </TableCell>
              <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.name}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.countryCode}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.projectName}
                >{row.taxId}</TableCell>
              <TableCell align="center">
                <IconButton
                onClick = {() => handleUpdateCompany(row)}
                >
                  <EditIcon/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {openUpdateCompany ? (
          <Modal title={t('administration.company.formUpdate')} defaultOpen={openUpdateCompany} setCurrentOpen={setOpenUpdateCompany}>
            <CompanyForm
            setCurrentOpen={setOpenUpdateCompany}
            currentSelection={currentCompany}
            setSucc={setSucc}
            setError = {setErr}
            reload={reload}
            update={true}
            />
          </Modal>
          ) : null}
      </>
    );
  };

  const tableContent = () => {
    if (loading) {
      return (
      <TableRow>
        <TableCell colSpan={9} className={styles.progress}>
          <CircularProgress />
          <h4>{t('users.userInfo.loading')}</h4>
        </TableCell>
      </TableRow>
      );
    }
    return contentCompanies();
  };

  return (
    <>
          <Paper className={styles.documentsTable}>
          <>
          {companiesToolbar()}
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
               {companiesTableHeader()}
                <TableBody>
                  {tableContent()}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          <Pagination
              setPage={setPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              quantity={totalRowsQuantity}
            />
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </>
  );
};

export default CompaniesAdmin;

import React from 'react';
import DocumentTickets from './DocumentTickets';
import useStyles from './styles';

function Document() {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <DocumentTickets/>
    </div>
  );
}

export default Document;

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Toolbar,
  Typography,
  Paper,
  IconButton,
  TextField,
  Button as MuiButton,
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  MenuList,
  Grid,
} from '@material-ui/core';
// icons
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import Modal from '../../components/Modal';
import Button from '../../components/Button';

import Register from '../Register';

import useStyles from './styles';

// Filters area, table header
export default function TableToolbar({
  open, numSelected, searchText, setFilterOptions,
  setOpen, getExcel, page, rowsPerPage, filter,
  column, handleDeleteDialog, setUpdate,
}) {
  const styles = useStyles();
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();

  const [openCreateUser, setOpenCreateUser] = useState(false);

  const updateComponent = () => {
    setUpdate((prevState) => !prevState);
    setOpenCreateUser(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // Filters the data in the Role and Active columns
  const handleSelectFilter = (event) => {
    const { id } = event.target;
    // eslint-disable-next-line no-shadow
    let column;

    let searchValue;
    if (id === 'active' || id === 'inactive') {
      // Active filter
      searchValue = id === 'active' ? 'true' : 'false';
      column = 'active';
    } else if (id === 'clear') {
      // Clear filters
      searchValue = '';
      column = false;
    } else {
      // Role filter
      searchValue = id;
      column = 'role';
    }
    setFilterOptions(searchValue, column);

    handleClose(event);
  };

  const handleCreateUser = () => {
    setOpenCreateUser(true);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Toolbar className={styles.toolbarTable}>
      <Typography
        className={styles.title}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        <MuiButton
          id="filter-button"
          ref={anchorRef}
          aria-controls={open ? 'menu-list' : undefined}
          aria-haspopup="true"
          variant="contained"
          onClick={handleToggle}
        >
          <svg style={{ width: '20px', height: '20px' }} viewBox="0 0 24 24">
            <path fill="currentColor" d="M14,12V19.88C14.04,20.18 13.94,20.5 13.71,20.71C13.32,21.1 12.69,21.1 12.3,20.71L10.29,18.7C10.06,18.47 9.96,18.16 10,17.87V12H9.97L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L14.03,12H14Z" />
          </svg>
          {t('users.tableToolbar.button')}
        </MuiButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          className={styles.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list">
                    <MenuItem disabled className={styles.titleOption}>{t('users.tableToolbar.active')}</MenuItem>
                    <MenuItem
                      id="active"
                      onClick={handleSelectFilter}
                      className={styles.activeOption}
                    >
                      {t('users.tableToolbar.active')}
                    </MenuItem>
                    <MenuItem
                      id="inactive"
                      onClick={handleSelectFilter}
                      className={styles.activeOption}
                    >
                      {t('users.tableToolbar.inactive')}
                    </MenuItem>
                    <MenuItem disabled className={styles.titleOption}>{t('users.tableToolbar.role')}</MenuItem>
                    <MenuItem
                      id="superAdmin"
                      onClick={handleSelectFilter}
                      className={styles.roleOption}
                    >
                      SUPER_ADMIN
                    </MenuItem>
                    <MenuItem
                      id="client"
                      onClick={handleSelectFilter}
                      className={styles.roleOption}
                    >
                      CLIENT
                    </MenuItem>
                    <MenuItem
                      id="owner"
                      onClick={handleSelectFilter}
                      className={styles.roleOption}
                    >
                      OWNER
                    </MenuItem>
                    <MenuItem
                      id="scout"
                      onClick={handleSelectFilter}
                      className={styles.roleOption}
                    >
                      SCOUT
                    </MenuItem>
                    <MenuItem
                      id="clear"
                      onClick={handleSelectFilter}
                      className={styles.clearOption}
                    >
                      {t('users.tableToolbar.clear')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <TextField
          id="search"
          variant="outlined"
          value={searchText}
          onChange={(event) => setFilterOptions(event.target.value, ['id', 'full_name', 'email'])}
          placeholder={t('users.tableToolbar.filterPlaceholder')}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                id="clear"
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: searchText ? 'visible' : 'hidden' }}
                onClick={() => setFilterOptions('', false)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Typography>
      <Grid container xs={6} spacing={1} justifyContent="flex-end">
        <Grid item>
          {numSelected > 0 ? (
            <Button
              id="delete-user-btn"
              text={t('users.userInfo.titleDeleteUser')}
              height="40px"
              width="120px"
              backgroundColor='theme-secondary-blue'
              color='theme-primary-white'
              borderRadius='10px'
              onClick={() => handleDeleteDialog(true)}
            />
          ) : <Button
            id="delete-user-btn"
            text={t('users.userInfo.titleDeleteUser')}
            height="40px"
            width="120px"
            backgroundColor='theme-secondary-blue'
            color='theme-primary-white'
            borderRadius='10px'
            onClick={() => handleDeleteDialog(true)}
            disabled
          />}
        </Grid>
        <Grid item>
          <Button
            id="create-user-btn"
            text={t('users.addButton')}
            height="40px"
            width="120px"
            backgroundColor='theme-secondary-blue'
            color='theme-primary-white'
            borderRadius='10px'
            onClick={handleCreateUser}
          />
        </Grid>
        <Grid item>
          <Button
            text={t('excel.getExcel')}
            height="40px"
            width="120px"
            backgroundColor='theme-secondary-blue'
            color='theme-primary-white'
            borderRadius='10px'
            onClick={(event) => {
              getExcel(event, page, rowsPerPage, filter, column);
            }}
          />
        </Grid>
        <Grid item>
          <Button
            text={t('users.userProjects.userProjectAssociation')}
            height="40px"
            width="120px"
            backgroundColor='theme-secondary-blue'
            color='theme-primary-white'
            borderRadius='10px'
            onClick={() => { window.location.href = '/user-projects'; }}
          />
        </Grid>
      </Grid>
      {openCreateUser ? (
        <Modal title={t('users.userInfo.titleNewUser')} defaultOpen={openCreateUser} setCurrentOpen={setOpenCreateUser}>
          <Register update={updateComponent} />
        </Modal>
      ) : null}
    </Toolbar>
  );
}

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  users: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& svg': {
      width: '40px',
      height: '40px',
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  reportName: {
    paddingLeft: '1%',
    marginLeft: '10px',
    textDecoration: 'none',
    '&:hover': {
      color: '#000000',
      textDecoration: 'underline #000000',
    },
  },
  icon: {
    '& svg': {
      color: theme.palette.primary.main,
    },
    '& svg:hover': {
      color: theme.palette.primary.blue,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  toolbarTable: {
    '& .MuiOutlinedInput-root': {
      height: '35px',
      fontSize: '13px',
      width: '100vh',
      flexGrow: 1,
    },
    '& .Mui-focused': {
      border: `1px solid ${theme.palette.secondary.gray}`,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.gray,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '2px',
    },
    '& button': {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.secondary.gray,
      fontSize: '13.5px',
      '& svg': {
        color: theme.palette.secondary.gray,
      },
    },
    '& .MuiMenuItem-root': {
      color: 'black',
    },
  },
  boxTitle: {
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    paddingBottom: '20px',
  },
  title: {
    flexGrow: 1,
    letterSpacing: '-0.5px',
  },
  underlined: {
    textDecoration: 'underline',
  },
  reportTable: {
    marginTop: '10px',
    '& .MuiPaper-root': {
      paddingTop: '50px',
    },
    '& .MuiTableCell-root': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-paddingNone': {
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: 'rgb(44, 157, 201, 0.05)',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(44, 157, 201, 0.05)',
    },
  },
  tableHeader: {
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
    '& .MuiCheckbox-indeterminate ': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
  },
  progress: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  cardProgress: {
    display: 'flex',
    justifyContent: 'center',
  },
  reportCard: {
    height: '100%',
  },
  noReports: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '50px',
    '& svg': {
      width: '40px',
      height: '40px',
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
    reportGrid: {
      width: '350px',
      minWidth: '350px',
    },
    reportPages: {
      paddingTop: '100px',
    },
  },
  lowWarning: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '28%',
    backgroundColor: 'rgba(255,221,80,0.95)',
    color: 'white',
    padding: '10px',
  },
  highWarning: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '28%',
    backgroundColor: 'rgba(201, 76, 76, 0.95)',
    color: 'white',
    padding: '10px',
  },
  divider: {
    marginTop: '5px',
    marginBottom: '5px',
  },
}));

export default useStyles;

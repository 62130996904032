// types
const SHORT_ANSWER = 'short answer';
const LONG_ANSWER = 'long answer';
const SIMPLE_SELECTION = 'simple selection';
const MULTIPLE_SELECTION = 'multiple selection';
const SATISFACTION = 'satisfaction';
const DROP_DOWN_MENU = 'drop down menu';
const IMAGE = 'image';
const YES_NO_QUESTION = 'yes/no question';
const NUMERIC_ANSWER = 'numeric answer';
const DATE = 'date';
const CONTENT = 'content';
const NPS_QUESTION = 'nps question';
const MULTIPLE_IMAGE_QUESTION = 'multiple image question';
const SIGNATURE_QUESTION = 'signature question';
const BARCODE_QUESTION = 'barcode question';
const DECIMAL_ANSWER = 'decimal answer';
const PRICE = 'price';

// identifier
const FIRST_IDENTIFIER = '1';

// Welcome
const WELCOME_PAGE = 'welcome page';

// End page
const END_PAGE = 'end page';

// Resume
const RESUME_PAGE = 'resume page';

export {
  SHORT_ANSWER,
  LONG_ANSWER,
  SIMPLE_SELECTION,
  MULTIPLE_SELECTION,
  SATISFACTION,
  DROP_DOWN_MENU,
  FIRST_IDENTIFIER,
  IMAGE,
  YES_NO_QUESTION,
  NUMERIC_ANSWER,
  DATE,
  WELCOME_PAGE,
  RESUME_PAGE,
  END_PAGE,
  CONTENT,
  NPS_QUESTION,
  MULTIPLE_IMAGE_QUESTION,
  SIGNATURE_QUESTION,
  BARCODE_QUESTION,
  DECIMAL_ANSWER,
  PRICE,
};

import React from 'react';

import { Tab, Tabs } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  groupTable: {
    '& .MuiTableCell-root': {
      border: 'none',
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-paddingNone': {
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& .MuiCheckbox-root': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableRow-root': {
      borderBottom: `1px solid ${theme.palette.primary.snow}`,
      '&:hover': {
        backgroundColor: 'rgb(44, 157, 201, 0.05)',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(44, 157, 201, 0.05)',
    },
  },
  iconsCell: {
    display: 'flex',
    '& button': {
      color: theme.palette.primary.gray,
    },
    '& button:last-child': {
      color: theme.palette.secondary.blue,
    },
  },
  roleCells: {
    fontSize: '11px',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '24px',
    marginBottom: '10px',
  },
  toolbarTable: {
    paddingLeft: '0px',
    justifyContent: 'space-between',
  },
  toolbarButton: {
    justifyContent: 'right',
  },
  toolbarButtons: {
    display: 'table-cell',
    '& button': {
      marginRight: '10px',
      '& svg': {
        color: theme.palette.secondary.gray,
      },
    },
    '& .MuiOutlinedInput-root': {
      height: '35px',
      fontSize: '13px',
    },
    '& .MuiFormControl-root': {
      display: 'grid',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.gray,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '2px',
    },
  },
  toolbarContainer: {
    display: 'flex',
    marginTop: '8px',
  },
  titleOption: {
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
  },
  roleOption: {
    fontSize: '12px',
  },
  activeOption: {
    fontSize: '13.5px',
  },
  clearOption: {
    fontSize: '13.5px',
    borderTop: `1px solid ${theme.palette.secondary.gray}`,
  },
  noContent: {
    height: '710px',
  },
  progress: {
    height: '610px',
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  groupInfo: {
    display: 'grid',
    gridTemplateColumns: '45% 45%',
    columnGap: '5%',
    padding: '30px',
    paddingTop: '8px',
    width: '70vw',
    borderRadius: '10px',
    '& .MuiInputLabel-formControl': {
      transform: 'none',
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.secondary.darkGray,
      fontSize: '14px',
    },
    '& .MuiFormControl-root': {
      marginTop: '25px',
    },
    '& input': {
      padding: '10px',
    },
    '& .MuiSelect-root': {
      padding: '10px',
      fontSize: '13.5px',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },
  },
  itemList: {
    fontSize: '13.5px',
    color: 'black',
  },
  role: {
    gridColumn: 1,
  },
  selectOulined: {
    marginTop: '26px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'end',
    gridColumn: 2,
    marginTop: '25px',
    '& button': {
      width: '50%',
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.secondary.blue,
      borderRadius: '10px',
    },
  },
  buttonColumn: {
    gridRow: 7,
  },
  groups: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& svg': {
      width: '40px',
      height: '40px',
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      marginTop: '0px',
      color: theme.palette.primary.gray,
    },
    '& .MuiInputBase-root': {
      color: theme.palette.primary.main,
      marginTop: '0px',
      fontSize: '24px',
      fontWeight: 'bold',
    },
    '& .MuiSelect-root': {
      fontWeight: '100',
    },
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  span: {
    borderBottom: `1px solid ${theme.palette.secondary.blue}`,
    color: theme.palette.secondary.blue,
  },
  tableHeader: {
    color: theme.palette.secondary.gray,
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
    '& .MuiCheckbox-indeterminate ': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
    '& .MuiTableSortLabel-icon': {
      fontSize: '13px',
    },
  },
  editDeleteButtons: {
    marginRight: '20px',
    '& .MuiIconButton-root': {
      width: '35px',
      '&:hover': {
        backgroundColor: theme.palette.primary.white,
      },
    },
  },
  info: {
    boxShadow: '0px 5px 12px 0px rgba(0,0,0,0.02)',
  },
  divider: {
    backgroundColor: theme.palette.primary.snow,
    height: '30px',
    '& .MuiTableCell-root': {
      display: 'table-cell',
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
    },
  },
  loadingGroupContainer: {
    height: '100vh',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& p': {
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
    },
  },
  linearProgress: {
    margin: '0 auto',
    width: '400px',
  },
  emptySpace: {
    color: theme.palette.secondary.blue,
    opacity: '0.6',
  },
  backButton: {
    width: '80px',
    paddingLeft: '0px',
    color: theme.palette.secondary.middleGray,
    '& span': {
      fontSize: '13px',
    },
  },
  searchBar: {
    backgroundColor: theme.palette.primary.white,
    '& input': {
      padding: '5px',
    },
  },
  groupForm: {
    display: 'grid',
    gridTemplateColumns: '47% 47%',
    columnGap: '5%',
    padding: '30px 60px 55px',
    width: '70vw',
    borderRadius: '10px',
    '& label': {
      color: theme.palette.primary.darkGray,
      fontSize: '13px',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'none',
    },
    '& .MuiInput-root': {
      border: `1px solid ${theme.palette.primary.lightGray}`,
      borderRadius: '5px',
      marginTop: '27px',
      padding: '5px',
      paddingLeft: '10px',
    },
    '& .MuiInput-root.Mui-focused': {
      border: `1px solid ${theme.palette.secondary.blue}`,
      borderRadius: '5px',
    },
    '& .MuiInput-input': {
      textAlign: 'left',
      fontSize: '14px',
      paddingLeft: '7px',
    },
    '& label.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.secondary.blue,
    },
  },
  buttonsContainerModal: {
    gridColumn: 2,
    gridRow: 2,
    marginTop: '25px',
    '& button': {
      fontWeight: 'bold',
      width: '160px',
      borderRadius: '10px',
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.secondary.blue,
    },
  },
  buttonsModal: {
    display: 'flex',
    justifyContent: 'end',
  },
}));

export const StyledTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.secondary.blue,
  },
}))(Tabs);

export const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: '75px',
    textTransform: 'none',
    padding: '0px 8px',
    margin: '0px 10px',
    color: theme.palette.primary.gray,
  },
}))((props) => <Tab disableRipple {...props} />);

export default useStyles;

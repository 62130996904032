import React, { useEffect, useState } from 'react';
import { Modal as MuiModal } from '@material-ui/core/';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Paper from '@material-ui/core/Paper';
import Button from '../Button';
import useStyles from './styles';

function Modal({
  id,
  children,
  titleButton,
  title,
  defaultOpen,
  setOpenEditModal,
  hideButton,
  view,
  setCurrentOpen,
  table,
  ...props
}) {
  const classes = useStyles(props);
  const [open, setOpen] = useState(defaultOpen || false);

  useEffect(() => {
    if (defaultOpen) setOpen(true);
    if (!defaultOpen && table) setOpen(false);
  }, [defaultOpen, open, table]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (defaultOpen && setCurrentOpen) setCurrentOpen(false);
    setOpen(false);
    if (setOpenEditModal) {
      setOpenEditModal(false);
    }
  };

  const handleIcon = () => {
    if (hideButton || setCurrentOpen) {
      return null;
    }

    if (!view) {
      return (
        <Button
          id="submit-ticket-btn"
          onClick={handleOpen}
          text={titleButton}
          height="40px"
          width="130px"
          backgroundColor='theme-secondary-blue'
          color='theme-primary-white'
          borderRadius='10px'
        />
      );
    }
    return (
      <IconButton onClick={handleOpen}>
        <MoreHoriz
          id="view"
          className={classes.icon}
        />
      </IconButton>
    );
  };

  function handleToolbar() {
    if (!view) {
      return (
        <Toolbar className={classes.modalToolbar}>
          <Typography align='left' className={classes.modalTitle} variant='h6'>{title}</Typography>
          <IconButton onClick={handleClose}>
            <ClearIcon fontSize="small" className={classes.loading} />
          </IconButton>
        </Toolbar>
      );
    } return <></>;
  }

  return (
    <div>
      {handleIcon()}
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paperContainer} >
            {handleToolbar()}
            <hr />
            {children}
          </Paper>
        </Fade>
      </MuiModal>
    </div>
  );
}
// No proptypes needed yet

export default Modal;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import { Typography } from '@material-ui/core';
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import Snackbar from '../../components/Snackbar';

import useStyles from './styles';
// services
import AuthService from '../../services/authService';

const RecoverPassword = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSend = async () => {
    setMessage('');
    try {
      setLoading(true);
      if (!email) {
        setSeverity('warning');
        setMessage(t('recoverPassword.messages.requiredEmail'));
        setLoading(false);
        return;
      }

      const response = await AuthService.recoverPassword(email);

      if (!response.success) {
        setSeverity('error');
        setMessage(response.errorMessage);
        setLoading(false);
        return;
      }

      setSeverity('success');
      setMessage(t('recoverPassword.messages.success'));
    } catch (e) {
      setSeverity('error');
      setMessage(t('recoverPassword.messages.error'));
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !loading) {
      handleSend();
    }
  };

  return (
    <div className={styles.resetPasswordWrapper}>
      <div className={styles.resetPassword}>
        <img className={styles.image} src="/TCG_logo.png" alt="TCG logo" />
        <Typography variant="h5" className={styles.title} align="center">{t('recoverPassword.title')}</Typography>
        <div>
          <TextField
            id="email"
            type="text"
            label={t('recoverPassword.labels.email')}
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <Button
          id="submit-btn"
          backgroundColor="#1b5d77"
          color="#FFFFFF"
          onClick={handleSend}
          text={t('recoverPassword.button')}
          height="40px"
          width="100%"
        />
        <p className={styles.passwordLink}>
          <a href="/login">{t('recoverPassword.links.goBack')}</a>
        </p>
      </div>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div>
  );
};

export default RecoverPassword;

import React from 'react';
import { useTranslation } from 'react-i18next';

// core
import {
  Toolbar,
} from '@material-ui/core';

// components
// import { Link } from 'react-router-dom';
import Button from '../../components/Button';

// requests
import { postNewUserGroup } from '../../requests/api/userGroup';
import { postNewListing } from '../../requests/api/listing';
import { postNewCluster } from '../../requests/api/cluster';

import useStyles, { StyledTab, StyledTabs } from './styles';

function MainToolbar({
  tab,
  create,
  setCreate,
  groupToView,
  setGroupToView,
  elementType,
  groupInfo,
  groupData,
  inputValuesGroup,
  setInputValuesGroup,
  setMessage,
  setSeverity,
  handleCreate,
  handleChangeTab,
  saveChanges,
  listing,
  setListing,
  setGroupInfo,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const handleSaveNew = async () => {
    let response;

    const elementsIdArray = groupInfo.inTheGroup.map((element) => element.id);
    switch (tab) {
      case 1:
        response = await postNewListing({
          name: inputValuesGroup.name,
          productIds: elementsIdArray,
        });
        break;
      case 2:
        response = await postNewCluster({
          name: inputValuesGroup.name,
          locationIds: elementsIdArray,
          listingId: listing || null,
        });
        break;
      default:
        response = await postNewUserGroup({
          name: inputValuesGroup.name,
          userIds: elementsIdArray,
        });
        break;
    }
    // Cleans values after creation.
    setInputValuesGroup({
      name: '',
    });
    setGroupInfo({
      inTheGroup: [],
      notInTheGroup: [],
    });
    setGroupToView(null);
    setCreate(false);

    if (listing) setListing('');

    if (response.success) {
      setSeverity('success');
      setMessage(t('groups.success.savingGroup'));

      setGroupToView(null);
      setCreate(false);
    } else {
      setSeverity('error');
      setMessage(t('groups.errors.creatingGroup'));
    }

    // Actualizar vista
    switch (tab) {
      case 1:
        handleChangeTab(null, 1);
        break;
      case 2:
        handleChangeTab(null, 2);
        break;
      default:
        handleChangeTab(null, 0);
        break;
    }
  };

  return (
    <Toolbar className={`${styles.toolbarTable} ${create || groupToView ? styles.toolbarButton : ''}`}>
      {!create && !groupToView && (
        <StyledTabs
          value={tab}
          variant="scrollable"
          onChange={handleChangeTab}
        >
          <StyledTab label={t('groups.groupsTabs.users')} />
          <StyledTab label={t('groups.groupsTabs.products')} />
          <StyledTab label={t('groups.groupsTabs.locations')} />
        </StyledTabs>
      )}
      {create && (
        <Button
          id="save-btn"
          variant="outlined"
          text={t('groups.createButton', { elementType })}
          height="40px"
          width="220px"
          backgroundColor='theme-secondary-blue'
          color='white'
          borderRadius='10px'
          onClick={handleSaveNew}
        />
      )}
      {!create && groupToView && (
        <Button
          id="save-btn"
          variant="outlined"
          text={t('groups.saveChanges')}
          height="40px"
          width="220px"
          backgroundColor='theme-secondary-blue'
          color='white'
          borderRadius='10px'
          onClick={() => (
            saveChanges(groupToView, inputValuesGroup.name
                || groupData.name, groupInfo.inTheGroup, true, true, listing)
          )}
        />
      )}
      {!groupToView && !create && (
        <Button
          id="create-btn"
          variant="outlined"
          text={t('groups.addButton')}
          height="40px"
          width="150px"
          backgroundColor='theme-secondary-blue'
          color='white'
          borderRadius='10px'
          onClick={handleCreate}
        />
      )}
    </Toolbar>
  );
}

export default MainToolbar;

/* eslint-disable no-underscore-dangle */
import FormData from 'form-data';
import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../utils/const/http';

const getProductsPerPage = async (page, limit, searchValue, column) => {
  try {
    const { data } = await axios.get(`/api/products?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProductsPerPageByCompanyId = async (page, limit, searchValue,
  column, idsInTheGroup) => {
  try {
    const { data } = await axios.get(`/api/product-company?page=${page}&limit=${limit}&searchValue=${searchValue}&column=${column}&idsInTheGroup=${idsInTheGroup}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProduct = async (id) => {
  try {
    const { data } = await axios.get(`/api/products/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const postNewProduct = async (inputValues) => {
  try {
    const { data } = await axios.post('/api/products', inputValues);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const putEditProduct = async (id, inputValues) => {
  try {
    const { data } = await axios.put(`/api/products/${id}`, inputValues);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const deleteProduct = async (id) => {
  try {
    const { data } = await axios.delete(`/api/products/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const updateProductChanges = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const { data } = await axios.post('/api/products/update', formData,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      });

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

const uploadProductFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const { data } = await axios.post('/api/products/upload', formData,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      });

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    const errorMessage = (err.response && err.response.data && err.response.data.data)
      ? err.response.data.data.errorMessage : 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.';

    return {
      success: false,
      status,
      message,
      data: {
        errorMessage,
      },
    };
  }
};

export {
  getProductsPerPage,
  getProductsPerPageByCompanyId,
  postNewProduct,
  putEditProduct,
  getProduct,
  deleteProduct,
  updateProductChanges,
  uploadProductFile,
};

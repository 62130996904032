import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@material-ui/core';

import useStyles from './styles';
import { formatDateToLocalString } from '../../../utils/date';

const Header = ({
  ticketsQuantity, subtitleState, filter, nextUpdateTimeUTC, lastUpdateTimeUTC,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={styles.boxHeader}>
      <Box sx={{ width: '100%' }}>
        <Typography variant='h1' className={styles.title} gutterBottom>
          {t('challenges.title')}
        </Typography>
        <Typography variant='h4' gutterBottom display="inline">
          {t('challenges.subtitle')}
        </Typography>
        <Typography variant='subtitle1' className={styles.underlined} gutterBottom display="inline">
          {ticketsQuantity} tickets {subtitleState.includes(filter) ? t(`support.subtitleState.${filter}`) : ''}
        </Typography>
        {lastUpdateTimeUTC && (
          <Typography variant='h4'>
            {t('challenges.lastUpdateInfo')} {formatDateToLocalString(lastUpdateTimeUTC, t('challenges.nexus'))}
          </Typography>
        )}
        {nextUpdateTimeUTC && (
          <Typography variant='h4'>
            {t('challenges.nextUpdateInfo')} {formatDateToLocalString(nextUpdateTimeUTC, t('challenges.nexus'))}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
// core
import { IconButton } from '@material-ui/core';
// icons
import RightArrow from '@material-ui/icons/ChevronRight';
import ExpandArrow from '@material-ui/icons/ExpandMore';

import useStyles from '../CreateTask/styles';

function TaskAssociationDetail({ taskInstances }) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [taskInstanceDetail, setTaskInstanceDetail] = useState(null);
  const [viewDetail, setViewDetail] = useState(false);

  const handleDetail = (e) => {
    const { id } = e.currentTarget;
    if (!viewDetail || id !== taskInstanceDetail) {
      setTaskInstanceDetail(id);
    } else {
      setTaskInstanceDetail(null);
    }
    setViewDetail((prevState) => !prevState);
  };

  const moreInfoDiv = (taskInstance) => (
    <div
      className={clsx(styles.taskInstanceDetail, {
        [styles.hide]: `${taskInstance.id}` !== taskInstanceDetail,
      })}
    >
      <p>
        {t('task.association.resetScale')}
        <span>{t(`task.association.resetTimes.${taskInstance.resetScale}`) || '-'}</span>
      </p>
      <p>
      {t('task.association.expectedResponses')}
        <span>{taskInstance.expectedLocationResponses || '-'}</span>
      </p>
    </div>
  );

  useEffect(() => {
  }, [taskInstanceDetail, viewDetail]);

  return (
    <>
      <p className={styles.categoryTitle}>Asignada a</p>
      <div>
        {taskInstances && taskInstances.map((taskInstance) => (
          <div className={styles.associationContainer} key={taskInstance.id}>
            <div className={styles.associationDetail}>
              <div>
                <IconButton
                  id={taskInstance.id}
                  className={styles.expandButton}
                  onClick={handleDetail}
                >
                  {viewDetail
                    && `${taskInstance.id}` === taskInstanceDetail ? (
                      <ExpandArrow fontSize="small" />
                    ) : (
                      <RightArrow fontSize="small" />
                    )}
                </IconButton>
                <p
                  className={styles.location}
                >
                  {`${taskInstance.User.fullName} - DNI ${taskInstance.User.dni}`}
                  <br/>
                  <span>
                    {taskInstance.Location ? `${taskInstance.Location.name}, ${taskInstance.Location.chain}` : ''}
                  </span>
                </p>
              </div>
              <p
                className={clsx({
                  [styles.active]: taskInstance.active,
                  [styles.inactive]: !taskInstance.active,
                })}
              >
                {taskInstance.active
                  ? t('task.association.active')
                  : t('task.association.inactive')}
              </p>
            </div>
            {moreInfoDiv(taskInstance)}
          </div>
        ))}
      </div>
    </>
  );
}

export default TaskAssociationDetail;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// core
import {
  Typography,
  Box,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';

// components
import useStyles from './styles';
import {
  getSurveyResponsesTeleaudit,
  getSurveyResponsesReaudit,
} from '../../../requests/api/survey-response';
import { UNAUTHORIZED } from '../../../utils/const/http';
import ExpandableRowTable from './surveyExpandableTable';

const SurveyResponsesTeleauditView = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const page = 0;
  const rowsPerPage = 10;
  const [categoriesQuantity, setCategoriesQuantity] = useState(0);
  const [categories, setCategories] = useState([]);
  const [reauditCategoriesQuantity, setReauditCategoriesQuantity] = useState(0);
  const [reauditCategories, setReauditCategories] = useState([]);
  const [locationName, setLocationName] = useState('');
  const [questionResponsesDone, setQuestionResponsesDone] = useState([]);
  const { taskResponseId } = useParams();

  // auxiliar states

  const requestSearch = async (pageOfTable, limit) => {
    const response = await getSurveyResponsesTeleaudit(
      { id: taskResponseId, page: pageOfTable, limit },
    );
    if (response.success) {
      setCategories(response.data.data.categories);
      setCategoriesQuantity(Object.keys(response.data.data.categories).length);
      setQuestionResponsesDone(response.data.data.questionsDone);
      if (response.data.data.taskResponse.TaskInstance.Location) {
        setLocationName(response.data.data.taskResponse.TaskInstance.Location.name);
      }
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const requestSearchReaudit = async (pageOfTable, limit) => {
    const response = await getSurveyResponsesReaudit(
      { id: taskResponseId, page: pageOfTable, limit },
    );
    if (response.success) {
      setReauditCategories(response.data.data.categories);
      setReauditCategoriesQuantity(Object.keys(response.data.data.categories).length);
    }
    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  useEffect(() => {
    requestSearch(page, rowsPerPage);
    requestSearchReaudit(page, rowsPerPage);
  }, [page, rowsPerPage, categoriesQuantity]);

  return (
    <>
      <div className={styles.content}>
        <div className={styles.header}>
          <Box sx={{ width: '100%' }}>
            <Typography variant='h1' className={styles.title} gutterBottom>
            {locationName}
            </Typography>
            <Typography variant='h4' gutterBottom>
              {t('teleaudit.categoriesIndex.subtitle1')}
            </Typography>
            <Typography variant='h4' gutterBottom display="inline">
              {locationName}
              {t('teleaudit.categoriesIndex.subtitle2.start')}
              {categoriesQuantity}
              {t('teleaudit.categoriesIndex.subtitle2.end')}
            </Typography>
          </Box>
        </div>
      <ExpandableRowTable
        data={categories}
        categoriesQuantity={categoriesQuantity}
        questionResponsesDone={questionResponsesDone}
        reaudit={false}
        taskResponseId={taskResponseId}
      />
      <div className={styles.header}>
        <Box sx={{ width: '100%' }} pt={10}>
          <Typography variant='h4' gutterBottom>
            {t('teleaudit.subtitleReaudit')}
          </Typography>
          <Typography variant='h4' gutterBottom display="inline">
            {locationName}
            {t('teleaudit.has')}
            {categoriesQuantity}
            {t('teleaudit.lastReauditSubtitle')}
          </Typography>
        </Box>
      </div>
      <ExpandableRowTable
        data={reauditCategories}
        categoriesQuantity={reauditCategoriesQuantity}
        questionResponsesDone={questionResponsesDone}
        reaudit={true}
        taskResponseId={taskResponseId}
        />
      </div>
    </>
  );
};

export default SurveyResponsesTeleauditView;
